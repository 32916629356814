import { useState } from "react";
import AddressInputs from "./SharedFields/AddressWithNameField";
import FromDate from "./SharedFields/FromDate";
import { MedicalProvider } from "..";
import { Form, Select } from "antd";
import { MedicineBoxOutlined } from "@ant-design/icons";

interface MedicalRecordsRequestProps {
  form: any;
  providers: MedicalProvider[];
}

const MedicalRecordsRequest: React.FC<MedicalRecordsRequestProps> = ({
  form,
  providers,
}) => {
  const [providerSelected, setProviderSelected] = useState(false);

  const handleProviderSelect = (providerId?: number) => {
    if (!providerId) {
      form.resetFields([
        "addressName",
        "addressLine1",
        "city",
        "state",
        "country",
        "line1",
        "line2",
        "zip",
      ]);
      setProviderSelected(false);
      return;
    }

    const provider = providers.find((p) => p.id === providerId);
    if (!provider) return;

    const [addressLine1, city, state, country] = provider.physicianAddress
      .split(", ")
      .map((item) => item.trim());

    form.setFieldsValue({
      addressName: provider.hospitalName,
      addressLine1,
      city,
      state,
      country,
      line1: addressLine1,
      line2: provider.addressTwo,
      zip: provider.zip,
    });

    setProviderSelected(true);
  };

  return (
    <>
      <Form.Item label='Fill provider address from template'>
        <Select
          prefix={<MedicineBoxOutlined />}
          style={{ maxWidth: 300 }}
          placeholder='Select a provider'
          onChange={handleProviderSelect}
          allowClear
        >
          {providers.map((provider) => (
            <Select.Option key={provider.id} value={provider.id}>
              {provider.hospitalName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <AddressInputs
        label='Provider Name'
        disabledAddressFields={providerSelected}
      />
      <FromDate />
    </>
  );
};

export default MedicalRecordsRequest;
