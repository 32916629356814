import axios from "axios";

export function GetPipeline() {
  return axios.get("Pipeline");
}
export function GetAllPipeline() {
  return axios.get("AllPipeline");
}
export function GetPipelineStatuses() {
  return axios.get("GetAllPipelineStatuses");
}
export function PipelineStatusDetails() {
  return axios.get("PipelineStatusDetails");
}
export function UpdateApplicationStatus(userId: any, id: any) {
  return axios.put("ApplicationStatus?UserId=" + userId + "&statusId=" + id);
}
export function GetLeadPipeline() {
  return axios.get("LeadPipeline");
}

export function GetConcurrentUsersForLead(leadId: any) {
  return axios.get("GetConcurrentUserIdsForLead?leadId=" + leadId);
}
export function UpdateLeadApplicationStatus(
  leadId: any,
  statusId: any,
  reasonNotQualified: any
) {
  return axios.put(
    "LeadApplicationStatus?leadId=" +
      leadId +
      "&statusId=" +
      statusId +
      "&reasonNotQualified=" +
      reasonNotQualified
  );
}
export function DeactivateLead(LeadId: any) {
  return axios.delete("DeactivateLead?LeadId=" + LeadId);
}
export function GetStatusByPipelineId(Id: any) {
  return axios.get("StatusByPipelineId?Id=" + Id);
}
export function UpdateAssign_ReAssignEnterpriseLeads(
  leadId: any,
  tenantId: any
) {
  return axios.put(
    "Assign_ReAssignEnterpriseLeads?leadId=" + leadId + "&tenantId=" + tenantId
  );
}
export function UpdateAssign_ReAssignEnterpriseClaimants(
  claimantId: any,
  tenantId: any
) {
  return axios.put(
    "Assign_ReAssignEnterpriseClaimants?claimantId=" +
      claimantId +
      "&tenantId=" +
      tenantId
  );
}
