import { useState, useEffect } from "react";
import {
  Form,
  Table,
  Select,
  DatePicker,
  Button,
  Flex,
  Typography,
} from "antd";
import { Constants } from "../../common/constants";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { getAllClients } from "../../apis/caseManagement";
import { get } from "../../utils/clientStorageUtils";
import { useForm } from "antd/lib/form/Form";
import { getSupportUsers } from "../../actions/supportUser";
import usePermissions from "../../common/customHooks/usePermissions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const openInNewTab = (url: any) => {
  window.open(url);
};

interface Client {
  applicationStatus: string;
  caseManagerName: string;
  encryptedId: string;
  firstName: string;
  lastName: string;
  lastModifiedDate: string;
  lastAssignedDate: string;
  createdDate: string;
}

export interface SupportUserResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  phoneNumber: string;
  supportUserType: string;
  encryptedId: string;
  createdDate: string;
}
export interface SupportUser extends SupportUserResponse {
  fullName: string;
}

const defaultSortDirection = [
  "ascend" as const,
  "descend" as const,
  "ascend" as const,
];

const supportUserTypes = [
  Constants.appUserRoles.caseManager,
  Constants.appUserRoles.admin,
  Constants.appUserRoles.superUser,
  Constants.appUserRoles.intakeMsr,
];

const CaseManagement = () => {
  const hasWritePermission = usePermissions("caseManagement");
  const userData = JSON.parse(get("model"));
  const [form] = useForm();
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(Constants.caseDefaultPageSize);
  const dateFormat = "MM/DD/YYYY";
  const [orderBy, setOrderBy] = useState("-modified_date");
  const [isLoader, setIsLoader] = useState(false);
  const [clients, setClients] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [supportUsers, setSupportUsers] = useState<SupportUser[]>([]);
  const [supportUserLoading, setSupportUserLoading] = useState(false);
  const [filter, setFilter] = useState({
    lawyer: -1,
    supportUser: hasWritePermission ? -1 : userData.userId,
    startDate: undefined,
    endDate: undefined,
  });

  const loadClients = () => {
    const formattedStartDate =
      filter.startDate && dayjs(filter.startDate).isValid()
        ? dayjs(filter.startDate).format("YYYY-MM-DD")
        : undefined;

    const formattedEndDate =
      filter.endDate && dayjs(filter.endDate).isValid()
        ? dayjs(filter.endDate).format("YYYY-MM-DD")
        : undefined;

    setIsLoader(true);
    getAllClients(
      orderBy,
      pageNum,
      pageSize,
      filter.supportUser,
      filter.lawyer,
      formattedStartDate,
      formattedEndDate,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    ).then((res) => {
      const response = res.data;
      console.log(response);
      setClients(
        response.data.map((client: Client) => ({
          fullName: `${client.firstName} ${client.lastName}`,
          ...client,
        }))
      );
      setTotalCount(response.totalCount);
      setIsLoader(false);
    });
  };

  const compareByName = (a: SupportUser, b: SupportUser) => {
    const nameA = a.fullName.toUpperCase(); // ignore case
    const nameB = b.fullName.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  const getSupportUsersApi = () => {
    setSupportUserLoading(true);
    getSupportUsers(true).then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        setSupportUsers(
          userdata.map((user: SupportUserResponse) => {
            return {
              ...user,
              fullName: `${user.firstName} ${user.lastName}`,
            };
          })
        );
        setSupportUserLoading(false);
      }
    });
  };

  useEffect(() => {
    getSupportUsersApi();
  }, []);

  useEffect(() => {
    loadClients();
  }, [pageNum, orderBy, filter, pageSize]);

  const columns = [
    {
      title: "Assigned Case Manager",
      dataIndex: "caseManagerName",
      key: "caseManagerName",
      sorter: true,
      sortDirections: defaultSortDirection,
      render: (item: any, file: any) =>
        item === "" ? (
          <span style={{ fontWeight: "bold" }}>Unassigned</span>
        ) : (
          item
        ),
    },
    {
      title: "Assigned Lawyer",
      dataIndex: "lawyerName",
      key: "lawyerName",
      sorter: true,
      sortDirections: defaultSortDirection,
      render: (item: any, file: any) =>
        item === "" ? (
          <span style={{ fontWeight: "bold" }}>Unassigned</span>
        ) : (
          item
        ),
    },
    {
      title: "Claimant Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      sortDirections: defaultSortDirection,
      render: (item: any, file: any) => {
        return (
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              openInNewTab(
                `/${Constants.pageRoutes.claimantProfile}?id=${file.encryptedId}`
              )
            }
          >
            {item}
          </span>
        );
      },
    },
    {
      title: "Last Assigned Date",
      dataIndex: "lastAssignedDate",
      key: "lastAssignedDate",
      defaultSortOrder: "descend" as const,
      sorter: true,
      render: (item: any, file: any) => {
        return item
          ? dayjs.utc(item).local().format(Constants.dayJsDateTimeFormat)
          : "";
      },
      sortDirections: defaultSortDirection,
    },
    {
      title: "Pipeline Status",
      dataIndex: "applicationStatus",
      key: "applicationStatus",
      sorter: true,
      sortDirections: defaultSortDirection,
    },
  ];
  return (
    <>
      <Typography.Title level={3}>Cases</Typography.Title>
      <Form
        form={form}
        name='SupportUserForm'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout='vertical'
        autoComplete='off'
        onFinish={(e) => setFilter(e)}
        initialValues={filter}
      >
        <Flex wrap='wrap' gap={8} justify='space-between'>
          <div style={{ flex: 1 }}>
            <Form.Item
              label='Case Manager'
              name='supportUser'
              rules={[{ validator: validateBlankSpace }]}
            >
              <Select
                placeholder='Support Users'
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.trim().toLowerCase())
                }
                defaultValue={hasWritePermission ? -1 : userData.userId}
                loading={supportUserLoading}
                options={
                  !hasWritePermission
                    ? [
                        { label: "My Cases", value: userData.userId },
                        { label: "Unassigned", value: -2 },
                      ]
                    : [
                        { label: "All", value: -1 },
                        { label: "Unassigned", value: -2 },
                        ...supportUsers
                          .filter((user) =>
                            supportUserTypes.includes(user.supportUserType)
                          )
                          .sort(compareByName)
                          .map((user) => ({
                            label: user.fullName,
                            value: user.id,
                          })),
                      ]
                }
              />
            </Form.Item>
          </div>

          <div style={{ flex: 1 }}>
            <Form.Item
              label='Lawyer'
              name='lawyer'
              rules={[{ validator: validateBlankSpace }]}
            >
              <Select
                placeholder='Lawyers'
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.trim().toLowerCase())
                }
                defaultValue={-1}
                loading={supportUserLoading}
                options={[
                  { label: "All", value: -1 },
                  { label: "Unassigned", value: -2 },
                  ...supportUsers
                    .filter(
                      (user) =>
                        user.supportUserType === Constants.appUserRoles.lawyer
                    )
                    .sort(compareByName)
                    .map((user) => ({ label: user.fullName, value: user.id })),
                ]}
              />
            </Form.Item>
          </div>

          <div style={{ flex: 1 }}>
            <Form.Item label='From' name='startDate'>
              <DatePicker format={dateFormat} />
            </Form.Item>
          </div>

          <div style={{ flex: 1 }}>
            <Form.Item label='To' name='endDate'>
              <DatePicker
                format={dateFormat}
                disabledDate={(d) =>
                  d.isBefore(form.getFieldValue("startDate"))
                }
              />
            </Form.Item>
          </div>
        </Flex>
        <Flex gap={8} justify='flex-end'>
          <Button type='primary' htmlType='submit'>
            Search
          </Button>
          <Button
            htmlType='reset'
            onClick={() =>
              setFilter({
                lawyer: -1,
                supportUser: hasWritePermission ? -1 : userData.userId,
                startDate: undefined,
                endDate: undefined,
              })
            }
          >
            Clear
          </Button>
        </Flex>
      </Form>

      <Table
        dataSource={clients}
        pagination={{
          current: pageNum,
          pageSize: pageSize,
          onChange: (page) => {
            setPageNum(page);
          },
          total: totalCount,
          onShowSizeChange: (currnet, size) => {
            setPageSize(size);
            setPageNum(1);
          },
          showSizeChanger: true,
          showTotal: (total) => {
            return `Total Count:  ${total}`;
          },
        }}
        sortDirections={["ascend", "descend"]}
        style={{ marginTop: "40px" }}
        loading={isLoader}
        columns={columns}
        onChange={(pagination, filters, sorter: any) => {
          setOrderBy(
            `${sorter.order === "descend" ? "-" : ""}${sorter.columnKey}`
          );
        }}
      />
    </>
  );
};

export default CaseManagement;
