import { Tag, Tooltip, Typography, theme } from "antd";
import {
  CheckCircleOutlined,
  FileTextOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { ReactNode } from "react";

const { Paragraph, Text } = Typography;
const { useToken } = theme;

interface ClaimBadgeProps {
  claim: any;
}

const ClaimBadge: React.FC<ClaimBadgeProps> = ({ claim }) => {
  const { token } = useToken();
  const displayName = claim.ClaimTypeIdDisplayNameReadOnly;

  const createTag = (
    label: string,
    icon: ReactNode,
    color?: string | undefined,
    tooltipTitle?: ReactNode
  ) => {
    const tag = (
      <Tag
        color={color}
        icon={icon}
        className='claim-badge'
        data-cy={`claim-badge-${displayName}`}
      >
        {label}
      </Tag>
    );

    return tooltipTitle ? (
      <Tooltip
        color={token.colorInfo}
        title={tooltipTitle}
        placement='right'
        mouseEnterDelay={0.25}
      >
        {tag}
      </Tooltip>
    ) : (
      tag
    );
  };

  if (claim.isEreSynced) {
    return createTag(
      "Synced with ERE",
      <CheckCircleOutlined />,
      "green",
      <>
        <Paragraph style={{ color: "white", marginBottom: 20 }} strong>
          Claim is present in the latest InitialReconReport from the ERE.
        </Paragraph>

        <Paragraph style={{ color: "white", marginBottom: 0 }}>
          <Text style={{ color: "white" }}>Last ERE Sync</Text>
          <br />
          <Text style={{ color: "white" }} strong>
            {dayjs(claim?.lastEreSyncDateUtc).format("MMMM D, YYYY h:mm A")}
          </Text>
        </Paragraph>
      </>
    );
  }

  if (claim.caseLevelId === 1) {
    return createTag(
      "Intake Incomplete",
      <FileTextOutlined />,
      undefined,
      <>
        <Paragraph style={{ color: "white", marginBottom: 0 }}>
          Submit the Initial Application on SSA.gov or Collect Information on
          the existing claim and click Finalize Intake.
        </Paragraph>
      </>
    );
  }

  if (claim.caseLevelId === 2) {
    if (claim.intakeCompletedByMindsetUserId) {
      return createTag(
        "Claim Not Filed",
        <FileTextOutlined />,
        undefined,
        <>
          <Paragraph style={{ color: "white", marginBottom: 0 }}>
            This claim has not been filed with SSA.
          </Paragraph>
        </>
      );
    }
    if (!claim.Faxed8001ByMindsetUserId) {
      return createTag("8001 Not Faxed", <FileTextOutlined />);
    }
  }

  if (claim.caseLevelId >= 3) {
    return createTag(
      "ERE Sync Pending",
      <SyncOutlined />,
      undefined,
      <>
        <Paragraph style={{ color: "white", marginBottom: 0 }}>
          Waiting for Claim to appear in the latest InitialReconReport from the
          Electronic Records Express.
        </Paragraph>
      </>
    );
  }

  return null;
};

export default ClaimBadge;
