import CustomerLeftPanel from "../components/CustomerLeftPanel";
import ActivityLog from "../components/ActivityLog";
import { BackTop, Layout } from "antd";
import ClaimantProfile from "../components/ClaimantProfile/ClaimantProfile";
import "./customerLayout.scss";

const { Content, Sider } = Layout;

const CustomerLayout = () => {
  return (
    <section id='admin_application_section'>
      <Layout>
        <Sider width={350} collapsedWidth={200} breakpoint="lg">
          <CustomerLeftPanel />
          <ActivityLog />
        </Sider>
        <Content className='profile-content'>
          <ClaimantProfile />
        </Content>

        <div className='back-top-con'>
          <BackTop />
        </div>
      </Layout>
    </section>
  );
};

export default CustomerLayout;
