import React, { useState, useEffect } from "react";
import "./index.scss";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Constants } from "../../common/constants";
import type { MenuProps } from "antd";
import type { SelectProps } from "antd/es/select";
import {
  getLeadPipeline,
  updateLeadApplicationStatus,
  deactivateLead,
  getConcurrentUsersForLead,
  updateAssign_ReAssignEnterpriseLeads,
} from "../../actions/pipeline";
import { FlashMessage } from "../../utils/flash_message";
import { getLeadsById } from "../../actions/application";
import {
  validateBlankSpace,
  validatePhoneNumberFormat,
} from "../../validations/validateBlankSpace";
import {
  Modal,
  Checkbox,
  Menu,
  Form,
  Input,
  AutoComplete,
  Button,
  Alert,
  Select,
  Row,
  Col,
  Skeleton,
} from "antd";
import {
  updateLeadsLeftPanel,
  mapClientWithLead,
} from "../../actions/application";
import { clientsWithNoLeadId } from "../../actions/leadsAndClients";
import { get } from "../../utils/clientStorageUtils";
import Loader from "../../components/Loader";
import {
  leadUserAttributes,
  getLeadUserAttributes,
} from "../../actions/leadsAndClients";
import { validateEmail } from "../../validations/validateEmail";
import * as signalR from "@microsoft/signalr";
import { formatPhoneNumber } from "../../utils/commonHelper";
import { Enums } from "../../common/enums";
import { getTenant } from "../../actions/tenants";
import EnterpriseAssignment from "../../common/components/EnterpriseAssignment";
import ReviewLead from "./components/ReviewLead";
import useFeatureFlag from "../useFeatureFlag";
import dayjs from "dayjs";
import { GetAdminApiBaseUrlFromWindow } from "../../utils/utils";

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
type MenuItem = Required<MenuProps>["items"][number];
const LeadsLeftPanel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [pipeline, setPipeline] = useState([
    {
      pipelineId: "",
      pipelineName: "",
      statusId: "",
      statusName: "",
    },
  ]);
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
  const [resById, setResById] = useState({
    id: null,
    age: 0,
    fullName: "",
    adId: "",
    completedEvalCallTs: "",
    email: "",
    fullTime: "",
    leadSource: "",
    mentalIllness: "",
    phoneNumber: "",
    physicalAilment: "",
    scheduledCallTs: "",
    seenDoc: "",
    timeZone: "",
    locationName: "",
    applicationStatus: "",
    timezoneNew: "",
    reasonForNotQualified: "",
    tenantId: "",
    isDenial: null,
    dateOfBirth: "",
  });
  const [pipelineName, setpipelineName] = useState<any[]>([]);
  const [noLeadIdClients, setNoLeadIdClients] = useState([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [clientsModel, setClientsModel] = useState(false);
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [isSideMenuPopUpLoader, setIsSideMenuPopUpLoader] = useState(false);
  const [isSpanish, setisSpanish] = useState(false);
  const [isCritical, setisCritical] = useState(false);
  const [isDeceased, setisDeceased] = useState(false);
  const [isMinor, setisMinor] = useState(false);
  const [isTerminal, setisTerminal] = useState(false);
  const [isDenial, setIsDenial] = useState(false);
  const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState(false);
  const [concurrentLeadViewers, setConcurrentLeadViewers] = useState([]);
  const [ReasonNotQualified, setReasonNotQualified] = useState(false);
  const [reasonNotQualifiedStore, setReasonNotQualifiedStore] = useState([]);
  const [formReasonNotQuailfied] = Form.useForm();
  const [isReasonNotQualifiedPopUpLoader, setIsReasonNotQualifiedPopUpLoader] =
    useState(false);
  const [
    isaAssign_reassignEnterpriseLeadModel,
    setIsAssign_reassignEnterPriseLeadModel,
  ] = useState(false);
  const [Assign_ReAssignEnterprise, setAssign_ReAssignEnterprise] = useState(
    []
  );
  const [tenants, setTenants] = useState([]);
  const [formAssign_ReAssignEnterprise] = Form.useForm();
  const leadId = searchParams.get("id") ?? "0";
  const [isPopUpEnterpriseLoader, setIsPopUpEnterpriseLoader] = useState(false);
  const onClick: MenuProps["onClick"] = (e) => {
    updateLeadApplicationStatusApi(e.key);
  };

  const featureFlags = useFeatureFlag().featureFlags;

  const getLeadUserAttributesApi = () => {
    getLeadUserAttributes(searchParams.get("id")).then((res) => {
      var resData = res.data.responseData;
      if (res.data.responseCode == 200) {
        setisSpanish(resData?.isSpanish);
        setisCritical(resData?.isCritical);
        setisDeceased(resData?.isDeceased);
        setisMinor(resData?.isMinor);
        setisTerminal(resData?.isTerminal);
        setIsDenial(resData?.isDenial);
        form1.setFieldsValue(resData);
      }
    });
  };

  const leadUserAttributesApi = (
    type:
      | "isSpanish"
      | "isCritical"
      | "isDeceased"
      | "isMinor"
      | "isTerminal"
      | "isDenial",
    value: any
  ) => {
    let payload = {
      Id: searchParams.get("id"),
      IsSpanish: isSpanish,
      IsCritical: isCritical,
      IsDeceased: isDeceased,
      IsMinor: isMinor,
      IsTerminal: isTerminal,
      isDenial: isDenial,
    };
    if (type == "isSpanish") {
      payload.IsSpanish = value;
    }
    if (type == "isCritical") {
      payload.IsCritical = value;
    }
    if (type == "isDeceased") {
      payload.IsDeceased = value;
    }
    if (type == "isMinor") {
      payload.IsMinor = value;
    }
    if (type == "isTerminal") {
      payload.IsTerminal = value;
    }
    if (type == "isDenial") {
      payload.isDenial = value;
    }
    leadUserAttributes(payload).then((res) => {
      if (res.data.responseCode == 200) {
        getLeadUserAttributesApi();
      }
    });
  };

  const onChangeisSpanish = (e: any) => {
    setisSpanish(e.target.checked);
    leadUserAttributesApi("isSpanish", e.target.checked);
  };
  const onChangeisCritical = (e: any) => {
    setisCritical(e.target.checked);
    leadUserAttributesApi("isCritical", e.target.checked);
  };
  const onChangeisDeceased = (e: any) => {
    setisDeceased(e.target.checked);
    leadUserAttributesApi("isDeceased", e.target.checked);
  };
  const onChangeisMinor = (e: any) => {
    setisMinor(e.target.checked);
    leadUserAttributesApi("isMinor", e.target.checked);
  };
  const onChangeisTerminal = (e: any) => {
    setisTerminal(e.target.checked);
    leadUserAttributesApi("isTerminal", e.target.checked);
  };
  const onChangeisDenial = (e: any) => {
    setIsDenial(e.target.checked);
    leadUserAttributesApi("isDenial", e.target.checked);
  };

  const deactivateLeadApi = () => {
    if (window.confirm("Are you sure you want to deactivate this lead?")) {
      deactivateLead(searchParams.get("id") ?? "0").then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", "Lead Deactivated.");
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
        }
        navigate("/leads");
      });
    }
  };

  const getLeadsByIdApi = (id: any) => {
    getLeadUserAttributesApi();
    setIsPopUpLoader(true);
    setIsPopUpEnterpriseLoader(true);
    getLeadsById(searchParams.get("id")).then((res) => {
      var resData = res.data.responseData;
      if (!resData.isActive) {
        FlashMessage(
          "error",
          `This lead (${resData.fullName}) is deactivated.`
        );
        navigate(`/leads?pipelineId=${1}`, { replace: true });
        return;
      }
      if (res.data.responseCode == 200) {
        setResById(resData);

        setIsPopUpLoader(false);
        getLeadPipelineApi(resData.applicationStatus);
        form.setFieldsValue({
          ...resData,
          phoneNumber: formatPhoneNumber(resData.phoneNumber),
        });
        formReasonNotQuailfied.setFieldsValue(resData);
        formAssign_ReAssignEnterprise.setFieldsValue(resData);
        setIsPopUpEnterpriseLoader(false);

        const pipelineId = res?.data?.responseData?.pipelineId;
        const pipelineStatusId = res?.data?.responseData?.pipelineStatusId;
        const searchParams = new URLSearchParams(location.search);

        if (
          searchParams.get("pipelineId") !== pipelineId ||
          searchParams.get("pipelineStatusId") !== pipelineStatusId
        ) {
          searchParams.set("pipelineId", pipelineId);
          searchParams.set("pipelineStatusId", pipelineStatusId);

          navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
          });
        }
      }
    });
  };

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${GetAdminApiBaseUrlFromWindow()}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("updatelead", (res: any) => {
      if (res == searchParams.get("id")) {
        getLeadsByIdApi(res);
      }
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("updatelead");
      connection.stop().catch((err) => console.log("connection Stop Error"));
    };
  }, [searchParams.get("id")]);

  const getLeadPipelineApi = (status: any) => {
    getLeadPipeline().then((res) => {
      var resData = res.data.responseData;
      var uniquePipeline: string[] = [];
      resData.map((item: any) => {
        if (uniquePipeline.indexOf(item.pipelineName) === -1) {
          uniquePipeline.push(item.pipelineName);
        }
      });

      var menuItem = menuItems;
      menuItem = [];
      var items = menuItems;
      items = [];
      uniquePipeline.map((item: any) => {
        var menuSubItem = menuItems;
        menuSubItem = [];
        resData
          .filter((a: any) => a.pipelineName == item)
          .sort((a: any, b: any) => a.orderBy - b.orderBy)
          .map((itemSub: any) => {
            menuSubItem.push(getItem(itemSub.statusName, itemSub.statusId));
          });
        items.push(getItem(item, item, null, menuSubItem));
      });
      menuItem.push(getItem(status, "sub1", null, items));
      setMenuItems(menuItem);
      setPipeline(resData);
      setpipelineName(uniquePipeline);
    });
  };

  const updateLeadApplicationStatusApi = (id: any) => {
    setReasonNotQualifiedStore(id);
    var param = searchParams.get("id") ?? "0";
    if (
      id == Enums.status.main_NonQualifiedStatus ||
      id == Enums.status.spanish_NonQualifiedStatus
    ) {
      setReasonNotQualified(true);
    } else {
      setIsSideMenuPopUpLoader(true);
      if (window.confirm("Are you sure you want to update the status?")) {
        updateLeadApplicationStatus(param, id, "").then((res) => {
          if (res.data.responseCode == 200) {
            formReasonNotQuailfied.resetFields();
            FlashMessage("success", "Application status updated Successfully.");
            setIsSideMenuPopUpLoader(false);
            getLeadsByIdApi(searchParams.get("id"));
          }
        });
      } else {
        setIsSideMenuPopUpLoader(false);
      }
    }
  };

  const submitReasonNotQualified = (e: any) => {
    setIsReasonNotQualifiedPopUpLoader(true);
    var param = searchParams.get("id") ?? "0";
    updateLeadApplicationStatus(
      param,
      reasonNotQualifiedStore,
      e.reasonForNotQualified
    ).then((res) => {
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Application status updated Successfully.");
        setIsReasonNotQualifiedPopUpLoader(false);
        setReasonNotQualified(false);
        getLeadsByIdApi(searchParams.get("id"));
      } else {
        setIsReasonNotQualifiedPopUpLoader(false);
      }
    });
  };

  const formSubmitReasonNotQualified = () => {
    formReasonNotQuailfied.submit();
  };
  const cancelReasonNotQualified = () => {
    formReasonNotQuailfied.resetFields();
    setReasonNotQualified(false);
  };

  useEffect(() => {
    getLeadUserAttributesApi();
    setIsPhoneNumberVisible(false);
    setConcurrentLeadViewers([]);
  }, [searchParams.get("id")]);
  useEffect(() => {
    if (searchParams.get("id") !== "0") {
      getLeadsByIdApi(searchParams.get("id"));
    }
  }, [searchParams.get("id")]);

  const formSubmit = () => {
    form.submit();
  };
  const cancel = () => {
    setOpen(false);
    form.resetFields();
  };
  const onBlur = (e: any) => {
    var onlyNumber = formatPhoneNumber(e.target.value);

    form.setFieldsValue({ phoneNumber: onlyNumber });
  };
  const updateLeadsLeftPanelApi = (e: any) => {
    var payload = {
      Id: e.id,
      FullName: e.fullName,
      Email: e.email,
      PhoneNumber: e.phoneNumber,
    };
    setIsPopUpLoader(true);
    updateLeadsLeftPanel(payload).then((res) => {
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Lead Details updated Successfully.");
        setOpen(false);
        setIsPopUpLoader(false);
        getLeadsByIdApi(e.id);
        setIsLoader(false);
      }
    });
  };

  const clientsWithNoLeadIdApi = () => {
    setIsPopUpLoader(true);
    clientsWithNoLeadId().then((res) => {
      var resData = res.data.responseData;
      setIsPopUpLoader(false);
      setNoLeadIdClients(resData);
    });
  };

  const manualMapping = () => {
    setClientsModel(true);
    clientsWithNoLeadIdApi();
  };

  const onSelect = (value: string, item: any) => {};

  const handleSearch = (value: string) => {
    setOptions(value ? searchResult(value) : []);
  };

  const mapClient = (encryptedId: any, item: any) => {
    setIsPopUpLoader(true);
    var leadId = searchParams.get("id") ?? "0";
    var mindsetUserId = item.id;
    if (
      window.confirm(
        "Are you sure, you want to map '" +
          item.firstName +
          " " +
          item.lastName +
          "' with current lead?"
      )
    ) {
      mapClientWithLead(leadId, mindsetUserId).then((res) => {
        var resData = res.data.responseData;

        if (res.data.responseCode == 200) {
          FlashMessage("success", res.data.responseMessage);
          navigate("/leads");
          setIsPopUpLoader(false);
        }
      });
    } else {
      setIsPopUpLoader(false);
    }
  };

  const searchResult = (query: string) => {
    return noLeadIdClients
      .filter(
        (a: any) =>
          a.email?.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          a.phoneNumber?.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          a.firstName?.toLowerCase().indexOf(query.toLowerCase()) >= 0
      )
      .map((item: any) => {
        const newItem = JSON.stringify(item);
        return {
          value: "",
          label: (
            <div
              className={"custom_customer"}
              onClick={() => mapClient(item.encryptedId, item)}
              style={{ display: "flex" }}
            >
              <span className='customer_icon'>C</span>{" "}
              <span>
                {" "}
                {(item.firstName ?? "") +
                  " " +
                  (item.lastName ?? "") +
                  " | " +
                  item.phoneNumber +
                  " | " +
                  item.email}
              </span>
            </div>
          ),
          item: JSON.parse(newItem),
        };
      });
  };

  const editPopUp = () => {
    setOpen(true);
    getLeadsByIdApi(searchParams.get("id"));
  };

  const revealPhoneNumber = () => {
    var leadId = searchParams.get("id") ?? "0";
    getConcurrentUsersForLead(leadId).then((r) => {
      setConcurrentLeadViewers(r.data.responseData);
      if (r.data.responseData.length === 0) {
        setIsPhoneNumberVisible(true);
      }
    });
  };
  const formSubmitAssign_ReAssignEnterprise = () => {
    formAssign_ReAssignEnterprise.submit();
  };
  const updateAssign_ReAssignEnterpriseApi = (e: any) => {
    setIsPopUpEnterpriseLoader(true);
    if (
      window.confirm(
        "Are you sure, you want to Assign/Reassign the Enterprise?"
      )
    ) {
      updateAssign_ReAssignEnterpriseLeads(leadId, e.tenantId).then((res) => {
        if (res.data.responseCode == Enums.responseCodes.success) {
          var resData = res.data.responseData;
          FlashMessage("success", "Enterprise status updated Successfully.");
          setAssign_ReAssignEnterprise(resData);
          formAssign_ReAssignEnterprise.resetFields();
          setIsPopUpEnterpriseLoader(false);
        } else {
          setIsPopUpEnterpriseLoader(false);
        }
        setIsAssign_reassignEnterPriseLeadModel(false);
        getTenantApi();
        getLeadsByIdApi(leadId);
      });
    } else {
      setIsPopUpEnterpriseLoader(false);
    }
  };
  const getTenantApi = () => {
    getTenant().then((res) => {
      var resData = res.data.responseData;
      setTenants(resData);
    });
  };
  useEffect(() => {
    getTenantApi();
  }, []);
  const assign_reassignEnterprise = () => {
    getLeadsByIdApi(leadId);
    setIsAssign_reassignEnterPriseLeadModel(true);
  };
  const cancelAssign_reAssignEnterprise = () => {
    setIsAssign_reassignEnterPriseLeadModel(false);
    formAssign_ReAssignEnterprise.resetFields();
  };

  console.log(resById);

  return (
    <>
      {isLoader && <Loader></Loader>}

      {!isLoader && (
        <>
          <Modal
            title='Edit Lead Details'
            centered
            okText={isPopUpLoader ? "Loading" : "Update"}
            open={open}
            onCancel={() => cancel()}
            width={1000}
            onOk={() => (isPopUpLoader ? null : formSubmit())}
          >
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <Form
                className='mt-4'
                form={form}
                name='LeadDetailForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={resById}
                autoComplete='off'
                onFinish={updateLeadsLeftPanelApi}
              >
                <div className='form-group row margin_mobile'>
                  <Form.Item name='id' />
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    1. Full Name
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='fullName'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    2. Phone Number
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='phoneNumber'
                      rules={[
                        {
                          validator: validatePhoneNumberFormat,
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        maxLength={12}
                        className={`form-control input_form_sign`}
                        onBlur={onBlur}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    3. Email
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        {
                          validator: validateBlankSpace,
                        },
                        { validator: validateEmail },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            )}
          </Modal>
          <div>
            <h4 className={`name_log mb-0`}>{resById?.fullName}</h4>
            {isPhoneNumberVisible ? (
              <h5 className={"phone_log"}>
                <span key={resById?.phoneNumber}>
                  <a href={`tel:${formatPhoneNumber(resById.phoneNumber)}`}>
                    {formatPhoneNumber(resById.phoneNumber) +
                      `${resById.timeZone ? ` (${resById.timeZone})` : ""}`}
                  </a>
                </span>

                <span className={"save_num"}>
                  {" "}
                  {resById?.timezoneNew ? resById?.timezoneNew : "N/A"}
                </span>
                <span
                  onClick={() => editPopUp()}
                  className={"save_num cursorPointer"}
                >
                  {" "}
                  edit
                </span>
              </h5>
            ) : concurrentLeadViewers.length > 0 ? (
              <Alert
                message='Recent Lead Activity Detected'
                description={
                  <>
                    <div>
                      The following user(s) viewed this profile in the past 15
                      minutes.{" "}
                    </div>
                    <div>
                      {concurrentLeadViewers.map((element, index, array) => (
                        <React.Fragment key={index}>
                          <strong>{element}</strong>
                          {index < array.length - 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                    </div>
                    <div>
                      It is recommended to come back to this profile later or
                      sync with them before calling this claimant.
                    </div>
                    <div className='mt-2'>
                      <Button
                        size='small'
                        type='primary'
                        onClick={() => setIsPhoneNumberVisible(true)}
                      >
                        Reveal Phone Number Anyway
                      </Button>
                    </div>
                  </>
                }
                type='info'
              />
            ) : (
              <span
                onClick={() => revealPhoneNumber()}
                className={"save_num cursorPointer"}
              >
                {" "}
                Reveal Phone Number
              </span>
            )}
            <p className={"mail_log mb-0"}>{resById?.email}</p>
            {resById?.id && (
              <p className={"mail_log mb-0"}>
                Date of Birth:{" "}
                {resById?.dateOfBirth
                  ? `${dayjs
                      .utc(resById.dateOfBirth)
                      .local()
                      .format(Constants.dateFormat)} (Age: ${dayjs().diff(
                      resById.dateOfBirth,
                      "years"
                    )})`
                  : "Not Provided"}
              </p>
            )}
            <Form
              className='mt-4'
              form={form1}
              name='LeadUserAttributeForm'
              autoComplete='off'
            >
              <Row>
                <Col md={10}>
                  <Form.Item
                    label={<span style={{ fontWeight: "bold" }}>Spanish</span>}
                    name='isSpanish'
                    valuePropName='checked'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox onChange={onChangeisSpanish} />
                  </Form.Item>
                </Col>
                <Col md={10}>
                  <Form.Item
                    label={<span style={{ fontWeight: "bold" }}>Critical</span>}
                    name='isCritical'
                    valuePropName='checked'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox onChange={onChangeisCritical} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md={10}>
                  <Form.Item
                    label={<span style={{ fontWeight: "bold" }}>Deceased</span>}
                    name='isDeceased'
                    valuePropName='checked'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox onChange={onChangeisDeceased} />
                  </Form.Item>
                </Col>
                <Col md={10}>
                  <Form.Item
                    label={<span style={{ fontWeight: "bold" }}>Minor</span>}
                    name='isMinor'
                    valuePropName='checked'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox onChange={onChangeisMinor} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md={10}>
                  <Form.Item
                    label={<span style={{ fontWeight: "bold" }}>Terminal</span>}
                    name='isTerminal'
                    valuePropName='checked'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox onChange={onChangeisTerminal} />
                  </Form.Item>
                </Col>
                <Col md={10}>
                  <Form.Item
                    label={<span style={{ fontWeight: "bold" }}>Denial</span>}
                    name='isDenial'
                    valuePropName='checked'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox onChange={onChangeisDenial} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {featureFlags[
              Constants.featureFlags.claimantAccountCreationFromLeadFeatureFlag
            ] && (
              <ReviewLead
                moveLeadToNotQualified={updateLeadApplicationStatusApi}
              />
            )}

            <div id='container' />
            {isSideMenuPopUpLoader && (
              <Loader overrideClass='loaderLeftPanelHeight'></Loader>
            )}
            {menuItems.length > 0 && !isSideMenuPopUpLoader && (
              <Menu
                style={{
                  textAlign: "center",
                  border: "none",
                  height: 48,
                  margin: "20px 0px",
                  backgroundColor: "#f6f6f6",
                }}
                expandIcon={false}
                onClick={onClick}
                mode='vertical'
                items={menuItems}
              />
            )}

            {roleInStorage == "PreQualMSR" ? (
              <></>
            ) : (
              <>
                <button
                  onClick={manualMapping}
                  type='button'
                  className={
                    "btn btn-primary maping_button w-100 mt-3" +
                    (location.pathname.indexOf(Constants.pageRoutes.notes) >= 0
                      ? " activemenu"
                      : "")
                  }
                >
                  Claimants Mapping
                </button>
              </>
            )}
            <EnterpriseAssignment
              isLead
              roleInStorage={roleInStorage}
              userId={searchParams.get("id") ?? "0"}
              onEnterpriseUpdated={() =>
                getLeadsByIdApi(searchParams.get("id"))
              }
            />
            {roleInStorage == "PreQualMSR" ? (
              <></>
            ) : (
              <>
                <button
                  onClick={deactivateLeadApi}
                  type='button'
                  className={
                    "btn btn-primary maping_button w-100 mt-3 deactivate_button" +
                    (location.pathname.indexOf(Constants.pageRoutes.notes) >= 0
                      ? " activemenu"
                      : "")
                  }
                >
                  Deactivate Lead
                </button>
              </>
            )}
            <Modal
              title='Select claimant to map'
              centered
              footer={null}
              open={clientsModel}
              onCancel={() => setClientsModel(false)}
              width={600}
              onOk={() => formSubmit()}
            >
              {isPopUpLoader && <Loader></Loader>}
              {!isPopUpLoader && (
                <AutoComplete
                  className='search_ant_drp w-100 pt-4 '
                  dropdownMatchSelectWidth={252}
                  style={{ width: 500 }}
                  options={options}
                  onSelect={onSelect}
                  onSearch={handleSearch}
                >
                  <Input
                    placeholder='Search by number, email, name'
                    className={"input_style"}
                    suffix={
                      <Button
                        style={{
                          marginRight: -12,
                          marginTop: -10,
                        }}
                        size='large'
                        type='primary'
                      >
                        Go
                      </Button>
                    }
                  />
                </AutoComplete>
              )}
            </Modal>
          </div>
        </>
      )}

      <>
        <Modal
          open={ReasonNotQualified}
          onCancel={() => cancelReasonNotQualified()}
          onOk={() => formSubmitReasonNotQualified()}
          width={500}
        >
          {isReasonNotQualifiedPopUpLoader && (
            <Loader style={{ width: 1000 }}></Loader>
          )}
          {!isReasonNotQualifiedPopUpLoader && (
            <Form
              className='mt-4'
              form={formReasonNotQuailfied}
              name='post'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={submitReasonNotQualified}
              initialValues={resById}
            >
              <label className='col-form-label fill_description col-sm-12 des_font mt-3'>
                Reason for Not Qualified
              </label>
              <div className='col-sm-12 p-0 mt-3'>
                <Form.Item
                  name='reasonForNotQualified'
                  rules={[
                    { required: true, message: "Please select a reason" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp='label'
                    options={Constants.notQualifiedReason.map((loc: any) => ({
                      label: loc.name,
                      value: loc.name,
                    }))}
                  />
                </Form.Item>
              </div>
            </Form>
          )}
        </Modal>
      </>
    </>
  );
};
export default LeadsLeftPanel;
