import { useEffect, useState } from "react";
import { getPipeline, getPipelineStatuses } from "../../actions/pipeline";
import { Button, Checkbox, Select } from "antd";
import "./styles.css";

export const ReassignmentStep2 = (props: any) => {
  const [pipelines, setPipelines] = useState([]);
  const [pipelineLoading, setPipelineLoading] = useState(false);
  const [pipelineStatuses, setPipelineStatuses] = useState<any[]>([]);
  const [pipelineStatusLoading, setPipelineStatusLoading] = useState(false);
  const [selectedPipelineId, setSelectedPipelineId] = useState();
  const [selectedApplicationStatusId, setSelectedApplicationStatusId] =
    useState();

  const [filterOption, setFilterOption] = useState<any>({});

  const getPipelinesApi = () => {
    setPipelineLoading(true);
    getPipeline()
      .then((res) => {
        const pipelineData = res.data.responseData;
        setPipelines(pipelineData);
      })
      .finally(() => {
        setPipelineLoading(false);
      });
  };
  const getPipelineStatusesApi = () => {
    setPipelineStatusLoading(true);
    getPipelineStatuses()
      .then((res) => {
        const pipelineStatusData = res.data.responseData;
        setPipelineStatuses(pipelineStatusData);
      })
      .finally(() => {
        setPipelineStatusLoading(false);
      });
  };

  useEffect(() => {
    getPipelinesApi();
    getPipelineStatusesApi();
  }, []);

  return (
    <div className='reassignment-container'>
      <div className='reassignment-step'>
        <h2 className='reassignment-title'>
          Set Filter Options for <b>{props.originalCaseManager.fullName}</b>'s
          claimants
        </h2>

        <div className='mb-4'>
          <label className='mb-2'>Application Status</label>
          <div style={{ display: "flex", gap: "10px" }}>
            <Select
              className='flex-1'
              placeholder='Select pipeline'
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onSelect={(value) => setSelectedPipelineId(value)}
              value={selectedPipelineId}
              loading={pipelineLoading}
              options={pipelines.map((pipeline: any) => ({
                label: pipeline.name,
                value: pipeline.id,
              }))}
            />
            <Select
              className='flex-1'
              placeholder='Select status'
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={pipelineStatusLoading}
              onSelect={(value) => setSelectedApplicationStatusId(value)}
              value={selectedApplicationStatusId}
              options={pipelineStatuses
                .filter(
                  (pipeline: any) => pipeline.pipelineId === selectedPipelineId
                )
                .map((pipeline: any) => ({
                  label: pipeline.name,
                  value: pipeline.id,
                }))}
            />
            <Button
              onClick={() => {
                setSelectedApplicationStatusId(undefined);
                setSelectedPipelineId(undefined);
              }}
            >
              Clear
            </Button>
          </div>
        </div>

        <div className='filter-grid'>
          {[
            { label: "Spanish", key: "isSpanish" },
            { label: "Critical", key: "isCritical" },
            { label: "Deceased", key: "isDeceased" },
            { label: "Minor", key: "isMinor" },
            { label: "Terminal", key: "isTerminal" },
            { label: "Denial", key: "isDenial" },
          ].map(({ label, key }) => (
            <div className='filter-item' key={key}>
              <Checkbox
                onChange={(e) =>
                  setFilterOption({
                    ...filterOption,
                    [key]: e.target.checked,
                  })
                }
                checked={filterOption[key]}
              >
                {label}
              </Checkbox>
            </div>
          ))}
        </div>

        <div className='reassignment-actions'>
          <Button
            type='primary'
            onClick={() => {
              if (
                window.confirm(
                  `Reassignment will be only applied to the claimants with application status: ${
                    selectedApplicationStatusId
                      ? pipelineStatuses.find(
                          (p: any) => p.id === selectedApplicationStatusId
                        )?.name
                      : "All"
                  } (${Object.entries(filterOption)
                    .filter(([_, value]) => value === true)
                    .map(([key]) => key)
                    .join(", ")}). Are you sure?`
                )
              ) {
                props.setFilterOption({
                  ...filterOption,
                  applicationStatusId: selectedApplicationStatusId,
                });
                props.setStep(2);
              }
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
