import { useState, useEffect } from "react";
import { getAllPipeline, getPipelineStatuses } from "../../actions/pipeline";

type Pipeline = { id: number; name: string; isLead: boolean };
type PipelineStatus = { id: number; name: string };

const usePipelinesAndStatuses = () => {
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const [pipelineStatuses, setPipelineStatuses] = useState<PipelineStatus[]>(
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [pipelineResponse, statusResponse] = await Promise.all([
          getAllPipeline(),
          getPipelineStatuses(),
        ]);

        setPipelines(pipelineResponse.data.responseData);
        setPipelineStatuses(statusResponse.data.responseData);
      } catch (error) {
        console.error("Failed to fetch pipelines or statuses", error);
      }
    };

    fetchData();
  }, []);

  return { pipelines, pipelineStatuses };
};

export default usePipelinesAndStatuses;
