import { TabletOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { IntakeFieldItem } from "..";

export const ScreeningFieldLabel = ({ field }: { field: IntakeFieldItem }) => {
  return (
    <div
      className='prequal_fields_key fw-semibold d-flex align-items-center'
      style={{
        minWidth: "420px",
      }}
    >
      {field.isTablet && (
        <Tooltip className='qualification_details me-2' title='Tablet Field'>
          <TabletOutlined />
        </Tooltip>
      )}
      <span>{field.label}</span>
    </div>
  );
};
