import { useState, useEffect } from "react";
import { getGroupUsers } from "../../actions/notes";
import { Enums } from "../enums";
import { GroupUser } from "../../utils/mentions";

const useGroupUsers = () => {
  const [groups, setGroups] = useState<GroupUser[]>([]);

  const fetchGroupUsers = async () => {
    try {
      const res = await getGroupUsers();
      if (res.data.responseCode === Enums.responseCodes.success) {
        let groupData: GroupUser[] = res.data.responseData.map((ele: any) => ({
          id: ele.groupId,
          display: ele.groupName,
          userIds: ele.operationalUserId,
        }));

        setGroups(groupData);
      }
    } catch (error) {
      console.error("Error fetching group users:", error);
    }
  };

  useEffect(() => {
    fetchGroupUsers();
  }, []);

  return { groups, refreshGroups: fetchGroupUsers };
};

export default useGroupUsers;
