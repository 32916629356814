import React, { useEffect, useState } from "react";
import { Form, Select, DatePicker, Button, Flex, Typography } from "antd";
import {
  getGlobalTask,
  getTaskType,
  getTaskPriority,
  getTaskStatus,
  getClientsData,
  getLeadsData,
} from "../../actions/taskManagement";
import Loader from "../../components/Loader";
import { getSupportUsers } from "../../actions/supportUser";
import { get } from "../../utils/clientStorageUtils";
import { Spinner } from "react-bootstrap";
import { Enums } from "../../common/enums";
import GlobalTasksTableV2 from "./components/GlobalTasksTableV2";
import dayjs from "dayjs";
import StatesDropdownFormItem from "../../common/components/StatesDropdownFormItem";

const GlobalTaskScreen = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [task, setTask] = useState([]);
  const [priority, setPriority] = useState([]);
  const [status, setStatus] = useState([]);
  const [user, setUser] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [taskType, setTaskType] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [filtterTaskVal, setFilterTaskval] = useState([]);
  const [clientsData, setClientData] = useState<any[]>([]);
  const [leadsData, setLeadsData] = useState([]);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const userIdData = JSON.parse(get("model"))
    ? JSON.parse(get("model")).userId
    : "";
  const [valueId, setValueId] = useState({
    assignTo: userIdData,
  });
  const [isUpdatingAssignedTo, setIsUpdatingAssignedTo] = useState(false);
  const getSupportUsersApi = () => {
    setIsPopUpLoader(true);
    setIsUpdatingAssignedTo(true);
    getSupportUsers().then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        setIsPopUpLoader(false);
        let userid = JSON.parse(get("model"))
          ? JSON.parse(get("model")).userId
          : "";
        var filterData = userdata
          .map((ele: any) => ({
            id: ele.id,
            display: `${ele.firstName} ${ele.lastName}`,
          }))
          .filter((ele: any) => ele.id != userid);
        setUser(filterData);
        var allUser = userdata
          .map((a: any) => ({
            id: a.id,
            allUsersDisplay: `${a.firstName} ${a.lastName}`,
          }))
          .filter((b: any) => b.id == userid || b.id != userid);
        setAllUsers(allUser);
        setIsUpdatingAssignedTo(false);
      }
    });
  };

  const dateFormat = "MM/DD/YYYY";
  const defaultStatus = "Not Started";

  useEffect(() => {
    getGlobalTaskApi();
    getTaskPriorityApi();
    getTaskStatusApi();
    getTaskTypeApi();
    getSupportUsersApi();
    getLeadsDataStore();
    getClientsDataStore();
  }, []);

  const getFilterTaskApi = (e: any) => {
    setIsLoader(true);

    var payload = {
      AssignTo: e.assignTo || null,
      AssignBy: e.assignedBy || null,
      StartDate: e.startDate ? dayjs(e.startDate).toISOString() : null,
      EndDate: e.endDate ? dayjs(e.endDate).toISOString() : null,
      PriorityIds: e.priorityIds || null,
      TypeIds: e.typeIds || null,
      StatusIds: e.statusIds || Enums.taskStatus.notStarted,
      State: e.state || null,
      Timezone: e.timezone || null,
    };

    getGlobalTask(payload).then((res) => {
      if (res.data.responseCode == 200) {
        var taskVal = res.data.responseData;
        setFilterTaskval(taskVal);
        setTask(taskVal);
        setIsLoader(false);
      }
    });
  };

  const clear = () => {
    form1.resetFields();

    form1.setFieldsValue({
      assignTo: userIdData,
      assignedBy: undefined,
      priorityIds: undefined,
      typeIds: undefined,
      statusIds: Enums.taskStatus.notStarted,
      startDate: undefined,
      endDate: undefined,
    });
  };

  const getGlobalTaskApi = () => {
    setIsLoader(true);

    var payloads = {
      AssignTo: userIdData || null,
      AssignBy: null,
      StartDate: null,
      EndDate: null,
      PriorityIds: null,
      TypeIds: null,
      StatusIds: Enums.taskStatus.notStarted,
    };

    getGlobalTask(payloads).then((res) => {
      if (res.data.responseCode == 200) {
        setTask(res.data.responseData);
        setIsLoader(false);
        form.setFieldsValue({
          ...valueId,
        });
      }
    });
  };

  const getTaskTypeApi = () => {
    getTaskType().then((res) => {
      if (res.data.responseCode == 200) {
        setTaskType(res.data.responseData);
      }
    });
  };

  const getClientsDataStore = () => {
    getClientsData().then((res) => {
      if (res.data.responseData) {
        var clientsDataStore = res.data.responseData;

        clientsDataStore.push({
          fullName: "----Please Select----",
          id: null,
        });
        clientsDataStore = clientsDataStore.sort((a: any, b: any) => {
          if (a.id < b.id) {
            return -1;
          }
        });
        setClientData(clientsDataStore);
      }
    });
  };
  const getLeadsDataStore = () => {
    getLeadsData().then((res) => {
      if (res.data.responseData) {
        var leadsDataStore = res.data.responseData;
        leadsDataStore.push({
          fullName: "----Please Select----",
          id: null,
        });
        leadsDataStore = leadsDataStore.sort((s: any, t: any) => {
          if (s.id < t.id) {
            return -1;
          }
        });
        setLeadsData(leadsDataStore);
      }
    });
  };

  const getTaskPriorityApi = () => {
    getTaskPriority().then((res) => {
      if (res.data.responseCode == 200) {
        setPriority(res.data.responseData);
      }
    });
  };

  const getTaskStatusApi = () => {
    getTaskStatus().then((res) => {
      if (res.data.responseCode == 200) {
        setStatus(res.data.responseData);
      }
    });
  };

  return (
    <>
      <Typography.Title level={3}>Tasks</Typography.Title>
      <Form
        form={form1}
        name='TaskForm1'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete='off'
        onFinish={getFilterTaskApi}
        initialValues={valueId}
      >
        <Flex wrap='wrap' gap={8} justify='space-between'>
          <Form.Item name='assignTo' label='Assigned To' style={{ flex: 1 }}>
            {isUpdatingAssignedTo ? (
              <Select placeholder={<Spinner size='sm' />} />
            ) : (
              <Select
                placeholder='Assigned To'
                showSearch
                optionFilterProp='label'
                options={allUsers.map((loc: any) => ({
                  label: loc.allUsersDisplay,
                  value: loc.id,
                  key: loc.id,
                }))}
              />
            )}
          </Form.Item>

          <Form.Item name='assignedBy' label='Assigned By' style={{ flex: 1 }}>
            <Select
              placeholder='Assigned By'
              showSearch
              optionFilterProp='label'
              options={allUsers.map((loc: any) => ({
                label: loc.allUsersDisplay,
                value: loc.id,
              }))}
            />
          </Form.Item>

          <Form.Item name='typeIds' label='Task Type' style={{ flex: 1 }}>
            <Select
              placeholder='Type'
              showSearch
              optionFilterProp='label'
              options={taskType.map((loc: any) => ({
                label: loc.name,
                value: loc.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            name='priorityIds'
            label='Task Priority'
            style={{ flex: 1 }}
          >
            <Select
              placeholder='Priority'
              showSearch
              optionFilterProp='label'
              options={priority.map((loc: any) => ({
                label: loc.name,
                value: loc.id,
              }))}
            />
          </Form.Item>

          <Form.Item name='statusIds' label='Task Status' style={{ flex: 1 }}>
            <Select
              placeholder='Status'
              showSearch
              optionFilterProp='label'
              defaultValue={defaultStatus}
              options={status.map((loc: any) => ({
                label: loc.name,
                value: loc.id,
              }))}
            />
          </Form.Item>

          <div style={{ flex: 1 }}>
            <StatesDropdownFormItem showLabel allowClear customLabel='State' />
          </div>

          <Form.Item
            name='timezone'
            label='Timezone'
            style={{ flex: 1 }}
          >
            <Select
              placeholder='Timezone'
              allowClear
              showSearch
              optionFilterProp='label'
              options={[
                { label: "EST", value: "EST" },
                { label: "CST", value: "CST" },
                { label: "MST", value: "MST" },
                { label: "PST", value: "PST" },
                { label: "AKST", value: "AKST" },
                { label: "GMT", value: "GMT"}
              ]}
            />
          </Form.Item>

          <Form.Item
            name='startDate'
            label='Due Date After'
            style={{ flex: 1 }}
            getValueProps={(i) => ({
              value: i == null ? null : dayjs(i),
            })}
          >
            <DatePicker placeholder='From' format={dateFormat} />
          </Form.Item>

          <Form.Item
            name='endDate'
            label='Due Date Before'
            style={{ flex: 1 }}
            getValueProps={(i) => ({
              value: i == null ? null : dayjs(i),
            })}
          >
            <DatePicker placeholder='To' format={dateFormat} />
          </Form.Item>
        </Flex>

        <Flex justify='end' gap={8}>
          <Button
            type='primary'
            htmlType='submit'
            ref={buttonRef}
            disabled={isLoader}
          >
            Search
          </Button>
          <Button type='default' onClick={() => clear()}>
            Clear
          </Button>
        </Flex>
      </Form>

      {isLoader ? (
        <Loader></Loader>
      ) : (
        <>
          <GlobalTasksTableV2 dataSource={task} />
        </>
      )}
    </>
  );
};

export default GlobalTaskScreen;
