import { IntakeFieldItem } from "..";

const intake_field_labels: {
  [key: string]: IntakeFieldItem;
} = {
  hasPreviouslyFiledForSocialSecurityBenefits: {
    label: "Have you applied for Social Security benefits before?",
    type: "boolean",
  },
  previousSocialSecurityBenefitsFilingDate: {
    label: "When did you apply for Social Security benefits?",
    type: "select",
    displayIf: "hasPreviouslyFiledForSocialSecurityBenefits",
    options: [
      "less than 30 days",
      "less than 6 months",
      "more than 6 months",
      "more than a year",
    ],
  },
  previousSocialSecurityBenefitsResult: {
    label: "What was the result?",
    type: "select",
    options: ["Pending", "Denied", "Requested Hearing", "I don't know"],
    displayIf: "hasPreviouslyFiledForSocialSecurityBenefits",
  },
  previousSocialSecurityHearingDate: {
    label: "Do you have a hearing date scheduled?",
    type: "date",
    displayIf: "hasPreviouslyFiledForSocialSecurityBenefits",
  },
  lastDateOfEmployment: {
    label: "When was the time you last worked?",
    type: "date",
  },
  isLastEmploymentFullTime: {
    label: "Was it full-time or part-time work?",
    type: "radio",
    options: ["Full-time", "Part-time"],
    trueOption: "Full-time",
  },
  whyDidTheLastJobEnd: {
    label: "Why did you leave your last job?",
    type: "select",
    options: [
      "I was arrested",
      "A disability/injury",
      "The job was seasonal, or ended",
      "Something else",
    ],
  },
  howLongDidLastJobLast: {
    label: "How long did your last job last?",
    type: "select",
    options: [
      "less than 30 days",
      "less than 6 months",
      "more than 6 months",
      "more than a year",
    ],
  },
  yearsWorkedInTheLastTenYears: {
    label: "How many years have you worked in the past 10 years?",
    type: "number",
  },
  yearsPaidTax: {
    label: "How many did you pay taxes?",
    type: "number",
  },
  hasRegularEarnedIncomeFromJobs: {
    label: "Do you do jobs for cash regularly?",
    type: "boolean",
  },
  isLivingWithPartner: {
    label: "Do you live with a partner or spouse?",
    type: "boolean",
  },
  partnerIncome: {
    label: "What is their annual income?",
    type: "dollar",
    displayIf: "isLivingWithPartner",
  },
  childrenCount: {
    label: "How many children do you have?",
    type: "number",
  },
  childCountReceivingSocialSecurityBenefits: {
    label: "How many of them are receiving disability benefits currently?",
    type: "number",
    displayIf: "childrenCount",
  },
  hasTooMuchCaseOrAssetsToQualifyForSSI: {
    label:
      "Do you have any assets or cash over $2,000 (single) or $3,000 (married)?",
    type: "boolean",
  },
  isReceivingVaBenefits: {
    label: "Are you currently receiving Veterans Affairs (VA) benefits?",
    type: "boolean",
  },
  vaBenefitAmount: {
    label: "What is the monthly VA benefit amount you receive?",
    type: "dollar",
  },
  highestLevelOfEducation: {
    label: "What is the highest level of education you’ve completed?",
    type: "select",
    options: [
      "Did not complete High School",
      "High School Diploma or GED",
      "Some College",
      "College Degree (Associate's, Bachelor's)",
      "Advanced Degree (Master's, PhD, etc.)",
    ],
  },
  canWorkFullTime: {
    label: "Do you feel capable of working full-time now?",
    type: "boolean",
  },
  isSeekingFullTimeEmployment: {
    label: "Are you currently looking for full-time work?",
    type: "boolean",
  },
  longestJobLengthHeldInLastTenYears: {
    label: "What’s the longest you’ve worked full-time in the past 10 years?",
    type: "select",
    options: ["0 to 6 months", "7 to 12 months", "More than a year"],
  },
  conditionsAffectAbilityToWork: {
    label: "What conditions affect your ability to work?",
    type: "string_array",
    options: ["Mental", "Physical"],
  },
  relatedInLast15Months: {
    label:
      "Select all that apply to you that's related to your health condition in the past 12 months",
    type: "string_array",
    options: [
      "Hospital Visit",
      "Emergency Room/Urgent Care",
      "Surgery",
      "Doctor Visit",
      "Therapist/Psychiatrist",
      "Specialists",
      "Physical Therapist/Occupational Therapist",
    ],
  },
  currentMedications: {
    label: "What medications are you taking?",
    type: "medications_input",
  },
  mentalHealthConditions: {
    label: "What are your mental health conditions?",
    type: "string_array",
    options: [
      "Anxiety",
      "Bipolar",
      "Depression",
      "Post Traumatic Stress Disorder",
      "Schizophrenia",
      "ADHD",
      "Other Mental Condition",
      "Developmental Disability",
      "Intellectual Disability",
      "Other",
    ],
  },
  physicalHealthConditions: {
    label: "What are your physical health conditions?",
    type: "string_array",
    options: [
      "Neurological Disorder (e.g., multiple sclerosis, Parkinson's disease)",
      "Cardiovascular Condition (e.g., heart failure, coronary artery disease)",
      "Cancer (various types, depending on severity)",
      "Chronic Respiratory Disease (e.g., COPD, asthma)",
      "Kidney Disease (e.g., chronic kidney disease requiring dialysis)",
      "Diabetes (with complications)",
      "HIV/AIDS",
      "Epilepsy",
      "Liver Disease (e.g., cirrhosis)",
      "Autoimmune Disorder (e.g., lupus, rheumatoid arthritis)",
      "Vision Impairment (e.g., blindness)",
      "Traumatic Brain Injury/Stroke",
      "Burns and Other Skin Disorder (when significantly impairing)",
      "Other",
    ],
  },
  mentalHealthSymtops: {
    label: "Mental health symptoms",
    type: "string_array",
    options: [
      "Anticipating the worst",
      "Avoiding friends, family, and activities.",
      "Avoiding places, people, and activities",
      "Avoiding social interactions and isolating",
      "Decreased need for sleep",
      "Difficulty concentrating",
      "Difficulty organizing tasks and activities",
      "Difficulty sleeping",
      "Easily distracted",
      "Erratic, unpredictable, or excessive movement",
      "Excessive talking",
      "Excessive worry",
      "Feeling euphoric, excessively happy",
      "Feeling hopeless, empty, or down most of the time",
      "Feeling hopeless, guilty, ashamed, or worthless",
      "Feelings of hopelessness or worthlessness",
      "Feelings of worthlessness or guilt",
      "Fidgeting or squirming",
      "Flashbacks",
      "Forgetfulness",
      "Hallucinations",
      "Hypervigilance",
      "Impatience",
      "Insomnia",
      "Intrusive thoughts",
      "Irritability",
      "Irritability or angry outbursts",
      "Loss of energy",
      "Loss of interest",
      "Loss of interest in activities",
      "More sociable and talkative",
      "Negative or suicidal thoughts",
      "Nightmares",
      "Panic attacks",
      "Paranoid delusions",
      "Racing thoughts",
      "Struggling to focus or concentrate on tasks",
      "Trouble falling asleep, staying asleep, or experiencing restless sleep",
      "Trouble staying focused on tasks or activities",
      "Impulsive or risky behavior",
    ],
  },
  hasDrRecommendedApplicationForDisabilityBenefits: {
    label:
      "Has any doctor recommended that you should apply for disability benefits?",
    type: "boolean",
  },
  hasCurrentOrPastDrugOrAlcoholDependency: {
    label: "Have you ever struggled with drug or alcohol dependency?",
    type: "boolean",
  },
  sobrietyLength: {
    label: "How long have you been sober?",
    type: "select",
    options: [
      "Currently not sober",
      "1-6 months",
      "7-12 months",
      "More than a year",
    ],
  },
  isUsingMedicalEquipment: {
    label: "Do you use medical equipment in your daily life?",
    type: "boolean",
  },
  currentMedicalEquipment: {
    label: "What medical equipment do you use?",
    type: "string_array",
    displayIf: "isUsingMedicalEquipment",
    options: [
      "Wheelchair",
      "Cane",
      "Walker",
      "Oxygen Machine",
      "CPAP Machine",
      "Prosthetics",
      "Feeding Tubes",
      "Magnifiers",
      "Hearing Aids",
    ],
  },
  hasServiceOrSupportAnimal: {
    label: "Do you have a certified service or support animal?",
    type: "boolean",
  },
  additionalInformation: {
    label: "Additional information",
    type: "textarea",
  },
};

export default intake_field_labels;
