import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Select, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import PhoneField from "../../pages/GenerateDocuments/TemplateForms/SharedFields/PhoneField";
import StatesDropdownFormItem from "../../common/components/StatesDropdownFormItem";
import { CreateLead } from "../../apis/leadsAndClients";
import { getTenant } from "../../actions/tenants";
import { PlusOutlined } from "@ant-design/icons";
import { Constants } from "../../common/constants";

type FormValues = {
  fullName: string;
  email: string;
  phone: string;
  state: string;
};

type CreateLeadFormProps = {
  compact?: boolean;
};

const CreateLeadForm: React.FC<CreateLeadFormProps> = ({ compact = false }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();
  const [tenants, setTenants] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getTenantApi();
  }, []);

  const showModal = (): void => {
    setIsModalVisible(true);
  };

  const handleCancel = (): void => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleFinish = async (values: FormValues): Promise<void> => {
    console.log("Form Values:", values);
    setIsLoading(true);
    try {
      const res = await CreateLead(values);
      if (res.data.responseCode === 303) {
        form.setFields([
          {
            name: "email",
            errors: ["Email already exists as a lead in our system"],
          },
        ]);
      } else {
        const id = res.data.responseData.id;
        form.resetFields();
        setIsModalVisible(false);
        navigate(`/${Constants.pageRoutes.leadProfile}?id=${id}`);
      }
    } catch (error) {
      console.error("Error creating lead:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTenantApi = () => {
    getTenant().then((res) => {
      var resData = res.data.responseData;
      setTenants(resData);
    });
  };

  return (
    <div>
      <Button
        className='secondary-button'
        icon={compact ? <PlusOutlined /> : undefined}
        size='large'
        onClick={showModal}
      >
        {compact ? null : "Create Lead"}
      </Button>
      <Modal
        title='Create Lead'
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form<FormValues>
          form={form}
          layout='vertical'
          onFinish={handleFinish}
          style={{ paddingTop: 20 }}
        >
          <Form.Item
            label='Full Name'
            name='fullName'
            rules={[{ required: true, message: "Enter the full name" }]}
          >
            <Input placeholder='Enter full name' />
          </Form.Item>

          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                message: "Enter a valid email",
                type: "email",
              },
            ]}
          >
            <Input placeholder='Enter email address' />
          </Form.Item>

          <PhoneField label='Phone Number' name='phoneNumber' />

          <StatesDropdownFormItem showLabel useLocationIdsAsBackingValue />
          <Form.Item label='Partner Organization (optional)' name='tenantId'>
            <Select
              showSearch
              optionFilterProp='label'
              placeholder='Select a partner organization'
              options={tenants
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((loc: any) => ({
                  label: loc.name,
                  value: loc.id,
                }))}
            />
          </Form.Item>

          <Flex justify='flex-end' gap={8}>
            <Button type='default' onClick={handleCancel}>
              Cancel
            </Button>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Create Lead
            </Button>
          </Flex>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateLeadForm;
