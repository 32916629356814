import dayjs from "dayjs";
import { Constants } from "../../../common/constants";
import { Tag } from "antd";
import { IntakeFieldItem } from "..";

export const displayFieldByType = (
  field: IntakeFieldItem,
  value: any,
  forBot?: boolean
) => {
  const { type } = field;
  if (type === "boolean") {
    if (value == null) {
      return ""; // or "N/A"
    } else if (value) {
      return "Yes";
    } else {
      return "No";
    }
  }
  if (type === "radio") {
    if (value === true) {
      return field.trueOption; // or "N/A"
    } else if (value === false) {
      return (field.options || [])[1];
    } else {
      return "";
    }
  }
  if (type === "date" && value) {
    return dayjs.utc(value).local().format(Constants.dateFormat);
  }
  if (
    (type === "string_array" || type === "medications_input") &&
    value &&
    forBot
  ) {
    return value.split(Constants.stringArrayDelimiter).join(", ");
  }
  if ((type === "string_array" || type === "medications_input") && value) {
    return value.map((val: string) => (
      <Tag className='mb-2 me-0 ms-2'>{val}</Tag>
    ));
  }

  return value;
};
