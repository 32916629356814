import { useSearchParams } from "react-router-dom";
import usePermissions from "../../common/customHooks/usePermissions";
import FaxHistoryTable from "../../components/FaxManager/FaxHistoryTable";

const FaxManager = () => {
  usePermissions("faxManager");
  const [searchParams] = useSearchParams();

  const userId = searchParams.get("id") ?? "0";

  return (
    <section id='FaxManager'>
      <FaxHistoryTable userId={userId} />
    </section>
  );
};

export default FaxManager;
