import { Alert, List } from "antd";
import { ScreeningListHeader } from "./ScreeningListHeader";

export const ScreeningListEmpty = ({
  title,
  message,
  addOn,
  alertType = "warning",
}: {
  title: "Pre-Qualification" | "Intake Questions";
  message: string;
  addOn?: React.ReactNode;
  alertType?: "warning" | "info";
}) => {
  return (
    <List
      className='screening_fields_list'
      bordered
      size='small'
      dataSource={[]}
      header={<ScreeningListHeader title={title} addOn={addOn} />}
      locale={{
        emptyText: (
          <Alert
            className='centered-alert screening-list-alert'
            type={alertType}
            showIcon
            message={message}
          />
        ),
      }}
    />
  );
};
