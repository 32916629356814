import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../pages/Login";
import PrivateRoute from "./PrivateRoute";
import AccountLayout from "../layouts/accountLayout";
import CustomerLayout from "../layouts/customerLayout";
import LeadsLayout from "../layouts/leadslayout/leadsLayout";
import Leads from "../pages/Leads";
import Clients from "../pages/Clients";
import ChangePassword from "../pages/ChangePassword";
import LeadsDetailLayout from "../layouts/leadsDetailLayout";
import ManageUser from "../pages/ManageUser";
import ForgotPassword from "../pages/ForgotPassword";
import Tenants from "../pages/Tenants";
import NotificationConfiguration from "../pages/NotificationConfiguration";
import NotificationTemplates from "../pages/NotificationTemplates";
import Logs from "../pages/Logs";
import GlobalTaskScreen from "../pages/GlobalTaskScreen";
import CaseManagement from "../pages/CaseManagement";
import ManageGroupUser from "../pages/ManageGroupUser";
import ManageMedication from "../pages/ManageMedication";
import FeatureFlagSettingsPage from "../pages/FeatureFlagSettings";
import { Constants } from "../common/constants";
import HearingCalendar from "../pages/HearingCalendar";
import Facilities from "../pages/Facilities";
import Reassignment from "../pages/Reassignment";
import MainLayout from "../layouts/MainLayout";
import ClaimantProfile from "../components/ClaimantProfile/ClaimantProfile";
import LeadProfile from "../components/LeadProfile/LeadProfile";
import { redirectRoutes, RedirectWithQueryParams } from "./Redirects";
import NotFoundPage from "../pages/NotFound/NotFoundPage";

const RouterComponent = () => {
  const routes = [
    // Leads and Claimants Pages
    {
      name: "Claimants",
      element: <Clients />,
      url: `/${Constants.pageRoutes.clients}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Claimant Profile",
      element: <ClaimantProfile />,
      url: `/${Constants.pageRoutes.claimantProfile}`,
      isPublic: false,
      layout: "customer",
    },
    {
      name: "Leads",
      element: <Leads />,
      url: `/${Constants.pageRoutes.leads}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Lead Profile",
      element: <LeadProfile />,
      url: `/${Constants.pageRoutes.leadProfile}`,
      isPublic: false,
      layout: "leadsDetail",
    },
    // Auth Pages
    {
      name: "Login",
      element: <LoginPage />,
      url: `/${Constants.pageRoutes.login}`,
      isPublic: true,
      layout: "account",
    },
    {
      name: "Login",
      element: <Navigate to={"/login"} />,
      url: "/",
      isPublic: true,
      layout: "account",
    },
    {
      name: "Change Password",
      element: <ChangePassword />,
      url: `/${Constants.pageRoutes.changePassword}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Forgot Password",
      element: <ForgotPassword />,
      url: "/forgotPassword",
      isPublic: true,
      layout: "account",
    },
    // All Other Pages
    {
      name: "Cases",
      element: <CaseManagement />,
      url: `/${Constants.pageRoutes.caseManagement}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Tasks",
      element: <GlobalTaskScreen />,
      url: `/${Constants.pageRoutes.globaltaskscreen}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Notification Configuration",
      element: <NotificationConfiguration />,
      url: `/${Constants.pageRoutes.notificationconfiguration}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Manage Users",
      element: <ManageUser />,
      url: `/${Constants.pageRoutes.manageUser}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Manage Tenants",
      element: <Tenants />,
      url: `/${Constants.pageRoutes.tenants}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Manage Facilities",
      element: <Facilities />,
      url: `/${Constants.pageRoutes.facilities}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "NotificationTemplates",
      element: <NotificationTemplates />,
      url: `/${Constants.pageRoutes.notificationTemplates}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Logs",
      element: <Logs />,
      url: `/${Constants.pageRoutes.logs}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Manager User Groups",
      element: <ManageGroupUser />,
      url: `/${Constants.pageRoutes.managegroupuser}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Manage Medication",
      element: <ManageMedication />,
      url: `/${Constants.pageRoutes.manageMedication}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Feature Flags",
      element: <FeatureFlagSettingsPage />,
      url: `/${Constants.pageRoutes.featureFlagSettings}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Hearings Calendar",
      element: <HearingCalendar />,
      url: `/${Constants.pageRoutes.hearingCalendar}`,
      isPublic: false,
      layout: "leads",
    },
    {
      name: "Reassignment",
      element: <Reassignment />,
      url: `/${Constants.pageRoutes.claimantReassignment}`,
      isPublic: false,
      layout: "leads",
    },
  ];

  return (
    <Routes>
      <Route element={<AccountLayout />}>
        {routes
          .filter((a) => a.layout === "account")
          .map((route, index) => {
            if (route.isPublic) {
              return (
                <Route key={index} path={route?.url} element={route?.element} />
              );
            } else {
              return (
                <Route
                  key={index}
                  path={route?.url}
                  element={<PrivateRoute> {route?.element} </PrivateRoute>}
                />
              );
            }
          })}
      </Route>

      <Route element={<MainLayout />}>
        <Route element={<LeadsLayout />}>
          {routes
            .filter((a) => a.layout === "leads")
            .map((route, index) => {
              if (route.isPublic) {
                return (
                  <Route
                    key={index}
                    path={route?.url}
                    element={route?.element}
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={route?.url}
                    element={<PrivateRoute> {route?.element} </PrivateRoute>}
                  />
                );
              }
            })}
        </Route>

        <Route element={<CustomerLayout />}>
          {routes
            .filter((a) => a.layout === "customer")
            .map((route, index) => {
              if (route.isPublic) {
                return (
                  <Route
                    key={index}
                    path={route?.url}
                    element={route?.element}
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={route?.url}
                    element={<PrivateRoute> {route?.element} </PrivateRoute>}
                  />
                );
              }
            })}
        </Route>
        <Route element={<LeadsDetailLayout />}>
          {routes
            .filter((a) => a.layout === "leadsDetail")
            .map((route, index) => {
              if (route.isPublic) {
                return (
                  <Route
                    key={index}
                    path={route?.url}
                    element={route?.element}
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={route?.url}
                    element={<PrivateRoute> {route?.element} </PrivateRoute>}
                  />
                );
              }
            })}
        </Route>

        <Route path='*' element={<NotFoundPage />} />
      </Route>

      {redirectRoutes.map(({ path, to, tab }, index) => (
        <Route
          key={index}
          path={path}
          element={
            <PrivateRoute>
              <RedirectWithQueryParams to={to} tab={tab} />
            </PrivateRoute>
          }
        />
      ))}
    </Routes>
  );
};

export default RouterComponent;
