import React, { useEffect } from "react";
import { Card } from "antd";
import CustomerNotes from "../SaveNotes";
import { useCurrentTab } from "../../common/customHooks/useCurrentTab";
import LeadProfileMenu from "./components/LeadProfileMenu";
import { useLocation } from "react-router-dom";
import useAppData from "../../common/customHooks/useAppData";
import Title from "antd/es/typography/Title";
import useAuthorizedTabs from "./hooks/useAuthorizedTabs";
import TabKeys from "../../common/constants/LeadProfileTabKeys";
import Notes from "../Notes";
import "./leadProfile.scss";
import LeadProfileTabKeys from "../../common/constants/LeadProfileTabKeys";
import IntakeScreening from "../IntakeScreening";

interface ComponentMap {
  [key: string]: {
    title: string;
    component: React.ReactNode;
  };
}

const LeadProfile: React.FC = () => {
  const location = useLocation();
  const availableTabs = useAuthorizedTabs();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const { activeTab, setTab } = useCurrentTab();
  const { isLoading, error } = useAppData(id);

  const componentMap: ComponentMap = {
    [TabKeys.NOTES_TASKS]: {
      title: "Notes & Tasks",
      component: <Notes isLead />,
    },
    [TabKeys.PRE_QUALIFICATIONS]: {
      title: "Intake Screening",
      component: isLoading || error ? null : <IntakeScreening isLead />,
    },
  };

  const filteredComponentMap = Object.fromEntries(
    Object.entries(componentMap).filter(([key]) => {
      return availableTabs[key as keyof typeof availableTabs];
    })
  );

  useEffect(() => {
    if (!(activeTab in filteredComponentMap)) {
      setTab(LeadProfileTabKeys.NOTES_TASKS);
    }
  }, [activeTab, filteredComponentMap, setTab]);

  const activeComponent =
    filteredComponentMap[activeTab as keyof ComponentMap] ||
    filteredComponentMap[TabKeys.NOTES_TASKS];

  return (
    <>
      <CustomerNotes IsLeads={true} />

      <div className='lead-profile-menu-section'>
        {activeComponent && (
          <>
            <LeadProfileMenu
              activeTab={activeTab}
              handleMenuClick={(e) => setTab(e.key)}
            />
            <Card bordered style={{ marginTop: 16 }}>
              <Title level={4}>{activeComponent.title}</Title>
              {activeComponent.component}
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default LeadProfile;
