import { useState, useEffect } from "react";

const useMediaQuery = (breakpoint: number) => {
  const [isCompact, setIsCompact] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    const handleResize = () => setIsCompact(window.innerWidth < breakpoint);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return isCompact;
};

export default useMediaQuery;
