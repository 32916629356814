import LeftMenu from "./components/LeftMenu";
import RightMenu from "./components/RightMenu";
import horizontal_logo from "../../images/horizontal-logo.png";
import compact_logo from "../../images/compact-logo.png";
import { Link } from "react-router-dom";
import useMediaQuery from "../../common/customHooks/useMediaQuery";
import "./navigation.scss";

const Navbar: React.FC = () => {
  const isCompactLogo = useMediaQuery(1100);

  return (
    <div className='navbar-container'>
      <Link to='/leads'>
        {isCompactLogo ? (
          <img src={compact_logo} className='navbar-logo-compact' />
        ) : (
          <img src={horizontal_logo} className='navbar-logo' />
        )}
      </Link>
      <LeftMenu />
      <RightMenu />
    </div>
  );
};

export default Navbar;
