import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Breadcrumbs from "../components/Navigation/components/Breadcrumbs";
import Navbar from "../components/Navigation/Navbar";
import "./mainLayout.scss";

const { Header, Content } = Layout;

const MainLayout = () => {
  return (
    <Layout className='main-layout'>
      <Header className='main-header'>
        <Navbar />
      </Header>
      <Layout className='main-content-layout'>
        <div className='breadcrumbs-container'>
          <Breadcrumbs />
        </div>
        <Content className='content'>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
