import { useLocation, useNavigate } from "react-router-dom";

export const useCurrentTab = () => {
  const location = useLocation();
  const history = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab") || "1";

  const setTab = (key: string) => {
    const newParams = new URLSearchParams(location.search);
    newParams.set("tab", key);
    history({
      pathname: location.pathname,
      search: newParams.toString(),
    });
  };

  return {
    activeTab,
    setTab,
  };
};
