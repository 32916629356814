import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import Title from "antd/es/typography/Title";
import useBreadcrumbsData from "../hooks/useBreadcrumbsData";

const Breadcrumbs: React.FC = () => {
  const { breadcrumbItems, pageTitle } = useBreadcrumbsData();

  return (
    <>
      {pageTitle && (
        <Title style={{ marginBottom: 0, paddingBottom: 0 }} level={3}>
          {pageTitle}
        </Title>
      )}
      {breadcrumbItems.length > 0 && (
        <Breadcrumb>
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              {item.href ? (
                <Link style={{ textDecoration: "none" }} to={item.href}>
                  {item.title}
                </Link>
              ) : (
                item.title
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </>
  );
};

export default Breadcrumbs;
