import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Table,
  Tooltip,
  Select,
  Button,
  Typography,
} from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Constants } from "../../common/constants";
import { number } from "../../validations/numeric_only-validate";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { FlashMessage } from "../../utils/flash_message";
import { getNotificationTemplate } from "../../actions/notificationTemplate";
import { getAllPipeline, getStatusByPipelineId } from "../../actions/pipeline";
import {
  saveNotificationConfiguration,
  getNotificationConfiguration,
  getNotificationConfigurationById,
  updateNotificationConfiguration,
  deleteNotificationConfiguration,
} from "../../actions/notificationConfiguration";
import Switch from "react-switch";
import Loader from "../../components/Loader";
import usePermissions from "../../common/customHooks/usePermissions";
import { CaseInsensitiveContains } from "../../utils/commonHelper";

const NotificationConfiguration = () => {
  const [form] = Form.useForm();
  const [template, setTemplate] = useState([]);
  const [user, setUser] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [userById, setUserById] = useState({});
  const [isText, setIsText] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isMail, setIsMail] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [pipe, setPipe] = useState([]);
  const [pipeStatus, setPipeStatus] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [searchString, setSearchString] = useState("");
  const { canWrite } = usePermissions("notificationConfiguration");
  const formSubmit = () => {
    form.submit();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Pipeline",
      dataIndex: "pipelineName",
      key: "pipelineName",
    },
    {
      title: "Status",
      dataIndex: "pipelineStatusName",
      key: "pipelineStatusName",
    },
    {
      title: "Text Template",
      dataIndex: "textTemplateName",
      key: "textTemplateName",
    },
    {
      title: "Email Template",
      dataIndex: "emailTemplateName",
      key: "emailTemplateName",
    },
    {
      title: "Trigger (Days)",
      dataIndex: "triggerDays",
      key: "triggerDays",
    },
    {
      title: "Trigger (Hours)",
      dataIndex: "triggerHours",
      key: "triggerHours",
    },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled: boolean) => {
        return (
          <>
            <div className='col-sm-12 p-0'>
              <Switch onChange={() => console.log("ff")} checked={isEnabled} />
              <span className='checkmark'></span>
            </div>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        if (canWrite) {
          return (
            <div className='d-flex'>
              <Tooltip title={"Edit"}>
                <a
                  className='mr-2'
                  onClick={() => getNotificationConfigurationByIdApi(id)}
                >
                  <EditFilled
                    style={{
                      fontSize: "25px",
                      color: "#747497",
                    }}
                  />
                </a>
              </Tooltip>
              <Tooltip title={"Delete"}>
                <a
                  className='mr-2'
                  onClick={() => deleteNotificationConfigurationApi(id)}
                >
                  <DeleteFilled
                    style={{
                      fontSize: "25px",
                      color: "#747497",
                    }}
                  />
                </a>
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];

  const handleActive = (checked: any) => {
    setIsEnabled(checked);
  };

  const handleText = (checked: any) => {
    setIsText(checked);
  };

  const handleEmail = (checked: any) => {
    setIsEmail(checked);
  };

  const handlePhone = (checked: any) => {
    setIsPhone(checked);
  };
  const handleMail = (checked: any) => {
    setIsMail(checked);
  };

  const addPopUp = () => {
    getNotificationTemplateApi();
    getAllPipelineApi();
    setIsAdd(true);
    setOpen(true);
    setPipeStatus([]);
    resetFormValues();
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      name: "",
      pipeline: "",
      status: null,
      textTemp: "",
      emailTemp: "",
      phoneTemp: "",
      mailTemp: "",
      triggerDays: "",
      triggerHours: "",
      pipelineStatusName: null,
    };
    setIsEnabled(false);
    setIsEmail(false);
    setIsText(false);
    setIsPhone(false);
    setIsMail(false);
    setTimeout(() => {
      setUserById(formDefaultValues);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const addUpdateNotificationConfigurationApi = (e: any) => {
    var payload = {
      Id: isAdd ? 0 : e.id,
      Name: e.name,
      PipelineId: e.pipeline,
      PipelineStatusId: e.status,
      TriggerDays: parseInt(e.triggerDays),
      TriggerHours: parseInt(e.triggerHours),
      IsText: isText,
      IsEmail: isEmail,
      IsPhone: isPhone,
      IsMail: isMail,
      TextTemplate: isText ? e.textTemp : null,
      EmailTemplate: isEmail ? e.emailTemp : null,
      PhoneTemplate: isPhone ? e.phoneTemp : null,
      MailTemplate: isMail ? e.mailTemp : null,
      IsEnabled: isEnabled,
    };
    if (isAdd) {
      setIsPopUpLoader(true);
      saveNotificationConfiguration(payload, form).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage(
            "success",
            "Notification Configuration added Successfully."
          );
          setOpen(false);
          setIsPopUpLoader(false);
          getNotificationConfigurationApi();
        } else {
          FlashMessage("error", res.data.responseMessage);
          // setOpen(false);
          setIsPopUpLoader(false);
        }
      });
    } else {
      setIsPopUpLoader(true);
      updateNotificationConfiguration(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage(
            "success",
            "Notification Configuration updated Successfully."
          );
          setOpen(false);
          setIsPopUpLoader(false);
          getNotificationConfigurationApi();
        } else {
          FlashMessage("error", res.data.responseMessage);
          setIsPopUpLoader(false);
        }
      });
    }
  };

  const cancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const getNotificationConfigurationApi = () => {
    setIsLoader(true);
    setIsAdd(false);
    getNotificationConfiguration().then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        setUser(userdata);
        setIsLoader(false);
      }
    });
  };

  useEffect(() => {
    getNotificationConfigurationApi();
  }, []);

  const deleteNotificationConfigurationApi = (id: any) => {
    setIsLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteNotificationConfiguration(id).then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", response.data.responseMessage);
          setIsLoader(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setIsLoader(false);
        }
        getNotificationConfigurationApi();
      });
    } else {
      setIsLoader(false);
    }
  };

  const getNotificationConfigurationByIdApi = (id: any) => {
    setIsPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    getNotificationConfigurationById(id).then((res) => {
      var appRes = res.data.responseData;
      appRes.pipeline = appRes.pipelineId;
      appRes.status = appRes.pipelineStatusId;
      appRes.emailTemp = appRes.emailTemplate;
      appRes.textTemp = appRes.textTemplate;
      appRes.phoneTemp = appRes.phoneTemplate;
      appRes.mailTemp = appRes.mailTemplate;
      setUserById(appRes);
      setIsText(appRes.isText);
      setIsEmail(appRes.isEmail);
      setIsPhone(appRes.isPhone);
      setIsMail(appRes.isMail);
      setIsEnabled(appRes.isEnabled);
      form.setFieldsValue(appRes);
      getAllPipelineApi();
      getStatusByPipelineIdApi(appRes.pipelineId);
      setIsPopUpLoader(false);
      getNotificationTemplateApi(appRes);
    });
  };

  const getAllPipelineApi = () => {
    getAllPipeline().then((res) => {
      if (res.data.responseCode == 200) {
        var pipelineData = res.data.responseData;
        setPipe(pipelineData);
      }
    });
  };

  const getStatusByPipelineIdApi = (pipeline: any) => {
    getStatusByPipelineId(pipeline).then((res) => {
      var resData = res.data.responseData;
      setPipeStatus(resData);
    });
  };

  const pipelineChange = (e: any) => {
    getStatusByPipelineIdApi(e);
  };

  const getNotificationTemplateApi = (appRes: any = null) => {
    setIsAdd(false);
    getNotificationTemplate().then((res) => {
      var userdata = res.data.responseData;

      if (appRes != null) {
        var emailTempFil = userdata.filter(
          (a: any) => a.id === appRes.emailTemp
        );

        if (emailTempFil.length <= 0) {
          appRes.emailTemp = null;
        }
        var textTempFil = userdata.filter((a: any) => a.id === appRes.textTemp);
        if (textTempFil.length <= 0) {
          appRes.textTemp = null;
        }
        var phoneTempFil = userdata.filter(
          (a: any) => a.id === appRes.phoneTemp
        );
        if (phoneTempFil.length <= 0) {
          appRes.phoneTemp = null;
        }
        var mailTempFil = userdata.filter((a: any) => a.id === appRes.mailTemp);
        if (mailTempFil.length <= 0) {
          appRes.mailTemp = null;
        }
        setUserById(appRes);
        form.setFieldsValue(appRes);
      }
      if (res.data.responseCode == 200) {
        setTemplate(userdata);
      }
    });
  };

  return (
    <>
      <Typography.Title level={3}>Notification Configurations</Typography.Title>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          <Modal
            title={
              isAdd
                ? "Add Notification Configuration"
                : "Update Notification Configuration"
            }
            centered
            okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
            open={open}
            onOk={() => (isPopUpLoader ? null : formSubmit())}
            onCancel={() => cancel()}
            width={1000}
          >
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <Form
                className='mt-4'
                form={form}
                name='TenantForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={userById}
                autoComplete='off'
                onFinish={addUpdateNotificationConfigurationApi}
              >
                <div>
                  <Form.Item name='id' />
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      1. Name
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='name'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      2. Pipeline
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='pipeline'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                      >
                        <Select
                          placeholder='Choose the pipeline'
                          onChange={pipelineChange}
                          options={pipe.map((loc: any) => ({
                            label: loc.name,
                            value: loc.id,
                          }))}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      3. Status
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='status'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                      >
                        <Select
                          placeholder='Choose the pipeline status'
                          options={pipeStatus.map((loc: any) => ({
                            label: loc.name,
                            value: loc.id,
                          }))}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      4. Enabled
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Switch onChange={handleActive} checked={isEnabled} />
                      <span className='checkmark'></span>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      5. Trigger (Days)
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='triggerDays'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          { validator: number },
                        ]}
                      >
                        <Input
                          maxLength={3}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      6. Trigger (Hours)
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='triggerHours'
                        rules={[{ validator: number }]}
                      >
                        <Input
                          maxLength={2}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='grid'>
                    <div className='form-group row margin_mobile'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        7. Text
                      </label>
                      <div className='col-sm-9 p-0'>
                        <div className='row'>
                          <div className='col-sm-1'>
                            <Switch onChange={handleText} checked={isText} />
                            <span className='checkmark'></span>
                          </div>
                          {isText ? (
                            <>
                              <label className='col-form-label fill_description col-sm-3 des_font text-center'>
                                Select Template
                              </label>
                              <div className='col-sm-7 p-0'>
                                <Form.Item
                                  name='textTemp'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the value!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder='Choose the Template'
                                    options={template.map((loc: any) => ({
                                      label: loc.name,
                                      value: loc.id,
                                    }))}
                                  />
                                </Form.Item>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='form-group row margin_mobile'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        8. Email
                      </label>
                      <div className='col-sm-9 p-0'>
                        <div className='row'>
                          <div className='col-sm-1'>
                            <Switch onChange={handleEmail} checked={isEmail} />
                            <span className='checkmark'></span>
                          </div>
                          {isEmail ? (
                            <>
                              <label className='col-form-label fill_description col-sm-3 des_font text-center'>
                                Select Template
                              </label>
                              <div className='col-sm-7 p-0'>
                                <Form.Item
                                  name='emailTemp'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the value!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder='Choose the Template'
                                    options={template.map((loc: any) => ({
                                      label: loc.name,
                                      value: loc.id,
                                    }))}
                                  />
                                </Form.Item>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='form-group row margin_mobile'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        9. Phone
                      </label>
                      <div className='col-sm-9 p-0'>
                        <div className='row'>
                          <div className='col-sm-1'>
                            <Switch onChange={handlePhone} checked={isPhone} />
                            <span className='checkmark'></span>
                          </div>
                          {isPhone ? (
                            <>
                              <label className='col-form-label fill_description col-sm-3 des_font text-center'>
                                Select Template
                              </label>
                              <div className='col-sm-7 p-0'>
                                <Form.Item
                                  name='phoneTemp'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the value!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder='Choose the Template'
                                    options={template.map((loc: any) => ({
                                      label: loc.name,
                                      value: loc.id,
                                    }))}
                                  />
                                </Form.Item>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='form-group row margin_mobile'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        10. Mail
                      </label>
                      <div className='col-sm-9 p-0'>
                        <div className='row'>
                          <div className='col-sm-1'>
                            <Switch onChange={handleMail} checked={isMail} />
                            <span className='checkmark'></span>
                          </div>
                          {isMail ? (
                            <>
                              <label className='col-form-label fill_description col-sm-3 des_font text-center'>
                                Select Template
                              </label>
                              <div className='col-sm-7 p-0'>
                                <Form.Item
                                  name='mailTemp'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the value!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder='Choose the Template'
                                    options={template.map((loc: any) => ({
                                      label: loc.name,
                                      value: loc.id,
                                    }))}
                                  />
                                </Form.Item>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Modal>
          <div className='d-flex justify-content-end'>
            <div className='col-md-4 mb-4'>
              <div className='d-flex justify-content-end mt-5'>
                <Input
                  placeholder='Search configurations...'
                  className={"input_style"}
                  style={{
                    borderRadius: "5px",
                    marginRight: "20px",
                  }}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
                {canWrite && (
                  <Button type='primary' onClick={() => addPopUp()}>
                    Add
                  </Button>
                )}
              </div>
            </div>
          </div>
          <Table
            dataSource={user.filter(
              (item: any) =>
                CaseInsensitiveContains(item.name, searchString) ||
                CaseInsensitiveContains(item.textTemplateName, searchString) ||
                CaseInsensitiveContains(item.emailTemplateName, searchString)
            )}
            pagination={{
              pageSize: Constants.notificationConfigurationPageSize,
              showSizeChanger: false,
              showTotal: (total) => {
                return `Total Count:  ${total}`;
              },
            }}
            columns={columns}
          />
        </>
      )}
    </>
  );
};

export default NotificationConfiguration;
