import React, { useEffect } from "react";
import { Card } from "antd";
import CustomerNotes from "../SaveNotes";
import { useCurrentTab } from "../../common/customHooks/useCurrentTab";
import ClaimantProfileMenu from "./components/ClaimantProfileMenu";
import Notes from "../Notes";
import FaxManager from "../../pages/FaxManager";
import UploadDoc from "../../pages/UploadDoc";
import GenerateDocuments from "../../pages/GenerateDocuments";
import SendPhysicalMail from "../../pages/SendPhysicalMail";
import RequestedInfo from "../../pages/RequestedInfo";
import AdminTemplates from "../AdminTemplates";
import { useLocation } from "react-router-dom";
import IntakeScreening from "../IntakeScreening";
import useAppData from "../../common/customHooks/useAppData";
import Title from "antd/es/typography/Title";
import useAuthorizedTabs from "./hooks/useAuthorizedTabs";
import ClaimantProfileTabKeys from "../../common/constants/ClaimantProfileTabKeys";
import CaseManagementAISummary from "../CaseManagementAI/CaseManagementAISummary";
import "./claimantProfile.scss";
import SSAForm from "../../pages/SSAForm";

interface ComponentMap {
  [key: string]: {
    title: string;
    component: React.ReactNode;
  };
}

const ClaimantProfile: React.FC = () => {
  const location = useLocation();
  const availableTabs = useAuthorizedTabs();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const { activeTab, setTab } = useCurrentTab();
  const { appData, isLoading, error } = useAppData(id);

  const componentMap: ComponentMap = {
    [ClaimantProfileTabKeys.SUMMARY]: {
      title: "Claimant Summary",
      component: (
        <CaseManagementAISummary claimantId={Number(appData?.mindsetUserId)} />
      ),
    },
    [ClaimantProfileTabKeys.NOTES_TASKS]: {
      title: "Notes & Tasks",
      component: <Notes isLead={false} />,
    },
    [ClaimantProfileTabKeys.PRE_QUALIFICATIONS]: {
      title: "Intake Screening",
      component: isLoading || error ? null : <IntakeScreening isLead={false} />,
    },
    [ClaimantProfileTabKeys.APPLICATION]: {
      title: "Application",
      component: <SSAForm />,
    },
    [ClaimantProfileTabKeys.TEMPLATES]: {
      title: "Templates",
      component: <AdminTemplates />,
    },
    [ClaimantProfileTabKeys.DOCUMENTS]: {
      title: "Documents",
      component: <UploadDoc />,
    },
    [ClaimantProfileTabKeys.GENERATE_DOCUMENTS]: {
      title: "Generate Documents",
      component: <GenerateDocuments />,
    },
    [ClaimantProfileTabKeys.FAX_MANAGER]: {
      title: "Fax Manager",
      component: <FaxManager />,
    },
    [ClaimantProfileTabKeys.SEND_PHYSICAL_MAIL]: {
      title: "Send Physical Mail",
      component: <SendPhysicalMail />,
    },
    [ClaimantProfileTabKeys.CONTACT_MESSAGES]: {
      title: "Contact Messages",
      component: <RequestedInfo />,
    },
  };

  const filteredComponentMap = Object.fromEntries(
    Object.entries(componentMap).filter(([key]) => {
      return availableTabs[key as keyof typeof availableTabs];
    })
  );

  useEffect(() => {
    if (!(activeTab in filteredComponentMap)) {
      setTab(ClaimantProfileTabKeys.NOTES_TASKS);
    }
  }, [activeTab, filteredComponentMap, setTab]);

  const activeComponent =
    filteredComponentMap[activeTab as keyof ComponentMap] ||
    filteredComponentMap[ClaimantProfileTabKeys.NOTES_TASKS];

  return (
    <>
      <CustomerNotes />

      <div className='claimant-profile-menu-section'>
        {activeComponent && (
          <>
            <ClaimantProfileMenu
              activeTab={activeTab}
              handleMenuClick={(e) => setTab(e.key)}
            />
            <Card style={{ marginTop: 16 }}>
              <Title level={4}>{activeComponent.title}</Title>
              {activeComponent.component}
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default ClaimantProfile;
