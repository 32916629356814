import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

// Extend dayjs with required plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

/**
 * Formats a given UTC date string into a localized format with fixed EST/EDT timezone abbreviation.
 * @param dateString - The UTC date string to format.
 * @returns Formatted date string (e.g., "Feb. 7th, 2025 at 11:15:51 AM EST")
 */
export const formatDateWithTimezone = (dateString?: string): string => {
  if (!dateString) return "Invalid date";

  // Step 1: Ensure the date is parsed as UTC
  const utcDate = dayjs.utc(dateString);

  // Step 2: Convert to EST/EDT (fixed timezone)
  const estDate = utcDate.tz("America/New_York"); // EST/EDT timezone

  // Step 3: Output formatted string with timezone abbreviation
  const formattedDate = estDate.format("MMM. Do, YYYY [at] h:mm:ss A z");

  return formattedDate;
};
