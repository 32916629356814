import { Button, Form } from "antd";
import { validateBlankSpace } from "../../../validations/validateBlankSpace";
import { useEffect, useState } from "react";
import {
  getUpdatedMentionsValue,
  GroupUser,
  MentionUser,
} from "../../../utils/mentions";
import { getGroupUsers } from "../../../actions/notes";
import { getSupportUsers } from "../../../actions/supportUser";
import { Enums } from "../../../common/enums";
import NotesMetaData from "../../SaveNotes/NoteMetaData";
import { Mention, MentionsInput } from "react-mentions";
import defaultMentionStyle from "../../SaveNotes/defaultMentionStyle";
import { get } from "../../../utils/clientStorageUtils";
import { Spinner } from "react-bootstrap";
import { INTAKE_METHODS_DEFUALT_NOTE } from "./Claims/constants";

const CreateNoteWrapper = ({
  value,
  onChange,
  intakeMethod,
  defaultValues,
  caseManagerName,
}: {
  value: any;
  onChange: Function;
  intakeMethod?: "admin" | "ssa";
  defaultValues: any;
  caseManagerName?: string;
}) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<MentionUser[]>([]);
  const [groups, setGroups] = useState<GroupUser[]>([]);

  const handleChangeSingle = (
    e: any,
    newValue: any,
    newPlainTextValue: any,
    _mentions: any
  ) => {
    const { newTagUserIds, newSingleLineValue, newPlainText, newTagsText } =
      getUpdatedMentionsValue(
        newValue,
        newPlainTextValue,
        _mentions,
        users,
        groups
      );

    // Backup mentioning
    let tagUserIds = newTagUserIds;
    if (_mentions.length > 0 && !tagUserIds) {
      tagUserIds = _mentions.map((user: any) => `U:${user.id}`).join(",");
    }

    onChange({
      ...value,
      tagUserIds,
      description: newSingleLineValue,
      plainText: newPlainText,
      tagsText: newTagsText,
    });
  };

  const getSupportUsersApi = async () => {
    const res = await getSupportUsers();
    const userdata = res.data.responseData;
    if (res.data.responseCode == 200) {
      const userid = JSON.parse(get("model"))
        ? JSON.parse(get("model")).userId
        : "";
      const filterData = userdata
        .map((ele: any) => ({
          id: ele.id,
          display: `${ele.firstName} ${ele.lastName}`,
        }))
        .filter((ele: any) => ele.id != userid);
      setUsers(filterData);
    }
  };

  const getGroupUserApi = async () => {
    const res = await getGroupUsers();
    if (res.data.responseCode == Enums.responseCodes.success) {
      const groupData = res.data.responseData.map((ele: any) => ({
        id: ele.groupId,
        display: ele.groupName,
      }));
      setGroups(groupData);
    }
  };

  useEffect(() => {
    getGroupUserApi();
    getSupportUsersApi();
  }, []);

  useEffect(() => {
    if (!intakeMethod) {
      setIsLoading(false);
    } else {
      if (users.length > 0) {
        let tagUserIdsArr: string[] = [];

        let method_tags_text = INTAKE_METHODS_DEFUALT_NOTE[intakeMethod] || "";

        // Regular expression to match the pattern [name]()
        const mentions_regex = /\[([^\]]+)\]\(\)/g;

        // Replace the mention pattern [name]() with @[name](id)
        method_tags_text = method_tags_text.replace(
          mentions_regex,
          (_matchingText, userDisplayName) => {
            const mentioned_user = users.find(
              (user) => user.display === userDisplayName
            );

            if (!mentioned_user) {
              return "";
            }

            tagUserIdsArr.push(`U:${mentioned_user.id}`);
            return `@[${mentioned_user.display}](${mentioned_user.id})`;
          }
        );

        // Mention the case manager, if set.
        let case_manager_mention = null;
        if (caseManagerName) {
          case_manager_mention = users.find(
            (user) => user.display === caseManagerName
          );
          if (case_manager_mention) {
            method_tags_text = method_tags_text.replace(
              "ENTER_CASE_MANAGER",
              `@[${case_manager_mention.display}](${case_manager_mention.id})`
            );
            tagUserIdsArr.push(`U:${case_manager_mention.id}`);
          }
        }

        const intial_values = {
          ...defaultValues,
          tagsText: method_tags_text,
          description: method_tags_text,
          plainText: method_tags_text,
        };
        let tagUserIds = tagUserIdsArr.join(",");
        form.setFieldsValue(intial_values);
        onChange({
          ...intial_values,
          tagUserIds,
        });
        setIsLoading(false);
      }
    }
  }, [intakeMethod, users]);

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-md-12 text-center mt-2'>
          <Spinner size='sm' />
        </div>
      </div>
    );
  }

  return (
    <Form
      form={form}
      name='NotesForm'
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete='off'
      initialValues={defaultValues}
    >
      <div className='row'>
        <div className='col-md-12'>
          <Form.Item
            className='note_add'
            name='plainText'
            rules={[
              {
                required: true,
                message: "Please input the value!",
              },
              { validator: validateBlankSpace },
            ]}
          >
            <MentionsInput
              id='intake-note-mentions-input'
              className='intake-note-area'
              singleLine={false}
              value={value.tagsText}
              onChange={handleChangeSingle}
              placeholder='Post a note'
              allowSpaceInQuery={true}
            >
              <Mention
                style={defaultMentionStyle}
                trigger='@'
                data={groups}
                displayTransform={(id: any, name: any) => `@${name}`}
                appendSpaceOnAdd={true}
              />
              <Mention
                style={defaultMentionStyle}
                trigger='@'
                data={users}
                displayTransform={(id: any, name: any) => `@${name}`}
                appendSpaceOnAdd={true}
              />
            </MentionsInput>
          </Form.Item>
          <NotesMetaData
            mode='Claims'
            noteType={value.type}
            setNoteType={(val: string[]) => onChange({ ...value, type: val })}
            noteMinutes={value.minutes}
            setNoteMinutes={(val: number) =>
              onChange({ ...value, minutes: val })
            }
            noteSource={value.source}
            setNoteSource={(val: string) => onChange({ ...value, source: val })}
            noteCategory={value.category}
            setNoteCategory={(val: string) =>
              onChange({ ...value, category: val })
            }
          />
        </div>
      </div>
    </Form>
  );
};

export default CreateNoteWrapper;
