import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import usePipelinesAndStatuses from "../../../common/customHooks/usePipelinesAndStatuses";

const useBreadcrumbsData = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<
    { title: string; href: string | null }[]
  >([]);
  const [pageTitle, setPageTitle] = useState<string | null>(null);

  const { pipelines, pipelineStatuses } = usePipelinesAndStatuses();
  const location = useLocation();
  const path = location.pathname;

  const queryParams = new URLSearchParams(location.search);
  const pipelineId = queryParams.get("pipelineId");
  const pipelineStatusId = queryParams.get("pipelineStatusId");
  const onlyShowMyCases = queryParams.get("onlyShowMyCases");

  const getPipelineById = (pipelineId: string) =>
    pipelines.find((pipeline) => pipeline.id.toString() === pipelineId);

  const getStatusById = (statusId: string) =>
    pipelineStatuses.find((status) => status.id.toString() === statusId);

  const pageTitleMapping = {
    "/claimants": "Claimants",
    "/leads": "Leads",
  };

  useEffect(() => {
    let updatedBreadcrumbItems: { title: string; href: string | null }[] = [];

    if (path.includes("/claimants")) {
      setPageTitle(
        path === "/claimants"
          ? pageTitleMapping["/claimants"]
          : "Claimant Profile"
      );
    } else if (path.includes("/leads")) {
      setPageTitle(
        path === "/leads" ? pageTitleMapping["/leads"] : "Lead Profile"
      );
    } else {
      setPageTitle(null);
    }

    const buildHref = (
      basePath: string,
      extraParams: Record<string, string | null>,
      removeParams: string[] = []
    ) => {
      const newParams = new URLSearchParams(location.search);

      Object.entries(extraParams).forEach(([key, value]) => {
        if (value) {
          newParams.set(key, value);
        } else {
          newParams.delete(key);
        }
      });

      removeParams.forEach((param) => newParams.delete(param));

      return `${basePath}?${newParams.toString()}`;
    };

    if (pipelineId) {
      const pipeline = getPipelineById(pipelineId);

      if (pipeline) {
        if (path.includes("/claimants")) {
          updatedBreadcrumbItems.push({
            title: "Claimants",
            href: buildHref(
              "/claimants",
              { pipelineId: "2", onlyShowMyCases },
              ["pipelineStatusId"]
            ),
          });
        } else if (path.includes("/leads")) {
          updatedBreadcrumbItems.push({
            title: "Leads",
            href: buildHref("/leads", { pipelineId: "1", onlyShowMyCases }, [
              "pipelineStatusId",
            ]),
          });
        }

        updatedBreadcrumbItems.push({
          title: pipeline.name || "Loading",
          href: buildHref(
            path.includes("/claimants") ? "/claimants" : "/leads",
            { pipelineId, onlyShowMyCases },
            ["pipelineStatusId"]
          ),
        });
      }
    }

    if (pipelineStatusId) {
      const status = getStatusById(pipelineStatusId);
      if (status) {
        updatedBreadcrumbItems.push({
          title: status.name,
          href: null,
        });
      }
    }

    // Ensure last breadcrumb has no link
    updatedBreadcrumbItems = updatedBreadcrumbItems.map((item, index) => {
      if (index === updatedBreadcrumbItems.length - 1) {
        return { ...item, href: null };
      }
      return item;
    });

    setBreadcrumbItems(updatedBreadcrumbItems);
  }, [location.search, path, pipelines, pipelineStatuses]);

  return { breadcrumbItems, pageTitle };
};

export default useBreadcrumbsData;
