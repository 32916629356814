import { useMemo } from "react";
import ClaimantProfileTabKeys from "../../../common/constants/ClaimantProfileTabKeys";
import useUser from "../../../common/customHooks/useUser";
import useFeatureFlag from "../../useFeatureFlag";
import { Constants } from "../../../common/constants";
import useGroupUsers from "../../../common/customHooks/useGroupUsers";

const useAuthorizedTabs = () => {
  const user = useUser();
  const { featureFlags, loading } = useFeatureFlag();
  const { groups } = useGroupUsers();

  const hasPermission = (
    permissionGroup: string[],
    featureFlag?: boolean,
    allowBetaTestingGroup?: boolean
  ) => {
    const hasRolePermission = permissionGroup.includes(user.role);
    let canAccessFeature = false;

    if (loading) {
      return false;
    }

    if (featureFlag == null) {
      canAccessFeature = true;
    } else if (!featureFlag && allowBetaTestingGroup) {
      canAccessFeature =
        groups?.some(
          (group) =>
            group.display === Constants.groupUsers.betaTesters &&
            group.userIds.includes(Number(user.userId))
        ) ?? false;
    } else {
      canAccessFeature = featureFlag ?? false;
    }

    return hasRolePermission && canAccessFeature;
  };

  return useMemo(
    () => ({
      [ClaimantProfileTabKeys.SUMMARY]: hasPermission(
        Constants.permissionedRolesBySection.claimantProfileSensitiveData
          .readGroup,
        featureFlags[Constants.featureFlags.aiCaseSummary],
        true
      ),
      [ClaimantProfileTabKeys.NOTES_TASKS]: hasPermission(
        Constants.permissionedRolesBySection.claimantNotes.readGroup
      ),
      [ClaimantProfileTabKeys.PRE_QUALIFICATIONS]: hasPermission(
        Constants.permissionedRolesBySection.claimantProfileSensitiveData
          .readGroup
      ),
      [ClaimantProfileTabKeys.APPLICATION]: hasPermission(
        Constants.permissionedRolesBySection.claimantProfileSensitiveData
          .readGroup
      ),
      [ClaimantProfileTabKeys.TEMPLATES]: hasPermission(
        Constants.permissionedRolesBySection.templates.readGroup
      ),
      [ClaimantProfileTabKeys.DOCUMENTS]: hasPermission(
        Constants.permissionedRolesBySection.uploadDocuments.readGroup
      ),
      [ClaimantProfileTabKeys.GENERATE_DOCUMENTS]: hasPermission(
        Constants.permissionedRolesBySection.generateDocuments.readGroup
      ),
      [ClaimantProfileTabKeys.FAX_MANAGER]: hasPermission(
        Constants.permissionedRolesBySection.faxManager.readGroup,
        featureFlags[Constants.featureFlags.faxManager]
      ),
      [ClaimantProfileTabKeys.SEND_PHYSICAL_MAIL]: hasPermission(
        Constants.permissionedRolesBySection.sendPhysicalMail.readGroup
      ),
      [ClaimantProfileTabKeys.CONTACT_MESSAGES]: hasPermission(
        Constants.permissionedRolesBySection.requestedInfo.readGroup
      ),
    }),
    [user.role, featureFlags, groups]
  );
};

export default useAuthorizedTabs;
