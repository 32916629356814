import { Outlet } from "react-router-dom";
import { BackTop, Layout } from "antd";

const { Content } = Layout;

const LeadsLayout = () => {
  return (
    <section id='admin_application_section'>
      <Layout>
        <Content>
          <Outlet />
        </Content>
      </Layout>
      <div className='back-top-con'>
        <BackTop />
      </div>
    </section>
  );
};

export default LeadsLayout;
