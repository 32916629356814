import { useEffect, useState } from "react";
import { getSupportUsers } from "../../actions/supportUser";
import { SupportUser, SupportUserResponse } from "../CaseManagement";
import { ReassignmentStep1 } from "./ReassignmentStep1";
import { ReassignmentStep2 } from "./ReassignmentStep2";
import { ReassignmentStep3 } from "./ReassignmentStep3";
import "./styles.css";

const Reassignment = () => {
  const [step, setStep] = useState(0);
  const [supportUsers, setSupportUsers] = useState<SupportUser[]>([]);
  const [supportUserLoading, setSupportUserLoading] = useState(false);
  const [originalCaseManagerId, setOriginalCaseManagerId] =
    useState<number>(-1);
  const [filterOption, setFilterOption] = useState<any>({});
  const getSupportUsersApi = () => {
    setSupportUserLoading(true);
    getSupportUsers(true).then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        setSupportUsers(
          userdata.map((user: SupportUserResponse) => {
            return {
              ...user,
              fullName: `${user.firstName} ${user.lastName}`,
            };
          })
        );
        setSupportUserLoading(false);
      }
    });
  };

  useEffect(() => {
    getSupportUsersApi();
  }, []);
  return (
    <div className='reassignment-container'>
      <div className='reassignment-header'>
        <h1>Case Manager Reassignment</h1>
      </div>

      {step === 0 ? (
        <ReassignmentStep1
          supportUsers={supportUsers}
          supportUserLoading={supportUserLoading}
          setStep={setStep}
          setOriginalCaseManagerId={setOriginalCaseManagerId}
        />
      ) : step === 1 ? (
        <ReassignmentStep2
          originalCaseManager={
            supportUsers.filter((user) => user.id === originalCaseManagerId)[0]
          }
          setFilterOption={setFilterOption}
          setStep={setStep}
        />
      ) : (
        <ReassignmentStep3
          supportUsers={supportUsers.filter((user) => user.isActive)}
          supportUserLoading={supportUserLoading}
          caseManagerId={originalCaseManagerId}
          filterOption={filterOption}
          setStep={setStep}
        />
      )}
    </div>
  );
};
export default Reassignment;
