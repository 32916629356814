import { Button, Form, Select } from "antd";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { SupportUser } from "../CaseManagement";
import { useForm } from "antd/lib/form/Form";
import "./styles.css";

export const ReassignmentStep1 = (props: {
  supportUsers: SupportUser[];
  supportUserLoading: boolean;
  setOriginalCaseManagerId: (id: number) => void;
  setStep: (step: number) => void;
}) => {
  const [form] = useForm();
  const compareByName = (a: SupportUser, b: SupportUser) => {
    const nameA = a.fullName.toUpperCase(); // ignore case
    const nameB = b.fullName.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };
  return (
    <div className='reassignment-container'>
      <div className='reassignment-step'>
        <h2 className='reassignment-title'>Select Original Case Manager</h2>
        <Form
          className='reassignment-form'
          form={form}
          name='SupportUserForm'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete='off'
          onFinish={(e) => {
            props.setOriginalCaseManagerId(e.supportUser);
            props.setStep(1);
          }}
        >
          <Form.Item
            label='Original Case Manager'
            name='supportUser'
            rules={[
              {
                required: true,
                message: "Please select a case manager",
              },
            ]}
          >
            <Select
              placeholder='Search by name'
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={props.supportUserLoading}
              options={props.supportUsers
                .sort(compareByName)
                .map((user: SupportUser) => ({
                  label: user.fullName,
                  value: user.id,
                }))}
            />
          </Form.Item>
          <div className='reassignment-actions'>
            <Button htmlType='submit' type='primary'>
              Next
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
