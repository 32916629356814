import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getLeadsById } from "../../actions/application";
import { FlashMessage } from "../../utils/flash_message";
import { Constants } from "../../common/constants";
import { getTenant } from "../../actions/tenants";
import { Modal, Form, Select, Typography } from "antd";
import { TenantSelectValidator } from "../../validations/validateTenantValue";
import { getApplicationDetailsById } from "../../actions/application";
import {
  updateAssign_ReAssignEnterpriseLeads,
  updateAssign_ReAssignEnterpriseClaimants,
} from "../../actions/pipeline";
import { Enums } from "../../common/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";

const { Title } = Typography;

export interface EnterpriseAssignmentProps {
  roleInStorage: string;
  userId: string;
  isLead: boolean;
  facilityName?: string;
  onEnterpriseUpdated?: () => void;
}

export interface Tenant {
  name: string;
  id: number;
  code: string;
}

export default function EnterpriseAssignment(props: EnterpriseAssignmentProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTenantId, setCurrentTenantId] = useState<number | undefined>();
  const [currentTenant, setCurrentTenant] = useState<Tenant | undefined>(
    undefined
  );
  const location = useLocation();

  const navigate = useNavigate();

  const [formAssign_ReAssignEnterprise] = Form.useForm();

  const getUserTenantInfoByIdApi = () => {
    setIsModalLoading(true);
    setIsLoading(true);
    if (props.isLead) {
      getLeadsById(props.userId).then((res) => {
        var resData = res.data.responseData;
        if (!resData.isActive) {
          FlashMessage(
            "error",
            `This lead (${resData.fullName}) is deactivated.`
          );
          navigate("/leads");
        }
        if (res.data.responseCode == 200) {
          formAssign_ReAssignEnterprise.setFieldsValue(resData);
          setCurrentTenantId(resData.tenantId);
          setIsModalLoading(false);
          setIsLoading(false);
        }
      });
    } else {
      getApplicationDetailsById(props.userId).then((res) => {
        var appRes = res.data.responseData;
        formAssign_ReAssignEnterprise.setFieldsValue(appRes);
        setCurrentTenantId(appRes.tenantId);
        setIsModalLoading(false);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (currentTenantId) {
      setCurrentTenant(tenants.find((t: Tenant) => t.id === currentTenantId));
    } else {
      setCurrentTenant(undefined);
    }
  }, [tenants, currentTenantId]);

  const getTenantId = () => {
    getUserTenantInfoByIdApi();
    setIsModalOpen(true);
  };
  const getTenantApi = () => {
    getTenant().then((res) => {
      var resData = res.data.responseData;
      setTenants(resData);
    });
  };
  useEffect(() => {
    getUserTenantInfoByIdApi();
    getTenantApi();
  }, [props.userId]);

  const onModalCancel = () => {
    setIsModalOpen(false);
    formAssign_ReAssignEnterprise.resetFields();
  };

  const onModalOk = () => {
    formAssign_ReAssignEnterprise.submit();
  };

  const onEnterpriseUpdate = (res: any) => {
    if (res.data.responseCode == Enums.responseCodes.success) {
      setIsLoading(true);
      FlashMessage("success", "Enterprise status updated Successfully.");
      formAssign_ReAssignEnterprise.resetFields();
      setIsModalLoading(false);
    } else {
      setIsModalLoading(false);
    }
    setIsModalOpen(false);
    getTenantApi();
    getUserTenantInfoByIdApi();
    if (props.onEnterpriseUpdated) props.onEnterpriseUpdated();
  };

  const updateAssign_ReAssignEnterpriseApi = (e: any) => {
    setIsModalLoading(true);
    if (
      window.confirm(
        "Are you sure, you want to Assign/Reassign the Enterprise?"
      )
    ) {
      if (props.isLead) {
        updateAssign_ReAssignEnterpriseLeads(props.userId, e.tenantId).then(
          onEnterpriseUpdate
        );
      } else {
        updateAssign_ReAssignEnterpriseClaimants(props.userId, e.tenantId).then(
          onEnterpriseUpdate
        );
      }
    } else {
      setIsModalLoading(false);
    }
  };

  return (
    <div id='enterprise-assignment'>
      <Title level={3} className='brand-header'>
        Partner Organization
      </Title>
      {!isLoading ? (
        <>
          <div id='enterprise-assignment-body'>
            <button
              onClick={getTenantId}
              type='button'
              className={
                "btn btn-primary maping_button w-100" +
                (location.pathname.indexOf(Constants.pageRoutes.notes) >= 0
                  ? " activemenu"
                  : "")
              }
            >
              {currentTenant ? (
                <>
                  <span>{currentTenant.name}</span>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </>
              ) : (
                <>
                  <span>Organization: Click to Assign</span>
                  <FontAwesomeIcon icon={faPlus} />
                </>
              )}
            </button>
          </div>
          {props.facilityName && (
            <div id='enterprise-assignment-facility-name'>
              <strong>Facility Name:</strong> {props.facilityName}
            </div>
          )}
        </>
      ) : (
        <div className='text-center'>
          <Spinner size='sm' />
        </div>
      )}
      <Modal
        title='Assign/Reassign Enterprise'
        open={isModalOpen}
        onCancel={onModalCancel}
        onOk={onModalOk}
        width={500}
      >
        {isModalLoading && (
          <div className='text-center mt-2'>
            <Spinner />
          </div>
        )}
        {!isModalLoading && (
          <Form
            className='mt-4'
            form={formAssign_ReAssignEnterprise}
            name='post'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            autoComplete='off'
            onFinish={updateAssign_ReAssignEnterpriseApi}
          >
            <div className='col-sm-12 p-0 mt-3'>
              <Form.Item
                name='tenantId'
                rules={[{ required: true, validator: TenantSelectValidator }]}
              >
                <Select
                  showSearch
                  optionFilterProp='label'
                  options={tenants
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                    .map((loc: any) => ({
                      label: loc.name,
                      value: loc.id,
                    }))}
                />
              </Form.Item>
            </div>
          </Form>
        )}
      </Modal>
    </div>
  );
}
