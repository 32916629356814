import React from "react";
import { Menu } from "antd";
import {
  FilePdfOutlined,
  FileTextOutlined,
  MailOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd/es/menu";
import ClaimantProfileTabKeys from "../../../common/constants/ClaimantProfileTabKeys";
import useAuthorizedTabs from "../hooks/useAuthorizedTabs";
import { LuSparkles } from "react-icons/lu";

type MenuItem = Required<MenuProps>["items"][number];

interface ClaimantProfileMenuProps {
  activeTab: string;
  handleMenuClick: (e: { key: string }) => void;
}

const ClaimantProfileMenu: React.FC<ClaimantProfileMenuProps> = ({
  activeTab,
  handleMenuClick,
}) => {
  const authorizedTabs = useAuthorizedTabs();

  const generateMenuItem = (
    hasPermission: boolean,
    key: string,
    label: string,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem | null => {
    if (!hasPermission) return null;
    return {
      key,
      label,
      icon,
      children,
    };
  };

  const menuItems: MenuProps["items"] = [
    generateMenuItem(
      authorizedTabs[ClaimantProfileTabKeys.SUMMARY],
      ClaimantProfileTabKeys.SUMMARY,
      "Summary",
      <LuSparkles />
    ),
    generateMenuItem(
      authorizedTabs[ClaimantProfileTabKeys.NOTES_TASKS],
      ClaimantProfileTabKeys.NOTES_TASKS,
      "Notes & Tasks",
      <FileTextOutlined />
    ),
    generateMenuItem(
      true,
      "case-information-dropdown-menu",
      "Case Information",
      <SolutionOutlined />,
      [
        generateMenuItem(
          authorizedTabs[ClaimantProfileTabKeys.PRE_QUALIFICATIONS],
          ClaimantProfileTabKeys.PRE_QUALIFICATIONS,
          "Intake Screening"
        ),
        generateMenuItem(
          authorizedTabs[ClaimantProfileTabKeys.APPLICATION],
          ClaimantProfileTabKeys.APPLICATION,
          "Application"
        ),
        generateMenuItem(
          authorizedTabs[ClaimantProfileTabKeys.TEMPLATES],
          ClaimantProfileTabKeys.TEMPLATES,
          "Templates"
        ),
      ]
    ),
    generateMenuItem(
      true,
      "documents-dropdown-menu",
      "Documents",
      <FilePdfOutlined />,
      [
        generateMenuItem(
          authorizedTabs[ClaimantProfileTabKeys.DOCUMENTS],
          ClaimantProfileTabKeys.DOCUMENTS,
          "Documents"
        ),
        generateMenuItem(
          authorizedTabs[ClaimantProfileTabKeys.GENERATE_DOCUMENTS],
          ClaimantProfileTabKeys.GENERATE_DOCUMENTS,
          "Generate Documents"
        ),
      ]
    ),
    generateMenuItem(
      true,
      "correspondence-dropdown-menu",
      "Correspondence",
      <MailOutlined />,
      [
        generateMenuItem(
          authorizedTabs[ClaimantProfileTabKeys.FAX_MANAGER],
          ClaimantProfileTabKeys.FAX_MANAGER,
          "Fax Manager"
        ),
        generateMenuItem(
          authorizedTabs[ClaimantProfileTabKeys.SEND_PHYSICAL_MAIL],
          ClaimantProfileTabKeys.SEND_PHYSICAL_MAIL,
          "Send Physical Mail"
        ),
        generateMenuItem(
          authorizedTabs[ClaimantProfileTabKeys.CONTACT_MESSAGES],
          ClaimantProfileTabKeys.CONTACT_MESSAGES,
          "Contact Messages"
        ),
      ]
    ),
  ].filter(Boolean);

  return (
    <Menu
      mode='horizontal'
      selectedKeys={[activeTab]}
      onClick={handleMenuClick}
      items={menuItems}
    />
  );
};

export default ClaimantProfileMenu;
