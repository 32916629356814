import React from "react";
import { Menu } from "antd";
import { FileTextOutlined, SolutionOutlined } from "@ant-design/icons";
import { MenuProps } from "antd/es/menu";
import LeadProfileTabKeys from "../../../common/constants/LeadProfileTabKeys";
import useAuthorizedTabs from "../hooks/useAuthorizedTabs";

type MenuItem = Required<MenuProps>["items"][number];

interface LeadProfileMenuProps {
  activeTab: string;
  handleMenuClick: (e: { key: string }) => void;
}

const LeadProfileMenu: React.FC<LeadProfileMenuProps> = ({
  activeTab,
  handleMenuClick,
}) => {
  const authorizedTabs = useAuthorizedTabs();

  const generateMenuItem = (
    hasPermission: boolean,
    key: string,
    label: string,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem | null => {
    if (!hasPermission) return null;
    return {
      key,
      label,
      icon,
      children,
    };
  };

  const menuItems: MenuProps["items"] = [
    generateMenuItem(
      authorizedTabs[LeadProfileTabKeys.NOTES_TASKS],
      LeadProfileTabKeys.NOTES_TASKS,
      "Notes & Tasks",
      <FileTextOutlined />
    ),
    generateMenuItem(
      true,
      "case-information-dropdown-menu",
      "Case Information",
      <SolutionOutlined />,
      [
        generateMenuItem(
          authorizedTabs[LeadProfileTabKeys.PRE_QUALIFICATIONS],
          LeadProfileTabKeys.PRE_QUALIFICATIONS,
          "Intake Screening"
        ),
      ]
    ),
  ].filter(Boolean);

  return (
    <Menu
      mode='horizontal'
      selectedKeys={[activeTab]}
      onClick={handleMenuClick}
      items={menuItems}
    />
  );
};

export default LeadProfileMenu;
