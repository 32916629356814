import { Provider } from "react-redux";
import { App as AntdApp } from "antd";
import store from "./redux/store";
import setUpInterceptor from "./axios/setupAxios";
import { BrowserRouter } from "react-router-dom";
import RouterComponent from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./stylesheet/global.css";
import "./stylesheet/global.scss";
import { FeatureFlagProvider } from "./components/FeatureFlagProvider";
import { initFlashMessage } from "./utils/flash_message";

function App() {
  setUpInterceptor(store);

  const antdApp = AntdApp.useApp();
  initFlashMessage(antdApp);

  return ( 
    <FeatureFlagProvider>
      <Provider store={store}>
        <BrowserRouter>
          <RouterComponent />
        </BrowserRouter>
      </Provider>
    </FeatureFlagProvider>
  );
}

export default App;
