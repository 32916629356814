import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
  TimePicker,
  Tooltip,
} from "antd";
import React, { useState, useEffect } from "react";
import usePermissions from "../customHooks/usePermissions";
import { GroupUser, MentionUser } from "../../utils/mentions";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { get } from "../../utils/clientStorageUtils";
import {
  getGlobalTaskById,
  getTaskPriority,
  getTaskStatus,
  getTaskType,
  saveGlobalTask,
  saveGlobalTaskNotes,
  updateGlobalTask,
} from "../../actions/taskManagement";
import { getGroupUsers, getNotes } from "../../actions/notes";
import { FlashMessage } from "../../utils/flash_message";
import { Enums } from "../enums";
import { getSupportUsers } from "../../actions/supportUser";
import { Constants } from "../constants";
import Loader from "../../components/Loader";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { responseCodes } from "../../components/Common/constants";
import { getOnlyShowMyCasesFromSearchParams } from "../../utils/commonHelper";
import {
  addHearingCalendarEvent,
  deleteHearingCalendarEvent,
  updateHearingCalendarEvent,
} from "../../actions/hearingCalendarEvents";
import { getApplicationDetailsById } from "../../actions/application";
import dayjs from "dayjs";
import { PlusSquareOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
const GlobalTaskNotes = (props: any) => {
  usePermissions("claimantNotes");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [user, setUser] = useState<MentionUser[]>([]);
  const [tagsText, setTagsText] = useState("");
  const [notes, setNotes] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [searchParams] = useSearchParams();
  const [form5] = Form.useForm();
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoaderr, setisPopUpLoaderr] = useState(false);
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const dateFormat = "MM/DD/YYYY";
  const [isAdd, setIsAdd] = useState(false);
  const [isHearingScheduleTask, setIsHearingScheduleTask] = useState(false);
  const [calendarNoteId, setCalendarNoteId] = useState(0);
  const [taskType, setTaskType] = useState([]);
  const [statusId, setStatusId] = useState([]);
  const [priorityId, setPriorityId] = useState([]);
  const [taskById, setTaskById] = useState<any>({});
  const [statusIdsData, setStatusIdsData] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [priority, setPriority] = useState([]);
  const [groups, setgroups] = useState<GroupUser[]>([]);
  const [checkText, setCheckText] = useState(false);
  const [applicationData, setApplicationData] = useState<any>({});
  const [isApplicationDataLoading, setIsApplicationDataLoading] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userIdData = JSON.parse(get("model"))
    ? JSON.parse(get("model")).userId
    : "";
  const [defaultTaskUser, setDefaultTaskUser] = useState({
    assignTo: userIdData,
  });
  const getTaskTypeApi = () => {
    getTaskType().then((res) => {
      if (res.data.responseCode == 200) {
        setTaskType(res.data.responseData);
      }
    });
  };

  const getNotesApi = () => {
    setIsLoader(true);
    var param = searchParams.get("id") ?? "0";
    if (param != "0") {
      getNotes(param, props?.isLeads ?? false).then((res) => {
        var noteData = res.data.responseData;
        if (res.data.responseCode == 200) {
          noteData.sort((a: any, b: any) => {
            if (a.completedBy === null && b.completedBy) return -1;
            if (a.completedBy && b.completedBy === null) return 1;
            return 0;
          });
          setNotes(noteData);
          setIsLoader(false);
        }
      });
    }
  };
  const formSubmitOne = () => {
    form5.submit();
  };
  useEffect(() => {
    getNotesApi();
  }, [searchParams.get("id")]);

  useEffect(() => {
    getGroupUserApi();
    getSupportUsersApi();
    if (!props.isLeads) {
      setIsApplicationDataLoading(true);
      getApplicationDetailsById(searchParams.get("id")).then((res) => {
        setApplicationData(res.data.responseData);
        setIsApplicationDataLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (props?.openModal === true) {
      setOpen1(props?.openModal);
    }
  }, [props?.openModal]);

  useEffect(() => {
    if (props.NoteTypeIdData) {
      getGlobalTaskByIdApi(
        props.NoteTypeIdData.taskId,
        props.NoteTypeIdData.description,
        props.NoteTypeIdData.id,
        props.NoteTypeIdData.statusId,
        props.NoteTypeIdData.createdUserId
      );
    }
  }, [props?.NoteTypeIdData]);

  const getGroupUserApi = () => {
    getGroupUsers().then((res) => {
      if (res.data.responseCode == Enums.responseCodes.success) {
        var groupData = res.data.responseData.map((ele: any) => ({
          id: ele.groupId,
          display: ele.groupName,
        }));
        setgroups(groupData);
        setTagsText("");
      }
    });
  };
  const getSupportUsersApi = () => {
    setisPopUpLoaderr(true);
    getSupportUsers().then((res) => {
      var userdata = res.data.responseData;

      if (res.data.responseCode == 200) {
        setisPopUpLoaderr(false);
        let userid = JSON.parse(get("model"))
          ? JSON.parse(get("model")).userId
          : "";
        var filterData = userdata
          .map((ele: any) => ({
            id: ele.id,
            display: `${ele.firstName} ${ele.lastName}`,
          }))
          .filter((ele: any) => ele.id != userid);
        setUser(filterData);
        let currentId = JSON.parse(get("model"))
          ? JSON.parse(get("model")).id
          : "";
        var allUser = userdata
          .map((a: any) => ({
            id: a.id,
            allUsersDisplay: `${a.firstName} ${a.lastName}`,
          }))
          .filter((b: any) => b.id == userid || b.id != userid);
        setAllUsers(allUser);
      }
    });
  };
  const onChange = (timeString: any): void => {};

  const addUpdateGlobalTask = async (e: any) => {
    var param = searchParams.get("id") ?? "0";
    var storeDueTime =
      dayjs(e.dueDate).format("YYYY-MM-DD") +
      "T" +
      dayjs(e.dueTime).format("HH:mm:ss");

    setIsLoader(true);
    let hearingCalendarEventPayload = {};
    if (
      !props.isLeads &&
      e.typeId === Constants.hearingCalendar.scheduledHearingTaskId
    ) {
      hearingCalendarEventPayload = {
        startDate: dayjs(storeDueTime).utc().format(),
        endDate: dayjs(storeDueTime).utc().add(1, "hours").format(),
        meetingType: e.hearingCalendarMeetingType,
        hearingType: e.hearingCalendarHearingType,
        description: e.description,
        mindsetUserId: applicationData.id,
        id: taskById.hearingCalendarEventId,
      };
    }
    var payload = {
      Id: isAdd ? 0 : e.id,
      Description: e.description,
      // PriorityId: priorityId,
      priorityId: e.priorityId,
      StatusId: statusIdsData == null ? statusId : statusIdsData,
      TypeId: e.typeId,
      OriginalEstimationedHours: e.originalEstimationedHours
        ? e.originalEstimationedHours
        : 0.0,
      DueDate: storeDueTime,
      DueDateUtc: dayjs(storeDueTime).utc().format(),
      NotesId: calendarNoteId,
      AssignedTo:
        e.assignedTo == null || e.assignedTo.length == 0
          ? null
          : e.assignedTo.toString().split(","),
      ReminderDate: e.reminderDate
        ? dayjs(e.reminderDate).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      HearingCalendarEventId: taskById.hearingCalendarEventId,
    };

    if (isAdd) {
      setisPopUpLoaderr(true);
      let eventId = null;
      if (e.typeId === Constants.hearingCalendar.scheduledHearingTaskId) {
        const createdEventData = (
          await addHearingCalendarEvent({
            ...hearingCalendarEventPayload,
            id: null,
          })
        ).data;
        eventId = createdEventData.id;
      }
      saveGlobalTask({
        ...payload,
        HearingCalendarEventId: eventId,
      }).then((res) => {
        if (res.data.responseCode == 200) {
          setIsLoader(false);
          FlashMessage("success", "Task added Successfully.");
          setOpen1(false);
          setisPopUpLoaderr(false);
          props.setCloseModal(false);

          if (
            location.pathname.indexOf(Constants.pageRoutes.claimantProfile) >=
              0 ||
            location.pathname.indexOf(Constants.pageRoutes.leadProfile) >= 0
          ) {
            setTimeout(function () {
              if (props.isLeads) {
                navigate("/leadsnotes?id=" + searchParams.get("id") ?? "0");
              } else {
                navigate(
                  `/clientsnotes?id=${
                    searchParams.get("id") ?? "0"
                  }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                    searchParams
                  )}`
                );
              }
            }, 200);
          }
        }
      });
    } else {
      let eventId = taskById.hearingCalendarEventId;
      if (taskById.typeId === 2) {
        if (e.typeId === Constants.hearingCalendar.scheduledHearingTaskId) {
          if (eventId !== null) {
            await updateHearingCalendarEvent(hearingCalendarEventPayload);
          } else {
            const createdEventData = (
              await addHearingCalendarEvent({
                ...hearingCalendarEventPayload,
                id: null,
              })
            ).data;
            eventId = createdEventData.id;
          }
        } else {
          eventId = null;
        }
      } else {
        if (e.typeId === Constants.hearingCalendar.scheduledHearingTaskId) {
          const createdEventData = (
            await addHearingCalendarEvent({
              ...hearingCalendarEventPayload,
              id: null,
            })
          ).data;
          eventId = createdEventData.id;
        }
      }
      setisPopUpLoaderr(true);
      updateGlobalTask({
        ...payload,
        HearingCalendarEventId: eventId,
      }).then((res) => {
        if (res.data.responseCode == 200) {
          if (taskById.typeId === 2 && e.typeId !== 2) {
            deleteHearingCalendarEvent(taskById.hearingCalendarEventId).then(
              (res) => {
                FlashMessage(
                  "warning",
                  "Linked event was removed from hearing calendar."
                );
              }
            );
          }
          setIsLoader(false);
          setisPopUpLoaderr(false);
          FlashMessage("success", "Task updated Successfully.");
          setOpen1(false);
          props.setCloseModal(false);
          getNotesApi();

          if (props.isLeads) {
            navigate("/leadsnotes?id=" + searchParams.get("id") ?? "0");
          } else {
            navigate(
              `/clientsnotes?id=${
                searchParams.get("id") ?? "0"
              }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                searchParams
              )}`
            );
          }
        }
      });
    }
  };
  const getTaskPriorityApi = () => {
    getTaskPriority().then((res) => {
      if (res.data.responseCode == 200) {
        var storePriorityId = res.data.responseData[0].id;
        setPriorityId(storePriorityId);
        setPriority(res.data.responseData);
      }
    });
  };

  const getTaskStatusApi = () => {
    getTaskStatus().then((res) => {
      if (res.data.responseCode == 200) {
        var storeStatusId = res.data.responseData[0].id;
        setStatusId(storeStatusId);
      }
    });
  };
  const resetFormValuesForCalendar = (description?: string) => {
    var formDefaultValues = {
      title: "",
      description: description || "",
      statusId: 1,
      typeId: 9,
      priorityId: 1,
      originalEstimationedHours: "",
      dueDate: null,
      dueTime: dayjs("9:00:00", "HH:mm:ss"),
      assignedTo: defaultTaskUser.assignTo,
      reminderDate: null,
    };
    setTimeout(() => {
      form5.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const calendarClose = () => {
    setOpen1(false);

    if (props.setCloseModal) {
      props.setCloseModal(false);
    }

    form5.resetFields();
  };

  const getGlobalTaskByIdApi = (
    id: any,
    datas: any,
    noteids: any,
    statusIds: any,
    createdUserId: string
  ) => {
    if (
      roleInStorage !== Constants.appUserRoles.superUser &&
      createdUserId !== JSON.parse(get("model")).id &&
      id === null
    ) {
      FlashMessage(
        "error",
        "Only superadmin or author of this note can create a task!"
      );
      return;
    }
    setStatusIdsData(statusIds);
    setCalendarNoteId(noteids);
    getSupportUsersApi();
    getTaskPriorityApi();
    getTaskTypeApi();
    getTaskStatusApi();
    getGroupUserApi();
    if (id == null) {
      setIsAdd(true);
      resetFormValuesForCalendar(datas);
      setCheckText(false);
    } else {
      setIsAdd(false);
      getGlobalTaskById(id).then((res) => {
        if (res.data.responseCode == 200) {
          var data = res.data.responseData;
          if (data.dueDateUTC) {
            data.dueDate = dayjs.utc(data.dueDateUTC).local();
          }
          data.dueTime = data.dueDate == null ? null : dayjs(data.dueDate);
          data.reminderDate =
            data.reminderDate == null ? null : dayjs(data.reminderDate);
          data.description = datas;

          setCheckText(false);
          setTaskById(data);
          form5.setFieldsValue(data);
          setIsHearingScheduleTask(
            form5.getFieldValue("typeId") === 2 && !props.isLeads
          );
        }
      });
    }
  };
  const TaskOpen = () => {
    setOpen1(true);
    setIsAdd(true);
    setCheckText(true);
    resetFormValuesForCalendar();
    getSupportUsersApi();
    getTaskPriorityApi();
    getTaskTypeApi();
    getTaskStatusApi();
    setTaskById(null);
  };
  const addGlobalTaskNotesApi = async (e: any) => {
    setisPopUpLoaderr(true);
    var param = searchParams.get("id") ?? "0";
    var storeDueTime =
      dayjs(e.dueDate).format("YYYY-MM-DD") +
      "T" +
      dayjs(e.dueTime).format("HH:mm:ss");

    let eventId = null;
    if (
      !props.isLeads &&
      e.typeId === Constants.hearingCalendar.scheduledHearingTaskId
    ) {
      const createdEventData = (
        await addHearingCalendarEvent({
          startDate: dayjs(storeDueTime).utc().format(),
          endDate: dayjs(storeDueTime).utc().add(1, "hours").format(),
          meetingType: e.hearingCalendarMeetingType,
          hearingType: e.hearingCalendarHearingType,
          description: e.description,
          mindsetUserId: applicationData.id,
          id: null,
        })
      ).data;
      eventId = createdEventData.id;
    }
    var payload = {
      Id: isAdd ? 0 : e.id,
      Description: e.description,
      EncryptedUserId: props?.isLeads ? null : param,
      LeadsId: props?.isLeads ? param : null,
      priorityId: e.priorityId,
      StatusId: statusIdsData == null ? statusId : statusIdsData,
      TypeId: e.typeId,
      OriginalEstimationedHours: e.originalEstimationedHours
        ? e.originalEstimationedHours
        : 0.0,
      DueDate: storeDueTime,
      DueDateUtc: dayjs(storeDueTime).utc().format(),
      AssignedTo:
        e.assignedTo == null || e.assignedTo.length == 0
          ? null
          : e.assignedTo.toString().split(","),
      ReminderDate: e.reminderDate
        ? dayjs(e.reminderDate).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      HearingCalendarEventId: eventId,
    };

    saveGlobalTaskNotes(payload).then((res) => {
      if (res.data.responseCode == responseCodes.success) {
        FlashMessage("success", "Task added Successfully.");
        setOpen1(false);
        setisPopUpLoaderr(false);
        if (
          location.pathname.indexOf(Constants.pageRoutes.claimantProfile) >=
            0 ||
          location.pathname.indexOf(Constants.pageRoutes.leadProfile) >= 0
        ) {
          navigate("/leadsnotes?id=0");
          setTimeout(function () {
            if (props.isLeads) {
              navigate("/leadsnotes?id=" + searchParams.get("id") ?? "0");
            } else {
              navigate(
                `/clientsnotes?id=${
                  searchParams.get("id") ?? "0"
                }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                  searchParams
                )}`
              );
            }
          }, 200);
        }
      }
    });
  };
  return (
    <>
      {props.directTaskOpen && (
        <Button icon={<PlusSquareOutlined />} type='default' onClick={TaskOpen}>
          Create Task
        </Button>
      )}

      <Modal
        className='modal-ant-popup modal-new-design schedule-modal'
        title='Schedule Task'
        centered
        okText={
          isAdd
            ? isPopUpLoaderr
              ? "Loading"
              : "Add"
            : isPopUpLoaderr
            ? "Loading"
            : "Update"
        }
        open={open1}
        onOk={() => formSubmitOne()}
        onCancel={() => calendarClose()}
        width={1000}
      >
        {(isPopUpLoaderr || isApplicationDataLoading) && <Loader></Loader>}
        {!isPopUpLoaderr && !isApplicationDataLoading && (
          <Form
            form={form5}
            name='TaskForms'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={taskById}
            autoComplete='off'
            onFinish={checkText ? addGlobalTaskNotesApi : addUpdateGlobalTask}
            onFieldsChange={() => {
              setIsHearingScheduleTask(
                form5.getFieldValue("typeId") === 2 && !props.isLeads
              );
            }}
          >
            <div>
              <Form.Item name='id' />
              <div className='form-group row'>
                <div className='col-md-6'>
                  <div className='col-sm-12 p-0 position-relative new-global-des-notes'>
                    <Form.Item
                      label='Assigned To'
                      name='assignedTo'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        className='asigne-input'
                        mode='multiple'
                        style={{ width: "100%" }}
                        placeholder='Assignee'
                        optionLabelProp='label'
                        showSearch
                        optionFilterProp='label'
                      >
                        {allUsers.map((ele: any) => {
                          return (
                            <Option
                              key={ele.id}
                              value={ele.id}
                              label={ele.allUsersDisplay}
                            >
                              <Space> {ele.allUsersDisplay}</Space>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='col-sm-12 p-0 position-relative new-global-des-notes'>
                    <Form.Item
                      name='typeId'
                      label='Task Type'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp='label'
                        options={taskType.map((loc: any) => ({
                          label: loc.name,
                          value: loc.id,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='col-sm-12 p-0 new-global-des-notes'>
                    <Form.Item
                      name='dueDate'
                      label='Due Date'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                      getValueFromEvent={(onChange) =>
                        onChange == null
                          ? null
                          : dayjs(onChange).format(dateFormat)
                      }
                      getValueProps={(i) => ({
                        value: i == null ? null : dayjs(i),
                      })}
                    >
                      <DatePicker
                        format={dateFormat}
                        placeholder='Due Date'
                        disabledDate={(d) =>
                          d.isBefore(dayjs()?.subtract(1, "day"))
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='col-sm-12 p-0 new-global-des-notes'>
                    <Form.Item
                      label='Due Time'
                      name='dueTime'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <TimePicker
                        use12Hours
                        format={Constants.TimeFormate}
                        defaultValue={dayjs("9:00:00", "HH:mm:ss")}
                        onChange={onChange}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='col-sm-12 p-0 new-global-des-notes'>
                    <Form.Item
                      label='Reminder Date'
                      name='reminderDate'
                      getValueFromEvent={(onChange) =>
                        onChange == null
                          ? null
                          : dayjs(onChange).format(dateFormat)
                      }
                      getValueProps={(i) => ({
                        value: i == null ? null : dayjs(i),
                      })}
                    >
                      <DatePicker
                        format={dateFormat}
                        placeholder='Reminder Date'
                      />
                    </Form.Item>
                  </div>
                </div>

                {isHearingScheduleTask && (
                  <>
                    <div className='col-md-6'>
                      <div className='col-sm-12 p-0 position-relative new-global-des-notes'>
                        <Form.Item
                          label='Hearing Type'
                          name='hearingCalendarHearingType'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp='label'
                            placeholder='Hearing Type'
                            options={Constants.hearingCalendar.hearingTypes.map(
                              (item: any) => ({
                                label: item.label,
                                value: item.value,
                              })
                            )}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='col-sm-12 p-0 position-relative new-global-des-notes'>
                        <Form.Item
                          label='Meeting Type'
                          name='hearingCalendarMeetingType'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp='label'
                            placeholder='Meeting Type'
                            options={Constants.hearingCalendar.meetingTypes.map(
                              (item: any) => ({
                                label: item.label,
                                value: item.value,
                              })
                            )}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}

                <div className='col-md-6'>
                  <div className='col-sm-12 p-0 position-relative new-global-des-notes'>
                    <Form.Item
                      label='Priority'
                      name='priorityId'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp='label'
                        placeholder='Priority'
                        options={priority.map((loc: any) => ({
                          label: loc.name,
                          value: loc.id,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </div>

                <Form.Item
                  label='Description'
                  name='description'
                  rules={[
                    {
                      required: true,
                      message: "Please input the value!",
                    },
                  ]}
                >
                  <TextArea
                    placeholder='Description'
                    rows={4}
                    maxLength={5000}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default GlobalTaskNotes;
