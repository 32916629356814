import { useState } from "react";
import NameInput from "./SharedFields/NameField";
import { Checkbox, Form, Select } from "antd";
import { MedicalProvider, TemplateOption } from "..";
import Loader from "../../../components/Loader";
import AddressWithNameField from "./SharedFields/AddressWithNameField";
import { MedicineBoxOutlined } from "@ant-design/icons";

type CheckboxValueType = string | number | (string | number)[];

interface RFCRequestProps {
  templates: TemplateOption[];
  form: any;
  providers: MedicalProvider[];
}

const RFCRequest = ({ templates, form, providers }: RFCRequestProps) => {
  const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>([]);
  const [providerSelected, setProviderSelected] = useState(false);

  const handleChange = (checkedList: CheckboxValueType[]) => {
    setCheckedValues(checkedList);
  };

  const handleProviderSelect = (providerId?: number) => {
    if (!providerId) {
      form.resetFields([
        "addressName",
        "addressLine1",
        "physicianName",
        "city",
        "state",
        "country",
        "line1",
        "line2",
        "zip",
      ]);
      setProviderSelected(false);
      return;
    }

    const provider = providers.find((p) => p.id === providerId);
    if (!provider) return;

    const [addressLine1, city, state, country] = provider.physicianAddress
      .split(", ")
      .map((item) => item.trim());

    form.setFieldsValue({
      addressName: provider.hospitalName,
      physicianName: provider.physicianName,
      addressLine1,
      city,
      state,
      country,
      line1: addressLine1,
      line2: provider.addressTwo,
      zip: provider.zip,
    });

    setProviderSelected(true);
  };

  return (
    <>
      <div className='row'>
        <Form.Item label='Fill provider address from template'>
          <Select
            prefix={<MedicineBoxOutlined />}
            style={{ maxWidth: 300 }}
            placeholder='Select a provider'
            onChange={handleProviderSelect}
            allowClear
          >
            {providers.map((provider) => (
              <Select.Option key={provider.id} value={provider.id}>
                {provider.hospitalName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <NameInput
          label="Physician's First and Last Name"
          placeholder="Enter Physician's Name"
          name='physicianName'
        />
        <label className='col-form-label'>
          <strong>Medical Facility</strong>
        </label>
        <AddressWithNameField
          label='Medical Facility Name'
          disabledAddressFields={providerSelected}
        />
      </div>
      <div className='row'>
        {templates && templates.length ? (
          <Form.Item label='Select RFC Forms to Enclose' name='EnclosedFormIds'>
            <Checkbox.Group
              className={"d-flex flex-column mh-100"}
              onChange={handleChange}
              options={templates}
              value={checkedValues}
            />
          </Form.Item>
        ) : (
          <Loader overrideClass='loaderLeftPanelHeight'></Loader>
        )}
      </div>
    </>
  );
};

export default RFCRequest;
