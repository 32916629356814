import { useEffect, useState } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import { Alert, Card, Form, Spin } from "antd";
import { displayFieldByType } from "../helpers/displayFieldByType";
import intake_field_labels from "../IntakeFieldsList/fields";
import prequal_field_labels from "../PreQualFieldList/fields";
import axios from "axios";
import dayjs from "dayjs";
import {
  getApplicationDetailsById,
  getIntakeQuestionnaires,
  getLeadsById,
} from "../../../actions/application";
import { FlashMessage } from "../../../utils/flash_message";

const IntakeBot = ({
  claimantId,
  leadId,
}: {
  claimantId: string | null;
  leadId: string | null;
}) => {
  const [isLoadingDecision, setIsLoadingDecision] = useState(false);
  const [decisionResults, setDecisionResults] = useState<any>();
  // const [additonalQuestions, setAdditionalQuestions] = useState<any[]>([]);
  const [modelError, setModelError] = useState(false);
  const [botError, setBotError] = useState("");
  const [intakeDetails, setIntakeDetails] = useState<any>(null);
  const [prequalDetails, setPrequalDetails] = useState<any>(null);

  const fetchPrequal = async () => {
    try {
      const {
        data: { responseData: lead_details },
      } = await getLeadsById(leadId);
      setPrequalDetails(lead_details);
    } catch (err) {
      FlashMessage("error", "Failed to fetch lead details.");
      setPrequalDetails({});
    }
  };

  const fetchIntake = async () => {
    try {
      const {
        data: { responseData: intake_details },
      } = await getIntakeQuestionnaires(claimantId);

      setIntakeDetails(intake_details);
    } catch (err) {
      // Will throw error if intake does not exist.
      console.error(err);
      setIntakeDetails({});
    }
  };

  const checkModel = async (additional_questions: any[] = []) => {
    try {
      setDecisionResults(null);
      setIsLoadingDecision(true);

      const prequal_text = Object.keys(prequal_field_labels)
        .filter(
          (fieldKey) =>
            prequalDetails[fieldKey] != null && prequal_field_labels[fieldKey]
        )
        .map(
          (fieldKey) =>
            `${prequal_field_labels[fieldKey].label}: ${displayFieldByType(
              prequal_field_labels[fieldKey],
              prequalDetails[fieldKey],
              true
            )}`
        )
        .join("\n");

      const intake_text = Object.keys(intake_field_labels)
        .filter(
          (fieldKey) =>
            intakeDetails[fieldKey] != null && intake_field_labels[fieldKey]
        )
        .map(
          (fieldKey) =>
            `${intake_field_labels[fieldKey].label}: ${displayFieldByType(
              intake_field_labels[fieldKey],
              intakeDetails[fieldKey],
              true
            )}`
        )
        .join("\n");

      let claimantDetails: {
        dateOfBirth?: string;
      } = {};

      if (claimantId) {
        const {
          data: { responseData },
        } = await getApplicationDetailsById(claimantId);
        claimantDetails = responseData;
      }

      const dateOfBirth =
        claimantDetails.dateOfBirth ||
        (intakeDetails || {}).dateOfBirth ||
        (prequalDetails || {}).dateOfBirth;

      const wallOfText =
        `Age: ${dateOfBirth ? dayjs().diff(dateOfBirth, "years") : `N/A`} \n` +
        prequal_text +
        "\n" +
        intake_text;

      console.log("Wall of Text");
      console.log(wallOfText);

      const {
        data: { responseData },
      } = await axios.post(`intake-ai/start-intake-app`, {
        wallOfText,
      });

      setDecisionResults(responseData);
      setIsLoadingDecision(false);
    } catch (err: any) {
      setBotError(err.message);
    }
  };

  const determineStatus = (type: "ssiIneligible" | "ssdiIneligible") => {
    if (decisionResults.eligibilityResult[type] === true) {
      return "error";
    }
    // if (decisionResults.currentQuestion) {
    //   return "warning";
    // }
    if (decisionResults.eligibilityResult[type] === false) {
      return "success";
    }
  };

  const determineAction = (type: "ssiIneligible" | "ssdiIneligible") => {
    if (decisionResults.eligibilityResult[type] === true) {
      return `This claimaint is ineligible for ${
        type === "ssiIneligible" ? "SSI" : "SSDI"
      } benefits.`;
    }
    if (decisionResults.currentQuestion) {
      // return `We need more information to determine eligibility.`;
      return `This claimaint is currently eligible for ${
        type === "ssdiIneligible" ? "SSI" : "SSDI"
      } benefits.`;
    }
    if (decisionResults.eligibilityResult[type] === false) {
      return `This claimaint is eligible for ${
        type === "ssdiIneligible" ? "SSI" : "SSDI"
      } benefits.`;
    }
  };

  // const addAdditionalQuestion = (values: any) => {
  //   const additional_questions = [
  //     ...additonalQuestions,
  //     {
  //       question: decisionResults.currentQuestion.question,
  //       answer: values[decisionResults.currentQuestion.question],
  //     },
  //   ];
  //   setAdditionalQuestions(additional_questions);
  //   form.resetFields();
  //   checkModel(additional_questions);
  // };

  // console.log(dataFinished);

  // useEffect(() => {
  //   if (dataFinished.length === (isLead ? 1 : 2)) {
  //     checkModel();
  //   }
  // }, [dataFinished]);

  useEffect(() => {
    if (leadId) {
      fetchPrequal();
    } else {
      setPrequalDetails({});
    }
  }, [leadId]);

  useEffect(() => {
    if (claimantId) {
      fetchIntake();
    } else {
      setIntakeDetails({});
    }
  }, [claimantId]);

  useEffect(() => {
    if (intakeDetails && prequalDetails) {
      checkModel();
    }
  }, [intakeDetails, prequalDetails]);

  if (botError) {
    return (
      <Card title='AI-Powered Decision'>
        <p>{botError}</p>
      </Card>
    );
  }

  if (!intakeDetails || !prequalDetails) {
    return (
      <Card title='AI-Powered Decision' extra={null} style={{ width: "100%" }}>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <Spin />
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card title='AI-Powered Decision' extra={null} style={{ width: "100%" }}>
      {decisionResults ? (
        <div className='row'>
          <div className='col-md-12 mb-2'>
            <Alert
              className='align-items-center ai-intake-result'
              message='SSI Eligbilility'
              description={determineAction("ssiIneligible")}
              type={determineStatus("ssiIneligible")}
              showIcon
            />
            <div className='col-md-12'>
              {decisionResults.eligibilityResult.ssiIneligible && (
                <ul className='mt-2'>
                  {decisionResults.eligibilityResult.ssiIneligible &&
                    decisionResults.eligibilityResult.ssiIneligibleReasons.map(
                      (item: any) => <li key={item}>{item}</li>
                    )}
                </ul>
              )}
            </div>
          </div>
          <div className='col-md-12'>
            <Alert
              className='align-items-center ai-intake-result'
              message='SSDI Eligbilility'
              description={determineAction("ssdiIneligible")}
              type={determineStatus("ssdiIneligible")}
              showIcon
            />
            <div className='col-md-12'>
              {decisionResults.eligibilityResult.ssdiIneligible && (
                <ul className='mt-2'>
                  {decisionResults.eligibilityResult.ssdiIneligible &&
                    decisionResults.eligibilityResult.ssdiIneligibleReasons.map(
                      (item: any) => <li key={item}>{item}</li>
                    )}
                </ul>
              )}
            </div>
          </div>

          <div className='col-md-12 mt-3'>
            {decisionResults.currentQuestion && (
              <>
                <p className='mb-0'>
                  <strong>
                    To increase the accuracy of the assessment, we recommend
                    asking the following question:
                  </strong>
                </p>{" "}
                <p>{decisionResults.currentQuestion.question}</p>
              </>
            )}
            {/* {decisionResults.currentQuestion && (
              <Form
                layout='vertical'
                form={form}
                onFinish={addAdditionalQuestion}
              >
                <Form.Item
                  label={decisionResults.currentQuestion.question}
                  name={decisionResults.currentQuestion.question}
                >
                  <TextArea rows={4} placeholder='Enter answer...' />
                </Form.Item>
                <Button
                  className='mt-2'
                  htmlType='submit'
                  loading={isLoadingDecision}
                >
                  {isLoadingDecision ? "Running..." : "Update Analysis"}
                </Button>
              </Form>
            )} */}
          </div>
        </div>
      ) : (
        <div className='row text-center'>
          {isLoadingDecision ? (
            <Spin />
          ) : (
            <Paragraph>
              You can run an AI analysis to see the intake results.
            </Paragraph>
          )}
        </div>
      )}
    </Card>
  );
};

export default IntakeBot;
