import { Button, List, Modal } from "antd";
import { useEffect, useState } from "react";
import { getIntakeQuestionnaires } from "../../../actions/application";
import intake_field_labels from "./fields";
import { ScreeningListEmpty } from "../components/ScreeningListEmpty";
import { ScreeningListHeader } from "../components/ScreeningListHeader";
import { ScreeningFieldLabel } from "../components/ScreeningFieldLabel";
import { ScreeningFieldEdit } from "../components/ScreeningFieldEdit";
import { displayFieldByType } from "../helpers/displayFieldByType";
import { PlusOutlined } from "@ant-design/icons";
import { Constants } from "../../../common/constants";
import axios from "axios";
import { FlashMessage } from "../../../utils/flash_message";
import dayjs from "dayjs";
import "../styles.css";
import { useSearchParams } from "react-router-dom";

const IntakeFieldsList = ({
  claimantId,
  pipelineStatusId,
}: {
  claimantId: string | null;
  pipelineStatusId?: number;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [intakeDetails, setIntakeDetails] = useState(null);
  const [values, setValues] = useState<any>({});
  const [filterFields, setFilterFields] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const intake_fields = intakeDetails
    ? Object.entries(intake_field_labels)
        .filter((field) => field[0] in intakeDetails)
        .map(([key, value]) => ({
          key,
          value: intakeDetails[key],
          ...intake_field_labels[key],
        }))
    : [];

  const shouldDisplayField = (item: any) => {
    if (!intake_field_labels[item.key].displayIf) {
      return true;
    }

    if (values[intake_field_labels[item.key].displayIf as string]) {
      return true;
    }

    return false;
  };

  const getIntakeDetails = async () => {
    try {
      const {
        data: { responseData: intake_details },
      } = await getIntakeQuestionnaires(claimantId);

      setIntakeDetails(intake_details);
    } catch (err) {
      // Will throw error if intake does not exist.
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const createIntake = async () => {
    try {
      setIsStarting(true);
      await axios.post("IntakeQuestionnaireClaimant", {
        ClaimantMindsetUserId: claimantId,
      });
      FlashMessage("success", "Intake started successfully.");
      await getIntakeDetails();
    } catch (err) {
      FlashMessage("error", "Failed to start intake.");
    } finally {
      setIsStarting(false);
    }
  };

  useEffect(() => {
    if (intakeDetails) {
      const formatted_values = Object.keys(intake_field_labels).reduce(
        (acc, key) => {
          if (
            intake_field_labels[key].type === "string_array" ||
            intake_field_labels[key].type === "medications_input"
          ) {
            acc[key] = intakeDetails[key]
              ? (intakeDetails[key] as string).split(
                  Constants.stringArrayDelimiter
                )
              : [];
          } else {
            acc[key] = intakeDetails[key];
          }
          return acc;
        },
        {} as Record<string, any>
      );
      setValues(formatted_values);
    }
  }, [intakeDetails]);

  useEffect(() => {
    if (claimantId) {
      getIntakeDetails();
    }
  }, [claimantId]);

  const onCloseModal = async () => {
    await onSave(null, true);
    setIsModalOpen(false);
  };

  const onSave = async (
    InitialIntakeQuestionnaireCompletionDateUtc: string | null,
    skipCompletion?: boolean
  ) => {
    try {
      if (
        pipelineStatusId === 88 &&
        !InitialIntakeQuestionnaireCompletionDateUtc &&
        !skipCompletion
      ) {
        setIsModalOpen(true);
        return;
      }

      const string_arrays = Object.keys(intake_field_labels).reduce(
        (acc, fieldKey) => {
          if (
            intake_field_labels[fieldKey].type === "string_array" ||
            intake_field_labels[fieldKey].type === "medications_input"
          ) {
            acc[fieldKey] = values[fieldKey].join(
              Constants.stringArrayDelimiter
            );
          }
          return acc;
        },
        {} as Record<string, string>
      );
      let payload = {
        Id: (intakeDetails || ({} as any)).id,
        ClaimantMindsetUserId: claimantId,
        InitialIntakeQuestionnaireCompletionDateUtc,
        ...values,
        ...string_arrays,
      };

      await axios.patch("IntakeQuestionnaireClaimant", payload);
      await getIntakeDetails();
      setIsModalOpen(false);
      FlashMessage("success", "Intake saved successfully.");

      if (InitialIntakeQuestionnaireCompletionDateUtc) {
        setSearchParams((prevParams: any) => {
          const newParams = new URLSearchParams(prevParams);
          newParams.set("refresh_claimant", "1");
          return newParams.toString();
        });
      }
    } catch (err: any) {
      if (err.response.data.errors) {
        Object.keys(err.response.data.errors).map((errorKey) =>
          FlashMessage("error", err.response.data.errors[errorKey])
        );
      }
    }
  };

  if (!intakeDetails) {
    return (
      <ScreeningListEmpty
        title='Intake Questions'
        message='This claimant has not started intake yet.'
        alertType='info'
        addOn={
          <Button
            loading={isStarting}
            onClick={createIntake}
            icon={<PlusOutlined />}
          >
            Start Intake
          </Button>
        }
      />
    );
  }

  return (
    <div className='col-12'>
      <Modal
        title='Complete Intake Survey'
        open={isModalOpen}
        onOk={() => onSave(dayjs().format(Constants.dateFormatWithoutUtc))}
        onCancel={onCloseModal}
        cancelText='No, just save.'
        okText='Yes, complete survey.'
      >
        <p>
          Would you like to move the user to the <i>Intake Survey Completed</i>{" "}
          pipeline status?
        </p>
      </Modal>
      <List
        className='screening_fields_list'
        size='small'
        loading={isLoading}
        bordered
        locale={{
          emptyText: "No questions found, try a different search.",
        }}
        header={
          <ScreeningListHeader
            title='Intake Questions'
            onFilter={setFilterFields}
            onEdit={setIsEditing}
            onSave={() => onSave(null)}
          />
        }
        itemLayout='horizontal'
        dataSource={intake_fields.filter((field) =>
          filterFields
            ? intake_field_labels[field.key].label
                .toLowerCase()
                .includes(filterFields.toLowerCase())
            : true
        )}
        renderItem={(item) =>
          isEditing ? (
            <List.Item className='d-flex align-items-center'>
              <ScreeningFieldLabel field={intake_field_labels[item.key]} />
              <div className='prequal_fields_value'>
                {shouldDisplayField(item) && (
                  <ScreeningFieldEdit
                    defaultValue={values[item.key]}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        [item.key]: val,
                      })
                    }
                    field={item}
                  />
                )}
              </div>
            </List.Item>
          ) : (
            <List.Item className='d-flex align-items-center'>
              <ScreeningFieldLabel field={intake_field_labels[item.key]} />
              <div className='prequal_fields_value'>
                {displayFieldByType(
                  intake_field_labels[item.key],
                  values[item.key]
                )}
              </div>
            </List.Item>
          )
        }
      />
    </div>
  );
};

export default IntakeFieldsList;
