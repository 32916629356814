import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Spin } from "antd";
import { ExpireTokens } from "../../utils/expire_tokens";
import { set, get, remove } from "../../utils/clientStorageUtils";
import "./login.scss";
import { FlashMessage } from "../../utils/flash_message";
import { Constants } from "../../common/constants";
import { GoogleLogin } from "@react-oauth/google";

import Footer from "../../components/Footer";
import { GoogleSso } from "../../apis/accounts";
const LoginPage = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [preLoginPath] = useState(get("originalUrl"));
  useEffect(() => {
    remove("originalUrl");
  }, []);

  const responseMessage = (response: any) => {
    setLoading(true);
    GoogleSso({ IdToken: response.credential })
      .then((response: any) => {
        set("model", JSON.stringify(response.data.responseData));
        set("accessToken", response.data.responseData.token);
        set("rid", response.data.responseData.repositoryId);
        const roles = response.data.responseData.userRoles[0];
        if (
          response.data.responseData.userRoles.some(
            (role: string) => role === Constants.userRoles.customer
          )
        ) {
          FlashMessage("error", "Login failed");
          throw new Error("Customer role is not allowed to login");
        }
        navigate(preLoginPath || `/${Constants.pageRoutes.globaltaskscreen}`);
      })
      .catch((error: any) => {
        FlashMessage("error", "Login failed " + error);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const errorMessage = (error: any) => {
    console.log(error);
  };

  useEffect(() => {
    ExpireTokens();
  });

  return (
    <Row className={`login_bg formContainer justify-content-center`}>
      <Col
        span={8}
        xs={18}
        md={18}
        lg={8}
        className={`login_form formFieldContainer`}
      >
        <h4 className={`heading_app text-center mb-4`}>
          Mindset Login via Google Sso
        </h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GoogleLogin
            onSuccess={responseMessage}
            onError={() => errorMessage("error")}
          />
          {isLoading && <Spin style={{ marginLeft: "15px" }} />}
        </div>
        <Footer />
      </Col>
    </Row>
  );
};

export default LoginPage;
