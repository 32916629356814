import axios from "axios";

export function GetActivityLogByLoginId(id: any) {
  return axios.get("ActivityLog?encryptedId=" + id);
}
export function GetApplicationDetailsById(Id: any) {
  return axios.get("GetApplicationDetailsById?customerId=" + Id.toString());
}
export function GetLeads() {
  return axios.get("Leads");
}
export function GetLeadCounts() {
  return axios.get("LeadsInboundTotalCounts");
}
export function GetLeadsById(id: any) {
  return axios.get("LeadsById?leadsId=" + id);
}
export function GetClients(id: any) {
  return axios.get("Clients?pipelineId=" + id);
}
export function SearchLeadsandClients(keyword: any) {
  return axios.get("SearchLeadsandClients?keyword=" + keyword);
}
export function SearchClients(keyword: any) {
  return axios.get("SearchClients?keyword=" + keyword);
}
export function UpdateApplication(payload: any) {
  return axios.put("Application", payload);
}
export function AddUpdateApplicationFormStepTwo(payload: any) {
  return axios.post("Application/Step2", payload);
}
export function AddUpdateApplicationFormStepThree(payload: any) {
  return axios.post("Application/Step3", payload);
}
export function AddUpdateApplicationFormStepFour(payload: any) {
  return axios.post("Application/Step4", payload);
}
export function AddUpdateApplicationFormStepFive(payload: any) {
  return axios.post("Application/Step5", payload);
}
export function AddUpdateApplicationFormStepSix(payload: any) {
  return axios.post("Application/Step6", payload);
}
export function AddUpdateApplicationFormStepSeven(payload: any) {
  return axios.post("Application/Step7", payload);
}
export function AddUpdateApplicationFormStepEight(payload: any) {
  return axios.post("Application/Step8", payload);
}
export function AddUpdateApplicationFormStepNine(payload: any) {
  return axios.post("Application/Step9", payload);
}
export function AddUpdateApplicationFormStepTen(payload: any) {
  return axios.post("Application/Step10", payload);
}
export function GetPriorMarriageById(Id: any) {
  return axios.get("GetPriorMarriageById?customerId=" + Id);
}
export function GetHeights() {
  return axios.get("height");
}
export function GetWeights() {
  return axios.get("weight");
}
export function UpdateLeads(payload: any) {
  return axios.put("Leads", payload);
}
export function UpdatePrequalification(payload: any) {
  return axios.put("Prequalification", payload);
}
export function UpdateLeadsLeftPanel(payload: any) {
  return axios.put("LeadsLeftPanel", payload);
}
export function UpdateClientLeftPanel(
  payload: any,
  isEmail: boolean,
  isText: boolean
) {
  return axios.put(
    "ClientsLeftPanel?isEmail=" + isEmail + "&isText=" + isText,
    payload
  );
}
export function MapClientWithLead(id: any, clientId: any) {
  return axios.post(
    "MapClientWithLead?leadId=" + id + "&mindsetUserId=" + clientId
  );
}
export function ActivityLogUnmaskingSsn(id: any) {
  return axios.post("ActivityLogUnmaskingSsn?encryptedId=" + id);
}
export function ResetDocuSignStatus(id: any) {
  return axios.post("ResetDocusignStatus?encrypteduserId=" + id);
}
export function SearchMedicationNames(keyword: any) {
  return axios.get("SearchMedications?keyword=" + keyword);
}
export function GetMedicationNames() {
  return axios.get("MedicationNames");
}

export function GetFeatureFlags() {
  return axios.get("FeatureFlag");
}

export function UpdateFeatureFlags(config: any) {
  return axios.post("FeatureFlag", { config });
}
export function AddMedicationName(name: any, approved: boolean) {
  return axios.post("MedicationNames", {
    name,
    approved,
  });
}
export function UpdateMedication(id: string, name: string, approved: boolean) {
  return axios.put(`MedicationNames/${id}`, {
    name,
    approved,
  });
}
export function DeleteMedication(id: string) {
  return axios.delete(`MedicationNames/${id}`);
}
export function GetMedicalFacilities(Id: any, keyword: string) {
  return axios.get("MedicalFacilities?Id=" + Id + "&Keyword=" + keyword);
}
export function GetProfileViewDetails() {
  return axios.get("GetProfileVewDetails");
}
export function GetIntakeQuestionnaires(id: string) {
  return axios.get("IntakeQuestionnaire?claimantMindsetUserId=" + id);
}
