import { useState } from "react";
import "../index.scss";
import dwdtemp from "../../../images/download_temp.png";
import { Button, Flex, Modal, Table, Tooltip } from "antd";
import info from "../../../images/info.png";
import { FlashMessage } from "../../../utils/flash_message";
import { useSearchParams } from "react-router-dom";
import { Form, Input, DatePicker, Checkbox, Select } from "antd";
import {
  saveMarriageInfo,
  getMarriageInfo,
  getMarriageInfoById,
  updateMarriageInfo,
  deleteMarriageInfo,
} from "../../../actions/marriageInfo";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Constants } from "../../../common/constants";
import { validateBlankSpace } from "../../../validations/validateBlankSpace";
import { number } from "../../../validations/numeric_only-validate";
import { lessThanFourCharacters } from "../../../validations/lessThanFourCharacters";
import { getCommonAllLocations } from "../../../actions/common";
import Loader from "../../Loader";
import { minLengthNine } from "../../../validations/validate_min_length";
import dayjs from "dayjs";

const MarriageInfo = (props: any) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [marriageData, setMarriageData] = useState([]);
  const [searchParams] = useSearchParams();
  const dateFormat = "MM/DD/YYYY";
  const [form] = Form.useForm();
  const [appdata, setAppData] = useState({});
  const [location, setLocation] = useState([]);
  const [isPopUpLoader, setisPopUpLoader] = useState(false);
  const handleTextLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 20) + (item.length > 20 ? "......" : "")}
        </Tooltip>
      );
    }
  };
  const columns = [
    {
      title: "Date Of Marriage",
      dataIndex: "dateOfMarriage",
      key: "dateOfMarriage",
      render: (item: any, file: any) => {
        return item == null ? "" : dayjs(item).format(Constants.dateFormat);
      },
    },
    {
      title: "City Of Marriage",
      dataIndex: "cityOfMarriage",
      key: "cityOfMarriage",
      render: handleTextLength,
    },
    {
      title: "State Of Marriage",
      dataIndex: "stateOfMarriage",
      key: "stateOfMarriage",
      render: handleTextLength,
    },
    {
      title: "Date Of Divorce",
      dataIndex: "dateOfDivorce",
      key: "dateOfDivorce",
      render: (item: any, file: any) => {
        return item == null ? "" : dayjs(item).format(Constants.dateFormat);
      },
    },
    {
      title: "Spouse Name",
      dataIndex: "spouseName",
      key: "spouseName",
      render: handleTextLength,
    },
    {
      title: "Spouse Date Of Birth",
      dataIndex: "spouseDob",
      key: "spouseDob",
      render: (item: any, file: any) => {
        return item == null ? "" : dayjs(item).format(Constants.dateFormat);
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return (
          <div className='d-flex'>
            <Tooltip title={"Edit"}>
              <a className='mr-2' onClick={() => getMarriageInfoByIdApi(id)}>
                <EditFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <a onClick={() => deleteMarriageInfoApi(id)}>
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const getCommonAllLocationsApi = () => {
    getCommonAllLocations().then((res) => {
      var loc = res.data.responseData;
      setLocation(loc);
    });
  };
  const resetFormValues = () => {
    var formDefaultValues = {
      dateOfMarriage: null,
      cityOfMarriage: "",
      stateOfMarriage: "",
      dateOfDivorce: null,
      cityOfDivorce: "",
      stateOfDivorce: "",
      spouseName: "",
      spouseDob: null,
      spouseSSN: "",
    };
    setTimeout(() => {
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };
  const addUpdateMarriageInfoApi = (e: any) => {
    var param = searchParams.get("id") ?? "0";
    var payload = {
      DateOfMarriage: e.dateOfMarriage
        ? dayjs(e.dateOfMarriage).format(Constants.dateFormatWithoutUtc)
        : null,
      EncryptedUserId: param,
      CityOfMarriage: e.cityOfMarriage,
      StateOfMarriage: e.stateOfMarriage,
      DateOfDivorce: e.dateOfDivorce
        ? dayjs(e.dateOfDivorce).format(Constants.dateFormatWithoutUtc)
        : null,
      CityOfDivorce: e.cityOfDivorce,
      StateOfDivorce: e.stateOfDivorce,
      SpouseName: e.spouseName,
      SpouseDob: e.spouseDob
        ? dayjs(e.spouseDob).format(Constants.dateFormatWithoutUtc)
        : null,
      SpouseSSN: e.spouseSSN,
      Id: isAdd ? 0 : e.id,
    };
    if (!isAdd) {
      setisPopUpLoader(true);
      updateMarriageInfo(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Marriage Info updated Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          getMarriageInfoApi();
        }
      });
    } else {
      setisPopUpLoader(true);
      saveMarriageInfo(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Marriage Info added Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          props.TemplateCount();
        }
      });
    }
  };

  const deleteMarriageInfoApi = (id: any) => {
    setisPopUpLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteMarriageInfo(id).then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", response.data.responseMessage);
          setisPopUpLoader(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setisPopUpLoader(false);
        }
        getMarriageInfoApi();
        props.TemplateCount();
      });
    } else {
      setisPopUpLoader(false);
    }
  };

  const getMarriageInfoByIdApi = (id: any) => {
    setisPopUpLoader(true);
    getCommonAllLocationsApi();
    setOpenEdit(true);
    getMarriageInfoById(id).then((res) => {
      var eduData = res.data.responseData;
      if (res.data.responseCode == 200) {
        var dateOfDivorceValueStore =
          eduData.dateOfDivorce == null
            ? null
            : dayjs(eduData.dateOfDivorce).format();
        eduData.dateOfDivorce = dateOfDivorceValueStore;
        var dateOfMarriageValueStore =
          eduData.dateOfMarriage == null
            ? null
            : dayjs(eduData.dateOfMarriage).format();
        eduData.dateOfMarriage = dateOfMarriageValueStore;

        var spouseDobValueStore =
          eduData.spouseDob == null ? null : dayjs(eduData.spouseDob).format();
        eduData.spouseDob = spouseDobValueStore;
        setAppData(eduData);
        setisPopUpLoader(false);
        form.setFieldsValue(eduData);
      }
    });
  };

  const getMarriageInfoApi = () => {
    setisPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    var param = searchParams.get("id") ?? "0";

    getMarriageInfo(param).then((res) => {
      var marriage = res.data.responseData;
      if (res.data.responseCode == 200) {
        setMarriageData(marriage);
        setisPopUpLoader(false);
      } else {
        FlashMessage("error", res.data.responseMessage);
      }
    });
  };
  const formSubmit = () => {
    form.submit();
  };
  const addPopUp = () => {
    getCommonAllLocationsApi();
    setIsAdd(true);
    setOpenEdit(true);
    resetFormValues();
  };

  const cancel = () => {
    setOpenEdit(false);
    form.resetFields();
  };

  return (
    <>
      {openEdit && (
        <Modal
          title={isAdd ? "Add Marriage Info" : "Update Marriage Info"}
          centered
          okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
          open={openEdit}
          onOk={() => (isPopUpLoader ? null : formSubmit())}
          onCancel={() => cancel()}
          width={1000}
        >
          <>
            <div className='col-xl-9 margin-auto'>
              {isPopUpLoader && <Loader></Loader>}
              {!isPopUpLoader && (
                <>
                  <p className='fill_description mt-2 text-center mb-1'>
                    Please enter your marriage details (if any).
                  </p>
                  <div className='row'>
                    <div className='col-xl-1'></div>
                    <div className='col-xl-11'>
                      <Form
                        className='mt-4'
                        form={form}
                        name='WorkHistoryForm'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={appdata}
                        autoComplete='off'
                        onFinish={addUpdateMarriageInfoApi}
                      >
                        <div className=''>
                          <Form.Item name='id' />
                          <div className='form-group row '>
                            <label className='col-form-label fill_description col-sm-3 des_font'>
                              1. Date Of Marriage
                            </label>
                            <div className='col-sm-9 p-0'>
                              <Form.Item
                                name='dateOfMarriage'
                                getValueFromEvent={(onChange) =>
                                  onChange == null
                                    ? null
                                    : dayjs(onChange).format(dateFormat)
                                }
                                getValueProps={(i) => ({
                                  value: i == null ? null : dayjs(i),
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input the value!",
                                  },
                                ]}
                              >
                                <DatePicker format={dateFormat} />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            2. City of Marriage
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='cityOfMarriage'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            3. State of Marriage
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='stateOfMarriage'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Select
                                options={location.map((loc: any) => ({
                                  label: loc.name,
                                  value: loc.name,
                                }))}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            4. Date of Divorce
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='dateOfDivorce'
                              getValueFromEvent={(onChange) =>
                                onChange == null
                                  ? null
                                  : dayjs(onChange).format(dateFormat)
                              }
                              getValueProps={(i) => ({
                                value: i == null ? null : dayjs(i),
                              })}
                            >
                              <DatePicker
                                format={dateFormat}
                                disabledDate={(d) =>
                                  d.isBefore(
                                    form.getFieldValue("dateOfMarriage")
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            5. City of Divorce
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item name='cityOfDivorce'>
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            6. State of Divorce
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item name='stateOfDivorce'>
                              <Select
                                options={location.map((loc: any) => ({
                                  label: loc.name,
                                  value: loc.name,
                                }))}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            7. Spouse Name
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='spouseName'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            8. Spouse's Date of Birth
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='spouseDob'
                              getValueFromEvent={(onChange) =>
                                onChange == null
                                  ? null
                                  : dayjs(onChange).format(dateFormat)
                              }
                              getValueProps={(i) => ({
                                value: i == null ? null : dayjs(i),
                              })}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                              ]}
                            >
                              <DatePicker format={dateFormat} />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            9. Spouse SSN
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='spouseSSN'
                              rules={[
                                {
                                  validator: validateBlankSpace,
                                },
                                {
                                  validator: number,
                                },
                                {
                                  validator: minLengthNine,
                                },
                              ]}
                            >
                              <Input
                                maxLength={9}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <p className='click_doc text-center mb-0'>
                          Click save to enter additional jobs
                        </p>
                      </Form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        </Modal>
      )}
      {open && (
        <Modal
          title='Marriage(s)'
          centered
          footer={null}
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Table
              dataSource={marriageData}
              pagination={{
                pageSize: Constants.gridDefaultPageSize,
                showSizeChanger: false,
              }}
              columns={columns}
            />
          )}{" "}
        </Modal>
      )}

      <div className='col-md-12 col-xl-4 mt-3 template_panel'>
        <div className='background_sq_boxes height_temp_box p-4 text-center position-relative'>
          <div className='popover__wrapper2 position-absolute'>
            <img className='img-fluid info' src={info} />
            <div className='popover__content3'>
              <p className='popover__message2'>
                Please provide all your marriage information.
              </p>
            </div>
          </div>
          <img className='img-fluid dws_temp' src={dwdtemp} />
          <h5 className='boxes_headings mb-3 ps-5 pe-5'>Marriage(s)</h5>

          <Flex gap={16} justify='center' vertical>
            <Button onClick={getMarriageInfoApi} type='default'>
              View
            </Button>
            <Button onClick={() => addPopUp()} type='primary'>
              Add
            </Button>
          </Flex>

          {props.marriageInfoCount == 1 ? (
            <p className='mr_record'>{props.marriageInfoCount} record </p>
          ) : (
            <p className='mr_record'>{props.marriageInfoCount} records</p>
          )}
        </div>
      </div>
    </>
  );
};

export default MarriageInfo;
