import { Navigate, useLocation } from "react-router-dom";
import { Constants } from "../common/constants";
import ClaimantProfileTabKeys from "../common/constants/ClaimantProfileTabKeys";
import LeadProfileTabKeys from "../common/constants/LeadProfileTabKeys";

export const RedirectWithQueryParams = ({
  to,
  tab,
}: {
  to: string;
  tab?: string | null;
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  if (tab) {
    searchParams.delete("tab");
    searchParams.set("tab", tab);
  }

  const newTo = `${to}?${searchParams.toString()}`;

  return <Navigate to={newTo} replace />;
};

export const redirectRoutes = [
  {
    path: "/clients",
    to: `/${Constants.pageRoutes.clients}`,
    tab: null,
  },
  {
    path: "/uploaddocuments",
    to: `/${Constants.pageRoutes.claimantProfile}`,
    tab: ClaimantProfileTabKeys.DOCUMENTS,
  },
  {
    path: "/clientsnotes",
    to: `/${Constants.pageRoutes.claimantProfile}`,
    tab: ClaimantProfileTabKeys.NOTES_TASKS,
  },
  {
    path: "/leadsnotes",
    to: `/${Constants.pageRoutes.leadProfile}`,
    tab: LeadProfileTabKeys.NOTES_TASKS,
  },
  {
    path: "/PreQualificationClient",
    to: `/${Constants.pageRoutes.claimantProfile}`,
    tab: ClaimantProfileTabKeys.PRE_QUALIFICATIONS,
  },
  {
    path: "/prequalification",
    to: `/${Constants.pageRoutes.leadProfile}`,
    tab: LeadProfileTabKeys.PRE_QUALIFICATIONS,
  },
  {
    path: "/requestedinfo",
    to: `/${Constants.pageRoutes.claimantProfile}`,
    tab: ClaimantProfileTabKeys.CONTACT_MESSAGES,
  },
  {
    path: "/sendphysicalmail",
    to: `/${Constants.pageRoutes.claimantProfile}`,
    tab: ClaimantProfileTabKeys.SEND_PHYSICAL_MAIL,
  },
  {
    path: "/generateDocuments",
    to: `/${Constants.pageRoutes.claimantProfile}`,
    tab: ClaimantProfileTabKeys.GENERATE_DOCUMENTS,
  },
  {
    path: "/sendFax",
    to: `/${Constants.pageRoutes.claimantProfile}`,
    tab: ClaimantProfileTabKeys.FAX_MANAGER,
  },
  {
    path: "/faxManager",
    to: `/${Constants.pageRoutes.claimantProfile}`,
    tab: ClaimantProfileTabKeys.FAX_MANAGER,
  },
  {
    path: "/templates",
    to: `/${Constants.pageRoutes.claimantProfile}`,
    tab: ClaimantProfileTabKeys.TEMPLATES,
  },
];
