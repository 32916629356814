import React, { useState } from "react";
import "../index.scss";
import dwdtemp from "../../../images/download_temp.png";
import { Button, Flex, InputNumber, Modal, Table, Tooltip } from "antd";
import info from "../../../images/info.png";
import { FlashMessage } from "../../../utils/flash_message";
import { useSearchParams } from "react-router-dom";
import { Form, Input, Select } from "antd";
import {
  getEmergencyContact,
  getEmergencyContactById,
  deleteEmergencyContact,
  updateEmergencyContact,
  saveEmergencyContact,
} from "../../../actions/emergencyContact";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  number,
  validateZipCode,
} from "../../../validations/numeric_only-validate";
import { validateEmail } from "../../../validations/validateEmail";
import { Constants } from "../../../common/constants";
import { getCommonAllLocations } from "../../../actions/common";
import { validateBlankSpace } from "../../../validations/validateBlankSpace";
import { minLength } from "../../../validations/validate_min_length";
import trim from "lodash/trim";
import Loader from "../../Loader";
import GooglePlacesAutocompleteWrapper from "../../GooglePlacesAutoCompleteWrapper";

const relationshipTypes = [
  {
    id: 1,
    name: "Family Member",
  },
  {
    id: 2,
    name: "Attorney Representative",
  },
  {
    id: 3,
    name: "Non-Attorney Representative",
  },
  {
    id: 4,
    name: "Government Agency",
  },
  {
    id: 5,
    name: "Non-Profit Organization/Legal Aid Group",
  },
  {
    id: 6,
    name: "Health Service Agency/Hospital",
  },
  {
    id: 7,
    name: "Nursing care Facility",
  },
  {
    id: 8,
    name: "Friend",
  },
  {
    id: 9,
    name: "Other",
  },
];
const EmergencyContact = (props: any) => {
  const [open, setOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [location, setLocation] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [searchParams] = useSearchParams();
  const [appdata, setAppData] = useState({});
  const [addressValue, setAddressValue] = useState<string | null>(null);
  const [isPopUpLoader, setisPopUpLoader] = useState(false);
  const dateFormat = "MM/DD/YYYY";
  const [form] = Form.useForm();

  const handleTextLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 20) + (item.length > 20 ? "......" : "")}
        </Tooltip>
      );
    }
  };

  const handleEmailLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 30) + (item.length > 30 ? "......" : "")}
        </Tooltip>
      );
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: handleTextLength,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: handleTextLength,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: handleEmailLength,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return (
          <div className='d-flex'>
            <Tooltip title={"Edit"}>
              <a
                className='mr-2'
                onClick={() => getEmergencyContactByIdApi(id)}
              >
                <EditFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <a onClick={() => deleteEmergencyContactApi(id)}>
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getCommonAllLocationsApi = () => {
    getCommonAllLocations().then((res) => {
      var loc = res.data.responseData;
      setLocation(loc);
    });
  };

  const addUpdateEmergencyContactApi = (e: any) => {
    var param = searchParams.get("id") ?? "0";
    var payload = {
      Id: isAdd ? 0 : e.id,
      FirstName: e.firstName,
      LastName: e.lastName,
      Email: e.email,
      PhoneNumber: e.phoneNumber.toString(),
      StreetAddress: addressValue,
      City: e.city,
      State: e.state,
      Zip: e.zip,
      Relationship: e.relationship,
      EncryptedUserId: param,
    };
    if (!isAdd) {
      setisPopUpLoader(true);
      updateEmergencyContact(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Emergency Contact updated Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          getEmergencyContactApi();
        }
      });
    } else {
      setisPopUpLoader(true);
      saveEmergencyContact(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Emergency Contact added Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          props.TemplateCount();
        }
      });
    }
  };

  const deleteEmergencyContactApi = (id: any) => {
    setisPopUpLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteEmergencyContact(id).then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", response.data.responseMessage);
          setisPopUpLoader(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setisPopUpLoader(false);
        }
        getEmergencyContactApi();
        props.TemplateCount();
      });
    } else {
      setisPopUpLoader(false);
    }
  };

  const getEmergencyContactByIdApi = (id: any) => {
    setisPopUpLoader(true);
    setOpenEdit(true);
    setAddressValue(null);
    getCommonAllLocationsApi();
    getEmergencyContactById(id).then((res) => {
      var eduData = res.data.responseData;
      if (res.data.responseCode == 200) {
        setAppData(eduData);
        setisPopUpLoader(false);
        setAddressValue(eduData.streetAddress ?? "");
        form.setFieldsValue(eduData);
      }
    });
  };

  const getEmergencyContactApi = () => {
    setisPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    var param = searchParams.get("id") ?? "0";
    getEmergencyContact(param)
      .then((res) => {
        var contact = res.data.responseData;
        if (res.data.responseCode == 200) {
          setDocumentData(contact);
          setisPopUpLoader(false);
        } else {
          FlashMessage("error", res.data.responseMessage);
        }
      })
      .catch((Error) => {});
  };

  const formSubmit = () => {
    form.submit();
  };

  const addPopUp = () => {
    getCommonAllLocationsApi();
    setIsAdd(true);
    setOpenEdit(true);
    resetFormValues();
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      firstName: "",
      lastName: "",
      email: "",
      city: "",
      streetAddress: "",
      state: "",
      zip: "",
      phoneNumber: "",
      relationship: "",
    };
    setAddressValue(null);
    setTimeout(() => {
      setAppData(formDefaultValues);
      setAddressValue(formDefaultValues.streetAddress);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const onkeyup = (e: any) => {
    var onlyNumber = e.target.value
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split("-")
      .join("")
      .split(" ")
      .join("");
    if (onlyNumber.length > 10) {
      onlyNumber = onlyNumber.substring(0, 10);
    }
    form.setFieldsValue({ phoneNumber: onlyNumber });
  };
  const predefinedValue = {
    label: addressValue,
    value: Constants.googleAutoCompleteStaticId,
  };
  return (
    <>
      {openEdit && (
        <Modal
          title={isAdd ? "Add Emergency Contact" : "Update Emergency Contact"}
          centered
          okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
          open={openEdit}
          onOk={() => (isPopUpLoader ? null : formSubmit())}
          onCancel={() => setOpenEdit(false)}
          width={1000}
        >
          <>
            <div className='col-xl-9 margin-auto'>
              <div className='row'>
                <div className='col-xl-1'></div>
                <div className='col-xl-11'>
                  {isPopUpLoader && <Loader></Loader>}
                  {!isPopUpLoader && (
                    <>
                      <p className='fill_description mt-2 text-center mb-1'>
                        If necessary, please provide the name(s) of individuals
                        that are willing to assist with your application
                        details.
                      </p>

                      <Form
                        className='mt-4'
                        form={form}
                        name='EmergencyContactForm'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={appdata}
                        autoComplete='off'
                        onFinish={addUpdateEmergencyContactApi}
                      >
                        <div className='form-group row margin_mobile'>
                          <Form.Item name='id' />
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            1. First Name
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='firstName'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            2. Last Name
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='lastName'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            3. Email
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='email'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateEmail,
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            4. Street Address
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='streetAddress'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                              ]}
                            >
                              {addressValue != null && (
                                <GooglePlacesAutocompleteWrapper
                                  apiKey={process.env.REACT_APP_API_KEY ?? ""}
                                  autocompletionRequest={{
                                    componentRestrictions: {
                                      country: ["us"],
                                    },
                                  }}
                                  selectProps={{
                                    value: predefinedValue,
                                    isClearable: false,
                                    onChange: (o: any) => {
                                      form.setFieldsValue({
                                        address: o?.label ?? "",
                                      });
                                      if (o != null) {
                                        var addArr = o.label.split(",");
                                        var street =
                                          addArr.length > 0 ? addArr[0] : null;
                                        var city =
                                          addArr.length > 0 ? addArr[1] : null;
                                        var state =
                                          addArr.length > 0 ? addArr[2] : null;
                                        form.setFieldsValue({
                                          streetAddress: trim(street),
                                          city: trim(city),
                                          state: trim(state),
                                        });
                                        setAddressValue(street);
                                      }
                                    },
                                  }}
                                />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            5. City
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='city'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            6. State
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='state'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Select
                                options={location.map((loc: any) => ({
                                  label: loc.name,
                                  value: loc.abbreviations,
                                }))}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            7. Zip
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='zip'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                                {
                                  validator: number,
                                },
                                {
                                  validator: validateZipCode,
                                },
                              ]}
                            >
                              <Input
                                maxLength={10}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            8. Phone Number
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='phoneNumber'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: number,
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                                {
                                  validator: minLength,
                                },
                              ]}
                            >
                              <Input
                                onKeyUpCapture={onkeyup}
                                maxLength={15}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                            <Form.Item name='id'>
                              <Input
                                type='hidden'
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            9. Relationship To Claimant
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='relationship'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                              ]}
                            >
                              <Select
                                options={relationshipTypes.map((loc: any) => ({
                                  label: loc.name,
                                  value: loc.name,
                                }))}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <p className='click_doc text-center'>
                          Click save to enter additional emergency contacts
                        </p>
                      </Form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}

      {open && (
        <Modal
          title='Emergency Contact'
          centered
          footer={null}
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Table
              dataSource={documentData}
              pagination={{
                pageSize: Constants.gridDefaultPageSize,
                showSizeChanger: false,
              }}
              columns={columns}
            />
          )}
        </Modal>
      )}

      <div className='col-md-12 col-xl-4 mt-3 template_panel'>
        <div className='background_sq_boxes height_temp_box p-4 text-center position-relative'>
          <div className='popover__wrapper2 position-absolute'>
            <img className='img-fluid info' src={info} />
            <div className='popover__content3'>
              <p className='popover__message2'>
                {" "}
                List the individuals we can contact in the event we are unable
                to reach you regarding your application updates.
              </p>
            </div>
          </div>
          <img className='img-fluid dws_temp' src={dwdtemp} />
          <h5 className='boxes_headings mb-3 ps-5 pe-5'>Emergency Contact</h5>

          <Flex gap={16} justify='center' vertical>
            <Button onClick={getEmergencyContactApi} type='default'>
              View
            </Button>
            <Button onClick={() => addPopUp()} type='primary'>
              Add
            </Button>
          </Flex>

          {props.emergencyContactCount == 1 ? (
            <p className='mr_record'>{props.emergencyContactCount} record </p>
          ) : (
            <p className='mr_record'>{props.emergencyContactCount} records</p>
          )}
        </div>
      </div>
    </>
  );
};

export default EmergencyContact;
