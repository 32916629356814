import { Button, Modal, Tabs } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import { FlashMessage } from "../../../utils/flash_message";
import {
  GetSecureRegistrationLinksForClaimant,
  SendSecureRegistrationLinkToClaimant,
} from "../../../apis/leadsAndClients";
import { formatPhoneNumber } from "../../../utils/commonHelper";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import Loader from "../../Loader";

enum DeliveryType {
  Sms = "Sms",
  Email = "Email",
}

interface SendSecureRegistrationLinkProps {
  userId: string;
  phoneNumber: string;
  email: string;
  asMenuItem?: boolean;
}

const SendSecureRegistrationLink: React.FC<SendSecureRegistrationLinkProps> = ({
  userId,
  phoneNumber,
  email,
  asMenuItem,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [secureRegistrationLinks, setSecureRegistrationLinks] = useState<any[]>(
    []
  );
  const [deliveryType, setDeliveryType] = useState<DeliveryType>(
    DeliveryType.Sms
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const previousLinksExists = secureRegistrationLinks.length > 0;

  const handleOpenModal = () => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("isSecureRegistrationLinkModalOpen", "true");
    setSearchParams(updatedSearchParams);
    getClaimantSecureRegistrationLinks();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("isSecureRegistrationLinkModalOpen", "false");
    setSearchParams(updatedSearchParams);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const modalOpenSearchParam = searchParams.get(
      "isSecureRegistrationLinkModalOpen"
    );
    setIsModalOpen(modalOpenSearchParam === "true");
  }, [searchParams]);

  const getClaimantSecureRegistrationLinks = async () => {
    try {
      const {
        data: { secureRegistrationLinks },
      } = await GetSecureRegistrationLinksForClaimant(userId);
      setSecureRegistrationLinks(secureRegistrationLinks);
    } catch (error) {
      FlashMessage(
        "error",
        "Failed to fetch secure registration links for user."
      );
    }
  };

  useEffect(() => {
    getClaimantSecureRegistrationLinks();
  }, [userId]);

  const handleSendSecureRegistrationLink = async () => {
    if (userId !== "0") {
      const payload = {
        claimantMindsetUserId: userId,
        deliveryType: deliveryType,
      };

      setIsLoading(true);
      try {
        const { data } = await SendSecureRegistrationLinkToClaimant(payload);
        if (data.responseCode !== 200) {
          FlashMessage(
            "error",
            `${data.responseMessage}`,
            "Send Secure Registration Link Error",
            10000
          );
          return;
        }
        FlashMessage(
          "success",
          `Successfully sent new registration link to claimant via ${deliveryType}`
        );

        await getClaimantSecureRegistrationLinks();
      } catch (error) {
        FlashMessage("error", `${error}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const filteredLinks =
    deliveryType === DeliveryType.Sms
      ? secureRegistrationLinks.filter((link) => link.phone)
      : secureRegistrationLinks.filter((link) => link.email);

  return (
    <>
      {asMenuItem ? (
        <Dropdown.Item className='item_drp' onClick={handleOpenModal}>
          <h4 className='heading_tab cursorPointer'>
            Account Creation Messages
          </h4>
        </Dropdown.Item>
      ) : (
        <div className='secure_registration_link_section'>
          <Button className='secondary-button' onClick={handleOpenModal}>
            Account Creation Messages
          </Button>
        </div>
      )}

      <Modal
        open={isModalOpen}
        title='Send Account Creation Message'
        onCancel={handleModalClose}
        footer={
          <div
            className={`secure_registration_modal_buttons ${
              previousLinksExists && "reverse_button_order"
            }`}
          >
            <Button
              type={previousLinksExists ? "primary" : "default"}
              onClick={handleModalClose}
            >
              Close
            </Button>

            <Button
              type={previousLinksExists ? "default" : "primary"}
              className={
                previousLinksExists ? "brand-button" : "primary_button"
              }
              onClick={() =>
                isLoading ? null : handleSendSecureRegistrationLink()
              }
              loading={isLoading}
              disabled={deliveryType === DeliveryType.Sms && !phoneNumber}
            >
              Send {deliveryType === DeliveryType.Sms ? "Message" : "Email"}
            </Button>
          </div>
        }
      >
        <Tabs
          defaultActiveKey={DeliveryType.Sms}
          onChange={(key) => setDeliveryType(key as DeliveryType)}
          items={[
            { key: DeliveryType.Sms, label: "SMS" },
            { key: DeliveryType.Email, label: "Email" },
          ]}
        />
        {!phoneNumber && deliveryType === DeliveryType.Sms ? (
          <Loader />
        ) : (
          <>
            <div className='send_new_link_section'>
              {deliveryType === DeliveryType.Sms ? (
                <>
                  This will send a text message to the claimant's phone number{" "}
                  <p>
                    <strong>{formatPhoneNumber(phoneNumber)}</strong> to
                    register for Mindset. If this number is incorrect, please
                    update it first before sending.
                  </p>
                </>
              ) : (
                <>
                  This will send an email to the claimant's registered email
                  address{" "}
                  <p>
                    <strong>{email}</strong> to register for Mindset. If this
                    email is incorrect, please update it first before sending.
                  </p>
                </>
              )}
            </div>

            <div className='send_new_link_message_section'>
              <p className='send_new_link_message_section_title'>Message</p>
              <p className='send_new_link_message_section_content'>
                Complete your registration with Mindset by following this secure
                link:{" "}
                <span className='unique_link_placeholder'>[unique link]</span>
              </p>
            </div>

            <label className='registration_link_label'>History</label>
            {filteredLinks.length > 0 ? (
              <div className='sent_messages_section'>
                {filteredLinks
                  .sort(
                    (a, b) =>
                      moment(b.createdDate).unix() -
                      moment(a.createdDate).unix()
                  )
                  .map((link) => {
                    const expirationMoment = moment.utc(link?.expirationDate);
                    const sendMoment = moment.utc(link?.sendDate);
                    const currentMoment = moment.utc();
                    const isExpired = expirationMoment.isBefore(currentMoment);

                    const inactive = isExpired || link?.isUsed;
                    const backgroundColorClass = inactive
                      ? "expired-link"
                      : "active-link";

                    const formattedExpirationDate = expirationMoment
                      .local()
                      .format("MMM. Do, YYYY [at] h:mm:ss A");

                    const formattedSendDate = sendMoment
                      .local()
                      .format("MMM. Do, YYYY [at] h:mm:ss A");

                    return (
                      <div
                        className={`registration_link_info ${backgroundColorClass}`}
                        key={link.id}
                      >
                        <p>
                          <strong>Sent: </strong>
                          {formattedSendDate}
                        </p>
                        <p>
                          <strong>Status: </strong>
                          {inactive ? "Inactive" : "Active"}
                          {link.isUsed && " | Used"}
                        </p>
                        <p>
                          <strong>
                            {deliveryType === DeliveryType.Sms
                              ? "Phone"
                              : "Email"}
                            :{" "}
                          </strong>
                          {deliveryType === DeliveryType.Sms
                            ? formatPhoneNumber(link.phone)
                            : link.email}
                        </p>
                        <p>
                          <strong>Expiration: </strong>
                          {formattedExpirationDate}
                        </p>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div style={{ color: "grey", fontSize: 16 }}>
                No links have been sent.
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default SendSecureRegistrationLink;
