import { useEffect } from "react";
import { Button, Flex, Form, Input } from "antd";
import { saveChangePassword } from "../../actions/users";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../../validations/validatePassword";
import { FlashMessage } from "../../utils/flash_message";
import useFeatureFlag from "../../components/useFeatureFlag";
import { Constants } from "../../common/constants";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const { featureFlags } = useFeatureFlag();
  const navigate = useNavigate();

  useEffect(() => {
    if (featureFlags[Constants.featureFlags.Test]) {
      navigate("/leads");
    }
  }, [featureFlags]);

  const saveChangePasswordApi = (e: any) => {
    var payload = {
      OldPassword: e.oldPassword,
      NewPassword: e.password,
    };
    saveChangePassword(payload).then((res) => {
      if (res.data.responseCode == 303) {
        FlashMessage("error", "Old Password does not match.");
      }
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Password changed Successfully.");
        navigate("/leads");
      }
    });
  };
  return (
    <>
      <section id='change_password' className='change_password mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 p-0'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='signup_modal'>
                    <div className='sign_up_details'>
                      <h2 className='heading_signup text-center'>
                        <strong>Change Password!</strong>
                      </h2>
                      <p className='fill_info mt-3 text-center mb-1'>
                        Please fill in the below to Change Password:
                      </p>
                      <Flex justify='center'>
                        <Form
                          className='mt-4'
                          form={form}
                          name='ChangePasswordForm'
                          labelCol={{ span: 24 }}
                          wrapperCol={{
                            span: 24,
                          }}
                          autoComplete='off'
                          onFinish={saveChangePasswordApi}
                          style={{ width: 500 }}
                        >
                          <Form.Item
                            label='Old Password'
                            name='oldPassword'
                            rules={[
                              {
                                required: true,
                                message: "Please input the value!",
                              },
                            ]}
                          >
                            <Input.Password
                              maxLength={256}
                              minLength={4}
                              placeholder='Old Password'
                            />
                          </Form.Item>

                          <Form.Item
                            label='New Password'
                            name='password'
                            rules={[
                              {
                                required: true,
                                message: "Please input the value!",
                              },
                              {
                                validator: validatePassword,
                              },
                            ]}
                          >
                            <Input.Password placeholder='Password' />
                          </Form.Item>

                          <Form.Item
                            label='Confirm Password'
                            name='confirmPassword'
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },

                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "New password does not match the confirm password!"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password placeholder='Confirm Password' />
                          </Form.Item>

                          <Flex gap={8} justify='center'>
                            <Button htmlType='submit' type='primary'>
                              Submit
                            </Button>
                            <Button
                              onClick={() => navigate("/leads")}
                              type='default'
                            >
                              Cancel
                            </Button>
                          </Flex>
                        </Form>
                      </Flex>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
