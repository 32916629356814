import React, { useEffect, useState } from "react";
import { GetCaseManagementAiPlan } from "../../apis/caseManagementAI";
import Markdown from "react-markdown";
import Loader from "../Loader";
import { Alert, Card, Flex, Typography } from "antd";
import MindsetAILogo from "./MindsetAILogo";
import "./styles.scss";

const { Title, Paragraph, Text } = Typography;

interface CaseManagementAISummaryProps {
  claimantId: number;
}

interface CaseManagementAiPlan {
  claimantMindsetUserId: number;
  caseInconsistencies: string;
  caseSummary: string;
  suggestedActions: string;
}

const CaseManagementAISummary: React.FC<CaseManagementAISummaryProps> = ({
  claimantId,
}) => {
  const [caseManagementAiPlan, setCaseManagementAiPlan] =
    useState<CaseManagementAiPlan>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetCaseManagementAiPlan(claimantId);
        setCaseManagementAiPlan(response.data.responseData);
      } catch (error) {
        console.error("Error fetching case management AI plan:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [claimantId]);

  const MarkdownRenderer = ({ markdown }: { markdown: string }) => {
    return (
      <Markdown
        components={{
          h1: ({ children }: any) => <Title level={1}>{children}</Title>,
          h2: ({ children }: any) => <Title level={2}>{children}</Title>,
          h3: ({ children }: any) => <Title level={3}>{children}</Title>,
          h4: ({ children }: any) => <Title level={4}>{children}</Title>,
          h5: ({ children }: any) => <Title level={5}>{children}</Title>,
          p: ({ children }: any) => <Paragraph>{children}</Paragraph>,
          span: ({ children }: any) => <Text>{children}</Text>,
          ul: ({ children }: any) => <ul style={{ paddingLeft: "20px" }}>{children}</ul>,
          ol: ({ children }: any) => <ol style={{ paddingLeft: "20px" }}>{children}</ol>,
          li: ({ children }: any) => (
            <li>
              <Text>{children}</Text>
            </li>
          ),
        }}
      >
        {markdown}
      </Markdown>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return caseManagementAiPlan ? (
    <Flex vertical gap={20}>
      <Card className='ai-card'>
        <Flex justify='space-between' align='center'>
          <Typography.Title level={5}>Case Summary</Typography.Title>
          <MindsetAILogo />
        </Flex>
        <MarkdownRenderer markdown={caseManagementAiPlan?.caseSummary} />
      </Card>

      <Card className='ai-card'>
        <Flex justify='space-between' align='center'>
          <Typography.Title level={5}>Case Inconsistencies</Typography.Title>
          <MindsetAILogo />
        </Flex>
        <MarkdownRenderer
          markdown={caseManagementAiPlan?.caseInconsistencies}
        />
      </Card>

      <Card className='ai-card'>
        <Flex justify='space-between' align='center'>
          <Typography.Title level={5}>Suggested Actions</Typography.Title>
          <MindsetAILogo />
        </Flex>
        <MarkdownRenderer markdown={caseManagementAiPlan?.suggestedActions} />
      </Card>
    </Flex>
  ) : (
    <Alert
      type='warning'
      message='Unable to generate summary'
      description='AI Summary was not able to be generated. Please try again later and report this issue to the team if it persists.'
      showIcon
    />
  );
};

export default CaseManagementAISummary;
