import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Constants } from "../constants";
import { get } from "../../utils/clientStorageUtils";

interface Permissions {
  canRead: boolean;
  canWrite: boolean;
}

const usePermissions = (
  sectionKey: keyof typeof Constants.permissionedRolesBySection
): Permissions => {
  const [permissions, setPermissions] = useState<Permissions>({
    canRead: false,
    canWrite: false,
  });

  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPermissions = () => {
      if (roleInStorage) {
        const sectionPermissions =
          Constants.permissionedRolesBySection[sectionKey];

        if (sectionPermissions) {
          const canRead = sectionPermissions.readGroup.includes(roleInStorage);
          const canWrite =
            sectionPermissions.writeGroup.includes(roleInStorage);
          setPermissions({ canRead, canWrite });
        }
      }
    };

    fetchPermissions();
  }, [sectionKey, navigate]);

  if (!permissions.canRead) {
    navigate(`/${Constants.pageRoutes.leads}`);
  }

  return permissions;
};

export default usePermissions;
