import { List } from "antd";
import { useEffect, useState } from "react";
import {
  getLeadsById,
  updatePrequlification,
} from "../../../actions/application";
import { FlashMessage } from "../../../utils/flash_message";
import prequal_field_labels from "./fields";
import { ScreeningListEmpty } from "../components/ScreeningListEmpty";
import { ScreeningListHeader } from "../components/ScreeningListHeader";
import { ScreeningFieldLabel } from "../components/ScreeningFieldLabel";
import { ScreeningFieldEdit } from "../components/ScreeningFieldEdit";
import { displayFieldByType } from "../helpers/displayFieldByType";
import "../styles.css";

const PreQualFieldList = ({ leadId }: { leadId: string | null }) => {
  const [values, setValues] = useState<any>({});
  const [filterFields, setFilterFields] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [leadDetails, setLeadDetails] = useState(null);

  const getLeadDetails = async () => {
    try {
      const {
        data: { responseData: lead_details },
      } = await getLeadsById(leadId);
      setLeadDetails(lead_details);
    } catch (err) {
      FlashMessage("error", "Failed to fetch lead details.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (leadId) {
      getLeadDetails();
    }
  }, [leadId]);

  const prequal_fields = Object.entries(prequal_field_labels)
    .filter((field) => field[0] in values)
    .map(([key, value]) => ({
      key,
      value: values[key],
      ...prequal_field_labels[key],
    }));

  const shouldDisplayField = (item: any) => {
    if (!prequal_field_labels[item.key].displayIf) {
      return true;
    }

    if (values[prequal_field_labels[item.key].displayIf as string]) {
      return true;
    }

    return false;
  };

  const onSave = async () => {
    var payload = {
      Id: leadId,
      ...values,
    };
    try {
      const res = await updatePrequlification(payload);
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Pre-qualification updated successfully.");
        setIsEditing(false);
      }
    } catch (err: any) {
      if (err.response.data.errors) {
        Object.keys(err.response.data.errors).map((errorKey) =>
          FlashMessage("error", err.response.data.errors[errorKey])
        );
      }
    }
  };

  useEffect(() => {
    if (leadDetails) {
      const prequal_values = Object.keys(prequal_field_labels).reduce(
        (acc, key) => {
          acc[key] = leadDetails[key];
          return acc;
        },
        {} as Record<string, any>
      );
      setValues(prequal_values);
    }
  }, [leadDetails]);

  if (!leadId) {
    return (
      <ScreeningListEmpty
        title='Pre-Qualification'
        message='This claimant is not linked to a lead. To get prequalification data, please link a lead and try again.'
      />
    );
  }

  return (
    <div className='row mt-3'>
      <div className='col-12 mb-4'>
        <List
          className='screening_fields_list'
          size='small'
          bordered
          loading={isLoading}
          locale={{
            emptyText: "No questions found, try a different search.",
          }}
          header={
            <ScreeningListHeader
              title='Pre-Qualification'
              onFilter={setFilterFields}
              onEdit={setIsEditing}
              onSave={onSave}
            />
          }
          itemLayout='horizontal'
          dataSource={prequal_fields.filter((field) =>
            filterFields
              ? prequal_field_labels[field.key].label
                  .toLowerCase()
                  .includes(filterFields.toLowerCase())
              : true
          )}
          renderItem={(item) =>
            isEditing ? (
              <List.Item className='d-flex align-items-center'>
                <ScreeningFieldLabel field={prequal_field_labels[item.key]} />
                <div className='prequal_fields_value'>
                  {shouldDisplayField(item) && (
                    <ScreeningFieldEdit
                      defaultValue={item.value}
                      onChange={(val) =>
                        setValues({
                          ...values,
                          [item.key]: val,
                        })
                      }
                      field={item}
                    />
                  )}
                </div>
              </List.Item>
            ) : (
              <List.Item className='d-flex align-items-center'>
                <ScreeningFieldLabel field={prequal_field_labels[item.key]} />
                <div className='prequal_fields_value'>
                  {!prequal_field_labels[item.key].displayIf ||
                  values[prequal_field_labels[item.key].displayIf as string]
                    ? displayFieldByType(
                        prequal_field_labels[item.key],
                        item.value
                      )
                    : ""}
                </div>
              </List.Item>
            )
          }
        />
      </div>
    </div>
  );
};

export default PreQualFieldList;
