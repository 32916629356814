const ClaimantProfileTabKeys = {
  SUMMARY: "1",
  NOTES_TASKS: "2",
  PRE_QUALIFICATIONS: "3",
  APPLICATION: "4",
  TEMPLATES: "5",
  DOCUMENTS: "6",
  GENERATE_DOCUMENTS: "7",
  FAX_MANAGER: "8",
  SEND_PHYSICAL_MAIL: "9",
  CONTACT_MESSAGES: "10",
} as const;

export type TabKey = keyof typeof ClaimantProfileTabKeys;
export default ClaimantProfileTabKeys;
