import React, { useState, useEffect } from "react";
import { getActivityLogByLoginId } from "../../actions/application";
import { get } from "../../utils/clientStorageUtils";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { Constants } from "../../common/constants";
import * as signalR from "@microsoft/signalr";
import Loader from "../Loader";
import { GetAdminApiBaseUrlFromWindow } from "../../utils/utils";

const ActivityLog = () => {
  const [activityLog, setActivityLog] = useState([]);
  const [searchParams] = useSearchParams();
  const [isLoader, setIsLoader] = useState(false);

  const getActivityLogByLoginIdApi = () => {
    var param = searchParams.get("id") ?? "0";
    setIsLoader(true);
    getActivityLogByLoginId(param).then((res) => {
      var data = res.data.responseData;
      setIsLoader(false);
      setActivityLog(
        data.map((ele: any) => {
          // var date = moment.utc(ele.createdDate).local().format("LLL")
          var date = moment
            .utc(ele.createdDate)
            .local()
            .format(Constants.dateTimeFormat);
          return (
            <>
              <p className={`text_activity mb-0`}>{date}</p>
              <p className={`text_activity`}>{ele.description}</p>
            </>
          );
        })
      );
    });
  };

  useEffect(() => {
    getActivityLogByLoginIdApi();
  }, [searchParams.get("id") ?? "0"]);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${GetAdminApiBaseUrlFromWindow()}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("commonUpdate_Delete", (res: any) => {
      if (res == null || res == searchParams.get("id"))
        getActivityLogByLoginIdApi();
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("commonUpdate_Delete");
      connection.stop().catch((err) => console.log("connection Stop Error"));
    };
  }, [searchParams.get("id") ?? "0"]);
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_Mindset_API_URL ?? ""}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("commonUpdate_Delete", (res: any) => {
      getActivityLogByLoginIdApi();
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("commonUpdate_Delete");
      connection.stop().catch((err) => console.log("connection Stop Error"));
    };
  }, [searchParams.get("id") ?? "0"]);

  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <div className={`activity_bar p-4 mt-4 text-center`}>
          <p className={`activity_heading text-center`}>Activity</p>
          {activityLog}
        </div>
      )}
    </>
  );
};

export default ActivityLog;
