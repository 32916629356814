import { Avatar, Space, Dropdown, Button } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import NotificationBell from "../../Notification/NotificationBell";
import SearchBar from "../../Search/SearchBar";
import CreateLeadForm from "../../CreateLeadButton/CreateLeadButton";
import useUser from "../../../common/customHooks/useUser";
import { Constants } from "../../../common/constants";
import type { MenuProps } from "antd";
import { signOutUser } from "../../../actions/accounts";
import { Link } from "react-router-dom";
import { remove } from "../../../utils/clientStorageUtils";
import useFeatureFlag from "../../useFeatureFlag";
import useMediaQuery from "../../../common/customHooks/useMediaQuery";

const RightMenu: React.FC = () => {
  const user = useUser();

  // Responsive breakpoints
  const shortName = useMediaQuery(1350);
  const leadButtonCompact = useMediaQuery(1300);
  const avatarCompact = useMediaQuery(1200);

  const { featureFlags } = useFeatureFlag();

  const hasPermission = (permissionGroup: string[], featureFlag?: boolean) =>
    permissionGroup.includes(user.role) && (featureFlag ?? true);

  const generateMenuItem = (
    permission: boolean,
    key: string,
    label: string,
    route: string
  ) => {
    return permission
      ? [
          {
            key,
            label: <Link to={route}>{label}</Link>,
          },
        ]
      : [];
  };

  const signOutUserApi = () => {
    signOutUser()
      .then(() => {})
      .catch(() => {});
    remove("token");
    remove("username");
    remove("email");
    remove("id");
  };

  const items: MenuProps["items"] = [
    {
      type: "group",
      label: "Manage",
      children: [
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.manageUser.readGroup
          ),
          "manage-users",
          "Support User",
          Constants.pageRoutes.manageUser
        ),
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.groupUser.readGroup
          ),
          "manage-user-groups",
          "User Groups",
          Constants.pageRoutes.managegroupuser
        ),
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.tenantManagement.readGroup
          ),
          "manage-tenants",
          "Enterprises",
          Constants.pageRoutes.tenants
        ),
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.manageMedication.readGroup
          ),
          "manage-medication",
          "Medications",
          Constants.pageRoutes.manageMedication
        ),
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.manageFeatureFlagSettings
              .readGroup
          ),
          "feature-flags",
          "Feature Flags",
          Constants.pageRoutes.featureFlagSettings
        ),
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.facilities.readGroup
          ),
          "manage-facilities",
          "Facilities",
          Constants.pageRoutes.facilities
        ),
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.claimantReassignment.readGroup
          ),
          "reassignment",
          "Assignments",
          Constants.pageRoutes.claimantReassignment
        ),
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.hearingCalendar.readGroup,
            featureFlags[Constants.featureFlags.hearingCalendarFeatureFlag]
          ),
          "hearings-calendar",
          "Hearings Calendar",
          Constants.pageRoutes.hearingCalendar
        ),
        ...generateMenuItem(
          hasPermission(Constants.permissionedRolesBySection.logs.readGroup),
          "logs",
          "Logs",
          Constants.pageRoutes.logs
        ),
      ],
    },
    { type: "divider" },
    {
      type: "group",
      label: "Notifications",
      children: [
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.notificationTemplate.readGroup
          ),
          "notification-templates",
          "Templates",
          Constants.pageRoutes.notificationTemplates
        ),
        ...generateMenuItem(
          hasPermission(
            Constants.permissionedRolesBySection.notificationConfiguration
              .readGroup
          ),
          "notification-configuration",
          "Configurations",
          Constants.pageRoutes.notificationconfiguration
        ),
      ],
    },
    { type: "divider" },
    {
      type: "group",
      label: "Account",
      children: [
        ...generateMenuItem(
          !featureFlags[Constants.featureFlags.Test],
          "change-password",
          "Change Password",
          Constants.pageRoutes.changePassword
        ),
        {
          key: "logout",
          label: (
            <Link to={Constants.pageRoutes.login} onClick={signOutUserApi}>
              Sign Out
            </Link>
          ),
          icon: <LogoutOutlined />,
        },
      ],
    },
  ];

  return (
    <div className='navbar-right'>
      <SearchBar />
      <CreateLeadForm compact={leadButtonCompact} />
      <NotificationBell />
      <Dropdown
        menu={{ items }}
        trigger={["hover"]}
        placement='bottomRight'
        overlayClassName='profile-dropdown-menu'
      >
        <Button type='text' size='large' className='profile-dropdown-button'>
          <Space size={10}>
            {user?.picture ? (
              <Avatar src={user.picture} size='small' />
            ) : (
              <Avatar icon={<UserOutlined />} size='small' />
            )}
            {!avatarCompact &&
              ((shortName
                ? user.firstName
                : `${user?.firstName} ${user.lastName}`) ??
                "Settings")}
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default RightMenu;
