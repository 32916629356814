import { useEffect, useState } from "react";
import { getApplicationDetailsById } from "../../actions/application";

interface ApplicationData {
  id: number;
  mindsetUserId: string | null;
  firstName: string;
  lastName: string;
  middleName: string;
  ssn: string;
  dateOfBirth: string;
  gender: string;
  isDeniedSSN: boolean;
  isUnableWork: boolean;
  heightWithoutShoes: string;
  medicalCondition: string;
  mailingAddress: string;
  isLowVision: boolean;
  isDisabled: boolean;
  isDeniedBenefits: boolean;
  startDateOfDisability: string;
  phoneNumber: string;
  timeToCall: string;
  placeOfBirth: string;
  isReside: boolean;
  isDeadlyCondition: boolean;
  isUSCitizen: boolean;
  languageForSpeaking: string;
  languageForReading: string;
  typeOfCitizenship: string;
  isSpeakEnglish: boolean;
  isReadEnglish: boolean;
  isWriteEnglish: boolean;
  isOtherSSNumber: boolean;
  spouseFName: string;
  spouseMName: string;
  spouseLName: string;
  spouseSSNumber: string;
  spouseDOB: string;
  isMarried: boolean;
  isSpouseWorked: boolean;
  placeOfMarriage: string;
  dateOfMarriage: string;
  isPriorMarriage: boolean;
  isAnyChildren: boolean;
  isMillitary: boolean;
  isAppliedPDB: boolean;
  moneyEarnedIn2021: string;
  isOtherName: boolean;
  isWorkIn2022: boolean;
  isSelfEmployed2021: boolean;
  isSelfEmployed2022: boolean;
  isPriorApplications: boolean;
  moneyEarnedIn2022: string;
  isWorkedOutsideUS: boolean;
  isSpouseWorkedOutsideUS: boolean;
  isAgreeWithEarningHistory: boolean;
  isWorkedTaxesNotDecucted: boolean;
  isOwnBankAccount: boolean;
  weightWIthoutShoes: string;
  isReceiveMoneyUnableToWork: boolean;
  isExpectMoneyFromRecentEmployer: boolean;
  isOneParent: boolean;
  email: string;
  userName: string;
  applicationStatus: string;
  leadId: number;
  addressOne: string;
  city: string;
  state: string;
  zip: string;
  addressTwo: string;
  timezone: string;
  caseManagerName: string;
  caseManagerPhoneNumber: string;
  lawyerName: string;
  tenantId: string;
  isDocusignEligible: boolean;
  isSelfServeRegistration: boolean;
  isIntakeReviewStarted: boolean | null;
  isIntakeReviewAccepted: boolean | null;
  facilityName: string;
  isJailUser: boolean;
}

const useAppData = (id: string | null) => {
  const [appData, setAppData] = useState<ApplicationData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      if (!id) {
        setIsLoading(false);
        return;
      }
      try {
        const response = await getApplicationDetailsById(id);
        setAppData(response.data.responseData);
      } catch (error) {
        setError("Error fetching application details");
      } finally {
        setIsLoading(false);
      }
    };

    fetchApplicationDetails();
  }, [id]); // Re-run if `id` changes

  return { appData, isLoading, error };
};

export default useAppData;
