import { Button, Checkbox, Form, Modal, Radio, Row, Select, Space } from "antd";
import BrandButton from "../../BrandButton";
import { useState } from "react";
import { saveNotes } from "../../../actions/notes";
import { useSearchParams } from "react-router-dom";
import { FlashMessage } from "../../../utils/flash_message";
import { updateClaim } from "../../../actions/claims";
import { ClaimOptions, updateClaimPayloads } from "./Claims/constants";
import CreateNoteWrapper from "./CreateNoteWrapper";
import { claimsNoteValidation } from "./CompleteIntake";

type CheckboxValueType = string | number | (string | number)[];

const ConfirmSubmission = ({
  claims,
  onSuccess,
  claimOptions,
  isAllowed,
}: {
  claims: any;
  onSuccess: Function;
  claimOptions: ClaimOptions;
  isAllowed: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [claimTypes, setClaimTypes] = useState<CheckboxValueType[]>([]);
  const [priorClaim, setPriorClaim] = useState<string | undefined>(undefined);
  const [initialApplicationOrigin, setInitialApplicationOrigin] = useState("");
  const [priorCaseLevel, setPriorCaseLevel] = useState("");

  const [eligibleClaimTypes, setEligibleClaimTypes] = useState<
    CheckboxValueType[]
  >(
    claims
      .filter((c: any) => c.isEligible)
      .map((c: any) => c.ClaimTypeIdDisplayNameReadOnly)
  );

  const [intakeNote, setIntakeNote] = useState<any>({
    description: "",
    plainText: "",
    tagsText: "",
    type: undefined,
    minutes: undefined,
    category: undefined,
    source: undefined,
    tagUserIds: "",
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const submitModal = async () => {
    setIsLoading(true);

    if (eligibleClaimTypes.length === 0) {
      setIsLoading(false);
      return FlashMessage(
        "error",
        "Please select claimant's eligible claim types."
      );
    }
    if (claimTypes.length === 0) {
      setIsLoading(false);
      return FlashMessage("error", "Please select at least one claim type.");
    }

    // Create Note
    const note_payload = {
      Description: intakeNote.description,
      EncryptedUserId: searchParams.get("id") ?? "0",
      LeadsId: null,
      PlainText: intakeNote.plainText,
      TagsText: intakeNote.tagsText,
      Type: JSON.stringify(intakeNote.type),
      Source: intakeNote.source,
      Category: intakeNote.category,
      Minutes: intakeNote.minutes,
    };

    let validation_error = claimsNoteValidation(note_payload);

    if (validation_error) {
      FlashMessage("error", validation_error);
      setIsLoading(false);
      return;
    }

    const note_response = await saveNotes(note_payload, intakeNote.tagUserIds);

    FlashMessage("success", "Note added successfully.");

    const claim_response = await Promise.all(
      claims.map((claim: any) => {
        if (!claimTypes.includes(claim.ClaimTypeIdDisplayNameReadOnly)) {
          return updateClaim({
            id: claim.id,
            isEligible: eligibleClaimTypes.includes(
              claim.ClaimTypeIdDisplayNameReadOnly
            ),
          });
        }
        const payload = updateClaimPayloads(
          priorClaim === "true"
            ? "CONFIRM_PRIOR_SUBMISSION"
            : "CONFIRM_SUBMISSION",
          claim,
          eligibleClaimTypes.includes(claim.ClaimTypeIdDisplayNameReadOnly)
        );
        return updateClaim(
          priorClaim === "true"
            ? {
                ...payload,
                CaseLevelId: priorCaseLevel,
                InitialApplicationOriginId: initialApplicationOrigin,
              }
            : payload
        );
      })
    );

    FlashMessage("success", "Claim updated successfully.");

    searchParams.set(
      "refresh_notes",
      `claims_submission_${new Date().getTime()}`
    );
    setSearchParams(searchParams);

    await onSuccess();

    setIsModalOpen(false);
  };

  const cancelModal = () => {
    // TOOD: Do we want to clear our Notes form state?
    setIsModalOpen(false);
  };

  const changePriorClaim = (e: any) => {
    setPriorClaim(e.target.value);
  };

  if (!isAllowed) {
    return (
      <p>
        Once the claim has been submitted or a pending claim has been verified,
        Case Managers will cofirm the claim submission.
      </p>
    );
  }

  return (
    <>
      <p>
        Once the claim has been submitted or a pending claim has been verified,
        click Confirm Claim Submission.
      </p>
      <div className='d-flex flex-column align-items-start'>
        <Button
          className='confirm-claim-submission-btn'
          type='primary'
          onClick={showModal}
        >
          Confirm Claim Submission
        </Button>
      </div>
      <Modal
        className='claims-modal'
        title='Confirm Claim Submission'
        open={isModalOpen}
        onOk={submitModal}
        onCancel={cancelModal}
        width={760}
        footer={[
          <Button key='back' onClick={cancelModal}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={isLoading}
            onClick={submitModal}
          >
            Confirm Claim Submission
          </Button>,
        ]}
      >
        <p className='mt-2 mb-0'>
          Once you have attempted to submit an Initial Application on SSA.gov,
          verify the information below to register the claim in Mindset’s
          system.
        </p>
        <div className='d-flex flex-column'>
          <label className='intake-form-label'>
            What are the claimant's eligible claim types?
          </label>

          <Checkbox.Group
            value={eligibleClaimTypes}
            onChange={(v) => {
              setClaimTypes(
                [...claimTypes].filter((claimType) =>
                  !v.includes(claimType) ? false : true
                )
              );
              setEligibleClaimTypes(v);
            }}
          >
            <Row className='mb-2'>
              <Checkbox
                value='Ssdi'
                disabled={
                  claims.find(
                    (c: any) =>
                      c.ClaimTypeIdDisplayNameReadOnly === "Ssdi" &&
                      c.iaSubmittedByMindsetUserId
                  )
                    ? true
                    : false
                }
              >
                SSDI - Social Security Disability Insurance - T2
              </Checkbox>
            </Row>
            <Row>
              <Checkbox
                value='Ssi'
                disabled={
                  claims.find(
                    (c: any) =>
                      c.ClaimTypeIdDisplayNameReadOnly === "Ssi" &&
                      c.iaSubmittedByMindsetUserId
                  )
                    ? true
                    : false
                }
              >
                SSI - Supplemental Security Income - T16
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </div>
        <div className='d-flex flex-column'>
          <label className='intake-form-label'>
            Which claims are being confirmed?
          </label>

          <Checkbox.Group value={claimTypes} onChange={setClaimTypes}>
            <Row className='mb-2'>
              <Checkbox
                value='Ssdi'
                disabled={
                  claims.find(
                    (c: any) =>
                      c.ClaimTypeIdDisplayNameReadOnly === "Ssdi" &&
                      !eligibleClaimTypes.includes(
                        c.ClaimTypeIdDisplayNameReadOnly
                      )
                  )
                    ? true
                    : false
                }
              >
                SSDI - Social Security Disability Insurance - T2
              </Checkbox>
            </Row>
            <Row>
              <Checkbox
                value='Ssi'
                disabled={
                  claims.find(
                    (c: any) =>
                      c.ClaimTypeIdDisplayNameReadOnly === "Ssi" &&
                      !eligibleClaimTypes.includes(
                        c.ClaimTypeIdDisplayNameReadOnly
                      )
                  )
                    ? true
                    : false
                }
              >
                SSI - Supplemental Security Income - T16
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </div>

        <div className='d-flex flex-column'>
          <label className='intake-form-label'>
            Does Claimaint have a Prior or Pending claim?
          </label>
          <Radio.Group onChange={changePriorClaim} value={priorClaim}>
            <Space direction='vertical'>
              <Radio value='true'>
                Yes - Claimant has an active or pending claim
              </Radio>
              <Radio value='false'>
                No - Claimant has no pending or previous claims
              </Radio>
            </Space>
          </Radio.Group>
        </div>

        {priorClaim === "true" && (
          <>
            <div className='d-flex flex-column'>
              <label className='intake-form-label'>Prior Submission By</label>
              <p>Select who originally submitted the prior or pending claim.</p>
              <Select
                placeholder='Choose Origin'
                style={{
                  width: "300px",
                }}
                value={initialApplicationOrigin}
                onChange={setInitialApplicationOrigin}
                options={claimOptions.initialApplicationOrigins.map(
                  (origin) => ({
                    value: origin.id,
                    label: origin.name,
                  })
                )}
              />
            </div>

            <div className='d-flex flex-column'>
              <label className='intake-form-label'>
                Case Level of Prior Claim
              </label>
              <p>
                This will be updated with ERE data once it syncs with our
                system.
              </p>
              <Select
                placeholder='Choose Case Level'
                style={{
                  width: "300px",
                }}
                value={priorCaseLevel}
                onChange={setPriorCaseLevel}
                options={claimOptions.caseLevels.map((level) => ({
                  value: level.id,
                  label: level.name,
                }))}
              />
            </div>
          </>
        )}

        {priorClaim !== undefined && (
          <div className='d-flex flex-column'>
            <label className='intake-form-label'>
              Claimaint Submission Note
            </label>
            <p className='mb-0'>
              This will appear as a note in Notes for your claimaint.
            </p>
            <CreateNoteWrapper
              value={intakeNote}
              onChange={setIntakeNote}
              defaultValues={{}}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ConfirmSubmission;
