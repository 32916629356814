// Flash message gets instantiated in App.tsx so it inherits global Antd styles
let notificationInstance: any = null;

export const initFlashMessage = (antdApp: any) => {
  notificationInstance = antdApp.notification;
};

export const FlashMessage = (
  type: string,
  message: string | React.ReactElement,
  title = "",
  timeout = 3,
  key = ""
) => {
  if (!notificationInstance) {
    console.error("FlashMessage was called before initialization.");
    return;
  }

  const config = {
    message: title,
    description: message,
    duration: timeout,
    key: key || (typeof message === "string" ? message : ""),
  };

  switch (type) {
    case "info":
      notificationInstance.info(config);
      break;
    case "success":
      notificationInstance.success(config);
      break;
    case "warning":
      notificationInstance.warning(config);
      break;
    case "error":
      notificationInstance.error(config);
      break;
  }
};
