import { Button, Input, List, Radio, Select, Tooltip } from "antd";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { isFieldComplete } from ".";

const renderFieldValue = (field: any, value: any, formValues?: any) => {
  // const value = values[field.databaseKey];
  if (
    field.type === "Input" ||
    field.type === "FullDate" ||
    field.type === "DateOfBirthInput" ||
    field.type === "SSNInput" ||
    field.type === "Select" ||
    field.type === "NumberInput" ||
    field.type === "StateSelect" ||
    field.type === "CountrySelect" ||
    field.type === "AddressAutocomplete" ||
    field.type === "PhoneNumberInput" ||
    field.type === "Textarea" ||
    field.type === "Radio" ||
    field.type === "DateInput"
  ) {
    return value;
  } else if (field.type === "DollarInput") {
    return value ? `$${value}` : "";
  } else if (field.type === "YesNoRadioGroup") {
    if (value == undefined) {
      return value;
    }
    return value ? "Yes" : "No";
  } else if (field.type === "MultiCheckbox") {
    return (
      <ul
        style={{
          paddingLeft: 0,
        }}
      >
        {(value || []).map((val: any) => (
          <li key={val}>{val}</li>
        ))}
      </ul>
    );
    // return (value || []).join(", ");
  } else if (field.type === "ListManager") {
    // return (
    //   <List
    //             itemLayout='horizontal'
    //             dataSource={value || []}
    //             renderItem={(
    //               field // add displayIf logic
    //             ) => (
    //               <List.Item>
    //                 <List.Item.Meta
    //                   avatar={
    //                     values[field.databaseKey] !== undefined ? (
    //                       <CheckCircleFilled />
    //                     ) : (
    //                       <CloseCircleOutlined
    //                         style={{
    //                           color: "#f0f0f0",
    //                         }}
    //                       />
    //                     )
    //                   }
    //                   title={field.label}
    //                   description={renderFieldValue(
    //                     field,
    //                     values[field.databaseKey]
    //                   )}
    //                 />
    //               </List.Item>
    //             )}
    //           />
    // )

    return (value || []).map((val: any, index: number) => (
      <ul
        style={{
          paddingLeft: 0,
        }}
      >
        <li>
          {field.heading} #{index + 1}
        </li>
        <ul>
          {field.children.map((child: any) => (
            <li key={child.databaseKey}>
              <h4 className='ant-list-item-meta-title mt-2'>{child.label}:</h4>{" "}
              {renderFieldValue(child, val[child.databaseKey])}
            </li>
          ))}
        </ul>
      </ul>
    ));
  } else if (field.type === "GeneratedList") {
    if (field.databaseKey === "grossWages") {
      // TODO: Loop over employers
      return (
        <ul>
          {formValues.employers.map((employer: any) => (
            <li>
              <h4 className='ant-list-item-meta-title mt-2'>{employer.name}</h4>{" "}
              ${employer.grossWages}
            </li>
          ))}
        </ul>
      );
    } else if (field.databaseKey === "treatmentDates") {
      return (
        <ul>
          {formValues.hopitals.map((hospital: any) => (
            <li>
              {hospital.name}
              <ul>
                {field.children.map((child: any) => (
                  <li>
                    {child.databaseKey}: {hospital[child.databaseKey]}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      );
    }
    return (
      <ul>
        {Object.keys(value).map((key) => (
          <li>
            <h4 className='ant-list-item-meta-title mt-2'>{key}</h4> $
            {value[key]}
          </li>
        ))}
      </ul>
    );
  } else if (field.type === "ConditionsInput") {
    return value.join(",");
  }
  return `RENDER FIELD TYPE: ${field.type}`;
};

const editingSupporting = ["YesNoRadioGroup", "Input", "Select"];

const EditField = ({
  field,
  defaultValue,
  onChange,
}: {
  field: any;
  defaultValue: any;
  onChange: (val: any) => void;
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    onChange(value);
  }, [value]);

  if (field.type === "YesNoRadioGroup") {
    return (
      <Radio.Group
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
      >
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    );
  } else if (field.type === "Input") {
    return (
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={field.placeholder}
        style={{
          width: "240px",
        }}
      />
    );
  } else if (field.type === "Select") {
    return (
      <Select
        value={value}
        onChange={(val) => setValue(val)}
        options={field.options.map((val: string) => ({
          value: val,
          label: val,
        }))}
        style={{
          width: "240px",
        }}
      ></Select>
    );
  } else if (field.type === "DollarInput") {
    return <Input value={value} onChange={(val) => setValue(val)} />;
  }
  return (
    <div>
      <p>Add Editing Support: {field.type}</p>
    </div>
  );
};

const FieldItem = ({
  field,
  values,
  onSave,
}: {
  field: any;
  values: any;
  onSave: (newSingleValue: any) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newValue, setNewValue] = useState(values[field.databaseKey]);

  const saveValue = async () => {
    setIsSaving(true);

    await onSave({
      [field.databaseKey]: newValue,
    });

    setIsEditing(false);
    setIsSaving(false);
  };

  return (
    <List.Item>
      <List.Item.Meta
        avatar={
          isFieldComplete(field, values) ? (
            <CheckCircleFilled />
          ) : (
            <CloseCircleOutlined
              style={{
                color: "#f0f0f0",
              }}
            />
          )
        }
        title={field.label}
        description={
          isEditing ? (
            <EditField
              field={field}
              defaultValue={values[field.databaseKey]}
              onChange={setNewValue}
            />
          ) : (
            renderFieldValue(field, values[field.databaseKey], values)
          )
        }
      />
      {field.type !== "ListManager" && (
        <>
          {isEditing ? (
            <Tooltip title='Save Value'>
              <Button
                onClick={saveValue}
                shape='circle'
                variant='solid'
                type='primary'
                loading={isSaving}
                icon={<SaveOutlined />}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={
                editingSupporting.includes(field.type)
                  ? "Edit Value"
                  : "Coming Soon"
              }
            >
              <Button
                onClick={() => setIsEditing(true)}
                shape='circle'
                disabled={!editingSupporting.includes(field.type)}
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
        </>
      )}
    </List.Item>
  );
};

export default FieldItem;
