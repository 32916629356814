import React from "react";
import "./index.scss";
import drp_tab from "../../images/drp_tab.png";
import { formatPhoneNumber } from "../../utils/commonHelper";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Constants } from "../../common/constants";
import {
  addUpdateApplicationFormStepNine,
  getApplicationDetailsById,
  getLeadsById,
  resetDocuSignStatus,
} from "../../actions/application";
import { CopyOutlined, EllipsisOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { updateClientLeftPanel } from "../../actions/application";
import {
  pipelineStatusDetails,
  updateApplicationStatus,
  updateAssign_ReAssignEnterpriseClaimants,
  updateLeadApplicationStatus,
} from "../../actions/pipeline";
import { FlashMessage } from "../../utils/flash_message";
import {
  Button,
  Modal,
  Radio,
  Dropdown as AntDropdown,
  Flex,
  Row,
  Col,
} from "antd";
import {
  validateBlankSpace,
  validatePhoneNumberFormat,
} from "../../validations/validateBlankSpace";
import { Menu, Form, Input, Select, Checkbox, Tooltip } from "antd";
import {
  number,
  validateZipCode,
} from "../../validations/numeric_only-validate";
import { EmailExistByUserId } from "../../actions/accounts";
import { lessThanFourCharacters } from "../../validations/lessThanFourCharacters";
import { plusValidation, validateEmail } from "../../validations/validateEmail";
import { responseCodes } from "../Common/constants";
import { get } from "../../utils/clientStorageUtils";
import Loader from "../Loader";
import {
  clientUserAttributes,
  getClientUserAttributes,
  unMapLead,
} from "../../actions/leadsAndClients";
import StatesDropDownFormItem from "../../common/components/StatesDropdownFormItem";
import GooglePlacesAutocompleteWrapper from "../GooglePlacesAutoCompleteWrapper";
import { getTenant } from "../../actions/tenants";
import { Enums } from "../../common/enums";
import CustomerClaims from "./components/CustomerClaims";
import useFeatureFlag from "../useFeatureFlag";
import { getClaims } from "../../actions/claims";
import ClaimantAssignments from "./components/ClaimantAssignments/ClaimantAssignments";
import EnterpriseAssignment from "../../common/components/EnterpriseAssignment";
import SendSecureRegistrationLink from "./components/SendSecureRegistrationLink";
import SocialSecurityNumberInput from "../Common/form/SocialSecurityNumberInput";
import { minLengthNine } from "../../validations/validate_min_length";
import dayjs from "dayjs";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return { key, icon, children, label, type } as MenuItem;
}

const CustomerLeftPanel = (props: any) => {
  const [appData, setAppData] = useState({
    id: 0,
    mindsetUserId: null,
    firstName: "",
    lastName: "",
    middleName: "",
    ssn: "",
    dateOfBirth: "",
    gender: "",
    isDeniedSSN: false,
    isUnableWork: false,
    heightWithoutShoes: "",
    medicalCondition: "",
    mailingAddress: "",
    isLowVision: false,
    isDisabled: false,
    isDeniedBenefits: false,
    startDateOfDisability: "",
    phoneNumber: "",
    timeToCall: "",
    placeOfBirth: "",
    isReside: true,
    isDeadlyCondition: true,
    isUSCitizen: true,
    languageForSpeaking: "",
    languageForReading: "",
    typeOfCitizenship: "",
    isSpeakEnglish: true,
    isReadEnglish: true,
    isWriteEnglish: true,
    isOtherSSNumber: true,
    spouseFName: "",
    spouseMName: "",
    spouseLName: "",
    spouseSSNumber: "",
    spouseDOB: "",
    isMarried: false,
    isSpouseWorked: false,
    placeOfMarriage: "",
    dateOfMarriage: "",
    isPriorMarriage: true,
    isAnyChildren: true,
    isMillitary: true,
    isAppliedPDB: true,
    moneyEarnedIn2021: "",
    isOtherName: true,
    isWorkIn2022: true,
    isSelfEmployed2021: true,
    isSelfEmployed2022: true,
    isPriorApplications: true,
    moneyEarnedIn2022: "",
    isWorkedOutsideUS: true,
    isSpouseWorkedOutsideUS: true,
    isAgreeWithEarningHistory: true,
    isWorkedTaxesNotDecucted: true,
    isOwnBankAccount: true,
    weightWIthoutShoes: "",
    isReceiveMoneyUnableToWork: true,
    isExpectMoneyFromRecentEmployer: true,
    isOneParent: true,
    email: "",
    userName: "",
    applicationStatus: "",
    leadId: 0,
    addressOne: "",
    city: "",
    state: "",
    zip: "",
    addressTwo: "",
    timezone: "",
    caseManagerName: "",
    caseManagerPhoneNumber: "",
    lawyerName: "",
    tenantId: "",
    isDocusignEligible: false,
    isSelfServeRegistration: false,
    isIntakeReviewStarted: null,
    isIntakeReviewAccepted: null,
    facilityName: "",
    isJailUser: false,
  });
  const [addressValue, setAddressValue] = useState<string | null>(null);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isUpdatingCaseManager, setIsUpdatingCaseManager] = useState(true);
  const [leadDetails, setLeadDetails] = useState<{
    dateOfBirth: string;
  } | null>(null);
  const roleInStorage = JSON.parse(get("model")).userRoles[0];

  const featureFlags = useFeatureFlag().featureFlags;

  const getClientUserAttributesApi = () => {
    getClientUserAttributes(searchParams.get("id")).then((res) => {
      var resData = res.data.responseData;
      if (res.data.responseCode == 200) {
        setisSpanish(resData.isSpanish);
        setisCritical(resData.isCritical);
        setisDeceased(resData.isDeceased);
        setisMinor(resData.isMinor);
        setisTerminal(resData.isTerminal);
        setIsDenial(resData.isDenial);
        form1.setFieldsValue(resData);
      }
    });
  };
  const UnMapLeadApi = () => {
    if (
      window.confirm(
        "Are you sure you want to unlink the mapped lead? Unlinking will set the claimant's Denial, Critical, Deceased, Minor, Spanish, and Terminal checkboxes to unchecked"
      )
    ) {
      unMapLead(searchParams.get("id") ?? "0").then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", "Lead has been unlinked successfully.");
          setIsUpdatingCaseManager(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setIsUpdatingCaseManager(false);
        }
        getApplicationDetailsByIdApi();
      });
    }
  };
  const clientUserAttributesApi = (
    type:
      | "isSpanish"
      | "isCritical"
      | "isDeceased"
      | "isMinor"
      | "isTerminal"
      | "isDenial",
    value: any
  ) => {
    let payload = {
      EncryptedId: searchParams.get("id"),
      IsSpanish: isSpanish,
      IsCritical: isCritical,
      IsDeceased: isDeceased,
      IsMinor: isMinor,
      IsTerminal: isTerminal,
      isDenial: isDenial,
    };
    if (type == "isSpanish") {
      payload.IsSpanish = value;
    }
    if (type == "isCritical") {
      payload.IsCritical = value;
    }
    if (type == "isDeceased") {
      payload.IsDeceased = value;
    }
    if (type == "isMinor") {
      payload.IsMinor = value;
    }
    if (type == "isTerminal") {
      payload.IsTerminal = value;
    }
    if (type == "isDenial") {
      payload.isDenial = value;
    }

    clientUserAttributes(payload).then((res) => {
      if (res.data.responseCode == 200) {
        getClientUserAttributesApi();
      }
    });
  };
  const getApplicationDetailsByIdApi = () => {
    getClientUserAttributesApi();
    var param = searchParams.get("id") ?? "0";
    setIsPopUpEnterpriseLoader(true);
    getApplicationDetailsById(param).then((res) => {
      var appRes = res.data.responseData;
      setAppData(appRes);
      setIsEmail(appRes.isEmail);
      setIsText(appRes.isText);
      setIsUpdatingCaseManager(false);
      setAddressValue(appRes.addressOne ?? "");
      pipelineStatusDetailsApi(appRes.applicationStatus);
      form.setFieldsValue({
        ...appRes,
        phoneNumber: formatPhoneNumber(appRes.phoneNumber),
        dateOfBirth: dayjs(appRes.dateOfBirth).format("YYYY-MM-DD"),
      });
      formAssign_ReAssignEnterprise.setFieldsValue(appRes);
      setIsPopUpEnterpriseLoader(false);

      const pipelineId = appRes?.pipelineId;
      const pipelineStatusId = appRes?.pipelineStatusId;
      const searchParams = new URLSearchParams(location.search);

      if (
        searchParams.get("pipelineId") !== pipelineId ||
        searchParams.get("pipelineStatusId") !== pipelineStatusId
      ) {
        searchParams.set("pipelineId", pipelineId);
        searchParams.set("pipelineStatusId", pipelineStatusId);

        navigate({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
      }
    });
  };

  const getLeadDetails = async (id: any) => {
    try {
      const response = await getLeadsById(id);
      setLeadDetails(response.data.responseData);
    } catch (err) {
      console.error(`Error fetching lead w/ ID: ${id}`);
      console.error(err);
    }
  };

  const updateDocusignEligible = async (
    isEligible: boolean,
    showConfirm: boolean = true
  ) => {
    if (showConfirm) {
      if (
        !window.confirm(
          "Are you sure you want to make this Claimant Docusign eligible?"
        )
      ) {
        return;
      }
    }

    var payload = {
      EncryptedId: searchParams.get("id"),
      IsOtherSSNumber: appData.isOtherSSNumber,
      HeightWithoutShoes: appData.heightWithoutShoes,
      WeightWIthoutShoes: appData.weightWIthoutShoes,
      IsReceiveMoneyUnableToWork: appData.isReceiveMoneyUnableToWork,
      IsExpectMoneyFromRecentEmployer: appData.isExpectMoneyFromRecentEmployer,
      IsOneParent: appData.isOneParent,
      IsDocusignEligible: isEligible,
    };
    const res = await addUpdateApplicationFormStepNine(payload);
    if (res.data.responseCode === 200) {
      FlashMessage("success", "Claimant Docusign Eligibility Updated");
      setAppData({ ...appData, isDocusignEligible: isEligible });
    }
  };

  const onClick: MenuProps["onClick"] = (e) => {
    updateApplicationStatusApi(e.key);
  };

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isSpanish, setisSpanish] = useState(false);
  const [isCritical, setisCritical] = useState(false);
  const [isDeceased, setisDeceased] = useState(false);
  const [isMinor, setisMinor] = useState(false);
  const [isTerminal, setisTerminal] = useState(false);
  const [isDenial, setIsDenial] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [isText, setIsText] = useState(true);

  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [isSideMenuPopUpLoader, setIsSideMenuPopUpLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const [
    isaAssign_reassignEnterpriseClaimantModel,
    setIsAssign_reassignEnterPriseClaimantModel,
  ] = useState(false);
  const [Assign_ReAssignEnterprise, setAssign_ReAssignEnterprise] = useState(
    []
  );
  const [tenants, setTenants] = useState([]);
  const [formAssign_ReAssignEnterprise] = Form.useForm();
  const [isPopUpEnterpriseLoader, setIsPopUpEnterpriseLoader] = useState(false);
  const [ReasonNotQualified, setReasonNotQualified] = useState(false);
  const [formReasonNotQuailfied] = Form.useForm();
  const [isReasonNotQualifiedPopUpLoader, setIsReasonNotQualifiedPopUpLoader] =
    useState(false);

  const submitReasonNotQualified = (e: any) => {
    setIsReasonNotQualifiedPopUpLoader(true);
    updateLeadApplicationStatus(
      appData.leadId,
      Enums.status.main_NonQualifiedStatus,
      e.reasonForNotQualified
    ).then((res) => {
      if (res.data.responseCode == 200) {
        FlashMessage("success", "Reason saved successfully.");
        setIsReasonNotQualifiedPopUpLoader(false);
        setReasonNotQualified(false);
      } else {
        setIsReasonNotQualifiedPopUpLoader(false);
      }
    });
  };

  const formSubmitReasonNotQualified = () => {
    formReasonNotQuailfied.submit();
  };
  const cancelReasonNotQualified = () => {
    formReasonNotQuailfied.resetFields();
    setReasonNotQualified(false);
  };

  const updateApplicationStatusApi = (id: any, skipConfirmation?: boolean) => {
    setIsSideMenuPopUpLoader(true);
    var param = searchParams.get("id") ?? "0";
    if (
      skipConfirmation ||
      window.confirm("Are you sure you want to update the status?")
    ) {
      if (id == Enums.status.archived_NonQualifiedStatus && appData.leadId) {
        setReasonNotQualified(true);
      }
      updateApplicationStatus(param, id).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Application status updated Successfully.");
          setIsSideMenuPopUpLoader(false);
        }
        getApplicationDetailsByIdApi();
      });
    } else {
      setIsSideMenuPopUpLoader(false);
    }
  };

  const pipelineStatusDetailsApi = (status: any) => {
    pipelineStatusDetails().then((res) => {
      var resData = res.data.responseData;
      var uniquePipeline: string[] = [];
      resData.map((item: any) => {
        if (uniquePipeline.indexOf(item.pipelineName) === -1) {
          uniquePipeline.push(item.pipelineName);
        }
      });
      var menuItem = menuItems;
      menuItem = [];
      var items = menuItems;
      items = [];
      uniquePipeline.map((item: any) => {
        var menuSubItem = menuItems;
        menuSubItem = [];
        resData
          .filter((a: any) => a.pipelineName == item)
          .map((itemSub: any) => {
            menuSubItem.push(getItem(itemSub.statusName, itemSub.statusId));
          });
        items.push(getItem(item, item, null, menuSubItem));
      });
      menuItem.push(getItem(status, "sub1", null, items));
      setMenuItems(menuItem);
    });
  };

  useEffect(() => {
    getClientUserAttributesApi();
  }, []);

  useEffect(() => {
    getApplicationDetailsByIdApi();
  }, [searchParams.get("id"), searchParams.get("refresh_claimant")]);

  useEffect(() => {
    if (appData.leadId) {
      getLeadDetails(appData.leadId);
    }
  }, [appData.leadId]);

  const items: MenuItem[] = [
    getItem(
      "Navigation One",
      "sub1",
      <span>
        <img className='img-fluid arrow_image_drp2' src={drp_tab} />
      </span>,
      [
        getItem(
          "Item 1",
          null,
          null,
          [getItem("Option 1", "1"), getItem("Option 2", "2")],
          "group"
        ),
        getItem(
          "Item 2",
          null,
          null,
          [getItem("Option 3", "3"), getItem("Option 4", "4")],
          "group"
        ),
      ]
    ),
  ];

  const formSubmit = () => {
    form.submit();
  };

  const updateClientLeftPanelApi = (e: any) => {
    var payload = {
      Id: e.id,
      FirstName: e.firstName,
      UserName: e.userName || e.email,
      LastName: e.lastName,
      PhoneNumber: formatPhoneNumber(e.phoneNumber),
      Email: e.email,
      AddressOne: addressValue,
      AddressTwo: e.addressTwo,
      City: e.city,
      State: e.state,
      Zip: e.zip,
      Ssn: e.ssn,
      DateOfBirth:
        e.dateOfBirth === "Invalid Date" || e.dateOfBirth === ""
          ? null
          : dayjs(e.dateOfBirth).format("YYYY-MM-DDTHH:mm:ss"),
      Gender: e.gender,
    };
    setIsPopUpLoader(true);
    updateClientLeftPanel(payload, isEmail, isText)
      .then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Client Details updated Successfully.");
          setOpen(false);
          getApplicationDetailsByIdApi();
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          Object.keys(err.response.data.errors).map((errorKey) => {
            FlashMessage("error", err.response.data.errors[errorKey]);
          });
        }
      })
      .finally(() => {
        setIsPopUpLoader(false);
      });
  };

  const onClickEdit = () => {
    form.setFieldsValue({
      ...appData,
      phoneNumber: formatPhoneNumber(appData.phoneNumber),
      dateOfBirth: dayjs(
        appData.dateOfBirth || leadDetails?.dateOfBirth
      ).format("YYYY-MM-DD"),
    });
    setOpen(true);
  };

  const cancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const addPopUp = () => {
    if (
      window.confirm("Are you sure you want to reset docusign status?") == true
    ) {
      resetDocuSignStatus(searchParams.get("id")).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", res.data.responseMessage);
        } else if (res.data.responseCode != 200) {
          FlashMessage("error", res.data.responseMessage);
        }
      });
    }
  };

  const onChangeHandler = (event: any) => {
    var target = event.target.name;
    var value = event.target.value;
    var param = searchParams.get("id") ?? "0";

    if (target === "emailUser" && value != null && value.trim() != "") {
      EmailExistByUserId(param, value)
        .then((res) => {
          if (res.data.responseCode == responseCodes.success) {
            if (!res.data.responseData) {
              form.setFieldsValue({ email: value });
            } else {
              setIsPopUpLoader(false);
              FlashMessage("info", "Email Already Exists");
              form.setFieldsValue({ email: "" });
            }
          } else if (res.data.responseCode != responseCodes.NotFound) {
            FlashMessage("error", Constants.errorMessages.commonErrorMessage);
            setIsPopUpLoader(false);
          }
        })
        .catch((Error) => {});
    }
  };

  const onBlur = (e: any) => {
    var onlyNumber = formatPhoneNumber(e.target.value);

    form.setFieldsValue({ phoneNumber: onlyNumber });
  };

  const onChangeisSpanish = (e: any) => {
    setisSpanish(e.target.checked);
    clientUserAttributesApi("isSpanish", e.target.checked);
  };
  const onChangeisCritical = (e: any) => {
    setisCritical(e.target.checked);
    clientUserAttributesApi("isCritical", e.target.checked);
  };
  const onChangeisDeceased = (e: any) => {
    setisDeceased(e.target.checked);
    clientUserAttributesApi("isDeceased", e.target.checked);
  };
  const onChangeisMinor = (e: any) => {
    setisMinor(e.target.checked);
    clientUserAttributesApi("isMinor", e.target.checked);
  };
  const onChangeisTerminal = (e: any) => {
    setisTerminal(e.target.checked);
    clientUserAttributesApi("isTerminal", e.target.checked);
  };
  const onChangeIsDenial = (e: any) => {
    setIsDenial(e.target.checked);
    clientUserAttributesApi("isDenial", e.target.checked);
  };

  const copyToClipboard = (mindsetUserId: any) => {
    navigator.clipboard.writeText(mindsetUserId);
    FlashMessage("success", "Copied");
  };
  const predefinedValue = {
    label: addressValue,
    value: Constants.googleAutoCompleteStaticId,
  };
  const getTenantApi = () => {
    getTenant().then((res) => {
      var resData = res.data.responseData;
      setTenants(resData);
    });
  };
  useEffect(() => {
    getTenantApi();
  }, []);

  const actionMenuItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <SendSecureRegistrationLink
          userId={searchParams.get("id") ?? "0"}
          email={appData.email}
          phoneNumber={appData.phoneNumber}
          asMenuItem
        />
      ),
    },
    {
      key: "2",
      label: (
        <h4 onClick={() => addPopUp()} className='heading_tab cursorPointer'>
          Reset Docusign Status
        </h4>
      ),
    },
    {
      key: "3",
      label: (
        <h4
          onClick={() => updateDocusignEligible(true)}
          className='heading_tab cursorPointer'
        >
          Make Docusign Eligible
        </h4>
      ),
    },
  ];

  /* New Claims Functionality */
  const [claims, setClaims] = useState<any[]>([]);
  const getClaimantClaims = async () => {
    const {
      data: { responseData },
    } = await getClaims(appData.id);
    setClaims(responseData);
  };

  useEffect(() => {
    if (appData && appData.id) {
      getClaimantClaims();
    }
  }, [appData]);

  if (!appData) return null;

  const dateOfBirth = appData.dateOfBirth || leadDetails?.dateOfBirth;

  return (
    <>
      {isLoader && <Loader></Loader>}

      {open && !isLoader && (
        <Modal
          title='Edit Client Details'
          centered
          okText={isPopUpLoader ? "Loading" : "Update"}
          open={open}
          onCancel={() => cancel()}
          width={1000}
          onOk={() => (isPopUpLoader ? null : formSubmit())}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <>
              <Form
                className='mt-4'
                form={form}
                name='ClientDetailForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={appData}
                autoComplete='off'
                onFinish={updateClientLeftPanelApi}
              >
                <div className='form-group row margin_mobile'>
                  <Form.Item name='id' />
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    1. First Name
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='firstName'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        { validator: validateBlankSpace },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    2. Last Name
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='lastName'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        { validator: validateBlankSpace },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    3. Phone Number
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='phoneNumber'
                      rules={[
                        {
                          validator: validatePhoneNumberFormat,
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        onBlur={onBlur}
                        maxLength={12}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    4. Email
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: "Please input the value!",
                        },
                        { validator: validateBlankSpace },
                        { validator: plusValidation },
                        { validator: validateEmail },
                      ]}
                    >
                      <Input
                        onBlur={onChangeHandler}
                        name='emailUser'
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  {appData.userName !== appData.email && (
                    <>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        4a. Username
                      </label>
                      <div className='col-sm-9 p-0'>
                        <Form.Item
                          name='userName'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                            { validator: validateBlankSpace },
                          ]}
                        >
                          <Input
                            onBlur={onChangeHandler}
                            name='userName'
                            disabled={appData.email === appData.userName}
                            maxLength={100}
                            className={`form-control input_form_sign`}
                          />
                        </Form.Item>
                      </div>
                    </>
                  )}
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    5. Street Address
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='addressOne'
                      rules={[{ validator: validateBlankSpace }]}
                    >
                      {addressValue != null && (
                        <GooglePlacesAutocompleteWrapper
                          apiKey={process.env.REACT_APP_API_KEY ?? ""}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            value: predefinedValue,
                            isClearable: false,
                            onChange: (data: any) => {
                              form.setFieldsValue({
                                addressOne: data.streetAddress,
                                city: data.city,
                                state: data.state,
                                zip: data.postalCode,
                              });
                              setAddressValue(data.streetAddress);
                            },
                          }}
                        />
                      )}
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    6. Address 2 (optional)
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='addressTwo'
                      rules={[
                        { validator: validateBlankSpace },
                        {
                          validator: lessThanFourCharacters,
                        },
                      ]}
                    >
                      <Input
                        placeholder='i.e. Apt,Ste, Fl, Unit, etc..'
                        maxLength={1000}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    7. City
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='city'
                      rules={[{ validator: validateBlankSpace }]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    8. State
                  </label>
                  <div className='col-sm-9 p-0'>
                    <StatesDropDownFormItem />
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    9. Zip
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='zip'
                      rules={[
                        { validator: validateBlankSpace },
                        { validator: number },
                        { validator: validateZipCode },
                      ]}
                    >
                      <Input
                        maxLength={100}
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    10. SSN
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item
                      name='ssn'
                      rules={[
                        { validator: validateBlankSpace },
                        { validator: number },
                        { validator: minLengthNine },
                      ]}
                    >
                      <SocialSecurityNumberInput />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    11. Date Of Birth
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item name='dateOfBirth'>
                      <Input
                        maxLength={100}
                        type='date'
                        className={`form-control input_form_sign`}
                      />
                    </Form.Item>
                  </div>
                  <label className='col-form-label fill_description col-sm-3 des_font'>
                    12. Gender
                  </label>
                  <div className='col-sm-9 p-0'>
                    <Form.Item name='gender' style={{ textAlign: "left" }}>
                      <Radio.Group
                        options={[
                          { value: "Male", label: "Male" },
                          { value: "Female", label: "Female" },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
              <div className='form-group row margin_mobile'>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  Communication Preferences
                </label>
                <div className='col-sm-9 p-0'>
                  <div className='d-flex flex-column'>
                    <Checkbox
                      checked={isEmail}
                      onChange={(e) => setIsEmail(e.target.checked)}
                    >
                      Email
                    </Checkbox>
                    <small>
                      We use email to send you important updates on your claims
                      and benefits.
                    </small>

                    <Checkbox
                      checked={isText}
                      onChange={(e) => setIsText(e.target.checked)}
                    >
                      Text Message
                    </Checkbox>
                    <small>
                      We use text messages to send you important updates on your
                      claims and benefits.
                    </small>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>
      )}
      <div>
        <h4 className={`name_log mb-0`}>
          {appData?.firstName} {appData?.lastName}
        </h4>
        <h5 className={"phone_log"}>
          <span key={appData?.phoneNumber}>
            <a href={`tel:${formatPhoneNumber(appData.phoneNumber)}`}>
              {formatPhoneNumber(appData.phoneNumber)}
            </a>
          </span>
          <span className={"save_num"}> {appData?.timezone}</span>
          <span
            data-cy='edit-claimaint-details-btn'
            onClick={onClickEdit}
            className={"save_num cursorPointer"}
          >
            {" "}
            edit
          </span>
        </h5>
        <p className={"mail_log mb-0"}>{appData?.email}</p>
        <p className={"mail_log mb-0"}>
          Date of Birth:{" "}
          {dateOfBirth
            ? `${dayjs(dateOfBirth).format(
                Constants.dateFormat
              )} (Age: ${dayjs().diff(dateOfBirth, "years")})`
            : "N/A"}
        </p>
        <p className={"mail_log"}>
          {appData?.addressOne} {appData?.addressTwo} {appData?.city}{" "}
          {appData?.state} {appData?.zip}
        </p>
        <p className={"mail_log"}>
          <div className='d-flex '>
            Claimant ID: {appData?.id}
            <Tooltip title={"Copy to Clipboard"}>
              <CopyOutlined
                onClick={() => copyToClipboard(appData?.id)}
                style={{ fontSize: "20px", color: "#747497" }}
              />
            </Tooltip>
            {appData.isSelfServeRegistration && (
              <div
                style={{
                  borderRadius: "16px",
                  border:
                    "1px solid var(--Colors-Neutral-Border-colorBorder, #D9D9D9)",
                  background: "var(--Colors-Mindset-Site-Grey, #F6F6F6)",
                  maxWidth: "300px",
                  display: "flex",
                  marginLeft: "5px",
                  marginTop: "-3px",
                  padding: "2px var(--Space-Padding-paddingXS, 8px)",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "var(--Space-Margin-marginXXS, 4px)",
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <path
                    d='M4.76823 4.81511C4.0026 4.03907 4.0026 2.77865 4.76823 2.00261C5.53385 1.22657 6.77344 1.22657 7.53906 2.00261L8 2.47136L8.46094 2.00261C9.22656 1.22657 10.4661 1.22657 11.2318 2.00261C11.9974 2.77865 11.9974 4.03907 11.2318 4.81511L8.58594 7.50261C8.42448 7.66667 8.21354 7.7474 8 7.7474C7.78646 7.7474 7.57552 7.66667 7.41406 7.50261L4.76823 4.81511ZM15.2969 10.0078C15.638 10.4714 15.5391 11.1224 15.0755 11.4635L11.7786 13.8932C11.1693 14.3412 10.4349 14.5833 9.67708 14.5833H5.5H1.33333C0.872396 14.5833 0.5 14.2109 0.5 13.75V12.0833C0.5 11.6224 0.872396 11.25 1.33333 11.25H2.29167L3.46094 10.3125C4.05208 9.83855 4.78646 9.58334 5.54427 9.58334H7.58333H8H9.66667C10.1276 9.58334 10.5 9.95573 10.5 10.4167C10.5 10.8776 10.1276 11.25 9.66667 11.25H8H7.58333C7.35417 11.25 7.16667 11.4375 7.16667 11.6667C7.16667 11.8958 7.35417 12.0833 7.58333 12.0833H10.724L13.8411 9.78646C14.3047 9.44532 14.9557 9.54428 15.2969 10.0078ZM5.54167 11.25H5.51823C5.52604 11.25 5.53385 11.25 5.54167 11.25Z'
                    fill='#3E3E56'
                  />
                </svg>
                Self Serve
              </div>
            )}
          </div>
        </p>

        {featureFlags[
          Constants.featureFlags.claimantAccountCreationFromLeadFeatureFlag
        ] &&
          appData.applicationStatus == "Pending Registration" && (
            <SendSecureRegistrationLink
              userId={searchParams.get("id") ?? "0"}
              email={appData.email}
              phoneNumber={appData.phoneNumber}
            />
          )}

        {appData.mindsetUserId && <ClaimantAssignments appData={appData} />}

        <Form
          className='mt-4'
          form={form1}
          name='LeadUserAttributeForm'
          autoComplete='off'
        >
          <Row>
            <Col md={10}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Spanish</span>}
                name='isSpanish'
                valuePropName='checked'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Checkbox onChange={onChangeisSpanish} />
              </Form.Item>
            </Col>
            <Col md={10}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Critical</span>}
                name='isCritical'
                valuePropName='checked'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Checkbox onChange={onChangeisCritical} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={10}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Deceased</span>}
                name='isDeceased'
                valuePropName='checked'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Checkbox onChange={onChangeisDeceased} />
              </Form.Item>
            </Col>
            <Col md={10}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Minor</span>}
                name='isMinor'
                valuePropName='checked'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Checkbox onChange={onChangeisMinor} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={10}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Terminal</span>}
                name='isTerminal'
                valuePropName='checked'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Checkbox onChange={onChangeisTerminal} />
              </Form.Item>
            </Col>
            <Col md={10}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Denial</span>}
                name='isDenial'
                valuePropName='checked'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Checkbox onChange={onChangeIsDenial} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <CustomerClaims
          claims={claims}
          getClaimantClaims={getClaimantClaims}
          updateDocusignEligible={updateDocusignEligible}
          getApplicationDetailsByIdApi={getApplicationDetailsByIdApi}
          claimantMindsetUserId={appData.id}
          applicationStatus={appData.applicationStatus}
          caseManagerName={appData.caseManagerName}
          showPreClaimFlow={!appData.isIntakeReviewAccepted}
          isSelfServeRegistration={appData.isSelfServeRegistration}
          isJailUser={appData.isJailUser}
          isIntakeReviewStarted={appData.isIntakeReviewStarted}
          isIntakeReviewAccepted={appData.isIntakeReviewAccepted}
          updateApplicationStatus={updateApplicationStatusApi}
          onReject={() => {
            if (appData.leadId) {
              setReasonNotQualified(true);
            }
          }}
        />

        <Flex justify='center'>
          <AntDropdown menu={{ items: actionMenuItems }} trigger={["click"]}>
            <Button
              style={{ width: 262 }}
              icon={<EllipsisOutlined />}
              size='large'
              className='secondary-button'
            >
              More Actions
            </Button>
          </AntDropdown>
        </Flex>

        {isSideMenuPopUpLoader && (
          <Loader overrideClass='loaderLeftPanelHeight'></Loader>
        )}
        {menuItems.length > 0 && !isSideMenuPopUpLoader && (
          <Menu
            style={{
              textAlign: "center",
              border: "none",
              height: 48,
              margin: "20px 0px",
              backgroundColor: "#f6f6f6",
            }}
            expandIcon={false}
            onClick={onClick}
            mode='vertical'
            items={menuItems}
          />
        )}

        {isUpdatingCaseManager ? (
          ""
        ) : appData.leadId == null ? (
          ""
        ) : (
          <Flex justify='center'>
            <Button onClick={UnMapLeadApi} danger>
              Unlink Lead
            </Button>
          </Flex>
        )}
        <EnterpriseAssignment
          isLead={false}
          userId={searchParams.get("id") ?? "0"}
          roleInStorage={roleInStorage}
          facilityName={appData.facilityName}
        />
      </div>
      <>
        <Modal
          open={ReasonNotQualified}
          onCancel={() => cancelReasonNotQualified()}
          onOk={() => formSubmitReasonNotQualified()}
          width={500}
        >
          {isReasonNotQualifiedPopUpLoader && (
            <Loader style={{ width: 1000 }}></Loader>
          )}
          {!isReasonNotQualifiedPopUpLoader && (
            <Form
              className='mt-4'
              form={formReasonNotQuailfied}
              name='post'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete='off'
              onFinish={submitReasonNotQualified}
            >
              <label className='col-form-label fill_description col-sm-12 des_font mt-3'>
                Reason for Not Qualified
              </label>
              <div className='col-sm-12 p-0 mt-3'>
                <Form.Item
                  name='reasonForNotQualified'
                  rules={[
                    { required: true, message: "Please select a reason" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp='label'
                    options={Constants.notQualifiedReason.map((loc: any) => ({
                      label: loc.name,
                      value: loc.name,
                    }))}
                  />
                </Form.Item>
              </div>
            </Form>
          )}
        </Modal>
      </>
    </>
  );
};

export default CustomerLeftPanel;
