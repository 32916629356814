import React from "react";
import { Modal } from "antd";
import FaxPreview from "./FaxPreview";
import { Fax } from "./FaxHistoryTable";

interface FaxDetailsModalProps {
  visible: boolean;
  fax: Fax | null;
  onClose: () => void;
}

const FaxDetailsModal: React.FC<FaxDetailsModalProps> = ({
  visible,
  fax,
  onClose,
}) => {
  if (!fax) return null;

  return (
    <Modal
      title={`Fax Details - ID: ${fax.id}`}
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <FaxPreview fax={fax} />
    </Modal>
  );
};

export default FaxDetailsModal;
