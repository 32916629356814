import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Button, Input, Flex, Typography, Divider, Card } from "antd";
import axios from "axios";
import { FlashMessage } from "../../utils/flash_message";
import ChangeOfAddress from "./TemplateForms/ChangeOfAddress";
import { Select } from "antd";
import BundleList from "./BundleList";
import MedicalRecordsRequest from "./TemplateForms/MedicalRecordsRequest";
import EducationRequest from "./TemplateForms/EducationRequest";
import FiveDayLetter from "./TemplateForms/FiveDayLetter";
import PriorRepWithdrawalAndWaiver from "./TemplateForms/PriorRepWithdrawalWaiver";
import usePermissions from "../../common/customHooks/usePermissions";
import RFCRequest from "./TemplateForms/RFCRequest";
import Loader from "../../components/Loader";
import FeeLetter from "./TemplateForms/FeeLetter";
import FeePetitionLetter from "./TemplateForms/FeePetitionLetter";
import WithdrawalOfRepresentative from "./TemplateForms/WithdrawalOfRepresentative";
import dayjs from "dayjs";
import { AllPdfDocuments } from "../../apis/documents";
import MedicalRecordsHipaaAuthorization from "./TemplateForms/MedicalRecordsHipaaAuthorization";
import { FileOutlined, PlusOutlined } from "@ant-design/icons";
import "./index.scss";

interface GenericDocumentGenerationPayloadItem {
  FacilityAddressLine1: string;
  FacilityAddressLine2: string;
  FacilityCity: string;
  FacilityName?: string;
  FacilityState: string;
  FacilityZip: string;
  HearingDate: string | undefined;
  JudgeName: string | undefined;
  MedicalProviders: string | undefined;
  PhysicianName: string | undefined;
  PriorRep: string | undefined;
  RequestFromDate: string | undefined;
  TemplateType: number;
  FeeAttorneyWithheld: number | undefined;
  FeePetitionAmount: number | undefined;
  IncludedDocuments: SelectedDocument[] | undefined;
}

export interface MedicalProvider {
  id: number;
  physicianName: string;
  physicianAddress: string;
  addressTwo: string;
  zip: string;
  hospitalName: string;
}

export interface TemplateOption {
  label: string;
  value: number;
}

type TemplateOptionType = {
  id: number;
  description: string;
};

type SelectedDocument = {
  documentType: number;
  documentId: number;
};

export type DocumentOptionType = {
  value: number;
  label: string;
  documentType: number;
};

const GenerateDocuments = () => {
  usePermissions("generateDocuments");
  const [templateForm] = Form.useForm();
  const [bundleNameForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [searchParams] = useSearchParams();
  const [selectedTemplateId, setSelectedTemplateId] = useState(-1);
  const [RFCTemplateOptions, setRFCTemplateOptions] = useState<
    TemplateOption[]
  >([]);
  const [templateOptions, setTemplateOptions] = useState<TemplateOption[]>([]);
  const [emptyTemplateOptions] = useState<TemplateOption[]>([
    { value: -1, label: "All templates have been used!" },
  ]);
  const [documentGenerationPayload, setDocumentGenerationPayload] = useState<
    GenericDocumentGenerationPayloadItem[]
  >([]);
  const [documentOptions, setDocumentOptions] = useState<DocumentOptionType[]>(
    []
  );
  const [documentOptionsLoading, setDocumentOptionsLoading] = useState(false);
  const [providers, setProviders] = useState([]);

  const userId = searchParams.get("id") ?? "0";
  const getRFCTemplates = () =>
    axios.get("GenerateDocument/RFCFormTypes").then((res) =>
      setRFCTemplateOptions(
        res.data.map(({ id, description }: TemplateOptionType) => ({
          value: id,
          label: description,
        }))
      )
    );

  useEffect(() => {
    const fetchDocumentOptions = async () => {
      setDocumentOptionsLoading(true);
      try {
        const res = await AllPdfDocuments(userId);
        if (res.data.responseData.length < 1) return;
        const options = res.data.responseData.map(
          (item: {
            id: number;
            displayName: string;
            documentTypeId: number;
          }) => ({
            value: item.id,
            label: item.displayName,
            documentType: item.documentTypeId,
          })
        );
        setDocumentOptions(options);
      } catch (error) {
        console.error("Failed to load document options:", error);
      } finally {
        setDocumentOptionsLoading(false);
      }
    };

    fetchDocumentOptions();
  }, [userId]);

  useEffect(() => {
    axios.get("GenerateDocument/DisplayedTemplateTypes").then((r) => {
      setIsLoading(false);
      setSelectedTemplateId(r.data[0].id);
      setTemplateOptions(
        r.data.map(({ id, description }: TemplateOptionType) => ({
          value: id,
          label: description,
        }))
      );
    });
    getRFCTemplates();
  }, [setTemplateOptions, setSelectedTemplateId]);

  useEffect(() => {
    axios
      .get(`MedicalHistory?id=${searchParams.get("id") ?? "0"}`)
      .then((r) => {
        setIsLoading(false);
        setProviders(r.data.responseData);
      });
  }, [setProviders]);

  const convertToPayloadObject = (
    object: any
  ): GenericDocumentGenerationPayloadItem =>
    ({
      TemplateType: object.templateType,
      FacilityName: object.addressName ? object.addressName.trim() : "",
      FacilityAddressLine1: object.line1 ? object.line1.trim() : "",
      FacilityAddressLine2: object.line2 ? object.line2.trim() : "",
      FacilityCity: object.city ? object.city.trim() : "",
      FacilityState: object.state ?? "",
      FacilityZip: object.zip ?? "",
      RequestFromDate: object.fromDate ?? "",
      JudgeName: object.judgeName ? object.judgeName.trim() : "",
      PhysicianName: object.physicianName ? object.physicianName.trim() : "",
      HearingDate: dayjs(object.hearingDate).format("MM/DD/YYYY") ?? "",
      MedicalProviders: object.medicalProviders
        ? object.medicalProviders.trim()
        : "",
      PriorRep: object.priorRep ? object.priorRep.trim() : "",
      FeeAttorneyWithheld: object.feeAttorneyWithheld
        ? object.feeAttorneyWithheld
        : 0,
      FeePetitionAmount: object.feePetitionAmount
        ? object.feePetitionAmount
        : 0,
      IncludedDocuments: object.includedDocuments
        ? object.includedDocuments.map((docId: number) => {
            const documentOption = documentOptions.find(
              (doc) => doc.value === docId
            );
            if (documentOption) {
              return {
                documentType: documentOption.documentType,
                documentId: documentOption.value,
              };
            }
            return [];
          })
        : [],
    } as GenericDocumentGenerationPayloadItem);

  const onAddTemplateToDocumentBundle = async (formData: any) => {
    console.log(formData);
    const enclosedForms =
      formData.EnclosedFormIds?.map((formId: number) =>
        convertToPayloadObject({
          templateType: formId,
        })
      ) ?? [];
    setDocumentGenerationPayload((oldPayloads) => [
      ...oldPayloads,
      convertToPayloadObject({ ...formData, templateType: selectedTemplateId }),
      ...enclosedForms,
    ]);
    templateForm.resetFields();
  };

  useEffect(() => {
    const usedTemplateOptions = documentGenerationPayload.map(
      (item) => item.TemplateType
    );
    const newTemplateId = templateOptions.find(
      (item) => !usedTemplateOptions.includes(item.value)
    );
    setSelectedTemplateId(newTemplateId?.value || -1);
  }, [documentGenerationPayload]);

  const NoFieldsNeeded = () => (
    <Typography.Text type='secondary'>
      No additional information needed to bundle document.
    </Typography.Text>
  );

  const getSelectedTemplateFormFields = () => {
    const templateDisplayName = templateOptions.find(
      (item) => item.value === selectedTemplateId
    )?.label;
    let fields;
    switch (templateDisplayName) {
      case "Change of Address": {
        fields = <ChangeOfAddress />;
        break;
      }
      case "Medical Records Request": {
        fields = (
          <MedicalRecordsRequest form={templateForm} providers={providers} />
        );
        break;
      }
      case "Education Records Request": {
        fields = <EducationRequest />;
        break;
      }
      case "Five Day Letter": {
        fields = <FiveDayLetter />;
        break;
      }
      case "Fee Letter": {
        fields = <FeeLetter />;
        break;
      }
      case "Fee Petition Letter": {
        fields = <FeePetitionLetter />;
        break;
      }
      case "Approved Closing Letter": {
        fields = null;
        break;
      }
      case "Call In Letter": {
        fields = null;
        break;
      }
      case "Withdrawal of Representative": {
        fields = <WithdrawalOfRepresentative />;
        break;
      }
      case "Withdrawal and Waiver of Prior Representative": {
        fields = <PriorRepWithdrawalAndWaiver />;
        break;
      }
      case "RFC Request": {
        fields = (
          <RFCRequest
            templates={RFCTemplateOptions}
            providers={providers}
            form={templateForm}
          />
        );
        break;
      }
      case "Medical Records HIPAA Authorization": {
        fields = (
          <MedicalRecordsHipaaAuthorization
            documents={documentOptions}
            form={templateForm}
            providers={providers}
          />
        );
        break;
      }
    }

    return (
      <Form
        layout='vertical'
        form={templateForm}
        onFinish={onAddTemplateToDocumentBundle}
      >
        {fields ? <Card>{fields}</Card> : <NoFieldsNeeded />}

        <Form.Item>
          <Button
            className='mt-4'
            type='default'
            htmlType='submit'
            icon={<PlusOutlined />}
          >
            Add to Bundle
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const onClick = async (e: any) => {
    setIsRequestPending(true);
    axios
      .post("GenerateDocument", {
        EncryptedToUserId: searchParams.get("id") ?? "0",
        DocumentBundleFileName: e.bundleName,
        DocumentsGenerationList: documentGenerationPayload,
      })
      .then((res) => {
        FlashMessage(
          "success",
          <div>
            <span>Document Generated Successfully!</span>
          </div>,
          "",
          5
        );
        templateForm.resetFields();
        bundleNameForm.resetFields();
        setDocumentGenerationPayload([]);
      })
      .catch((error) => {
        FlashMessage("error", error.response.data, undefined, 7);
      })
      .finally(() => setIsRequestPending(false));
  };

  const listItems = documentGenerationPayload.map(
    (item) =>
      templateOptions.find((option) => option.value === item.TemplateType) ??
      RFCTemplateOptions.find((option) => option.value === item.TemplateType)
  );

  const onRemoveListItem = (item: TemplateOption) => {
    const newBundleItems = [...documentGenerationPayload].filter(
      (doc) => doc.TemplateType !== item.value
    );
    setDocumentGenerationPayload(newBundleItems);
    templateForm.resetFields();
  };

  const usedTemplateOptions = documentGenerationPayload.map(
    (item) => item.TemplateType
  );

  const isTemplateSelectDisabled =
    usedTemplateOptions.length === templateOptions.length;

  return (
    (!isLoading && (
      <section id='MindsetAdminTemplates' style={{ maxWidth: 800 }}>
        <Flex vertical gap={28} className='mt-4'>
          <div>
            <Typography.Title level={5}>Select a Template</Typography.Title>
            <Select
              prefix={<FileOutlined />}
              style={{ minWidth: 300 }}
              key={"templates"}
              showSearch
              optionFilterProp='label'
              options={
                isTemplateSelectDisabled
                  ? emptyTemplateOptions
                  : templateOptions
                      .filter(
                        (item) => !usedTemplateOptions.includes(item.value)
                      )
                      .map((item) => ({
                        ...item,
                        label:
                          item.label === "Medical Records Request"
                            ? "Medical Records Request Cover Letter"
                            : item.label,
                      }))
              }
              onChange={(e) => {
                templateForm.resetFields();
                setSelectedTemplateId(e);
              }}
              value={selectedTemplateId}
              disabled={isTemplateSelectDisabled}
            />
          </div>

          <div>
            <Typography.Title level={5}>
              Fill Additional Information
            </Typography.Title>
            {getSelectedTemplateFormFields()}
          </div>
        </Flex>

        <Divider style={{ margin: 0 }} />

        <Flex vertical gap={28} className='mt-4'>
          <div>
            <Typography.Title level={5}>Document Bundle</Typography.Title>
            <BundleList items={listItems} onRemoveItem={onRemoveListItem} />
          </div>

          {isRequestPending ? (
            <Loader overrideClass='loaderLeftPanelHeight'></Loader>
          ) : (
            <>
              <Form layout='vertical' form={bundleNameForm} onFinish={onClick}>
                <Flex align='flex-end' gap={18} wrap='wrap'>
                  <Form.Item
                    label='Bundle Name'
                    name='bundleName'
                    style={{ flex: 1, minWidth: 200, maxWidth: 360 }}
                    rules={[
                      {
                        required: true,
                        message: "Please input a bundle name",
                      },
                    ]}
                  >
                    <Input
                      disabled={
                        documentGenerationPayload.length === 0 ||
                        isRequestPending
                      }
                      style={{ width: "100%" }}
                      maxLength={450}
                      placeholder='Name this document bundle'
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      disabled={
                        documentGenerationPayload.length === 0 ||
                        isRequestPending
                      }
                      type='primary'
                      htmlType='submit'
                    >
                      Generate Document Bundle
                    </Button>
                  </Form.Item>
                </Flex>
              </Form>
            </>
          )}
        </Flex>
      </section>
    )) || <Loader />
  );
};

export default GenerateDocuments;
