import { useEffect, useState } from "react";
import { getApplicationDetailsById } from "../../actions/application";
import { useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import PreQualFieldList from "./PreQualFieldList";
import IntakeFieldsList from "./IntakeFieldsList";
import IntakeBot from "./IntakeBot";
import useGroupUsers from "../../common/customHooks/useGroupUsers";
import { Constants } from "../../common/constants";
import useUser from "../../common/customHooks/useUser";

export type IntakeFieldItem = {
  label: string;
  type:
    | "string"
    | "boolean"
    | "select"
    | "radio"
    | "date"
    | "number"
    | "dollar"
    | "string_array"
    | "textarea"
    | "medications_input";
  tablet?: boolean;
  displayIf?: string;
  options?: string[];
  isTablet?: boolean;
  trueOption?: string;
};

const IntakeScreening = ({ isLead }: { isLead: boolean }) => {
  const user = useUser();
  const { groups } = useGroupUsers();

  const canAccessFeature =
    groups?.some(
      (group) =>
        group.display === Constants.groupUsers.betaTesters &&
        group.userIds.includes(Number(user.userId))
    ) ?? false;
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [isLoading, setIsLoading] = useState(true);
  const [leadId, setLeadId] = useState<string | null>(null);
  const [claimantId, setClaimantId] = useState<string | null>(null);

  const [pipelineStatusId, setPipelineStatusId] = useState<
    number | undefined
  >();

  const getClaimantDetails = async () => {
    const {
      data: { responseData: claimant_details },
    } = await getApplicationDetailsById(id);
    setClaimantId(claimant_details.id);
    setPipelineStatusId(claimant_details.pipelineStatusId);
    setLeadId(claimant_details.leadId);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLead) {
      setLeadId(id);
      setIsLoading(false);
    } else {
      getClaimantDetails();
    }
  }, [id, searchParams.get("refresh_claimant")]);

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spin />
      </div>
    );
  }

  return (
    <>
      {canAccessFeature && (
        <div className='col-12 mb-4'>
          <IntakeBot leadId={leadId} claimantId={claimantId} />
        </div>
      )}
      <PreQualFieldList leadId={leadId} />
      {!isLead && (
        <IntakeFieldsList
          claimantId={claimantId}
          pipelineStatusId={pipelineStatusId}
        />
      )}
    </>
  );
};

export default IntakeScreening;
