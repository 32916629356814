import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Table,
  Select,
  DatePicker,
  Button,
  Flex,
  Typography,
} from "antd";
import { getAllActivityTypes, getActivityLogData } from "../../actions/logs";
import { FlashMessage } from "../../utils/flash_message";
import { Constants } from "../../common/constants";
import { validateEmail } from "../../validations/validateEmail";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import usePermissions from "../../common/customHooks/usePermissions";
import dayjs from "dayjs";

const Logs = () => {
  usePermissions("logs");

  const [form] = Form.useForm();
  const [isLoader, setIsLoader] = useState(false);
  const dateFormat = "MM/DD/YYYY";
  const [types, setTypes] = useState([]);
  const [logs, setLogs] = useState([]);
  const [emailEnbDis, setEmailEnbDis] = useState(null);
  const [activityByEnbDis, setActivityByEnbDis] = useState(null);

  const getAllActivityTypesApi = () => {
    getAllActivityTypes().then((res) => {
      setTypes(res.data.responseData);
    });
  };

  useEffect(() => {
    getAllActivityTypesApi();
    setEmailEnbDis(null);
    setActivityByEnbDis(null);
    console.log(emailEnbDis, "emailenableDis");
    console.log(activityByEnbDis, "activitybyenadis");
  }, []);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created Date and Time",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (item: any, file: any) => {
        return dayjs.utc(item).local().format(Constants.dayJsDateTimeFormat);
      },
    },
    {
      title: "Activity Type",
      dataIndex: "type",
      key: "type",
    },
  ];

  const ActivityByChangeValue = (a: any) => {
    setActivityByEnbDis(a.target.value ? a.target.value : "");
  };

  const emailChangeVlaue = (e: any) => {
    setEmailEnbDis(e.target.value ? e.target.value : "");
  };

  const getActivityLogDataApi = (e: any) => {
    setIsLoader(true);
    var email = e.email ? e.email : "";
    var activityTypeId = e.activityType ? e.activityType : "";
    var startDate = e.startDate ? e.startDate : "";
    var endDate = e.endDate ? e.endDate : "";
    var activityBy = e.activityBy ? e.activityBy : "";

    if (
      e.email == null &&
      e.activityType == null &&
      e.startDate == null &&
      e.endDate == null &&
      e.activityBy == null
    ) {
      FlashMessage("error", "It is required to fill at least one field.");
      setIsLoader(false);
    } else {
      getActivityLogData(
        email,
        activityTypeId,
        startDate,
        endDate,
        activityBy
      ).then((res) => {
        setIsLoader(false);
        if (res.data.responseCode == 200) {
          setLogs(res.data.responseData);
        } else {
          setLogs([]);
          FlashMessage("error", res.data.responseMessage);
        }
      });
    }
  };
  const clear = () => {
    setLogs([]);
    setActivityByEnbDis(null);
    setEmailEnbDis(null);
  };

  return (
    <>
      <Typography.Title level={3}>Logs</Typography.Title>
      <Form
        className='mt-4'
        form={form}
        name='SupportUserForm'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete='off'
        onFinish={getActivityLogDataApi}
      >
        <Flex wrap='wrap' gap={8} justify='space-between'>
          <Form.Item name='id' />

          <Form.Item
            name='email'
            label='Email'
            style={{ flex: 1 }}
            rules={[
              { validator: validateEmail },
              { validator: validateBlankSpace },
            ]}
          >
            {activityByEnbDis == null || activityByEnbDis == "" ? (
              <Input
                name='email'
                placeholder='Email'
                onChange={emailChangeVlaue}
                maxLength={100}
              />
            ) : (
              <Input
                name='email'
                placeholder='Email'
                disabled
                maxLength={100}
              />
            )}
          </Form.Item>

          <Form.Item
            name='activityType'
            label='Activity Type'
            style={{ flex: 1 }}
            rules={[{ validator: validateBlankSpace }]}
          >
            <Select
              placeholder='Activity Type'
              options={types.map((loc: any) => ({
                label: loc.type,
                value: loc.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            name='activityBy'
            label='Activity By'
            style={{ flex: 1 }}
            rules={[
              { validator: validateEmail },
              { validator: validateBlankSpace },
            ]}
          >
            {emailEnbDis == null || emailEnbDis == "" ? (
              <Input
                name='email'
                placeholder='Activity By'
                onChange={ActivityByChangeValue}
                maxLength={100}
              />
            ) : (
              <Input
                name='email'
                placeholder='Activity By'
                disabled
                maxLength={100}
              />
            )}
          </Form.Item>

          <Form.Item
            name='startDate'
            label='Start Date'
            getValueFromEvent={(onChange) =>
              onChange == null ? null : dayjs(onChange).format(dateFormat)
            }
            getValueProps={(i) => ({
              value: i == null ? null : dayjs(i),
            })}
          >
            <DatePicker placeholder='From' format={dateFormat} />
          </Form.Item>

          <Form.Item
            name='endDate'
            label='End Date'
            getValueFromEvent={(onChange) =>
              onChange == null ? null : dayjs(onChange).format(dateFormat)
            }
            getValueProps={(i) => ({
              value: i == null ? null : dayjs(i),
            })}
          >
            <DatePicker
              placeholder='To'
              format={dateFormat}
              disabledDate={(d) => d.isBefore(form.getFieldValue("startDate"))}
            />
          </Form.Item>
        </Flex>
        <Flex gap={8} justify='flex-end'>
          <Button htmlType='submit' type='primary' disabled={isLoader}>
            Search
          </Button>
          <Button htmlType='reset' onClick={() => clear()}>
            Clear
          </Button>
        </Flex>
      </Form>
      <Table
        dataSource={logs}
        style={{ marginTop: 20 }}
        loading={isLoader}
        pagination={{
          pageSize: Constants.logsDefaultPageSize,
          showSizeChanger: false,
          showTotal: (total) => {
            return `Total Count:  ${total}`;
          },
        }}
        columns={columns}
      />
    </>
  );
};

export default Logs;
