import { useEffect, useState } from "react";
import usePermissions from "../../common/customHooks/usePermissions";
import { getFeatureFlags, updateFeatureFlags } from "../../actions/application";
import { Switch, Tooltip, Typography } from "antd";
import useFeatureFlag from "../../components/useFeatureFlag";
import "./index.scss";
import { FeatureFlag } from "../../components/FeatureFlagProvider";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";

export interface loadingStates {
  [key: string]: boolean;
}
const FeatureFlagSettings = () => {
  const [featureFlags, setFeatureFlags] = useState<Array<FeatureFlag>>([]);
  const { canWrite } = usePermissions("manageFeatureFlagSettings");
  const [isSwitchLoading, setIsSwitchLoading] = useState<loadingStates>({});
  const ffConfig = useFeatureFlag().featureFlags;
  useEffect(() => {
    getFeatureFlags().then((res: any) => {
      setFeatureFlags(res.data.responseData);
    });
  }, [ffConfig]);

  const onChange = (name: string) => (e: boolean) => {
    setIsSwitchLoading((currentState: loadingStates) => {
      return {
        ...currentState,
        [name]: true,
      };
    });
    const ffArray = [...featureFlags];
    const indexToUpdate = ffArray.findIndex(
      (ff: FeatureFlag) => ff.name === name
    );
    ffArray[indexToUpdate].isActive = e;
    setFeatureFlags(ffArray);

    updateFeatureFlags(ffArray).then(() => {
      setIsSwitchLoading((currentState: loadingStates) => {
        return {
          ...currentState,
          [name]: false,
        };
      });
    });
  };
  return (
    <>
      <Typography.Title level={3}>Manage Feature Flags</Typography.Title>
      {featureFlags.length > 0 &&
        featureFlags.map((item, index) => (
          <div
            style={{
              borderRadius: ".25rem",
              backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
            }}
            className='d-flex justify-content-between pb-1 pt-1 ml-3'
          >
            <div className='d-flex align-items-center'>
              <div
                style={{ height: "19px", width: "20px", marginLeft: "8px" }}
                className='popover__wrapper'
              >
                <FontAwesomeIcon
                  className='popover__title'
                  icon={faInfoCircle}
                  style={{ color: "#77d4b1" }}
                />
                <div className='popover__content'>
                  <p className='popover__message'>{item.description} </p>
                </div>
              </div>
              <div>{item.name} </div>
            </div>

            <div>
              <Switch
                disabled={!canWrite}
                loading={isSwitchLoading[item.name]}
                defaultChecked={item.isActive}
                onChange={onChange(item.name)}
                checked={ffConfig[item.name]}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default FeatureFlagSettings;
