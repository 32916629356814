import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Oops, the page you are looking for does not exist."
      extra={
        <Button type="primary" onClick={() => navigate('/leads')}>
          Back to Leads
        </Button>
      }
    />
  );
};

export default NotFoundPage;
