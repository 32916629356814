import LeadProfile from "../../components/LeadProfile/LeadProfile";
import LeadsLeftPanel from "../../components/LeadsLeftPanel";
import { BackTop, Layout } from "antd";

const { Content, Sider } = Layout;

const LeadsDetailLayout = () => {
  return (
    <>
      <section id='admin_application_section'>
        <Layout>
          <Sider width={350} collapsedWidth={200} breakpoint="lg">
            <LeadsLeftPanel />
          </Sider>
          <Content className='profile-content'>
            <LeadProfile />
          </Content>

          <div className='back-top-con'>
            <BackTop />
          </div>
        </Layout>
      </section>
    </>
  );
};

export default LeadsDetailLayout;
