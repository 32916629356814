import { useEffect, useState } from "react";
import "./index.scss";
import { useSearchParams } from "react-router-dom";
import {
  deleteGlobalTask,
  getNotificationMessage,
  notificationContactMessage,
  completedNotification,
} from "../../actions/taskManagement";
import { Button, Form, Input, Tooltip } from "antd";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import Loader from "../../components/Loader";
import { FlashMessage } from "../../utils/flash_message";
import { Enums } from "../../common/enums";
import { CheckSquareFilled } from "@ant-design/icons";

const RequestedInfo = (e: any) => {
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [isLoader, setIsLoader] = useState(false);
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);
  const [notificationMessageData, setNotificationMessageData] = useState([]);
  const [count, setCount] = useState<any>([]);
  useEffect(() => {
    const numbers = [];
    for (let i = 1; i <= notificationMessageData.length; i++) {
      numbers.push(i);
    }
    setCount(numbers);
  }, [notificationMessageData]);

  const notificationContactMessageApi = (e: any) => {
    setIsLoader(true);
    var param = searchParams.get("id") ?? "0";
    var payload = {
      EncryptedUserId: param,
      Message: e.note,
    };
    notificationContactMessage(payload).then((res: any) => {
      if (res.data.responseCode == 200) {
        form.resetFields();
        setIsLoader(false);
        FlashMessage("success", "Notification added Successfully.");
      } else {
        FlashMessage("error", "Kindly update intake specialist to proceed");
        setIsLoader(false);
      }
      getNotificationMessageApi();
    });
  };

  const getNotificationMessageApi = () => {
    var param = searchParams.get("id") ?? "0";
    getNotificationMessage(param).then((res: any) => {
      if (res.data.responseCode == 200) {
        setNotificationMessageData(res.data.responseData);
      }
    });
  };
  const CompletedNotificationAPi = (e: any) => {
    if (
      window.confirm("Are you sure you want to mark this task as completed?")
    ) {
      completedNotification(e).then((res: any) => {
        getNotificationMessageApi();
      });
    }
  };
  useEffect(() => {
    getNotificationMessageApi();
  }, []);
  const deleteGlobalTaskApi = (id: any) => {
    setIsDeleteLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteGlobalTask(id).then((res: any) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", res.data.responseMessage);
          getNotificationMessageApi();
          setIsDeleteLoader(false);
        } else {
          FlashMessage("error", res.data.responseMessage);
          setIsDeleteLoader(false);
        }
      });
    } else {
      setIsDeleteLoader(false);
    }
  };
  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          <Form
            className='mt-4'
            form={form}
            name='post'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            autoComplete='off'
            onFinish={notificationContactMessageApi}
          >
            <section id='MindsetAdminTemplates' className='mt-5'>
              <div className='row'>
                <div className='col'>
                  <div className='row'>
                    <Form.Item
                      name='note'
                      rules={[
                        { required: true, message: "Please input the value!" },
                        { validator: validateBlankSpace },
                      ]}
                    >
                      <Input.TextArea
                        rows={4}
                        placeholder='Add a request for the client to complete here...'
                      />
                    </Form.Item>
                    <div className='text-center mt-4 mb-5'>
                      <Button htmlType='submit' type='primary'>
                        Post
                      </Button>
                    </div>
                    <div className='justify-content-between'>
                      <>
                        {notificationMessageData.map((a: any, index: any) => {
                          return (
                            <>
                              {isDeleteLoader && <Loader></Loader>}
                              {!isDeleteLoader && (
                                <>
                                  <div className='d-flex justify-content-between align-items-center mt-0 pointers-div mb-3'>
                                    <Tooltip title={a.message}>
                                      <p
                                        className='mb-0 indent-one'
                                        key={index}
                                      >
                                        <span className='count-int'>
                                          {" "}
                                          {count[index]}.
                                        </span>
                                        {a.message.substring(0, 100) +
                                          (a.message.length > 100
                                            ? "....."
                                            : "")}
                                      </p>
                                    </Tooltip>
                                    <>
                                      <div className=' min_hg_text new-contact-d'>
                                        {a.statusId ==
                                        Enums.taskStatus.inProgress ? (
                                          <>
                                            <Tooltip title='Mark As Complete'>
                                              {" "}
                                              <input
                                                type='checkbox'
                                                className='mr-1'
                                                onClick={() =>
                                                  CompletedNotificationAPi(a.id)
                                                }
                                                checked={false}
                                              />
                                            </Tooltip>
                                            <button
                                              className='btn flex p-0 me-3'
                                              type='button'
                                            ></button>
                                          </>
                                        ) : (
                                          <Tooltip title='Task Completed'>
                                            <CheckSquareFilled
                                              className='checked-box'
                                              style={{
                                                color: "#77d4b1",
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                    </>
                                    <p
                                      onClick={() => deleteGlobalTaskApi(a.id)}
                                      className='cursorPointer mb-0'
                                    >
                                      Delete
                                    </p>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Form>
        </>
      )}
    </>
  );
};

export default RequestedInfo;
