import { Card, Col, Divider, Flex, Row, Tag, Typography } from "antd";
import BrandButton from "../../BrandButton";
import { updateClaim } from "../../../actions/claims";
import { get } from "../../../utils/clientStorageUtils";
import { ReactNode, useState } from "react";
import profile_new from "../../../images/profile_new.png";
import { FlashMessage } from "../../../utils/flash_message";
import { Constants } from "../../../common/constants";
import { ClaimOptions, updateClaimPayloads } from "./Claims/constants";
import dayjs from "dayjs";
import ClaimBadge from "./ClaimBadge";

const { Title, Text } = Typography;

const claim_names: {
  [key: string]: string;
} = {
  Ssi: "Supplemental Security Income",
  Ssdi: "Social Security Disability Insurance",
};

export const UserRow = ({
  label,
  displayName,
}: {
  label: string;
  displayName: string;
}) => {
  return (
    <Row>
      <Col style={{ width: "50%", marginBottom: 5 }}>
        <Text strong>{label}</Text>
      </Col>
      <Col style={{ width: "50%", marginBottom: 5 }}>
        <Flex align='center' gap={5}>
          <img height={20} src={profile_new} /> {displayName}
        </Flex>
      </Col>
    </Row>
  );
};

export const CaseLevelRow = ({
  label,
  value,
}: {
  label: string;
  value: string | ReactNode;
}) => {
  return (
    <Row>
      <Col style={{ width: "50%", marginBottom: 5 }}>
        <Text strong>{label}</Text>
      </Col>
      <Col style={{ width: "50%" }}>{value}</Col>
    </Row>
  );
};

const TimestampRow = ({
  label,
  timestamp,
}: {
  label: string;
  timestamp: string;
}) => {
  const formattedDate = dayjs(timestamp).isValid()
    ? dayjs(timestamp).format("MMM. D, YYYY h:mm A")
    : "";

  return (
    <Row>
      <Col style={{ width: "50%", marginBottom: 5 }}>
        <Text strong>{label}</Text>
      </Col>
      <Col style={{ width: "50%", marginBottom: 5 }}>{formattedDate}</Col>
    </Row>
  );
};

const ClaimCard = ({
  claim,
  getClaimantClaims,
  claimOptions,
}: {
  claim: any;
  getClaimantClaims: Function;
  claimOptions: ClaimOptions;
}) => {
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const [isConfirmingFax, setIsConfirmingFax] = useState(false);

  const getClaimStatus = () => {
    if (!claim.intakeCompletedByMindsetUserId) {
      return (
        <CaseLevelRow label='Status' value={<Tag>Registration Complete</Tag>} />
      );
    } else if (claim.caseLevelId === 2) {
      return (
        <CaseLevelRow label='Status' value={<Tag>Information Collected</Tag>} />
      );
    } else if (claim.caseLevelId === 3 && !claim.isEreSynced) {
      return (
        <CaseLevelRow label='Case Level' value={<Tag>Initial (Assumed)</Tag>} />
      );
    } else if (claim.iaSubmittedByMindsetUserId) {
      return (
        <CaseLevelRow
          label='Case Level'
          value={
            <Tag>
              {
                claimOptions.caseLevels.find(
                  (level) => level.id === claim.caseLevelId
                )?.name
              }
            </Tag>
          }
        />
      );
    }

    return "getClaimStatus TODO";
  };

  const confirmFax = async () => {
    setIsConfirmingFax(true);
    const payload = updateClaimPayloads("CONFIRM_FAX", claim, claim.isEligible);
    await updateClaim(payload);
    FlashMessage("The claim has been updated successfully.", "success");
    await getClaimantClaims();
    setIsConfirmingFax(false);
  };

  const displayName = claim.ClaimTypeIdDisplayNameReadOnly;

  return (
    <Card
      className={`${
        claim.isEligible ? "claim-card" : "claim-not-eligible-card"
      }`}
      data-cy={`claim-card-${displayName}`}
    >
      <ClaimBadge claim={claim} />
      <Title
        level={5}
        className={`claim-title ${
          !claim.isEligible ? "not-eligible-text" : ""
        }`}
      >
        {claim_names[displayName]}
      </Title>
      {claim.isEligible ? (
        <>
          {getClaimStatus()}
          {claim.intakeCompletedByMindsetUserId != null && (
            <CaseLevelRow
              label='Prior Submission by'
              value={
                claimOptions.initialApplicationOrigins.find(
                  (x) => x.id == claim.initialApplicationOriginId
                )?.name || ""
              }
            />
          )}
          {claim.intakeCompletedByMindsetUserId && (
            <div className='intake-section'>
              <Divider style={{ margin: "10px 0px" }} />
              <UserRow
                label='Intake Completed by'
                displayName={claim.intakeCompletedByDisplayNameReadOnly}
              />
              <TimestampRow
                label='Intake Completed on'
                timestamp={claim.intakeCompletedDateTimeUtc}
              />
            </div>
          )}
          {claim.iaSubmittedByMindsetUserId && (
            <div className='intake-section'>
              <Divider style={{ margin: "10px 0px" }} />
              <UserRow
                label='IA Submitted by'
                displayName={claim.iaSubmittedByDisplayNameReadOnly}
              />
              <TimestampRow
                label='IA Submitted on'
                timestamp={claim.iaSubmittedDateTimeUtc}
              />
              {claim.faxed8001ByDisplayNameReadOnly && (
                <>
                  <Divider style={{ margin: "10px 0px" }} />
                  <UserRow
                    label='8001 Faxed by'
                    displayName={claim.faxed8001ByDisplayNameReadOnly}
                  />
                </>
              )}
              {claim.faxed8001DateTimeUtc && (
                <TimestampRow
                  label='Faxed on'
                  timestamp={claim.faxed8001DateTimeUtc}
                />
              )}
            </div>
          )}
          {claim.caseLevelId === 2 &&
            claim.iaSubmittedByMindsetUserId &&
            !claim.faxed8001ByDisplayNameReadOnly && (
              <BrandButton
                isLoading={isConfirmingFax}
                onClick={confirmFax}
                disabled={!Constants.caseManagerGroup.includes(roleInStorage)}
                className='mt-2'
                label='Confirm 8001 Faxed'
              />
            )}
        </>
      ) : (
        <p className={`${!claim.isEligible ? "not-eligible-text" : ""}`}>
          <b>Not eligible</b>
        </p>
      )}
    </Card>
  );
};

export default ClaimCard;
