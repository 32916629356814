export function GetAdminApiBaseUrlFromWindow() {
  if (window.location.hostname === "localhost") {
    return `https://localhost:44321`; //forgive me for I am only human
  }
  return `https://${window.location.hostname}`;
}

export function GetCarePublicWebsiteBaseUrl() {
  if (window.location.hostname === "localhost") {
    return `http://localhost:3000`;
  }
  return `https://www.${window.location.hostname.replace("admin.", "")}`;
}
