import axios from "axios";

export function GetFaxesForUser(encryptedUserId: string) {
  return axios.get(`faxes?encryptedUserId=${encryptedUserId}`);
}
export function GetFaxById(faxId: any) {
  return axios.get(`faxes/${faxId}`);
}
export function CreateFaxDraft(payload: any) {
  return axios.post("faxes", payload);
}
export function UpdateFax(payload: any, faxId: any) {
  return axios.patch(`faxes/${faxId}`, payload);
}
export function SendFax(faxId: any) {
  return axios.post(`faxes/${faxId}/send`);
}
export function DeleteFaxDraft(faxId: any) {
  return axios.delete(`faxes/${faxId}`);
}
export function GetSsaFieldOfficeData(zipCode: string) {
  return axios.post("field-office-info", {
    zipCodeSearched: zipCode,
  });
}
