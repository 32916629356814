import { useState } from "react";
import "../index.scss";
import dwdtemp from "../../../images/download_temp.png";
import { Button, Flex, Modal, Table, Tooltip } from "antd";
import info from "../../../images/info.png";
import {
  getWorkHistory,
  getWorkHistoryById,
  deleteWorkHistory,
  updateWorkHistory,
  saveWorkHistory,
} from "../../../actions/workHistory";
import { FlashMessage } from "../../../utils/flash_message";
import { useSearchParams } from "react-router-dom";
import { Form, Input, DatePicker, Checkbox, Select } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Constants } from "../../../common/constants";
import { validateBlankSpace } from "../../../validations/validateBlankSpace";
import {
  number,
  validateZipCode,
} from "../../../validations/numeric_only-validate";
import {
  numberGreaterThanSeven,
  numberGreaterThanTwentyFour,
} from "../../../validations/numeric_only-validate";
import { lessThanFourCharacters } from "../../../validations/lessThanFourCharacters";
import Loader from "../../Loader";
import GooglePlacesAutocompleteWrapper from "../../GooglePlacesAutoCompleteWrapper";
import dayjs from "dayjs";
import { PAY_FREQUENCY_OPTIONS } from "../../CustomerLeftPanel/components/Claims/constants";

const WorkHistory = (props: any) => {
  const [open, setOpen] = useState(false);
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
  const [isSelfEmployed, setIsSelfEmployed] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [searchParams] = useSearchParams();
  const [addressValue, setAddressValue] = useState<string | null>(null);
  const dateFormat = "MM/DD/YYYY";
  const [form] = Form.useForm();
  const [appdata, setAppData] = useState({});
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const handleTextLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 20) + (item.length > 20 ? "......" : "")}
        </Tooltip>
      );
    }
  };
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: handleTextLength,
    },
    {
      title: "Company Address",
      dataIndex: "companyAddress",
      key: "companyAddress",
      render: handleTextLength,
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: handleTextLength,
    },
    {
      title: "Employed From",
      dataIndex: "employedFrom",
      key: "employedFrom",
      render: (item: any, file: any) => {
        return item == null ? "" : dayjs(item).format(Constants.dateFormat);
      },
    },
    {
      title: "Employed To",
      dataIndex: "employedTo",
      key: "employedTo",
      render: (item: any, file: any) => {
        return item == null ? "" : dayjs(item).format(Constants.dateFormat);
      },
    },
    {
      title: "Job Duties",
      dataIndex: "jobDuties",
      key: "jobDuties",
      render: handleTextLength,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return (
          <div className='d-flex'>
            <Tooltip title={"Edit"}>
              <a className='mr-2' onClick={() => getWorkHistoryByIdApi(id)}>
                <EditFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <a onClick={() => deleteWorkHistoryApi(id)}>
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const addUpdateWorkHistoryApi = (e: any) => {
    var param = searchParams.get("id") ?? "0";
    var payload = {
      CompanyName: e.companyName,
      CompanyAddress: addressValue,
      JobTitle: e.jobTitle,
      EmployedFrom: dayjs(e.employedFrom).format(
        Constants.dateFormatWithoutUtc
      ),
      EmployedTo: isCurrentlyWorking
        ? null
        : dayjs(e.employedTo).format(Constants.dateFormatWithoutUtc),
      JobDuties: e.jobDuties,
      // IsCurrentlyWorking: e.isCurrentlyWorking,
      IsCurrentlyWorking: isCurrentlyWorking,
      IsSelfEmployed: e.isSelfEmployed,
      HoursPerDay: e.hoursPerDay,
      DaysPerWeek: e.daysPerWeek,
      PayAmount: e.payAmount,
      PayFrequency: e.payFrequency,
      Id: isAdd ? 0 : e.id,
      EncryptedUserId: param,
      AddressTwo: e.addressTwo,

      Zip: e.zip,
    };
    if (!isAdd) {
      setIsPopUpLoader(true);
      updateWorkHistory(payload).then((res) => {
        form.resetFields();
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Work History updated Successfully.");
          setOpenEdit(false);
          setIsPopUpLoader(false);
          getWorkHistoryApi();
        }
      });
    } else {
      setIsPopUpLoader(true);
      saveWorkHistory(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Work History added Successfully.");
          setOpenEdit(false);
          setIsPopUpLoader(false);
          props.TemplateCount();
        }
      });
    }
  };

  const onChangeCurrentlyWorking = (e: any) => {
    setIsCurrentlyWorking(e.target.checked);
  };
  const onChangeSelfEmployed = (e: any) => {
    setIsSelfEmployed(e.target.checked);
  };
  const deleteWorkHistoryApi = (id: any) => {
    setIsPopUpLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteWorkHistory(id).then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", response.data.responseMessage);
          setIsPopUpLoader(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setIsPopUpLoader(false);
        }
        getWorkHistoryApi();
        props.TemplateCount();
      });
    } else {
      setIsPopUpLoader(false);
    }
  };

  const getWorkHistoryByIdApi = (id: any) => {
    setIsPopUpLoader(true);
    setOpenEdit(true);
    setAddressValue(null);
    getWorkHistoryById(id).then((res) => {
      var eduData = res.data.responseData;
      if (res.data.responseCode == 200) {
        var EmployedFromValueStore =
          eduData.employedFrom == null
            ? null
            : dayjs(eduData.employedFrom).format();
        eduData.employedFrom = EmployedFromValueStore;
        setAppData(eduData);
        var EmployedToValueStore =
          eduData.employedTo == null
            ? null
            : dayjs(eduData.employedTo).format();
        eduData.employedTo = EmployedToValueStore;
        setAddressValue(eduData.companyAddress ?? "");
        setIsCurrentlyWorking(eduData.isCurrentlyWorking);
        setIsSelfEmployed(eduData.isSelfEmployed);
        form.setFieldsValue(eduData);
        setIsPopUpLoader(false);
      }
    });
  };

  const getWorkHistoryApi = () => {
    setIsPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    var param = searchParams.get("id") ?? "0";

    getWorkHistory(param).then((res) => {
      var work = res.data.responseData;
      if (res.data.responseCode == 200) {
        setDocumentData(work);
        setIsPopUpLoader(false);
      } else {
        FlashMessage("error", res.data.responseMessage);
      }
    });
  };
  const formSubmit = () => {
    form.submit();
  };
  const addPopUp = () => {
    setIsAdd(true);
    setOpenEdit(true);
    resetFormValues();
  };
  const cancel = () => {
    setOpenEdit(false);
    form.resetFields();
  };
  const resetFormValues = () => {
    var formDefaultValues = {
      companyName: "",
      companyAddress: "",
      jobTitle: "",
      addressTwo: "",
      employedFrom: null,
      employedTo: null,
      jobDuties: "",
      hoursPerDay: "",
      daysPerWeek: "",
      payAmount: "",
      zip: "",
      isCurrentlyWorking: null,
      isSelfEmployed: null,
      payFrequency: "",
    };
    setAddressValue(null);
    setIsCurrentlyWorking(false);
    setIsSelfEmployed(false);
    setTimeout(() => {
      setAppData(formDefaultValues);
      setAddressValue(formDefaultValues.companyAddress);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const predefinedValue = {
    label: addressValue,
    value: Constants.googleAutoCompleteStaticId,
  };
  return (
    <>
      {openEdit && (
        <Modal
          title={isAdd ? "Add Work History" : "Update Work History"}
          centered
          okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
          open={openEdit}
          onOk={() => (isPopUpLoader ? null : formSubmit())}
          onCancel={() => cancel()}
          width={1000}
        >
          <>
            <div className='col-xl-9 margin-auto'>
              {isPopUpLoader && <Loader></Loader>}
              {!isPopUpLoader && (
                <>
                  <p className='fill_description mt-2 text-center mb-1'>
                    Please enter your work history (at least the past two jobs)
                    below.
                  </p>
                  <div className='row'>
                    <div className='col-xl-1'></div>
                    <div className='col-xl-11'>
                      <Form
                        className='mt-4'
                        form={form}
                        name='WorkHistoryForm'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={appdata}
                        autoComplete='off'
                        onFinish={addUpdateWorkHistoryApi}
                      >
                        <div className='form-group row margin_mobile'>
                          <Form.Item name='id' />
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            1. Company Name
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='companyName'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            2. Company Address
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='companyAddress'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              {addressValue != null && (
                                <GooglePlacesAutocompleteWrapper
                                  apiKey={process.env.REACT_APP_API_KEY ?? ""}
                                  autocompletionRequest={{
                                    componentRestrictions: {
                                      country: ["us"],
                                    },
                                  }}
                                  selectProps={{
                                    value: predefinedValue,
                                    isClearable: false,
                                    onChange: (o: any) => {
                                      form.setFieldsValue({
                                        companyAddress: o?.label ?? "",
                                      });
                                      setAddressValue(o?.label ?? "");
                                    },
                                    onclose: (o: any) => {
                                      setAddressValue(o?.label ?? "");
                                    },
                                  }}
                                />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            3. Address 2 (optional)
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='addressTwo'
                              rules={[
                                {
                                  validator: validateBlankSpace,
                                },
                                {
                                  validator: lessThanFourCharacters,
                                },
                              ]}
                            >
                              <Input
                                placeholder='i.e. Apt,Ste, Fl, Unit, etc..'
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            4. Zip
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='zip'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                                {
                                  validator: number,
                                },
                                { validator: validateZipCode },
                              ]}
                            >
                              <Input
                                maxLength={10}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            5. Job Title
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='jobTitle'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile align-items-baseline'>
                          <label className='col-form-label fill_description des_font col-sm-3 mobile_view_chk'>
                            Currently Working
                          </label>
                          <div className='col-sm-1 p-0 mobile_view_chk_input text-left'>
                            <Form.Item
                              name='isCurrentlyWorking'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={onChangeCurrentlyWorking}
                                className={``}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile align-items-baseline'>
                          <label className='col-form-label fill_description des_font col-sm-3 mobile_view_chk'>
                            Self-Employed
                          </label>
                          <div className='col-sm-1 p-0 mobile_view_chk_input text-left'>
                            <Form.Item
                              name='isSelfEmployed'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={onChangeSelfEmployed}
                                className={``}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            6. Employed From
                          </label>
                          <div className='col-sm-4 p-0'>
                            <Form.Item
                              name='employedFrom'
                              getValueFromEvent={(onChange) =>
                                onChange == null
                                  ? null
                                  : dayjs(onChange).format(dateFormat)
                              }
                              getValueProps={(i) => ({
                                value: i == null ? null : dayjs(i),
                              })}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                              ]}
                            >
                              <DatePicker format={dateFormat} />
                            </Form.Item>
                          </div>
                          {isCurrentlyWorking == false ||
                          isCurrentlyWorking == null ? (
                            <>
                              <label className='col-form-label fill_description col-sm-2 des_font'>
                                To
                              </label>
                              <div className='col-sm-3 p-0'>
                                <Form.Item
                                  name='employedTo'
                                  getValueFromEvent={(onChange) =>
                                    onChange == null
                                      ? null
                                      : dayjs(onChange).format(dateFormat)
                                  }
                                  getValueProps={(i) => ({
                                    value: i == null ? null : dayjs(i),
                                  })}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the value!",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    format={dateFormat}
                                    disabledDate={(d) =>
                                      d.isBefore(
                                        form.getFieldValue("employedFrom")
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            7. Job Duties
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='jobDuties'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={1000}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            8. Hours Per Day
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='hoursPerDay'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: numberGreaterThanTwentyFour,
                                },
                                {
                                  validator: number,
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={2}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            9. Days Per Week
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='daysPerWeek'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: numberGreaterThanSeven,
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={1}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            10. Pay Amount
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='payAmount'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: number,
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Input
                                maxLength={100}
                                className={`form-control input_form_sign`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='form-group row margin_mobile'>
                          <label className='col-form-label fill_description col-sm-3 des_font'>
                            11. Pay Frequency
                          </label>
                          <div className='col-sm-9 p-0'>
                            <Form.Item
                              name='payFrequency'
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the value!",
                                },
                                {
                                  validator: validateBlankSpace,
                                },
                              ]}
                            >
                              <Select
                                options={PAY_FREQUENCY_OPTIONS.map(
                                  (loc: any) => ({
                                    label: loc.name,
                                    value: loc.name,
                                  })
                                )}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <p className='click_doc text-center mb-0'>
                          Click save to enter additional jobs
                        </p>
                      </Form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        </Modal>
      )}
      {open && (
        <Modal
          title='Work History'
          centered
          footer={null}
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Table
              dataSource={documentData}
              pagination={{
                pageSize: Constants.gridDefaultPageSize,
                showSizeChanger: false,
              }}
              columns={columns}
            />
          )}{" "}
        </Modal>
      )}

      <div className='col-md-12 col-xl-4 mt-3 template_panel'>
        <div className='background_sq_boxes height_temp_box p-4 text-center position-relative'>
          <div className='popover__wrapper2 position-absolute'>
            <img className='img-fluid info' src={info} />
            <div className='popover__content3'>
              <p className='popover__message2'>
                Information regarding all taxable work (W2/1099) over the past
                10 years.
              </p>
            </div>
          </div>
          <img className='img-fluid dws_temp' src={dwdtemp} />
          <h5 className='boxes_headings mb-3 ps-5 pe-5'>Work History</h5>

          <Flex gap={16} justify='center' vertical>
            <Button onClick={getWorkHistoryApi} type='default'>
              View
            </Button>
            <Button onClick={() => addPopUp()} type='primary'>
              Add
            </Button>
          </Flex>

          {props.workHistoryCount == 1 ? (
            <p className='mr_record'>{props.workHistoryCount} record </p>
          ) : (
            <p className='mr_record'>{props.workHistoryCount} records</p>
          )}
        </div>
      </div>
    </>
  );
};

export default WorkHistory;
