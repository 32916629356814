import React, { useState, useEffect } from "react";
import { Tag, Table, Tooltip, Alert } from "antd";
import { formatPhoneNumber } from "../../utils/commonHelper";
import { Fax } from "./FaxHistoryTable";
import { formatDateWithTimezone } from "../../utils/dateUtils";
import { DownloadDocumentAction } from "../../actions/documents";
import { useSearchParams } from "react-router-dom";
import { base64ToArrayBuffer } from "../../pages/UploadDoc";
import { EyeFilled } from "@ant-design/icons";
import Loader from "../Loader";
import { FlashMessage } from "../../utils/flash_message";
import "./style.css";

interface FaxPreviewProps {
  fax: Fax | null;
}

const FaxPreview: React.FC<FaxPreviewProps> = ({ fax }) => {
  const [sortedFaxHistory, setSortedFaxHistory] = useState<any[]>([]);
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (fax && fax.faxHistory) {
      setSortedFaxHistory(
        [...fax.faxHistory].sort(
          (a, b) =>
            new Date(b.createdDate).getTime() -
            new Date(a.createdDate).getTime()
        )
      );
    }
  }, [fax]);

  const viewDocument = async () => {
    try {
      setIsLoadingDocument(true);

      if (!fax?.faxFinalCopyDocumentId) return;

      const res = await DownloadDocumentAction(
        fax.faxFinalCopyDocumentId,
        3,
        searchParams.get("id") ?? "0"
      );

      const url = URL.createObjectURL(
        new Blob([base64ToArrayBuffer(res.data.responseData)], {
          type: "application/pdf",
        })
      );

      const newWindow = window.open(url, "_blank");
      if (newWindow) {
        newWindow.focus();
      } else {
        console.error(
          "Failed to open the document in a new window. Pop-up might be blocked."
        );
      }
    } catch (error) {
      FlashMessage("error", "Error downloading fax preview.");
      console.error("Error downloading document:", error);
    } finally {
      setIsLoadingDocument(false);
    }
  };

  const getDescription = () => {
    let latestFailedFax = sortedFaxHistory[0];
    let baseMessage = "This fax attempt has failed";

    switch (latestFailedFax.statusMessage) {
      case "Fax failed. Reason: fax_signaling_error":
        return `${baseMessage} due to a fax line signaling error. Please try sending the fax again. If that doesn't work, you may need to update the fax number.`;
      case "Fax failed. Reason: receiver_call_dropped":
        return `${baseMessage} due to the receiver call being dropped. Please try sending again. If that doesn't work, you may need to update the fax number.`;
      case "Fax failed. Reason: destination_invalid":
        return `${baseMessage} due to the destination being invalid. Please update the fax number and try again.`;
      case "Fax failed. Reason: user_busy":
        return `${baseMessage} due to the line being busy. Please try again.`;
      default:
        return `${baseMessage}. Please check the fax number and try again.`;
    }
  };

  if (!fax) return null;

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "draft":
        return "default";
      case "queued":
        return "blue";
      case "delivered":
        return "green";
      case "failed":
        return "red";
      default:
        return "orange";
    }
  };

  const statusColumns = [
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: string) => formatDateWithTimezone(date),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <Tag color={getStatusColor(status)}>{status.toUpperCase()}</Tag>
      ),
    },
  ];

  return (
    <div className='fax-preview'>
      {sortedFaxHistory.length > 0 &&
        sortedFaxHistory[0].status.toLowerCase() === "failed" && (
          <div>
            <Alert
              description={getDescription()}
              type='error'
              showIcon
              style={{ marginBottom: "16px" }}
            />
          </div>
        )}

      {!isLoadingDocument ? (
        <>
          {fax.faxFinalCopyDocumentId && (
            <div className='view-document-icon'>
              <Tooltip title={"View Document"}>
                <a
                  onClick={() => {
                    viewDocument();
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <EyeFilled
                    style={{
                      fontSize: "25px",
                      color: "#747497",
                      marginRight: "8px",
                    }}
                  />
                  View Document
                </a>
              </Tooltip>
            </div>
          )}

          <p>
            <strong>Fax Type:</strong> {fax.faxType ?? "N/A"}
          </p>
          <p>
            <strong>Sender Name:</strong> {fax.fromName ?? "N/A"}
          </p>
          <p>
            <strong>External Fax ID:</strong> {fax.externalFaxId ?? "N/A"}
          </p>
          <p>
            <strong>Created Date:</strong>{" "}
            {formatDateWithTimezone(fax.createdDate)}
          </p>
          <hr />
          <p>
            <strong>Recipient Name:</strong> {fax.receiverName ?? "N/A"}
          </p>
          <p>
            <strong>Recipient Fax Number:</strong>{" "}
            {formatPhoneNumber(fax.receiverFaxNumber) ?? "N/A"}
          </p>
          <p>
            <strong>Recipient Phone Number:</strong>{" "}
            {formatPhoneNumber(fax.receiverPhoneNumber ?? "N/A")}
          </p>
          <p>
            <strong>Fax Subject:</strong> {fax.coverLetterSubject}
          </p>

          <div className='fax-preview-container'>
            <p>
              <strong>Fax History</strong>
            </p>
            <Table
              className='v2-data-table'
              dataSource={sortedFaxHistory}
              columns={statusColumns}
              rowKey='id'
              pagination={false}
              rowClassName={() => "no-hover"}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default FaxPreview;
