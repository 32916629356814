import { Button, Checkbox, Modal, Radio, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { UndoOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import BrandButton from "../../BrandButton";
import { getNoteOptions, saveNotes } from "../../../actions/notes";
import { FlashMessage } from "../../../utils/flash_message";
import { deleteClaim, updateClaim } from "../../../actions/claims";
import { updateClaimPayloads } from "./Claims/constants";
import CreateNoteWrapper from "./CreateNoteWrapper";

type CheckboxValueType = string | number | (string | number)[];

export const claimsNoteValidation = (note_payload: any) => {
  let validation_error = "";

  if (!note_payload.TagsText || !note_payload.PlainText) {
    validation_error = "Please add additional information to the note.";
  } else if (!note_payload.Type) {
    validation_error = "Please select a note type.";
  } else if (!note_payload.Minutes) {
    validation_error = "Please enter note minutes.";
  } else if (!note_payload.Category) {
    validation_error = "Please select a note category.";
  } else if (!note_payload.Source) {
    validation_error = "Please select a note source.";
  }
  return validation_error;
};

const CompleteIntake = ({
  claims,
  onSuccess,
  onDelete,
  isAllowed,
  caseManagerName,
  canDelete,
}: {
  claims: any;
  onSuccess: Function;
  onDelete: Function;
  isAllowed: boolean;
  caseManagerName: string;
  canDelete: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [intakeMethod, setIntakeMethod] = useState<"ssa" | "admin" | "">("");
  const [eligibleClaimTypes, setEligibleClaimTypes] = useState<
    CheckboxValueType[]
  >([]);

  const [intakeNote, setIntakeNote] = useState<any>({
    description: "",
    plainText: "",
    tagsText: "",
    type: "",
    minutes: "",
    category: "",
    source: "",
    tagUserIds: "",
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [isIntakeModalOpen, setIsIntakeModalOpen] = useState(false);

  /* Preset Note Values Functionality */
  const [noteOptions, setNoteOptions] = useState<{
    noteTypes: { id: number; value: string }[];
    noteSourceTypes: { id: number; value: string }[];
    noteCategoryTypes: { id: number; value: string }[];
  }>({
    noteTypes: [],
    noteSourceTypes: [],
    noteCategoryTypes: [],
  });

  const loadNotesOptions = async () => {
    const notes_options = await getNoteOptions();
    setNoteOptions({
      noteCategoryTypes: notes_options.data.noteCategoryTypes,
      noteSourceTypes: notes_options.data.noteSourceTypes,
      noteTypes: notes_options.data.noteTypes,
    });
  };

  useEffect(() => {
    loadNotesOptions();
  }, []);
  /* Preset Note Values Functionality Ends */

  const showIntakeModal = () => {
    setIsIntakeModalOpen(true);
  };

  const completeIntake = async () => {
    setIsLoading(true);

    // Create Note
    const note_payload = {
      Description: intakeNote.description,
      EncryptedUserId: searchParams.get("id") ?? "0",
      LeadsId: null,
      PlainText: intakeNote.plainText,
      TagsText: intakeNote.tagsText,
      Type: JSON.stringify(intakeNote.type),
      Source: intakeNote.source,
      Category: intakeNote.category,
      Minutes: intakeNote.minutes,
    };

    let validation_error = claimsNoteValidation(note_payload);

    if (eligibleClaimTypes.length === 0) {
      validation_error = "Please select claimant's eligible claim types.";
    }

    if (validation_error) {
      FlashMessage("error", validation_error);
      setIsLoading(false);
      return;
    }

    const note_response = await saveNotes(note_payload, intakeNote.tagUserIds);

    FlashMessage("success", "Note added successfully.");

    const claim_response = await Promise.all(
      claims.map((claim: any) => {
        const payload = updateClaimPayloads(
          intakeMethod === "admin" ? "ADMIN_INTAKE" : "SSA_INTAKE",
          claim,
          eligibleClaimTypes.includes(claim.ClaimTypeIdDisplayNameReadOnly)
        );
        return updateClaim({
          ...payload,
        });
      })
    );

    FlashMessage("success", "Claim updated successfully.");

    await onSuccess("Pending Onboarding");
    setIsLoading(false);
    setIsIntakeModalOpen(false);
    searchParams.set("refresh_notes", "claims_intake");
    setSearchParams(searchParams);
  };

  const cancelIntake = () => {
    setIntakeMethod("");
    setIsLoading(false);
    // TOOD: Do we want to clear our Notes form state?
    setIsIntakeModalOpen(false);
  };

  const changeIntakeMethod = (e: any) => {
    setIntakeMethod(e.target.value);
  };

  const deleteClaims = async () => {
    setIsDeleting(true);
    await Promise.all(claims.map((claim: any) => deleteClaim(claim.id)));

    onDelete();

    setIsDeleting(false);
  };

  if (!isAllowed) {
    return (
      <p>
        Intake Specialists will submit the Initial Application on SSA.gov or
        Collect Information on the existing claim.
      </p>
    );
  }

  return (
    <>
      <p>
        Submit the Initial Application on SSA.gov or Collect Information on the
        existing claim and click Finalize Data Collection.
      </p>
      <div className='d-flex flex-column align-items-start'>
        <BrandButton
          dataCy='claims-complete-intake-btn'
          label='Finalize Data Collection'
          onClick={showIntakeModal}
        />
        {canDelete && (
          <Button
            className='undo-button p-0'
            type='text'
            icon={<UndoOutlined />}
            loading={isDeleting}
            onClick={deleteClaims}
            data-cy='claims-undo-registration-btn'
          >
            Undo Registration
          </Button>
        )}
      </div>
      <Modal
        className='claims-modal'
        title='Finalize Intake Data Collection'
        open={isIntakeModalOpen}
        onOk={completeIntake}
        onCancel={cancelIntake}
        width={760}
        footer={[
          <Button key='back' onClick={cancelIntake}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            data-cy='submit-intake-btn'
            loading={isLoading}
            disabled={!intakeMethod}
            onClick={completeIntake}
          >
            Finalize Data Collection
          </Button>,
        ]}
      >
        <p className='mt-2 mb-0'>
          For adult SSDI claims, submit an Initial Application on SSA.gov,
          otherwise collect information in the Mindset Admin for a Case Manager
          to submit.
        </p>
        <div className='d-flex flex-column'>
          <label className='intake-form-label'>
            How did you complete intake data collection?
          </label>
          <Radio.Group onChange={changeIntakeMethod} value={intakeMethod}>
            <Space direction='vertical'>
              <Radio value='ssa' data-cy='intake-ssa'>
                Filed on SSA.gov
              </Radio>
              <Radio value='admin' data-cy='intake-admin'>
                Information Collected in Mindset Admin
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className='d-flex flex-column'>
          <label className='intake-form-label'>
            What are the claimant's eligible claim types?
          </label>

          <Checkbox.Group
            value={eligibleClaimTypes}
            onChange={setEligibleClaimTypes}
          >
            <Row className='mb-2'>
              <Checkbox
                value='Ssdi'
                disabled={
                  claims.find(
                    (c: any) =>
                      c.ClaimTypeIdDisplayNameReadOnly === "Ssdi" &&
                      c.iaSubmittedByMindsetUserId
                  )
                    ? true
                    : false
                }
              >
                SSDI - Social Security Disability Insurance - T2
              </Checkbox>
            </Row>
            <Row>
              <Checkbox
                value='Ssi'
                disabled={
                  claims.find(
                    (c: any) =>
                      c.ClaimTypeIdDisplayNameReadOnly === "Ssi" &&
                      c.iaSubmittedByMindsetUserId
                  )
                    ? true
                    : false
                }
              >
                SSI - Supplemental Security Income - T16
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </div>
        {intakeMethod && (
          <div className='d-flex flex-column'>
            <label className='intake-form-label'>Claimaint Intake Note</label>
            <p className='mb-0 mt-0'>
              This will appear as a note in Notes for your claimaint.
            </p>

            <CreateNoteWrapper
              value={intakeNote}
              onChange={setIntakeNote}
              intakeMethod={intakeMethod}
              caseManagerName={caseManagerName}
              defaultValues={{
                minutes: "",
                type: (
                  noteOptions.noteTypes.find(
                    (noteType) => noteType.value === "Call"
                  ) || {}
                ).id,
                source: (
                  noteOptions.noteSourceTypes.find(
                    (noteType) => noteType.value === "Outbound"
                  ) || {}
                ).id,
                category: (
                  noteOptions.noteCategoryTypes.find(
                    (noteType) =>
                      noteType.value ===
                      (intakeMethod === "admin"
                        ? "All Information Collected"
                        : "IA Submitted")
                  ) || {}
                ).id,
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default CompleteIntake;
