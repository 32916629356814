import { useState, useEffect } from "react";
import { getGroupUsers, saveNotes } from "../../actions/notes";
import { Button, Flex, Form, Typography } from "antd";
import { useSearchParams } from "react-router-dom";
import { FlashMessage } from "../../utils/flash_message";
import { useNavigate, useLocation } from "react-router-dom";
import { MentionsInput, Mention } from "react-mentions";
import { getSupportUsers } from "../../actions/supportUser";
import { get } from "../../utils/clientStorageUtils";
import defaultMentionStyle from "./defaultMentionStyle";
import {
  GroupUser,
  MentionUser,
  getUpdatedMentionsValue,
} from "../../utils/mentions";
import { Enums } from "../../common/enums";
import NotesMetaData from "./NoteMetaData";
import GlobalTaskNotes from "../../common/components/GlobalTaskNotes";
import { FileTextOutlined } from "@ant-design/icons";
import { Constants } from "../../common/constants";
import { getOnlyShowMyCasesFromSearchParams } from "../../utils/commonHelper";
import ClaimantProfileTabKeys from "../../common/constants/ClaimantProfileTabKeys";
import LeadProfileTabKeys from "../../common/constants/LeadProfileTabKeys";

const SaveNotes = (props: any) => {
  const [form] = Form.useForm();
  const [user, setUser] = useState<MentionUser[]>([]);
  const [plainText, setPlainText] = useState("");
  const [tagsText, setTagsText] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [singleLineValue, setSingleLineValue] = useState("");
  const [tagUserIds, setTagUserIds] = useState("");
  const [checkValidation, setCheckValidation] = useState(false);
  const [noteById, setNoteById] = useState({ plainText: "" });
  const [disable, setDisable] = useState(false);
  const [groups, setgroups] = useState<GroupUser[]>([]);
  const [noteType, setNoteType] = useState();
  const [noteMinutes, setNoteMinutes] = useState();
  const [noteSource, setNoteSource] = useState();
  const [noteCategory, setNoteCategory] = useState();

  const getSupportUsersApi = () => {
    getSupportUsers().then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        let userid = JSON.parse(get("model"))
          ? JSON.parse(get("model")).userId
          : "";
        var filterData = userdata
          .map((ele: any) => ({
            id: ele.id,
            display: `${ele.firstName} ${ele.lastName}`,
          }))
          .filter((ele: any) => ele.id != userid);
        setUser(filterData);
        setTagsText("");
      }
    });
  };
  const getGroupUserApi = () => {
    getGroupUsers().then((res) => {
      if (res.data.responseCode == Enums.responseCodes.success) {
        var groupData = res.data.responseData.map((ele: any) => ({
          id: ele.groupId,
          display: ele.groupName,
        }));
        setgroups(groupData);
        setTagsText("");
      }
    });
  };
  const saveNotesApi = (e: any) => {
    setDisable(true);
    setCheckValidation(true);
    var param = searchParams.get("id") ?? "0";
    var payload = {
      Description: singleLineValue,
      EncryptedUserId: props?.IsLeads ? null : param,
      LeadsId: props?.IsLeads ? param : null,
      Id: e.id,
      PlainText: plainText,
      TagsText: tagsText,
      Type: JSON.stringify(noteType),
      Source: noteSource,
      Category: noteCategory,
      Minutes: noteMinutes,
    };
    if (payload.Description != null && payload.Description.trim() != "") {
      saveNotes(payload, tagUserIds).then((res) => {
        if (res.data.responseCode == 200) {
          setDisable(false);
          FlashMessage("success", "Note added Successfully.");
          resetFormValues();
          if (
            location.pathname.indexOf(Constants.pageRoutes.claimantProfile) >=
              0 ||
            location.pathname.indexOf(Constants.pageRoutes.leadProfile) >= 0
          ) {
            if (props.IsLeads) {
              navigate(
                `/leadsnotes?id=${
                  searchParams.get("id") || "0"
                }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                  searchParams
                )}&tab=${LeadProfileTabKeys.NOTES_TASKS}`
              );
            } else {
              navigate(
                `/clientsnotes?id=${
                  searchParams.get("id") ?? "0"
                }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                  searchParams
                )}&tab=${ClaimantProfileTabKeys.NOTES_TASKS}`
              );
            }
          }
        }
      });
    } else {
      setDisable(false);
    }
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      plainText: "",
      type: [],
      minutes: null,
      source: null,
      category: null,
    };
    setTimeout(() => {
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const handleChangeSingle = (
    e: any,
    newValue: any,
    newPlainTextValue: any,
    _mentions: any
  ) => {
    const { newTagUserIds, newSingleLineValue, newPlainText, newTagsText } =
      getUpdatedMentionsValue(
        newValue,
        newPlainTextValue,
        _mentions,
        user,
        groups
      );
    setTagUserIds(newTagUserIds);
    setSingleLineValue(newSingleLineValue);
    setPlainText(newPlainText);
    setTagsText(newTagsText);
  };

  useEffect(() => {
    getGroupUserApi();
    getSupportUsersApi();
  }, []);

  return (
    <>
      <Typography.Title level={4}>New Note</Typography.Title>
      <Form
        form={form}
        name='NotesForm'
        autoComplete='off'
        initialValues={noteById}
        onFinish={saveNotesApi}
      >
        <Form.Item
          className='note_add'
          name='plainText'
          required
          style={{ marginBottom: 8 }}
        >
          <MentionsInput
            singleLine={false}
            value={plainText}
            onChange={handleChangeSingle}
            placeholder='Post a note'
            allowSpaceInQuery={true}
          >
            <Mention
              style={defaultMentionStyle}
              trigger='@'
              data={groups}
              displayTransform={(id: any, name: any) => `@${name}:`}
            />
            <Mention
              style={defaultMentionStyle}
              trigger='@'
              data={user}
              displayTransform={(id: any, name: any) => `@${name}:`}
            />
          </MentionsInput>
        </Form.Item>

        <NotesMetaData
          mode='Create'
          noteType={noteType}
          setNoteType={setNoteType}
          noteMinutes={noteMinutes}
          setNoteMinutes={setNoteMinutes}
          noteSource={noteSource}
          setNoteSource={setNoteSource}
          noteCategory={noteCategory}
          setNoteCategory={setNoteCategory}
          setInitialValue={(params: object) => {
            form.setFieldsValue({
              ...params,
            });
          }}
        />

        <Flex gap={8} justify='flex-end'>
          <GlobalTaskNotes isLeads={props.IsLeads} directTaskOpen={true} />
          <Button
            className='secondary-button'
            htmlType='submit'
            icon={<FileTextOutlined />}
          >
            Create Note
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export default SaveNotes;
