import { useEffect, useState } from "react";
import AssignmentDropdown from "./AssignmentDropdown";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { getSupportUsers } from "../../../../actions/supportUser";
import * as signalR from "@microsoft/signalr";
import { GetAdminApiBaseUrlFromWindow } from "../../../../utils/utils";

export interface SupportUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isActive: boolean;
}

const ClaimantAssignments = ({ appData }: { appData: any }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [claimantAssignment, setClaimantAssignment] = useState<any>(null);
  const [supportUsers, setSupportUsers] = useState<SupportUser[]>([]);

  const getClaimaintAssignment = async () => {
    setIsLoading(true);
    const {
      data: { responseData },
    } = await axios.get(
      `ClaimantAssignment?claimantMindsetUserId=${appData.mindsetUserId}`
    );

    setClaimantAssignment(responseData);
    setIsLoading(false);
  };

  const loadSupportUsers = async () => {
    // TODO: simulate error
    const getInactiveAndActiveUsers = true;
    const {
      data: { responseData },
    } = await getSupportUsers(getInactiveAndActiveUsers);
    setSupportUsers(responseData);
  };

  useEffect(() => {
    getClaimaintAssignment();
    loadSupportUsers();
  }, [appData.id]);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${GetAdminApiBaseUrlFromWindow()}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("commonUpdate_Delete", (res: any) => {
      if (res === appData.mindsetUserId) {
        getClaimaintAssignment();
        loadSupportUsers();
      }
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("commonUpdate_Delete");
      connection.stop().catch((err) => console.log("connection Stop Error"));
    };
  }, []);

  if (isLoading || supportUsers.length === 0) {
    return <Spinner size='sm' />;
  }

  return (
    <>
      <AssignmentDropdown
        appData={appData}
        type='Intake Specialist'
        claimantAssignment={claimantAssignment}
        getClaimaintAssignment={getClaimaintAssignment}
        supportUsers={supportUsers}
      />
      <AssignmentDropdown
        appData={appData}
        type='Case Manager'
        claimantAssignment={claimantAssignment}
        getClaimaintAssignment={getClaimaintAssignment}
        supportUsers={supportUsers}
      />
      <AssignmentDropdown
        appData={appData}
        type='Lawyer'
        claimantAssignment={claimantAssignment}
        getClaimaintAssignment={getClaimaintAssignment}
        supportUsers={supportUsers}
      />
    </>
  );
};

export default ClaimantAssignments;
