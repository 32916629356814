import { useState, useEffect } from "react";
import "../index.scss";
import dwdtemp from "../../../images/download_temp.png";
import {
  Modal,
  Table,
  Tooltip,
  Select,
  Form,
  Input,
  DatePicker,
  Checkbox,
  Flex,
  Button,
  Radio,
} from "antd";
import info from "../../../images/info.png";
import {
  getEducationHistory,
  getEducationHistoryById,
  deleteEducationHistory,
  updateEducationHistory,
  saveEducationHistory,
} from "../../../actions/highSchoolInfo";
import { FlashMessage } from "../../../utils/flash_message";
import { useSearchParams } from "react-router-dom";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { getCommonAllLocations } from "../../../actions/common";
import { numberFromOneAndTwelve } from "../../../validations/numeric_only-validate";
import { Constants } from "../../../common/constants";
import { validateBlankSpace } from "../../../validations/validateBlankSpace";
import Loader from "../../Loader";
import { validateGradeCompleted } from "../../../validations/validateHighestGrade";
import dayjs from "dayjs";

const HighSchoolInformation = (props: any) => {
  const [open, setOpen] = useState(false);
  const [checkedMilitary, setcheckedMilitary] = useState(false);
  const [checkedGed, setCheckedGed] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [searchParams] = useSearchParams();
  const [appdata, setAppData] = useState({});
  const [location, setLocation] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isSpecialEducation, setIsSpecialEducation] = useState(false);
  const [isPopUpLoader, setisPopUpLoader] = useState(false);
  const dateFormat = Constants.dateFormat;
  const [form] = Form.useForm();
  const [neverAttendedSchool, setNeverAttendedSchool] = useState(false);
  const [checkedHSD, setCheckedHSD] = useState(false);

  const handleGradeChange = (value: any) => {
    if (value === Constants.highestGradeCompleted.neverAttended.value) {
      setNeverAttendedSchool(true);
      form.setFields([
        {
          name: "highSchoolCity",
          errors: [],
          value: "",
        },
        {
          name: "lastHighSchoolAttended",
          errors: [],
          value: "",
        },
        {
          name: "highSchoolState",
          errors: [],
          value: "",
        },
      ]);
    } else {
      setNeverAttendedSchool(false);
    }
  };

  const onChangeSpecialEducation = (e: any) => {
    setIsSpecialEducation(e.target.checked);
  };
  const handleTextLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 20) + (item.length > 20 ? "......" : "")}
        </Tooltip>
      );
    }
  };
  const columns = [
    {
      title: "Date Of Certificate",
      dataIndex: "dateOfCertificate",
      key: "dateOfCertificate",
      render: (item: any, file: any) => {
        return item == null ? "" : dayjs(item).format(Constants.dateFormat);
      },
    },
    {
      title: "Highest Grade Completed",
      dataIndex: "highGradeCompleted",
      key: "highGradeCompleted",
    },
    {
      title: "High School City",
      dataIndex: "highSchoolCity",
      key: "highSchoolCity",
      render: handleTextLength,
    },
    {
      title: "High School State",
      dataIndex: "highSchoolState",
      key: "highSchoolState",
    },
    {
      title: "Last High School Attended",
      dataIndex: "lastHighSchoolAttended",
      key: "lastHighSchoolAttended",
      render: handleTextLength,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return (
          <div className='d-flex'>
            <Tooltip title={"Edit"}>
              <a
                className='mr-2'
                onClick={() => getEducationHistoryByIdApi(id)}
              >
                <EditFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <a onClick={() => deleteEducationHistoryApi(id)}>
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getCommonAllLocationsApi = () => {
    getCommonAllLocations().then((res) => {
      var loc = res.data.responseData;
      setLocation(loc);
    });
  };

  const cancel = () => {
    setOpenEdit(false);
    setIsSpecialEducation(false);
  };
  const addUpdateEducationHistoryApi = (e: any) => {
    var param = searchParams.get("id") ?? "0";
    var payload = {
      DateOfCertificate: e.dateOfCertificate
        ? dayjs(e.dateOfCertificate).format(Constants.dateFormatWithoutUtc)
        : null,
      IsGED: checkedGed,
      IsHighSchoolDiploma: !checkedGed,
      IsStateType: checkedMilitary,
      Id: isAdd ? 0 : e.id,
      HighGradeCompleted: e.highGradeCompleted,
      LastHighSchoolAttended: e.lastHighSchoolAttended,
      HighSchoolCity: e.highSchoolCity,
      HighSchoolState: e.highSchoolState,
      EncryptedUserId: param,
      IsSpecialEducation: e.isSpecialEducation,
      StartGrade: e.isSpecialEducation ? e.startGrade : null,
      EndGrade: e.isSpecialEducation ? e.endGrade : null,
    };
    if (!isAdd) {
      setisPopUpLoader(true);
      updateEducationHistory(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "School Information updated Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          getEducationHistoryApi();
        }
      });
    } else {
      setisPopUpLoader(true);
      saveEducationHistory(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "School Information added Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          getEducationHistoryApi();
          props.TemplateCount();
        }
      });
    }
  };

  const deleteEducationHistoryApi = (id: any) => {
    setisPopUpLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteEducationHistory(id).then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", response.data.responseMessage);
          setisPopUpLoader(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setisPopUpLoader(false);
        }
        getEducationHistoryApi();
        props.TemplateCount();
      });
    } else {
      setisPopUpLoader(false);
    }
  };

  const getEducationHistoryByIdApi = (id: any) => {
    setisPopUpLoader(true);
    setOpenEdit(true);
    getCommonAllLocationsApi();
    getEducationHistoryById(id).then((res) => {
      var eduData = res.data.responseData;

      if (res.data.responseCode == 200) {
        var dateOfCertificateValueStore =
          eduData.dateOfCertificate == null
            ? null
            : dayjs(eduData.dateOfCertificate).format();
        eduData.dateOfCertificate = dateOfCertificateValueStore;
        setCheckedGed(eduData.isGED);
        setAppData(eduData);
        setIsSpecialEducation(eduData.isSpecialEducation);
        form.setFieldsValue(eduData);
        setisPopUpLoader(false);
      }
    });
  };

  const getEducationHistoryApi = () => {
    setisPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    var param = searchParams.get("id") ?? "0";
    getEducationHistory(param).then((res) => {
      var education = res.data.responseData;
      if (res.data.responseCode == 200) {
        setDocumentData(education);
        setisPopUpLoader(false);
      } else {
        FlashMessage("error", res.data.responseMessage);
      }
    });
  };

  const gedYes = (checked: any) => {
    // Allow both radio buttons to be unchecked
    if (!checked) {
      setCheckedGed(false);
    } else {
      setCheckedGed(true);
    }
  };
  const handleEducationStatusChange = (
    type: "GED" | "HSD",
    checked: boolean
  ) => {
    if (type === "GED") {
      setCheckedGed(checked);
      if (checked) {
        setCheckedHSD(false);
      }
    } else if (type === "HSD") {
      setCheckedHSD(checked);
      if (checked) {
        setCheckedGed(false);
      }
    }
  };
  const militaryState = (checked: any) => {
    setcheckedMilitary(checked);
  };

  const formSubmit = () => {
    form.submit();
  };
  const addPopUp = () => {
    getCommonAllLocationsApi();
    setIsAdd(true);
    setOpenEdit(true);
    resetFormValues();
  };
  const resetFormValues = () => {
    var formDefaultValues = {
      highGradeCompleted: "",
      lastHighSchoolAttended: "",
      highSchoolCity: null,
      highSchoolState: null,
      dateOfCertificate: null,
      collegeTradeSchool: "",
      collegeTradeDatesAttended: null,
      isSpecialEducation: false,
      startGrade: "",
      endGrade: "",
    };

    setTimeout(() => {
      setAppData(formDefaultValues);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };

  useEffect(() => {
    if (
      form.getFieldValue("highGradeCompleted") ===
      Constants.highestGradeCompleted.neverAttended.value
    ) {
      setNeverAttendedSchool(true);
    } else {
      setNeverAttendedSchool(false);
    }
  }, [form.getFieldValue("highGradeCompleted")]);
  return (
    <>
      {openEdit && (
        <Modal
          title={isAdd ? "Add School Information" : "Update School Information"}
          centered
          okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
          open={openEdit}
          onOk={() => (isPopUpLoader ? "Loading" : formSubmit())}
          onCancel={() => cancel()}
          width={1000}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Form
              className='mt-4'
              form={form}
              name='HighSchoolInfoForm'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={appdata}
              autoComplete='off'
              onFinish={addUpdateEducationHistoryApi}
            >
              <Form.Item name='id' hidden />

              <Form.Item label='GED'>
                <Radio.Group
                  onChange={(e) =>
                    handleEducationStatusChange("GED", e.target.value)
                  }
                  value={checkedGed}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='Date of Certificate'
                name='dateOfCertificate'
                getValueFromEvent={(onChange) =>
                  onChange == null ? null : dayjs(onChange).format(dateFormat)
                }
                getValueProps={(i) => ({ value: i == null ? null : dayjs(i) })}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>

              {checkedGed && (
                <Form.Item label='Type'>
                  <Radio.Group
                    onChange={(e) => militaryState(e.target.value)}
                    value={checkedMilitary}
                  >
                    <Radio value={true}>Military GED</Radio>
                    <Radio value={false}>State GED</Radio>
                  </Radio.Group>
                </Form.Item>
              )}

              <Form.Item label='High School Diploma'>
                <Radio.Group
                  onChange={(e) =>
                    handleEducationStatusChange("HSD", e.target.value)
                  }
                  value={checkedHSD}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='Highest Grade Completed'
                name='highGradeCompleted'
                rules={[{ validator: validateGradeCompleted }]}
              >
                <Select onChange={handleGradeChange}>
                  {Object.values(Constants.highestGradeCompleted).map(
                    (grade) => (
                      <Select.Option key={grade.value} value={grade.value}>
                        {grade.label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                label='Last High School Attended'
                name='lastHighSchoolAttended'
                rules={[
                  {
                    required: !neverAttendedSchool,
                    message: "Please input the value!",
                  },
                  {
                    validator: validateBlankSpace,
                    required: !neverAttendedSchool,
                  },
                ]}
              >
                <Input maxLength={100} disabled={neverAttendedSchool} />
              </Form.Item>

              <Form.Item
                label='City'
                name='highSchoolCity'
                rules={[
                  {
                    required: !neverAttendedSchool,
                    message: "Please input the value!",
                  },
                  { validator: validateBlankSpace },
                ]}
              >
                <Input maxLength={100} disabled={neverAttendedSchool} />
              </Form.Item>

              <Form.Item
                label='State'
                name='highSchoolState'
                rules={[
                  {
                    required: !neverAttendedSchool,
                    message: "Please input the value!",
                  },
                  { validator: validateBlankSpace },
                ]}
              >
                <Select
                  options={location.map((loc: any) => ({
                    label: loc.name,
                    value: loc.name,
                    key: loc.name,
                  }))}
                  disabled={neverAttendedSchool}
                />
              </Form.Item>

              <Form.Item name='isSpecialEducation' valuePropName='checked'>
                <Checkbox onChange={onChangeSpecialEducation}>
                  Special Education
                </Checkbox>
              </Form.Item>

              {isSpecialEducation && (
                <>
                  <Form.Item
                    label='Start Grade'
                    name='startGrade'
                    rules={[
                      { required: true, message: "Please input the value!" },
                      { validator: validateBlankSpace },
                      { validator: numberFromOneAndTwelve },
                    ]}
                  >
                    <Input maxLength={2} />
                  </Form.Item>

                  <Form.Item
                    label='End Grade'
                    name='endGrade'
                    rules={[
                      { required: true, message: "Please input the value!" },
                      { validator: validateBlankSpace },
                      { validator: numberFromOneAndTwelve },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            parseInt(getFieldValue("startGrade")) <=
                              parseInt(value)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Must not be less than start grade!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input maxLength={2} />
                  </Form.Item>
                </>
              )}
            </Form>
          )}
        </Modal>
      )}

      {open && (
        <Modal
          title='School Information'
          centered
          open={open}
          footer={null}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Table
              pagination={{
                pageSize: Constants.gridDefaultPageSize,
                showSizeChanger: false,
              }}
              dataSource={documentData}
              columns={columns}
            />
          )}
        </Modal>
      )}

      <div className='col-md-12 col-xl-4 mt-3 template_panel'>
        <div className='background_sq_boxes height_temp_box p-4 text-center position-relative'>
          <div className='popover__wrapper2 position-absolute'>
            <img className='img-fluid info' src={info} />
            <div className='popover__content3'>
              <p className='popover__message2'>
                {" "}
                List your High School or GED information.
              </p>
            </div>
          </div>
          <img className='img-fluid dws_temp' src={dwdtemp} />
          <h5 className='boxes_headings mb-3 ps-4 pe-4'>School Information</h5>

          <Flex gap={16} justify='center' vertical>
            <Button onClick={getEducationHistoryApi} type='default'>
              View
            </Button>
            {props.highSchoolInfoCount < 1 && (
              <Button onClick={() => addPopUp()} type='primary'>
                Add
              </Button>
            )}
          </Flex>

          {props.highSchoolInfoCount == 1 ? (
            <p className='mr_record'>{props.highSchoolInfoCount} record </p>
          ) : (
            <p className='mr_record'>{props.highSchoolInfoCount} records</p>
          )}
        </div>
      </div>
    </>
  );
};

export default HighSchoolInformation;
