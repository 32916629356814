import { useState, useEffect } from "react";
import { Table, Tag, Button } from "antd";
import { GetFaxesForUser } from "../../apis/faxManager";
import { SortOrder } from "antd/es/table/interface";
import FaxDetailsModal from "./FaxDetailsModal";
import { FlashMessage } from "../../utils/flash_message";
import SendFaxModal from "./SendFaxModal";
import { formatPhoneNumber } from "../../utils/commonHelper";
import { formatDateWithTimezone } from "../../utils/dateUtils";
import "./style.css";

export interface FaxHistoryItem {
  id: number;
  status: string;
  statusMessage?: string;
  createdDate: string;
}

export interface Fax {
  id: number;
  claimantMindsetUserId: number;
  receiverName: string;
  receiverFaxNumber: string;
  receiverPhoneNumber: string;
  fromName: string;
  coverLetterSubject: string;
  coverLetterBody: string;
  faxType: string;
  externalFaxId: string;
  faxFinalCopyDocumentId?: number;
  includedDocumentIds?: string;
  createdDate: string;
  faxHistory: FaxHistoryItem[];
}

interface FaxHistoryProps {
  userId: string;
}

const FaxHistoryTable: React.FC<FaxHistoryProps> = ({ userId }) => {
  const [faxes, setFaxes] = useState<Fax[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedFax, setSelectedFax] = useState<Fax | null>(null);
  const [createFaxModalVisible, setCreateFaxModalVisible] = useState(false);
  const [createFaxModalInitialStep, setCreateFaxModalInitialStep] =
    useState<number>(1);

  useEffect(() => {
    fetchFaxes();
    const intervalId = setInterval(fetchFaxes, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [userId]);

  const fetchFaxes = async () => {
    setLoading(true);
    try {
      const {
        data: { responseData },
      } = await GetFaxesForUser(userId);

      const sortedFaxes = responseData.sort(
        (a: Fax, b: Fax) =>
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );

      setFaxes(sortedFaxes);
    } catch (error) {
      FlashMessage("error", "Failed to fetch faxes for user.");
    } finally {
      setLoading(false);
    }
  };

  const getLatestStatus = (faxHistory: FaxHistoryItem[]) => {
    if (!faxHistory.length) {
      return "Unknown";
    }

    const latestEntry = faxHistory.reduce(
      (latest, entry) =>
        new Date(entry.createdDate) > new Date(latest.createdDate)
          ? entry
          : latest,
      faxHistory[0]
    );

    return latestEntry.status || "Unknown";
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "draft":
        return "default";
      case "queued":
        return "blue";
      case "delivered":
        return "green";
      case "failed":
        return "red";
      default:
        return "orange";
    }
  };

  const handleViewClose = () => {
    setSelectedFax(null);
    setVisible(false);
  };

  const handleCreateClose = () => {
    fetchFaxes();
    setCreateFaxModalInitialStep(1);
    setCreateFaxModalVisible(false);
    setSelectedFax(null);
  };

  const handleRowClick = (fax: Fax) => {
    const latestStatus = getLatestStatus(fax.faxHistory);
    const isDraft = latestStatus.toLowerCase() === "draft";
    const isFailed = latestStatus.toLowerCase() === "failed";

    if (isDraft || isFailed) {
      const initialStep = fax.faxFinalCopyDocumentId ? 3 : 2;
      setSelectedFax(fax);
      setCreateFaxModalVisible(true);
      setCreateFaxModalInitialStep(initialStep);
    } else {
      setSelectedFax(fax);
      setVisible(true);
    }
  };

  const columns = [
    {
      title: "Fax ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Recipient Name",
      dataIndex: "receiverName",
      key: "receiverName",
    },
    {
      title: "Recipient Fax Number",
      dataIndex: "receiverFaxNumber",
      key: "receiverNumber",
      render: (phone: string) => formatPhoneNumber(phone),
    },
    {
      title: "Subject",
      dataIndex: "coverLetterSubject",
      key: "coverLetterSubject",
    },
    {
      title: "Sender Name",
      dataIndex: "fromName",
      key: "fromName",
    },
    {
      title: "Status",
      dataIndex: "faxHistory",
      key: "status",
      render: (faxHistory: FaxHistoryItem[]) => {
        const latestStatus = getLatestStatus(faxHistory);
        return (
          <Tag color={getStatusColor(latestStatus)}>
            {latestStatus.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: string) => formatDateWithTimezone(date),
      sorter: (a: Fax, b: Fax) =>
        new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
      defaultSortOrder: "descend" as SortOrder,
    },
  ];

  return (
    <div className='fax-main-content'>
      <div className='fax-actions'>
        <Button type='primary' onClick={() => setCreateFaxModalVisible(true)}>
          Create Fax
        </Button>
      </div>

      <Table
        className='v2-data-table'
        columns={columns}
        dataSource={faxes.map((fax) => ({ ...fax, key: fax.id }))}
        pagination={{ pageSize: 10 }}
        rowKey='id'
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        scroll={{ x: "scroll" }}
      />

      {visible && (
        <FaxDetailsModal
          visible={visible}
          fax={selectedFax}
          onClose={handleViewClose}
        />
      )}

      {createFaxModalVisible && (
        <SendFaxModal
          visible={createFaxModalVisible}
          onClose={handleCreateClose}
          faxData={selectedFax}
          setFaxData={setSelectedFax}
          initialStep={createFaxModalInitialStep}
          userId={userId}
        />
      )}
    </div>
  );
};

export default FaxHistoryTable;
