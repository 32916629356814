import dayjs from "dayjs";
import { format } from "@react-input/mask";

const FULL_DATE_FORMAT = "MM/DD/YYYY";

const PhoneNumberMask = {
  mask: "(___) ___-____",
  replacement: { _: /\d/ },
};

const STR_ARR_DELIMITER = ";;;";

export interface ApplicationValues {
  middleInitial: string;
  gender: string;
  isLowVision: boolean;
  isDeniedBenefits: boolean;
  isUnableWork: boolean;
  livesAtMailingAddress: boolean;
  writtenLanguage: string;
  canReadSimpleMessage: boolean;
  canWriteSimpleMessage: boolean;
  canContactHelper: boolean;
  helpingContacts: {
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
    address: string;
    speaksEnglish: boolean;
    relationship: string;
    phone: string;
  }[];
  isMarried: boolean;
  spouseFirstName: string;
  spouseLastName: string;
  knowsSpouseSSN: boolean;
  spouseSSN: string;
  knowsSpouseDOB: boolean;
  spouseDOB: string;
  spouseAge: string;
  marriageDate: string;
  marriageCity: string;
  marriageState: string;
  hasPriorMarriages: boolean;
  hasPriorMarriage10Years: boolean;
  priorMarriages: {
    spouseFirstName: string;
    knowsSpouseSSN: boolean;
    knowsSpouseDOB: boolean;
    spouseDOB: string;
    spouseAge: string;
    marriageDate: string;
    spouseLastName: string;
    marriageLocation: string;
    marriageCity: string;
    marriageState: string;
    marriagePerformedBy: string;
    divorceDate: string;
    divorceLocation: string;
    divorceCity: string;
    divorceState: string;
    spouseSSN: string;
  }[];
  hasOtherNames: boolean;
  otherNames: {
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
  }[];
  isDeadlyCondition: boolean;
  weightWithoutShoes: string;
  heightWithoutShoes: string;
  hasChildren: boolean;
  childrenNames: {
    firstName: string;
    lastName: string;
  }[];
  isTakingMedicines: boolean;
  medicines: {
    name: string;
    reason: string;
    undefined: string;
    officeName: string;
  }[];
  hospitals: {
    officeName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
    officeAddress: string;
    phone: string;
    firstSeen: string;
    lastSeen: string;
    nextAppointment: string;
    medicalConditions: string[];
  }[];
  hasPreviouslyApplied: boolean;
  hasWorkedForIncome: boolean;
  lastYearWorked: string;
  conditions: string[];
  schoolName: string;
  lastSchoolYear: string;
  schoolCity: string;
  schoolState: string;
  isSpecialEducation: boolean;
  jobListings: {
    jobTitle: string;
    jobType: string;
    startDate: string;
    endDate: string;
    hoursPerDay: string;
    daysPerWeek: string;
    amountOfPay: string;
    frequencyOfPay: string;
  }[];
  internetSpecialNotice: boolean;
  residenceAddress: string;
  unableToWorkDate: string;
  phoneType: string;
  birthCity: string;
  birthState: string;
  birthCountry: string;
  citizenshipCountry: string;
  citizenshipDate: string;
  immigrationStatus: string;
  livingArrangements: string;
  highestLevelOfEducation: string;
  marriageLocation: string;
  marriagePerformedBy: string;
  hasPriorMarriageDeath: boolean;
  hasChildrenUnder18: boolean;
  hasChildrenStillAttending: boolean;
  childCareYears: boolean;
  hasMoreThan10Children: boolean;
  medicalTests: {
    kindOfTest: string;
    dateOfTest: string;
    undefined: string;
    orderedMoreThanOnce: boolean;
  }[];
  workedTwoYearsAgo: boolean;
  workedLastYear: boolean;
  workedThisYear: boolean;
  isReceivingSickPay: boolean;
  hasReceivedTraining: boolean;
  conditionsRelatedToWork: boolean;
  employers: {
    name: string;
    hasEnded: boolean;
    address: string;
    startDate: string;
    endDate: string;
    grossWages: string;
  }[];
  selfEmployed2023: boolean;
  selfEmployedTrade2023: string;
  selfEmployedEarnings2023: boolean;
  selfEmployed2024: boolean;
  selfEmployedTrade2024: string;
  selfEmployedEarnings2024: boolean;
  selfEmployed2025: boolean;
  selfEmployedTrade2025: string;
  selfEmployedEarnings2025: boolean;
  totalEarnings2YearsAgo: number;
  workOutsideUS2YearsAgo: boolean;
  earningsTax2YearsAgo: boolean;
  monthsWorked2YearsAgo: string[];
  totalEarningsLastYear: number;
  workOutsideUSLastYear: boolean;
  earningsTaxLastYear: boolean;
  monthsWorkedLastYear: string[];
  totalEarningsThisYear: number;
  workOutsideUSThisYear: boolean;
  earningsTaxThisYear: boolean;
  monthsWorkedThisYear: string[];
  workedTaxesNotDeducted: boolean;
  isReceivingPension: boolean;
  isGovernmentPension: boolean;
  incomeSources: string[];
  incomeSourcesAmounts: {
    [key: string]: number;
  };
  isWorking: string;
  stopWorkingReason: string;
  stopWorkingOtherReasons: string;
  stopWorkingConditionDate: string;
  conditionChangesWorkActivity: boolean;
  workChangesStartDate: string;
  conditionStartDate: string;
  hasHadEarnings: boolean;
  numberOfJobs: string;
  hasAppliedForWorkersCompensation: boolean;
  previouslyAppliedFor: string[];
  previouslyAppliedOwnSSN: boolean;
  itemsOwned: string[];
  itemsOwnedAmounts: {
    [key: string]: number;
  };
  hasTrusts: boolean;
  vehicleCountLastYear: string;
  hasRealEstate: boolean;
  hasLifeInsurance: boolean;
  hasOtherResources: boolean;
  hasTransferredMoney: boolean;
}

export const inchesToHeight = (value: string) => {
  const numInches = parseInt(value, 10);

  if (isNaN(numInches)) {
    return "";
  }

  const feet = Math.floor(numInches / 12);
  const remainderInches = numInches % 12;
  return `${feet}' ${remainderInches}"`;
};

export const buildInitialApplication = (
  userDetails: any,
  intakeDetails: any,
  templates: {
    children: any[];
    marriage: any[];
    college: any[];
    education: any[];
    medications: any[];
    emergency: any[];
    work: any[];
    doctors: any[];
  }
) => {
  const current_marriage =
    templates.marriage.find((marriage: any) => !marriage.dateOfDivorce) || {};

  const prior_marriages = templates.marriage.filter(
    (marriage: any) => marriage.dateOfDivorce
  );

  const currentMedications = intakeDetails.currentMedications || "";

  const isTakingMedicines =
    (currentMedications && currentMedications !== "None") ||
    templates.medications.length > 0;

  let medications: any[] = [];
  if (isTakingMedicines) {
    const intake_medications =
      currentMedications !== "None"
        ? currentMedications
            .split(STR_ARR_DELIMITER)
            .map((medication: string) => ({
              name: medication,
            }))
        : [];
    const template_medications = templates.medications.map(
      (medication: any) => ({
        name: medication.prescriptionName,
        reason: medication.prescriptionDetails,
        officeName: medication.physicianName,
      })
    );
    medications = [...intake_medications, ...template_medications];
  }

  let schoolName = "",
    lastSchoolYear = "",
    schoolCity = "",
    schoolState = "";
  let isSpecialEducation = undefined;

  if (templates.college.length > 0) {
    const lastCollege = templates.college[templates.college.length - 1];
    schoolName = lastCollege.collegeName;
    lastSchoolYear = lastCollege.collegeEndDate
      ? dayjs(lastCollege.collegeEndDate).format(FULL_DATE_FORMAT)
      : "";
  } else if (templates.education.length > 0) {
    const lastSchool = templates.education[templates.education.length - 1];
    schoolName = lastSchool.lastHighSchoolAttended;
    lastSchoolYear = lastSchool.dateOfCertificate
      ? dayjs(lastSchool.dateOfCertificate).format(FULL_DATE_FORMAT)
      : "";
    schoolCity = lastSchool.cityOfSchool;
    schoolState = lastSchool.stateOfSchool;
    isSpecialEducation = lastSchool.isSpecialEducation;
  }
  return {
    // user details
    middleInitial: userDetails.middleName
      ? userDetails.middleName.substring(0, 1)
      : "",
    gender: userDetails.gender,
    isLowVision: userDetails.isLowVision,
    isDeniedBenefits: userDetails.isDeniedSSN,
    isUnableWork: userDetails.isUnableWork,
    livesAtMailingAddress: userDetails.isReside,
    writtenLanguage: userDetails.languageForReading,
    canReadSimpleMessage:
      userDetails.isWriteEnglish &&
      userDetails.languageForReading === "English",
    canWriteSimpleMessage:
      userDetails.isReadEnglish && userDetails.languageForReading === "English",
    canContactHelper: templates.emergency.length > 0,
    helpingContacts: templates.emergency.map((contact: any) => ({
      firstName: contact.firstName,
      lastName: contact.lastName,
      phone: format(contact.phoneNumber, PhoneNumberMask),
      address:
        contact.streetAddress + ", " + contact.city + ", " + contact.state,
      relationship: contact.relationship,
    })),
    isMarried: userDetails.isMarried || current_marriage ? true : false,
    spouseFirstName: current_marriage.spouseName
      ? current_marriage.spouseName.split(" ")[0]
      : "",
    spouseLastName: current_marriage.spouseName
      ? current_marriage.spouseName.split(" ")[1]
      : "",
    knowsSpouseSSN: current_marriage.spouseSSN ? true : false,
    spouseSSN: current_marriage.spouseSSN,
    knowsSpouseDOB: current_marriage.spouseDob ? true : false,
    spouseDOB: current_marriage.spouseDob
      ? dayjs(current_marriage.spouseDob).format(FULL_DATE_FORMAT)
      : "",
    spouseAge: current_marriage.spouseDob
      ? dayjs().diff(current_marriage.spouseDob, "years")
      : "",
    marriageDate: current_marriage.dateOfMarriage
      ? dayjs(current_marriage.dateOfMarriage).format(FULL_DATE_FORMAT)
      : "",
    marriageCity: current_marriage.cityOfMarriage,
    marriageState: current_marriage.stateOfMarriage,
    hasPriorMarriages: userDetails.isPriorMarriage,
    hasPriorMarriage10Years: prior_marriages.find(
      (marriage: any) =>
        dayjs(marriage.dateOfDivorce).diff(marriage.dateOfMarriage, "years") >=
        10
    )
      ? true
      : false,
    priorMarriages: prior_marriages.map((marriage: any) => ({
      spouseFirstName: (marriage.spouseName || "").split(" ")[0],
      spouseLastName: (marriage.spouseName || "").split(" ")[1],
      knowsSpouseSSN: marriage.spouseSSN ? true : false,
      spouseSSN: marriage.spouseSSN,
      knowsSpouseDOB: marriage.spouseDob ? true : false,
      spouseDOB: marriage.spouseDob
        ? dayjs(marriage.spouseDob).format(FULL_DATE_FORMAT)
        : "",
      spouseAge: marriage.spouseDob
        ? dayjs().diff(marriage.spouseDob, "years")
        : "",
      marriageDate: marriage.dateOfMarriage
        ? dayjs(marriage.dateOfMarriage).format(FULL_DATE_FORMAT)
        : "",
      marriageCity: marriage.cityOfMarriage,
      marriageState: marriage.stateOfMarriage,
      divorceDate: marriage.dateOfDivorce
        ? dayjs(marriage.dateOfDivorce).format(FULL_DATE_FORMAT)
        : "",
      divorceCity: marriage.cityOfDivorce,
      divorceState: marriage.stateOfDivorce,
    })),
    hasOtherNames: userDetails.isOtherName,
    otherNames: userDetails.otherNames
      ? userDetails.otherNames.split(";").map((name: string) => ({
          firstName: name,
        }))
      : [],
    isDeadlyCondition: userDetails.isDeadlyCondition,
    weightWithoutShoes: userDetails.weightWIthoutShoes,
    heightWithoutShoes: inchesToHeight(userDetails.heightWithoutShoes),
    // intake
    hasChildren:
      intakeDetails.childrenCount > 0 || templates.children.length > 0
        ? true
        : false,
    childrenNames: templates.children.map((child: any) => ({
      firstName: child.firstName,
      lastName: child.lastName,
    })),
    isTakingMedicines:
      intakeDetails.currentMedications !== "None" ||
      templates.medications.length > 0
        ? true
        : false,
    medicines: medications,
    hospitals: templates.doctors.map((doctor: any) => ({
      officeName: doctor.hospitalName,
      firstName: doctor.physicianName,
      officeAddress: doctor.physicianAddress,
      phone: format(doctor.physicianPhoneNumber, PhoneNumberMask),
      firstSeen: doctor.treatmentStartDate
        ? dayjs(doctor.treatmentStartDate).format(FULL_DATE_FORMAT)
        : "",
      lastSeen: doctor.lastVisitDate
        ? dayjs(doctor.lastVisitDate).format(FULL_DATE_FORMAT)
        : "",
      nextAppointment: doctor.nextScheduledVisitDate
        ? dayjs(doctor.nextScheduledVisitDate).format(FULL_DATE_FORMAT)
        : "",
    })),
    hasPreviouslyApplied:
      intakeDetails.hasPreviouslyFiledForSocialSecurityBenefits,
    hasWorkedForIncome: intakeDetails.lastDateOfEmployment ? true : false,
    lastYearWorked: intakeDetails.lastDateOfEmployment
      ? dayjs(intakeDetails.lastDateOfEmployment).format("YYYY")
      : "",
    conditions: [
      ...(intakeDetails.mentalHealthConditions || "").split(STR_ARR_DELIMITER),
      ...(intakeDetails.physicalHealthConditions || "").split(
        STR_ARR_DELIMITER
      ),
      ...(intakeDetails.mentalHealthSymtops || "").split(STR_ARR_DELIMITER),
    ],
    schoolName,
    lastSchoolYear,
    schoolCity,
    schoolState,
    isSpecialEducation,
    jobListings: templates.work.map((job: any) => ({
      jobTitle: job.jobTitle,
      jobType: job.companyName,
      startDate: dayjs(job.employedFrom).format(FULL_DATE_FORMAT),
      endDate: dayjs(job.employedTo).format(FULL_DATE_FORMAT),
      hoursPerDay: job.hoursPerDay,
      daysPerWeek: job.daysPerWeek,
      amountOfPay: job.payAmount,
      frequencyOfPay: job.payFrequency,
    })),
  };
};

const SUFFIX_SELECT_OPTIONS = [
  "Miss",
  "Mrs",
  "Ms",
  "Mr",
  "H.M",
  "H.I.H",
  "H.R.H",
  "Dr",
  "Esq",
  "Jr",
  "Sr",
  "l",
  "ll",
  "lll",
];

// Array of all months in a year
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MARRIAGE_PERFORMED_BY = [
  "Clergy or Public Official",
  "Other Ceremonial",
  "Common Law",
];

const TWO_YEARS_AGO = new Date().getFullYear() - 2;
const LAST_YEAR = new Date().getFullYear() - 1;
const THIS_YEAR = new Date().getFullYear();

export const FORM_CATEGORIES = {
  GENERAL: "general",
  FAMILY: "family",
  MEDICAL: "medical",
  EMPLOYMENT: "employment",
  BENEFITS: "benefits",
};

type FormCategory = (typeof FORM_CATEGORIES)[keyof typeof FORM_CATEGORIES];

export const getApplicationFields = (
  formValues: ApplicationValues,
  category?: FormCategory
) => {
  return [
    {
      title: "Information About Applicant",
      category: FORM_CATEGORIES.GENERAL,
      fields: [
        {
          label: "What is your middle initial?",
          databaseKey: "middleInitial",
          type: "Input",
          placeholder: "A.",
        },
        // {
        //   label: "Last Name",
        //   databaseKey: "lastName",
        //   type: "Input",
        //   placeholder: "Doe",
        //   isLocked: true,
        // },
        // {
        //   label: "Social Security Number",
        //   databaseKey: "ssn",
        //   type: "SSNInput",
        //   isLocked: true,
        // },
        // {
        //   label: "Date of Birth",
        //   databaseKey: "dateOfBirth",
        //   type: "FullDate",
        //   isLocked: true,
        // },
        {
          label: "Gender",
          databaseKey: "gender",
          type: "Select",
          options: ["Male", "Female"],
        },
        {
          label:
            "Are you blind or do you have low vision even with glasses or contacts?",
          type: "YesNoRadioGroup",
          databaseKey: "isLowVision",
        },
        {
          label:
            "Have you visited and made a selection on the Internet Special Notice Option page?",
          type: "YesNoRadioGroup",
          databaseKey: "internetSpecialNotice",
        },
        {
          label:
            "During the last 14 months, have you been unable to do any substantial gainful work because of ilinesses, injuries or conditions that have lasted or are expected to last at least 12 months or can be expected to result in death?",
          type: "YesNoRadioGroup",
          databaseKey: "isUnableWork",
        },
        {
          label:
            "Approximately when do you believe your illnesses, injuries or conditions became severe enough to significantly reduce your ability to work or keep you from working?",
          type: "FullDate",
          databaseKey: "unableToWorkDate",
          displayIf: "isUnableWork",
        },
        {
          label:
            "Have you previously been denied for Social Security benefits or Supplemental Security Income (SSI) in the last 60 days?",
          type: "YesNoRadioGroup",
          databaseKey: "isDeniedBenefits",
        },
        {
          label:
            "Have you been diagnosed with any specific condition that is expected to end in death?",
          type: "YesNoRadioGroup",
          databaseKey: "isDeadlyCondition",
        },
      ],
    },
    {
      title: "Contact Information",
      category: FORM_CATEGORIES.GENERAL,
      fields: [
        // {
        //   label: "Mailing Address",
        //   databaseKey: "mailingAddress",
        //   type: "AddressAutocomplete",
        //   isLocked: true,
        // },
        {
          label: "Do you live at your mailing address?",
          type: "YesNoRadioGroup",
          databaseKey: "livesAtMailingAddress",
        },
        {
          label: "Residence Address",
          hideIf: "livesAtMailingAddress",
          type: "AddressAutocomplete",
          databaseKey: "residenceAddress",
        },
        // {
        //   label: "Daytime Phone Number",
        //   databaseKey: "phoneNumber",
        //   type: "PhoneNumberInput",
        //   isLocked: true,
        // },
        {
          label: "Phone Type",
          type: "Select",
          options: ["Mobile", "Home", "Work", "TTY", "Other"],
          databaseKey: "phoneType",
        },
        // {
        //   label: "Email Address",
        //   databaseKey: "email",
        //   type: "Input",
        //   isLocked: true,
        // },
      ],
    },
    {
      title: "Birth and Citizenship Information",
      category: FORM_CATEGORIES.GENERAL,
      fields: [
        {
          label: "Provide place of birth as it was known at the time of birth.",
          databaseKey: "placeOfBirth",
          type: "Radio",
          options: ["United States or U.S. Territory", "Other"],
        },
        {
          label: "Enter name of city where you were born",
          databaseKey: "birthCity",
          type: "Input",
          conditionalFn: (formValues: any) =>
            formValues.placeOfBirth === "United States or U.S. Territory",
        },
        {
          label: "Enter name of state where you were born",
          databaseKey: "birthState",
          type: "StateSelect",
          conditionalFn: (formValues: any) =>
            formValues.placeOfBirth === "United States or U.S. Territory",
        },
        {
          label: "Enter name of foreign country where you were born",
          databaseKey: "birthCountry",
          type: "CountrySelect",
          conditionalFn: (formValues: any) =>
            formValues.placeOfBirth === "Other",
        },
        {
          label: "Are you a US citizen?",
          type: "YesNoRadioGroup",
          databaseKey: "isUSCitizen",
        },
        {
          label: "Type of Citizenship",
          type: "Select",
          displayIf: "isUSCitizen",
          databaseKey: "typeOfCitizenship",
          options: [
            "Born inside U.S.",
            "Born outside U.S.",
            "Naturalized Citizen)",
          ],
        },
      ],
    },
    {
      title: "Other Names",
      category: FORM_CATEGORIES.GENERAL,
      fields: [
        {
          label: "Have you used any other names?",
          databaseKey: "hasOtherNames",
          type: "YesNoRadioGroup",
        },
        {
          label: "Other Names",
          displayIf: "hasOtherNames",
          description: "Please add any other names you have used.",
          databaseKey: "otherNames",
          type: "ListManager",
          heading: "Other Name",
          children: [
            {
              label: "First Name",
              databaseKey: "firstName",
              placeholder: "John",
              type: "Input",
            },
            {
              label: "Middle Name",
              databaseKey: "middleName",
              placeholder: "Allen",
              type: "Input",
            },
            {
              label: "Last Name",
              databaseKey: "lastName",
              placeholder: "Doe",
              type: "Input",
            },
            {
              label: "Suffix",
              databaseKey: "suffix",
              type: "Select",
              options: SUFFIX_SELECT_OPTIONS,
            },
          ],
        },
      ],
    },
    {
      title: "Marriage",
      category: FORM_CATEGORIES.FAMILY,
      fields: [
        {
          label: "Are you currently married?",
          databaseKey: "isMarried",
          type: "YesNoRadioGroup",
        },
        {
          label: "Spouse's first name at birth",
          databaseKey: "spouseFirstName",
          type: "Input",
          displayIf: "isMarried",
        },
        {
          label: "Spouse's last name at birth",
          databaseKey: "spouseLastName",
          type: "Input",
          displayIf: "isMarried",
        },
        {
          label: "Do you know your spouse's SSN?",
          databaseKey: "knowsSpouseSSN",
          type: "YesNoRadioGroup",
          displayIf: "isMarried",
        },
        {
          label: "Spouse's Social Security Number",
          databaseKey: "spouseSSN",
          type: "SSNInput",
          displayIf: "knowsSpouseSSN",
        },
        {
          label: "Do you know your spouse's date of birth?",
          databaseKey: "knowsSpouseDOB",
          type: "YesNoRadioGroup",
          displayIf: "isMarried",
        },
        {
          label: "Spouse's date of birth",
          databaseKey: "spouseDOB",
          type: "FullDate",
          conditionalFn: (formValues: any) =>
            formValues.knowsSpouseDOB && formValues.isMarried,
        },
        {
          label: "Spouse's Age",
          databaseKey: "spouseAge",
          placeholder: "45",
          type: "NumberInput",
          displayIf: "isMarried",
        },
        {
          label: "Date of Marriage",
          databaseKey: "marriageDate",
          type: "FullDate",
          displayIf: "isMarried",
        },
        {
          label: "US or US Territory or Other",
          databaseKey: "marriageLocation",
          type: "Select",
          options: ["US", "US Territory", "Other"],
          displayIf: "isMarried",
        },
        {
          label: "Where (Name of City)",
          databaseKey: "marriageCity",
          type: "Input",
          displayIf: "isMarried",
        },
        {
          label: "Where (Name of State)",
          databaseKey: "marriageState",
          type: "StateSelect",
          displayIf: "isMarried",
        },
        {
          label: "Marriage performed by",
          databaseKey: "marriagePerformedBy",
          type: "Select",
          options: MARRIAGE_PERFORMED_BY,
          displayIf: "isMarried",
        },
      ],
    },
    {
      title: "Prior Marriages",
      category: FORM_CATEGORIES.FAMILY,
      fields: [
        {
          label: "Did you have any prior marriages?",
          databaseKey: "hasPriorMarriages",
          type: "YesNoRadioGroup",
        },
        {
          label:
            "Did you have any prior marriage that lasted at least 10 years?",
          type: "YesNoRadioGroup",
          displayIf: "hasPriorMarriages",
          databaseKey: "hasPriorMarriage10Years",
        },
        {
          label: `Did you have any prior marriage that ended due to your spouse's death?`,
          type: "YesNoRadioGroup",
          displayIf: "hasPriorMarriages",
          databaseKey: "hasPriorMarriageDeath",
        },
        {
          label: "Prior Marriage Details",
          displayIf: "hasPriorMarriages",
          description:
            "Please add any prior marriages you have been involved in.",
          databaseKey: "priorMarriages",
          type: "ListManager",
          heading: "Prior Marriage",
          children: [
            {
              label: "Spouse's first name at birth",
              databaseKey: "spouseFirstName",
              type: "Input",
            },
            {
              label: "Spouse's last name at birth",
              databaseKey: "spouseLastName",
              type: "Input",
            },
            {
              label: "Do you know your spouse's SSN?",
              databaseKey: "knowsSpouseSSN",
              type: "YesNoRadioGroup",
            },
            {
              label: "Prior Spouse's Social Security Number",
              databaseKey: "spouseSSN",
              type: "SSNInput",
              displayIf: "knowsSpouseSSN",
            },
            {
              label: "Do you know your spouse's date of birth?",
              databaseKey: "knowsSpouseDOB",
              type: "YesNoRadioGroup",
            },
            {
              label: "Prior Spouse's Date of Birth",
              databaseKey: "spouseDOB",
              type: "FullDate",
              displayIf: "knowsSpouseDOB",
            },
            {
              label: "Prior Spouse's Age",
              databaseKey: "spouseAge",
              type: "NumberInput",
              placeholder: "45",
            },
            {
              label: "Date of Marriage",
              databaseKey: "marriageDate",
              type: "FullDate",
            },
            {
              label: "US or US Territory or Other",
              databaseKey: "marriageLocation",
              type: "Select",
              options: ["US", "US Territory", "Other"],
            },
            {
              label: "Where (Name of City)",
              databaseKey: "marriageCity",
              type: "Input",
            },
            {
              label: "Where (Name of State)",
              databaseKey: "marriageState",
              type: "StateSelect",
            },
            {
              label: "Marriage Performed By",
              databaseKey: "marriagePerformedBy",
              type: "Select",
              options: MARRIAGE_PERFORMED_BY,
            },
            {
              label: "Date of Divorce",
              databaseKey: "divorceDate",
              type: "FullDate",
            },
            {
              label: "Place of Divorce",
              databaseKey: "divorceLocation",
              type: "Select",
              options: ["US", "US Territory", "Other"],
            },
            {
              label: "City of Divorce",
              databaseKey: "divorceCity",
              type: "Input",
            },
            {
              label: "State of Divorce",
              databaseKey: "divorceState",
              type: "StateSelect",
            },
          ],
        },
      ],
    },
    {
      title: "Children",
      category: FORM_CATEGORIES.FAMILY,
      fields: [
        {
          label: "Do you have any children?",
          description: `These questions also apply to children born out of wedlock, adopted chidren, and step-children. In certain cases, grandchildren and step-grandchildren who live with you may qualify for benefits. Note: If a child reached the age limit within the last tweive months, please answer "Yes."`,
          type: "YesNoRadioGroup",
          databaseKey: "hasChildren",
        },
        {
          label: "Are any of your children unmarried and under age 18?",
          displayIf: "hasChildren",
          databaseKey: "hasChildrenUnder18",
          type: "YesNoRadioGroup",
        },
        {
          label:
            "Are any of your children unmarried, aged 18 to 19, and still attending elementary or secondary school (below college level) full time?",
          displayIf: "hasChildren",
          databaseKey: "hasChildrenStillAttending",
          type: "YesNoRadioGroup",
        },
        {
          label:
            "Do you have any years with no earnings in which you were caring for a child under the age of 3?",
          databaseKey: "childCareYears",
          type: "YesNoRadioGroup",
          displayIf: "hasChildren",
        },
        {
          label: "Do you have more than 10 children in the categories above?",
          type: "YesNoRadioGroup",
          displayIf: "hasChildren",
          databaseKey: "hasMoreThan10Children",
        },
        {
          label: "Children",
          displayIf: "hasChildren",
          description: "Please add the names of your children.",
          databaseKey: "childrenNames",
          type: "ListManager",
          heading: "Child Information",
          children: [
            {
              label: "First Name",
              databaseKey: "firstName",
              placeholder: "John",
              type: "Input",
            },
            {
              label: "Last Name",
              databaseKey: "lastName",
              placeholder: "Doe",
              type: "Input",
            },
          ],
        },
      ],
    },
    {
      title: "Total Earnings",
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label: "Have you ever worked at a job for income?",
          type: "YesNoRadioGroup",
          databaseKey: "hasWorkedForIncome",
        },
        {
          label: `If you were neither working for an employer nor self-employed in ${TWO_YEARS_AGO} or later, when was the last year worked?`,
          type: "NumberInput",
          placeholder: "2019",
          displayIf: "hasWorkedForIncome",
          databaseKey: "lastYearWorked",
        },
      ],
    },
    {
      title: "Employment Details",
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label: `Did you work for an employer in ${TWO_YEARS_AGO}?`,
          type: "YesNoRadioGroup",
          databaseKey: "workedTwoYearsAgo",
        },
        {
          label: `Did you work or will you work for an employer in ${LAST_YEAR}?`,
          type: "YesNoRadioGroup",
          databaseKey: "workedLastYear",
        },
        {
          label: `Will you work for an employer in ${THIS_YEAR}?`,
          type: "YesNoRadioGroup",
          databaseKey: "workedThisYear",
        },
        {
          label: `Are you receiving, or do you expect to receive sick pay in ${dayjs().format(
            "MMMM"
          )} ${dayjs().format("YYYY")}?`, // TODO: figure out current month
          type: "YesNoRadioGroup",
          databaseKey: "isReceivingSickPay",
        },
        {
          label: "Have you received any type of training?",
          description: "(specialized job, trade, or vocational training)",
          type: "YesNoRadioGroup",
          databaseKey: "hasReceivedTraining",
        },
        {
          label:
            "Are your illnesses, injuries or conditions related to work in any way?",
          type: "YesNoRadioGroup",
          databaseKey: "conditionsRelatedToWork",
        },
      ],
    },
    {
      title: "Details of Employer Information",
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label:
            "Enter below the names of all the persons, companies, or Government agencies for whom you have worked this year and last year.",
          type: "ListManager",
          databaseKey: "employers",
          heading: "Details of Employer",
          children: [
            {
              label: "Employer Name",
              databaseKey: "name",
              placeholder: "Acme Corp",
              type: "Input",
            },
            {
              label: "Employer Address",
              databaseKey: "address",
              placeholder: "Acme Corp",
              type: "AddressAutocomplete",
            },
            {
              label: "Start Date",
              databaseKey: "startDate",
              type: "FullDate",
            },
            {
              label: "Has employment ended?",
              databaseKey: "hasEnded",
              type: "YesNoRadioGroup",
            },
            {
              label: "End Date",
              displayIf: "hasEnded",
              databaseKey: "endDate",
              type: "FullDate",
            },
            {
              label:
                "How much do you expect your gross wages will be? Provide known wages or a best estimate.",
              description:
                "Gross wages are the amount of pay before anything is taken out. For example: taxes, health insurance.",
              databaseKey: "grossWages",
              type: "DollarInput",
            },
          ],
        },
      ],
    },
    {
      title: "Self-Employment Details",
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label: `Were you self-employed in ${TWO_YEARS_AGO}?`,
          databaseKey: "selfEmployed2023",
          type: "YesNoRadioGroup",
        },
        {
          label: `In what type of trade/business were you self-employed in ${TWO_YEARS_AGO}?`,
          description: "For example: storekeeper, farmer, physician.",
          displayIf: "selfEmployed2023",
          type: "Input",
          databaseKey: "selfEmployedTrade2023",
        },
        {
          label:
            "Were your net earnings from the trade or business $400 or more?",
          type: "YesNoRadioGroup",
          displayIf: "selfEmployed2023",
          databaseKey: "selfEmployedEarnings2023",
        },
        {
          label: `Were you self-employed in ${LAST_YEAR}?`,
          type: "YesNoRadioGroup",
          databaseKey: "selfEmployed2024",
        },
        {
          label: `In what type of trade/business were you self-employed in ${LAST_YEAR}?`,
          description: "For example: storekeeper, farmer, physician.",
          displayIf: "selfEmployed2024",
          databaseKey: "selfEmployedTrade2024",
          type: "Input",
        },
        {
          label: `Were your net earnings in ${LAST_YEAR} from the trade or business $400 or more?`,
          type: "YesNoRadioGroup",
          displayIf: "selfEmployed2024",
          databaseKey: "selfEmployedEarnings2024",
        },
        {
          label: `Do you expect to be self-employed in ${THIS_YEAR}?`,
          type: "YesNoRadioGroup",
          databaseKey: "selfEmployed2025",
        },
        {
          label:
            "In what type of trade/business will you be self-employed? (For example, storekeeper, farmer, physician)",
          description: "For example: storekeeper, farmer, physician.",
          displayIf: "selfEmployed2025",
          type: "Input",
          databaseKey: "selfEmployedTrade2025",
        },
        {
          label:
            "Will your net earnings from the trade or business $400 or more?",
          type: "YesNoRadioGroup",
          displayIf: "selfEmployed2025",
          databaseKey: "selfEmployedEarnings2025",
        },
      ],
    },
    {
      title: "Total Earnings " + TWO_YEARS_AGO,
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label: `Show the total of all wages and tips earned in ${TWO_YEARS_AGO}.`,
          description:
            "Include net income from self-employment. Estimate if necessary.",
          type: "DollarInput",
          databaseKey: "totalEarnings2YearsAgo",
          placeholder: "12,000",
          decimalsLimit: 0,
        },
        {
          label: `Did you work outside the United States for salary, wages, or self-employment in ${TWO_YEARS_AGO}?`,
          type: "YesNoRadioGroup",
          databaseKey: "workOutsideUS2YearsAgo",
        },
        {
          label:
            "Were all of your earnings subject to U.S. Social Security tax?",
          type: "YesNoRadioGroup",
          databaseKey: "earningsTax2YearsAgo",
        },
        {
          label: "Select all the months in which you worked 45 hours or more.",
          type: "MultiCheckbox",
          databaseKey: "monthsWorked2YearsAgo",
          options: MONTHS,
        },
      ],
    },
    {
      title: "Total Earnings " + LAST_YEAR,
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label: `Show the total of all wages and tips earned in ${LAST_YEAR}.`,
          description:
            "Include net income from self-employment. Estimate if necessary.",
          type: "DollarInput",
          databaseKey: "totalEarningsLastYear",
          placeholder: "12,000",
          decimalsLimit: 0,
        },
        {
          label: `Did you work outside the United States for salary, wages, or self-employment in ${LAST_YEAR}?`,
          type: "YesNoRadioGroup",
          databaseKey: "workOutsideUSLastYear",
        },
        {
          label:
            "Were all of your earnings subject to U.S. Social Security tax?",
          type: "YesNoRadioGroup",
          databaseKey: "earningsTaxLastYear",
        },
        {
          label: "Select all the months in which you worked 45 hours or more.",
          type: "MultiCheckbox",
          databaseKey: "monthsWorkedLastYear",
          options: MONTHS,
        },
      ],
    },
    {
      title: "Total Earnings " + THIS_YEAR,
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label: `Show the total of all wages and tips earned in ${THIS_YEAR}.`,
          description:
            "Include net income from self-employment. Estimate if necessary.",
          type: "DollarInput",
          databaseKey: "totalEarningsThisYear",
          placeholder: "12,000",
          decimalsLimit: 0,
        },
        {
          label: `Did you work outside the United States for salary, wages, or self-employment in ${THIS_YEAR}?`,
          type: "YesNoRadioGroup",
          databaseKey: "workOutsideUSThisYear",
        },
        {
          label:
            "Were all of your earnings subject to U.S. Social Security tax?",
          type: "YesNoRadioGroup",
          databaseKey: "earningsTaxThisYear",
        },
        {
          label: "Select all the months in which you worked 45 hours or more.",
          type: "MultiCheckbox",
          databaseKey: "monthsWorkedThisYear",
          options: MONTHS.filter(
            (month) => MONTHS.indexOf(month) <= new Date().getMonth()
          ),
        },
      ],
    },
    {
      title: "Work Not Covered By Social Security",
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label:
            "Did you ever work in a job where US Social Security taxes were not deducted or withheld?",
          type: "YesNoRadioGroup",
          databaseKey: "workedTaxesNotDeducted",
        },
        {
          label:
            "Are you receiving a pension or annuity based on this non-covered work?",
          type: "YesNoRadioGroup",
          databaseKey: "isReceivingPension",
        },
        {
          label: "Is the pension or annuity based on government employment?",
          type: "YesNoRadioGroup",
          displayIf: "isReceivingPension",
          databaseKey: "isGovernmentPension",
        },
      ],
    },
    {
      title: "Previous Applications",
      category: FORM_CATEGORIES.BENEFITS,
      fields: [
        {
          label:
            "Have you previously applied for Medicare, Social Security, or Supplemental Security Income (SSI) benefits?",
          type: "YesNoRadioGroup",
          databaseKey: "hasPreviouslyApplied",
        },
        {
          label: "Which type(s) of benefits?",
          description: "Please select all that apply.",
          displayIf: "hasPreviouslyApplied",
          type: "MultiCheckbox",
          databaseKey: "previouslyAppliedFor",
          options: [
            "Medicare benefits",
            "Social Security benefits",
            "Supplemental Security Income benefits",
          ],
        },
        {
          label: "Did you previously apply on your own Social Security Number?",
          type: "YesNoRadioGroup",
          databaseKey: "previouslyAppliedOwnSSN",
          displayIf: "hasPreviouslyApplied",
        },
        {
          label: `Have you applied or intend to apply for any workers' compensation or other public disability benefits?`,
          type: "YesNoRadioGroup",
          databaseKey: "hasAppliedForWorkersCompensation",
        },
      ],
    },
    {
      title: "Immigration Status (SSI)",
      category: FORM_CATEGORIES.GENERAL,
      fields: [
        {
          label: "Country of Citizenship",
          type: "CountrySelect",
          databaseKey: "citizenshipCountry",
        },
        {
          label: `What date did you become a citizen of this country?`,
          description:
            "If you were a citizen at birth, provide your birth date.",
          type: "FullDate",
          displayIf: "citizenshipCountry",
          databaseKey: "citizenshipDate",
        },
        {
          label: "Select your immigration status within the United States:",
          type: "Select",
          databaseKey: "immigrationStatus",
          options: [
            "Alien with deportation withheld",
            "Amerasian Immigrant",
            "Asylee",
            "Battered alien or alien with a battered parent or child",
            "Canadian-born American Indian",
            "Conditional entrant",
            "Cuban or Haitian entrant",
            "Iraqi or Afghan special immigrant",
            "Lawful permanent resident",
            "Non-U.S. citizen member of a federally recognized Indian tribe",
            "Parolee",
            "Refugee",
            "Victim of severe forms of trafficking (VSFT)",
            "Other",
            "Not lawfully present",
          ],
          conditionalFn: (formValues: any) =>
            formValues.citizenshipCountry !== "United States",
        },
      ],
    },
    {
      title: "Living Arrangements (SSI)",
      category: FORM_CATEGORIES.GENERAL,
      fields: [
        {
          label: "Select the option that best describes where you live:",
          type: "Radio",
          databaseKey: "livingArrangements",
          options: [
            "House, apartment, mobile home, houseboat",
            "Room in commercial establishment (example: hotel or motel)",
            "Room in private residence",
            "Institution (example: nursing home, rehabilitation center, hospital, school, or jail)",
            "Non-institutional care (example: placed by an agency in foster care, adult foster care, retirement home, or family care by an agency)",
            "Transient or homeless",
          ],
        },
      ],
    },
    {
      title: "Income Sources",
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label:
            "Select the income source(s) you received or expects to receive in December 2024",
          description:
            "If you do not know the exact amount, provide your best estimate.",
          type: "MultiCheckbox",
          databaseKey: "incomeSources",
          options: [
            "Alimony",
            "Child support",
            "Gambling winnings or prizes",
            "Gifts",
            "Monthly cash from family or friend",
            "Pensions or other retirement payments",
            "State disability insurance",
            "State or local assistance",
            "Temporary Assistance for Needy Families (TANF)",
            "Unemployment compensation",
            "Veterans Affairs (VA) benefits",
            "Workers compensation",
            "Other",
            "None of the above",
          ],
        },
        {
          label: "Per selected item, enter an estimate of how much",
          description:
            "If you do not know the exact amount, provide your best estimate.",
          databaseKey: "incomeSourcesAmounts",
          type: "GeneratedList",
          renderListFields: (formValues: any) => {
            return (formValues.incomeSources || [])
              .filter((value: string) => value !== "")
              .map((source: string) => {
                return {
                  label: source,
                  type: "DollarInput",
                  databaseKey: "incomeSourcesAmounts",
                  placeholder: "Total amount",
                  parentDatabaseKey: "incomeSources",
                  parentFieldType: "GeneratedList",
                };
              });
          },
        },
      ],
    },
    {
      title: "Resources (SSI)",
      category: FORM_CATEGORIES.BENEFITS,
      fields: [
        {
          label:
            "Select the items you own, either alone or with other people, as of December 1, 2024",
          description:
            "If you do not know the exact amount, provide your best estimate.",
          type: "MultiCheckbox",
          databaseKey: "itemsOwned",
          options: [
            "Cash (at home, with you, or anywhere else)",
            "Financial institution accounts (examples: checking or savings accounts, credit union, holiday club, time deposits, Individual Indian Money account, or Direct Express)",
            "Stocks",
            "Mutual funds",
            "OU.S. savings bonds",
            "None of the above",
          ],
        },
        {
          label: "Per selected item, enter an estimate of how much",
          description:
            "If you do not know the exact amount, provide your best estimate.",
          databaseKey: "itemsOwnedAmounts",
          type: "GeneratedList",
          renderListFields: (formValues: any) => {
            return (formValues.itemsOwned || [])
              .filter((value: string) => value !== "")
              .map((source: string) => {
                return {
                  label: source === "None of the above" ? "Other" : source,
                  type: "DollarInput",
                  databaseKey: "itemsOwnedAmounts",
                  placeholder: "Total amount",
                  parentDatabaseKey: "itemsOwned",
                  parentFieldType: "GeneratedList",
                };
              });
          },
        },
      ],
    },
    {
      title: "Additional Resources (SSI)",
      category: FORM_CATEGORIES.BENEFITS,
      fields: [
        {
          label:
            "Are you the owner or beneficiary of any trusts as of December 1, 2024?",
          description:
            "This includes, but is not limited to, special needs or pooled trusts, or any other trust where funds are being held for your benefit.",
          type: "YesNoRadioGroup",
          databaseKey: "hasTrusts",
        },
        {
          label:
            "How many vehicles do you have in your name as of December 1, 2024?",
          description:
            "Examples of vehicles: automobiles, trucks, motorcycles, campers, or boats. Include those secured by loan or lease.",
          type: "NumberInput",
          databaseKey: "vehicleCountLastYear",
        },
        {
          label:
            "Not counting the home where you live, do you own any real estate or property, either alone or with other people, as of December 1, 2024?",
          description:
            "This includes land, buildings, and homes in the U.S. or a foreign country.",
          databaseKey: "hasRealEstate",
          type: "YesNoRadioGroup",
        },
        {
          label:
            "Do you own any life insurance policies as of December 1, 2024?",
          type: "YesNoRadioGroup",
          databaseKey: "hasLifeInsurance",
        },
        {
          label:
            "Do you own anything else that could be turned into cash and used to pay for food and shelter as of December 1, 2024?",
          description:
            "Do not count your household goods (such as furniture, appliances, electronic devices) or personal items (such as clothes, personal jewelry, pets)",
          databaseKey: "hasOtherResources",
          type: "YesNoRadioGroup",
        },
        {
          label:
            "Since December 1, 2021, have you transferred title, or given away any money or property - either alone or with other people? This includes money or property in foreign countries.",
          type: "YesNoRadioGroup",
          databaseKey: "hasTransferredMoney",
        },
      ],
    },
    {
      title: "Medical Conditions",
      category: FORM_CATEGORIES.MEDICAL,
      fields: [
        {
          label:
            "Separately list each physical and/or mental condition that limits your ability to work.",
          description: `If you have cancer, please include the type and stage. We will consider these conditions whether or not you have been receiving treatment. Use your own words if you do not know the medical names.`,
          type: "ConditionsInput",
          databaseKey: "conditions",
        },
        {
          label: "What is your height?",
          type: "Input",
          placeholder: "5' 10\"",
          databaseKey: "heightWithoutShoes",
        },
        {
          label: "What is your weight?",
          description: "Without shoes, in pounds (lbs).",
          placeholder: "180",
          type: "NumberInput",
          databaseKey: "weightWithoutShoes",
        },
      ],
    },
    {
      title: "Your Claim Contacts",
      category: FORM_CATEGORIES.GENERAL,
      numberOfRecords: 2,
      fields: [
        {
          label:
            "Is there someone we can contact who can help with your claim, if needed?",
          description: "Examples include a family member, friend, or neighbor.",
          type: "YesNoRadioGroup",
          databaseKey: "canContactHelper",
        },
        {
          label: "Contact Information",
          type: "ListManager",
          displayIf: "canContactHelper",
          heading: "Contact",
          databaseKey: "helpingContacts",
          children: [
            {
              label: "First Name",
              databaseKey: "firstName",
              placeholder: "John",
              type: "Input",
            },
            {
              label: "Middle Name",
              databaseKey: "middleName",
              placeholder: "Allen",
              type: "Input",
            },
            {
              label: "Last Name",
              databaseKey: "lastName",
              lastName: "Doe",
              type: "Input",
            },
            {
              label: "Suffix",
              type: "Select",
              databaseKey: "suffix",
              options: SUFFIX_SELECT_OPTIONS,
            },
            {
              label: "Full Address",
              databaseKey: "address",
              type: "AddressAutocomplete",
            },
            {
              label: "Telephone",
              databaseKey: "phone",
              type: "PhoneNumberInput",
            },
            {
              label: "Can this person speak and understand English?",
              type: "YesNoRadioGroup",
              databaseKey: "speaksEnglish",
              input: "Yes",
            },
            {
              label: "Relationship",
              type: "Select",
              databaseKey: "relationship",
              options: [
                "Family Member",
                "Attorney Representative",
                "Non-Attorney Representative",
                "Government Agency",
                "Non-Profit Organization/Legal Aid Group",
                "Health Service Agency/Hospital",
                "Nursing Care Facility",
                "Friend",
                "Other",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Hospitals and Clinics",
      category: FORM_CATEGORIES.MEDICAL,
      description: "Top-level descroptions",
      fields: [
        {
          label: "List of Hospitals & Clinics",
          description:
            "If you don't have any doctors/healthcare providers to enter, click the Next button.",
          type: "ListManager",
          databaseKey: "hospitals",
          header: "Hospitals & Clinics",
          children: [
            {
              label: "Name of Office",
              type: "Input",
              databaseKey: "officeName",
            },
            {
              label: "Provider: First Name",
              type: "Input",
              databaseKey: "firstName",
            },
            {
              label: "Provider: Middle Name",
              type: "Input",
              databaseKey: "middleName",
            },
            {
              label: "Provider: Last Name",
              type: "Input",
              databaseKey: "lastName",
            },
            {
              label: "Provider: Suffix",
              databaseKey: "suffix",
              type: "Select",
              options: SUFFIX_SELECT_OPTIONS,
            },
            {
              label: "Office: Full Address",
              type: "AddressAutocomplete",
              databaseKey: "officeAddress",
            },
            {
              label: "Office: Telephone",
              type: "PhoneNumberInput",
              databaseKey: "phone",
            },
            {
              label: "First Seen Date",
              type: "FullDate",
              databaseKey: "firstSeen",
            },
            {
              label: "Last Seen Date",
              type: "FullDate",
              databaseKey: "lastSeen",
            },
            {
              label: "Date of Next Appointment",
              type: "FullDate",
              databaseKey: "nextAppointment",
            },
            {
              label:
                "What medical conditions were treated or evaluated by this hospital/clinic?",
              type: "MultiCheckbox",
              databaseKey: "medicalConditions",
              options: formValues.conditions || [],
            },
          ],
        },
      ],
    },
    // { title: "Treatment Dates at this Hospital/Clinic n", fields: [] },
    // {
    //   title: "Medical Conditions Treated by this Hospital/Clinic n",
    //   fields: [],
    // },
    {
      title: "Medical Tests",
      category: FORM_CATEGORIES.MEDICAL,
      fields: [
        {
          label: "Please list any medical tests you have had.",
          type: "ListManager",
          heading: "Medical Test",
          databaseKey: "medicalTests",
          children: [
            {
              label: "Kind of Test",
              type: "Select",
              databaseKey: "kindOfTest",
              options: [
                "Biopsy",
                "Blood Test (Not HIV)",
                "Breathing Test",
                "Cardiac Cathering",
                "EEG (Brain Wave Test)",
                "EKG (Heart Test)",
                "Hearing Test",
                "HIV Test",
                "MRI/CT Scan",
                "Psychological/IQ Test",
                "Speech/Language Test",
                "Treadmill (Exercise Test)",
                "Vision Test",
                "X-ray",
              ],
            },
            {
              label: "Date of Test",
              type: "FullDate",
              databaseKey: "dateOfTest",
            },
            {
              label: "Who sent them or will send them for this test?",
              type: "Select",
              options: [
                ...(formValues.hospitals || []).map(
                  (hospital: any) => hospital.officeName
                ),
                "No one ordered this test",
                "I don't know",
                "Nothing Entered",
                "Other Doctor/Healthcare Provider",
                "Other Hospital/Clinic",
              ],
            },
            {
              label: "This provider ordered this test more than once.",
              type: "YesNoRadioGroup",
              databaseKey: "orderedMoreThanOnce",
            },
          ],
        },
      ],
    },
    {
      title: "Medicines",
      category: FORM_CATEGORIES.MEDICAL,
      fields: [
        {
          label:
            "Are you currently taking any prescription or non-prescription medicine(s)?",
          databaseKey: "isTakingMedicines",
          type: "YesNoRadioGroup",
        },
        {
          label: "Medicine",
          displayIf: "isTakingMedicines",
          type: "ListManager",
          heading: "Medicine",
          databaseKey: "medicines",
          children: [
            {
              label: "Medicine Name",
              type: "Input",
              databaseKey: "name",
            },
            {
              label: "Reason for Medicine",
              type: "Input",
              databaseKey: "reason",
            },
            {
              label: "Doctor Name",
              description: "If prescribed",
              databaseKey: "officeName",
              type: "Select",
              options: [
                ...(formValues.hospitals || []).map(
                  (hospital: any) => hospital.officeName
                ),
                "Other Doctor/Healthcare Provider",
                "Other Hospital/Clinic",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Work Status",
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label: "Are you currently working?",
          databaseKey: "isWorking",
          type: "Radio",
          options: [
            "No, I have never worked.",
            "No, I have stopped working.",
            "Yes, I am currently working.",
          ],
        },
        {
          label: "When did you stop working?",
          type: "FullDate",
          placeholder: FULL_DATE_FORMAT,
          databaseKey: "stopWorkingDate",
          conditionalFn: (formValues: any) =>
            formValues.isWorking === "No, I have stopped working.",
        },
        {
          label: "Why did you stop working?",
          type: "Radio",
          databaseKey: "stopWorkingReason",
          options: [
            "Because of your condition.",
            "Because of your condition AND other reasons.",
            "Because of other reasons.",
          ],
          conditionalFn: (formValues: any) =>
            formValues.isWorking &&
            formValues.isWorking !== "Yes, I am currently working.",
        },
        {
          label: "Please explain the other reasons why you stopped working.",
          type: "Textarea",
          databaseKey: "stopWorkingOtherReasons",
          conditionalFn: (formValues: any) =>
            formValues.stopWorkingReason &&
            formValues.stopWorkingReason !== "Because of your condition",
        },
        {
          label:
            "Even though you stopped for other reasons, when do you believe that your condition(s) became severe enough to keep you from working?",
          type: "FullDate",
          placeholder: FULL_DATE_FORMAT,
          databaseKey: "stopWorkingConditionDate",
          conditionalFn: (formValues: any) =>
            formValues.stopWorkingReason &&
            formValues.stopWorkingReason !== "Because of your condition",
        },
        {
          label:
            "Did your condition(s) cause you or your employer to make changes in your work activity before you stopped working?",
          type: "YesNoRadioGroup",
          databaseKey: "conditionChangesWorkActivity",
        },
        {
          label: "When did the changes start?",
          type: "FullDate",
          databaseKey: "workChangesStartDate",
          conditionalFn: (formValues: any) =>
            formValues.conditionChangesWorkActivity,
        },
        {
          label: "When did your condition(s) first start bothering you?",
          type: "FullDate",
          databaseKey: "conditionStartDate",
          conditionalFn: (formValues: any) =>
            formValues.conditionChangesWorkActivity,
        },
      ],
    },
    {
      title: "Work History",
      category: FORM_CATEGORIES.EMPLOYMENT,
      fields: [
        {
          label:
            "Since September 1, 2024, have you had any earnings greater than $1,550 before tax in any month?",
          description: " Do not count sick leave, vacation, or disability pay.",
          type: "YesNoRadioGroup",
          databaseKey: "hasHadEarnings",
        },
        {
          label:
            "Select the number of jobs you had in the five years before you became unable to work.",
          type: "NumberInput",
          databaseKey: "numberOfJobs",
        },
      ],
    },
    {
      title: "Job Listing",
      category: FORM_CATEGORIES.EMPLOYMENT,
      type: "ListManager",
      description: "",
      fields: [
        {
          label: "Please list any jobs that you have been paid for.",
          description: "",
          type: "ListManager",
          heading: "Job Listing",
          numberOfRecords: "numberOfJobs",
          databaseKey: "jobListings",
          children: [
            {
              label: "Job Title",
              description: "Example: Cashier",
              type: "Input",
              databaseKey: "jobTitle",
            },
            {
              label: "Type of Business",
              description: "Example: Grocery store",
              type: "Input",
              databaseKey: "jobType",
            },
            {
              label: "Start Date",
              type: "FullDate",
              databaseKey: "startDate",
            },
            {
              label: "End Date",
              type: "FullDate",
              databaseKey: "endDate",
            },
            {
              label: "Hours per Day",
              type: "NumberInput",
              databaseKey: "hoursPerDay",
            },
            {
              label: "Days Per Week",
              type: "NumberInput",
              databaseKey: "daysPerWeek",
            },
            {
              label: "Amount of Pay",
              type: "DollarInput",
              databaseKey: "amountOfPay",
            },
            {
              label: "Frequency of Pay",
              type: "Select",
              options: [
                "Hourly",
                "Daily",
                "Bi-Weekly",
                "Weekly",
                "Monthly",
                "Yearly",
              ],
              databaseKey: "frequencyOfPay",
            },
          ],
        },
      ],
    },
    {
      title: "Education",
      category: FORM_CATEGORIES.GENERAL,
      fields: [
        {
          label: "Highest Grade Completed",
          description:
            "Select the highest level of school completed, including homeschooling, online education, and education received in another country.",
          type: "Select",
          databaseKey: "highestLevelOfEducation",
          options: [
            "None",
            "Kindergarten",
            "1st Grade",
            "2nd Grade",
            "3rd Grade",
            "4th Grade",
            "5th Grade",
            "6th Grade",
            "7th Grade",
            "8th Grade",
            "9th Grade",
            "10th Grade",
            "11th Grade",
            "12th Grade",
            "GED",
            "1 Year of College",
            "2 Years of College",
            "3 Years of College",
            "4 or More Years of College",
          ],
        },
        {
          label:
            "Enter the date when you most recently completed a school year, as close as you can remember.",
          type: "FullDate",
          databaseKey: "lastSchoolYear",
        },
        {
          label: "School Name",
          type: "Input",
          databaseKey: "schoolName",
        },
        {
          label: "School City",
          type: "Input",
          databaseKey: "schoolCity",
        },
        {
          label: "School State",
          databaseKey: "schoolState",
          type: "StateSelect",
        },
        {
          label: "Were you in special education?",
          type: "YesNoRadioGroup",
          databaseKey: "isSpecialEducation",
        },
      ],
    },
    {
      title: "Literacy",
      category: FORM_CATEGORIES.GENERAL,
      fields: [
        {
          label:
            "What written language do you use every day in most situations?",
          description: "(at home, work, school, in community, etc.)",
          type: "Input",
          databaseKey: "writtenLanguage",
        },
        {
          label:
            "In the language you identified above, can you read a simple message, such as a shopping list or short and simple notes?",
          type: "YesNoRadioGroup",
          databaseKey: "canReadSimpleMessage",
        },
        {
          label:
            "In the language you identified above, can you write a simple message, such as a shopping list or short and simple notes?",
          type: "YesNoRadioGroup",
          databaseKey: "canWriteSimpleMessage",
        },
      ],
    },
  ].filter((section) => {
    return category ? section.category === category : true;
  });
};
