import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { getFeatureFlags } from "../actions/application";
import * as signalR from "@microsoft/signalr";
import { GetAdminApiBaseUrlFromWindow } from "../utils/utils";

export interface FeatureFlag {
  name: string;
  isActive: boolean;
  description: string;
}

export interface FeatureFlagContextType {
  featureFlags: FeatureFlagConfig;
  loading: boolean;
  error: Error | null;
}

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(
  undefined
);

export const useFeatureFlagContext = (): FeatureFlagContextType => {
  const context = useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error(
      "useFeatureFlagContext must be used within an FeatureFlagProvider"
    );
  }
  return context;
};

interface FeatureFlagProviderProps {
  children: ReactNode;
}

interface FeatureFlagConfig {
  [key: string]: boolean;
}
export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({
  children,
}) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagConfig>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const loadFeatureFlags = () => {
    getFeatureFlags()
      .then((res) => {
        const ffConfig: FeatureFlagConfig = {};
        res.data.responseData.forEach((ff: FeatureFlag) => {
          ffConfig[ff.name] = ff.isActive;
        });
        setFeatureFlags(ffConfig);
      })
      .catch((error: any) => {
        console.error("Error fetching feature flags:", error);
        setError(error as Error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadFeatureFlags();
  }, []);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${GetAdminApiBaseUrlFromWindow()}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("featureFlagUpdate", (res: any) => {
      loadFeatureFlags();
    });
    connection.start().catch((err) => console.log(err));
    return () => {
      connection.off("featureFlagUpdate");
      connection.stop().catch((err) => console.log("connection Stop Error"));
    };
  }, []);

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, loading, error }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
