import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  formatPhoneNumber,
  getOnlyShowMyCasesFromSearchParams,
} from "../../utils/commonHelper";
import moment from "moment";
import info from "../../images/info.png";
import Loader from "../../components/Loader";
import LoaderLoadMore from "../../components/LoaderLoadMore";
import {
  pipelineStatusWithNoLead,
  getClientsByApplicationStatusId,
} from "../../actions/leadsAndClients";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import { faRibbon } from "@fortawesome/free-solid-svg-icons/faRibbon";
import { faSortUp } from "@fortawesome/free-solid-svg-icons/faSortUp";
import { faSortDown } from "@fortawesome/free-solid-svg-icons/faSortDown";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import { faChild } from "@fortawesome/free-solid-svg-icons/faChild";
import { faRocket } from "@fortawesome/free-solid-svg-icons/faRocket";
import blackLang from "../../images/language_black (1).png";
import { Constants } from "../../common/constants";
import { faBan, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { get } from "../../utils/clientStorageUtils";
import { Button } from "antd";

const Clients = () => {
  const navigate = useNavigate();
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const [pstatus, setpStatus] = useState<any[]>([]);
  const [pclient, setpClients] = useState<any[]>([]);
  const [isLoader, setIsLoader] = useState(false);
  const [loaderArray, setLoaderArray] = useState<boolean[]>([]);
  const [sortOrderArray, setSortOrderArray] = useState<boolean[]>([]);
  const [pageNumArray, setPageNumArray] = useState<any[]>([]);
  const [searchParams] = useSearchParams();

  const pipelineStatusWithNoLeadApi = () => {
    setIsLoader(true);
    var loaderArrayTemp: any[] = [];
    pipelineStatusWithNoLead(
      searchParams.get("pipelineId") || "2",
      getOnlyShowMyCasesFromSearchParams(searchParams)
    ).then((res) => {
      if (res.data.responseCode == 200) {
        var resData = res.data.responseData;
        setpStatus(resData);
        let clientData: any = [];
        var statusLength = resData.length;
        var currentCount = 0;
        resData.map((ele: any) => {
          loaderArrayTemp.push(false);
          getClientsByApplicationStatusId(
            ele.id,
            Constants.clientsDefaultPageSize,
            0,
            ele.id === Constants.applicationStatus.QualifiedIANeedsFiling
              ? false
              : true,
            getOnlyShowMyCasesFromSearchParams(searchParams)
          ).then((res) => {
            currentCount = currentCount + 1;
            if (res.data.responseCode == 200) {
              var cData = res.data.responseData;
              for (var i = 0; i < cData.length; i++) {
                clientData.push(cData[i]);
              }
              if (statusLength == currentCount) {
                setpClients(clientData);
                setIsLoader(false);
                setLoaderArray(loaderArrayTemp);
                setPageNumArray([...Array(statusLength).fill(0)]);
                const qualifiedIANeedsFilingStatusIndex = resData.findIndex(
                  (e: any) =>
                    e.id === Constants.applicationStatus.QualifiedIANeedsFiling
                );
                let sortOrderArrayValue = [...Array(statusLength).fill(true)];
                if (qualifiedIANeedsFilingStatusIndex > -1) {
                  sortOrderArrayValue[qualifiedIANeedsFilingStatusIndex] =
                    false;
                }
                setSortOrderArray(sortOrderArrayValue);
              }
            }
          });
        });
      }
    });
  };

  const handleLoadMore = (
    id: any,
    pageNumber: number,
    index: any,
    sortByDescending: boolean,
    triggeredBySortChange: boolean
  ) => {
    setLoaderArray([
      ...loaderArray.map((v, ind) => (ind === index ? true : v)),
    ]);
    if (triggeredBySortChange)
      setpClients([...pclient].filter((v) => v.applicationStatusId !== id));
    getClientsByApplicationStatusId(
      id,
      Constants.clientsDefaultPageSize,
      pageNumber,
      sortByDescending,
      getOnlyShowMyCasesFromSearchParams(searchParams)
    ).then((res) => {
      const loaderArrayTemp = [...loaderArray];
      loaderArrayTemp[index] = false;
      setLoaderArray(loaderArrayTemp);
      setPageNumArray(
        [...pageNumArray].map((v, ind) => (ind === index ? pageNumber : v))
      );
      if (res.data.responseCode == 200) {
        var response = res.data.responseData;
        setpClients([
          ...(triggeredBySortChange
            ? pclient.filter((v) => v.applicationStatusId !== id)
            : pclient),
          ...response,
        ]);
      }
    });
  };

  useEffect(() => {
    if (
      parseInt(searchParams.get("pipelineId") ?? "") === 6 &&
      Constants.adminGroup.indexOf(roleInStorage) === -1
    ) {
      navigate("/leads");
    }
    pipelineStatusWithNoLeadApi();
  }, [searchParams.get("pipelineId"), searchParams.get("onlyShowMyCases")]);

  return (
    <>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <section id='MindsetAdminDashboard'>
          <div className='row pb-5 desktop_scroll justify-content-between'>
            {pstatus.map((ele, index) => {
              return (
                <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-2 text-center'>
                  <div className='row'>
                    <div className='col-md-3'></div>
                    <div className='col-md-9'>
                      {ele.id ===
                        Constants.applicationStatus.QualifiedIANeedsFiling && (
                        <p className={`heading_cards_2 mb-1`}>
                          Sorted by Release Date
                        </p>
                      )}
                      <p className={`heading_cards_2 mb-1`}>
                        {ele.clientCount} claimants
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                        className='heading_cards'
                      >
                        <p style={{ margin: 0 }}>{ele.name}</p>
                        <div
                          onClick={() => {
                            setSortOrderArray(
                              [...sortOrderArray].map((v, ind) =>
                                ind === index ? !v : v
                              )
                            );
                            setPageNumArray(
                              [...pageNumArray].map((v, ind) =>
                                ind === index ? 0 : v
                              )
                            );
                            handleLoadMore(
                              ele.id,
                              0,
                              index,
                              !sortOrderArray[index],
                              true
                            );
                          }}
                        >
                          {sortOrderArray[index] ? (
                            <FontAwesomeIcon
                              className='popover__title'
                              style={{ cursor: "pointer" }}
                              icon={faSortDown}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className='popover__title'
                              style={{ cursor: "pointer" }}
                              icon={faSortUp}
                            />
                          )}
                        </div>
                      </div>
                      {
                        <>
                          {pclient
                            .filter((a: any) => a.applicationStatusId == ele.id)
                            .map((item: any) => {
                              var date = item.statusChangeDate
                                ? moment
                                    .utc(item.statusChangeDate)
                                    .local()
                                    .format("MM/DD/yyyy")
                                : moment
                                    .utc(item.createdDate)
                                    .local()
                                    .format("MM/DD/yyyy");
                              var time = item.statusChangeDate
                                ? moment
                                    .utc(item.statusChangeDate)
                                    .local()
                                    .format("hh:mm a")
                                : moment
                                    .utc(item.createdDate)
                                    .local()
                                    .format("hh:mm a");
                              return (
                                <>
                                  <div
                                    className='row position-relative mt-2 cards_bottom'
                                    onClick={() =>
                                      navigate(
                                        `/${
                                          Constants.pageRoutes.claimantProfile
                                        }?id=${
                                          item.encryptedId
                                        }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
                                          searchParams
                                        )}&pipelineId=${searchParams.get(
                                          "pipelineId"
                                        )}&pipelineStatusId=${
                                          item.applicationStatusId
                                        }`
                                      )
                                    }
                                  >
                                    <p
                                      className={`time_card position-absolute`}
                                    >
                                      {date}
                                    </p>
                                    <p
                                      className={`time_card_2 position-absolute`}
                                    >
                                      {time}
                                    </p>
                                    <div className='col-md-1 text-right'></div>
                                    <div className='name_cards_bg col-md-10 pe-3 ps-3 pt-2 pb-2 cursorPointer position-relative'>
                                      <p className={`name_cards mb-0`}>
                                        {" "}
                                        {item.firstName} {item.lastName}
                                        <span className='d-block'>
                                          {" "}
                                          {formatPhoneNumber(item.phoneNumber) +
                                            `${
                                              item.timeZone
                                                ? ` (${item.timeZone})`
                                                : ""
                                            }`}
                                          <div className='d-flex align-items-center justify-content-start flex-wrap'>
                                            {item.caseManagerId && (
                                              <span>
                                                <div className='icons_new popover__wrapper'>
                                                  <FontAwesomeIcon
                                                    icon={faCircleUser}
                                                  />

                                                  <div className='popover__content'>
                                                    <p className='popover__message'>{`Assigned to ${item.caseManagerName}`}</p>
                                                  </div>
                                                </div>
                                              </span>
                                            )}
                                            {item.isCritical && (
                                              <span>
                                                <div className='icons_new popover__wrapper'>
                                                  <FontAwesomeIcon
                                                    className='popover__title'
                                                    icon={faCircleExclamation}
                                                  />
                                                  <div className='popover__content'>
                                                    <p className='popover__message'>
                                                      Critical{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                              </span>
                                            )}
                                            {item.isSpanish && (
                                              <span>
                                                <div className='icons_new popover__wrapper'>
                                                  <img
                                                    className='es_icon img-fluid popover__title'
                                                    src={blackLang}
                                                  />
                                                  <div className='popover__content'>
                                                    <p className='popover__message'>
                                                      Spanish{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                              </span>
                                            )}
                                            {item.isDeceased && (
                                              <span>
                                                <div className='icons_new popover__wrapper'>
                                                  <FontAwesomeIcon
                                                    className='popover__title'
                                                    icon={faRibbon}
                                                  />
                                                  <div className='popover__content'>
                                                    <p className='popover__message'>
                                                      Deceased{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                              </span>
                                            )}
                                            {item.isMinor && (
                                              <span>
                                                <div className='icons_new popover__wrapper'>
                                                  <FontAwesomeIcon
                                                    className='popover__title'
                                                    icon={faChild}
                                                  />
                                                  <div className='popover__content'>
                                                    <p className='popover__message'>
                                                      Minor{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                              </span>
                                            )}
                                            {item.isTerminal && (
                                              <span>
                                                <div className='icons_new popover__wrapper'>
                                                  <FontAwesomeIcon
                                                    className='popover__title'
                                                    icon={faRocket}
                                                  />
                                                  <div className='popover__content'>
                                                    <p className='popover__message'>
                                                      Terminal{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                              </span>
                                            )}
                                            {item.isDenial && (
                                              <span>
                                                <div className='icons_new popover__wrapper'>
                                                  <FontAwesomeIcon
                                                    className='popover__title'
                                                    icon={faBan}
                                                  />
                                                  <div className='popover__content'>
                                                    <p className='popover__message'>
                                                      Denial{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                              </span>
                                            )}
                                            {item.tenantId !== null &&
                                              item.tenantName && (
                                                <span>
                                                  <div className='popover__wrapper vertical-super'>
                                                    <img
                                                      className='info_image2 img-fluid popover__title'
                                                      src={info}
                                                    />
                                                    <div className='popover__content'>
                                                      <p className='popover__message'>
                                                        {item.tenantName}{" "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </span>
                                              )}
                                          </div>
                                        </span>
                                      </p>
                                    </div>
                                    <div className='col-md-2'></div>
                                  </div>
                                </>
                              );
                            })}
                          {loaderArray[index] ? (
                            <div style={{ marginTop: "15px" }}>
                              <LoaderLoadMore></LoaderLoadMore>
                            </div>
                          ) : (
                            <>
                              {pclient.filter(
                                (e: any) => e.applicationStatusId == ele.id
                              ).length == 0 && (
                                <div className='error'>No Claimants</div>
                              )}
                              {ele.clientCount >= 15 &&
                              pclient.filter(
                                (el: any) => el.applicationStatusId == ele.id
                              ).length != ele.clientCount ? (
                                <div className='text-center mt-5'>
                                  <Button
                                    className='secondary-button'
                                    onClick={() =>
                                      handleLoadMore(
                                        ele.id,
                                        pageNumArray[index] + 1,
                                        index,
                                        sortOrderArray[index],
                                        false
                                      )
                                    }
                                  >
                                    Load More
                                  </Button>
                                </div>
                              ) : (
                                <> </>
                              )}
                            </>
                          )}
                        </>
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default Clients;
