import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Modal, Form, Select, Button, Alert, Input, Steps } from "antd";
import {
  CreateFaxDraft,
  DeleteFaxDraft,
  GetSsaFieldOfficeData,
  SendFax,
  UpdateFax,
} from "../../apis/faxManager";
import { AllPdfDocuments } from "../../apis/documents";
import PhoneField from "../../pages/GenerateDocuments/TemplateForms/SharedFields/PhoneField";
import { FlashMessage } from "../../utils/flash_message";
import { getApplicationDetailsById } from "../../actions/application";
import FaxPreview from "./FaxPreview";
import { Fax, FaxHistoryItem } from "./FaxHistoryTable";
import { DeleteOutlined } from "@ant-design/icons";
import "./style.css";

type DocumentOptionType = {
  value: number;
  label: string;
  documentType: number;
};

interface SendFaxModalProps {
  visible: boolean;
  onClose: () => void;
  faxData: Fax | null;
  setFaxData: Dispatch<SetStateAction<Fax | null>>;
  initialStep: number;
  userId: string;
}

const SendFaxModal: React.FC<SendFaxModalProps> = ({
  visible,
  onClose,
  faxData,
  setFaxData,
  initialStep,
  userId,
}) => {
  const [isLoadingSsaDetails, setIsLoadingSsaDetails] =
    useState<boolean>(false);
  const [autofillSuccess, setAutofillSuccess] = useState<boolean>(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<number[]>([]);
  const [documentOptions, setDocumentOptions] = useState<DocumentOptionType[]>(
    []
  );
  const [documentOptionsLoading, setDocumentOptionsLoading] = useState(false);
  const [isFaxSaving, setIsFaxSaving] = useState<boolean>(false);
  const [isFaxSending, setIsFaxSending] = useState<boolean>(false);
  const [isDeletingFax, setIsDeletingFax] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(initialStep ?? 1);
  const [application, setApplication] = useState<any>(null);
  const [selectedFaxType, setSelectedFaxType] = useState<string | undefined>();
  const [customFaxType, setCustomFaxType] = useState("");
  const [previousFaxType, setPreviousFaxType] = useState<string | undefined>(
    selectedFaxType
  );

  const [faxForm] = Form.useForm();

  const faxTypeOptions = [
    { label: "Rep Docs", value: "Rep Docs" },
    { label: "Other", value: "Other" },
  ];

  useEffect(() => {
    const fetchDocumentOptions = async () => {
      if (visible) {
        setDocumentOptionsLoading(true);
        try {
          const res = await AllPdfDocuments(userId ?? "0");
          if (res.data.responseData.length < 1) return;
          const options = res.data.responseData.map(
            (item: { id: number; name: string; documentTypeId: number }) => ({
              value: item.id,
              label: item.name,
              documentType: item.documentTypeId,
            })
          );
          setDocumentOptions(options);
        } catch (error) {
          console.error("Failed to load document options:", error);
        } finally {
          setDocumentOptionsLoading(false);
        }
      }
    };

    fetchDocumentOptions();
  }, [visible, userId]);

  useEffect(() => {
    if (faxData) {
      faxForm.setFieldsValue({
        receiverName: faxData.receiverName,
        receiverFaxNumber: faxData.receiverFaxNumber,
        receiverPhoneNumber: faxData.receiverPhoneNumber,
        fromName: faxData.fromName,
        coverLetterSubject: faxData.coverLetterSubject,
        coverLetterBody: faxData.coverLetterBody,
      });

      if (faxData.faxType !== "Rep Docs") {
        faxForm.setFieldsValue({
          faxType: "Other",
          customFaxType: faxData.faxType,
        });
        setSelectedFaxType("Other");
        setCustomFaxType(faxData.faxType);
      } else {
        faxForm.setFieldsValue({
          faxType: faxData.faxType,
        });
      }
    }
  }, [faxData, faxForm]);

  useEffect(() => {
    if (faxData?.includedDocumentIds && documentOptions.length > 0) {
      const parsedDocumentIds = faxData.includedDocumentIds
        .split(",")
        .map((id: string) => parseInt(id.trim(), 10))
        .filter((id: number) => !isNaN(id));

      faxForm.setFieldsValue({
        faxDocuments: parsedDocumentIds,
      });
      setSelectedDocumentIds(parsedDocumentIds);
    }
  }, [faxData, documentOptions, faxForm]);

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const appDetails = await getApplicationDetailsById(userId);
        setApplication(appDetails.data);
      } catch (error) {
        console.error("Error fetching application details:", error);
      }
    };

    fetchApplication();
  }, [userId]);

  const handleDocumentChange = (selectedValues: number[]) => {
    setSelectedDocumentIds(selectedValues);
  };

  const handleClose = () => {
    setSelectedDocumentIds([]);
    setDocumentOptions([]);
    setCurrentStep(1);
    setSelectedFaxType("");
    faxForm.resetFields();

    onClose();
  };

  const getLatestStatus = (faxHistory: FaxHistoryItem[]) => {
    if (!faxHistory.length) {
      return "Unknown";
    }

    const latestEntry = faxHistory.reduce(
      (latest, entry) =>
        new Date(entry.createdDate) > new Date(latest.createdDate)
          ? entry
          : latest,
      faxHistory[0]
    );

    return latestEntry.status || "Unknown";
  };

  // Autofill SSA Details
  useEffect(() => {
    if (selectedFaxType == "Rep Docs") {
      handleAutofill();
    }
  }, [selectedFaxType]);

  useEffect(() => {
    if (previousFaxType === "Rep Docs" && selectedFaxType !== "Rep Docs") {
      faxForm.resetFields();
    }
    setPreviousFaxType(selectedFaxType);
  }, [selectedFaxType, faxForm, previousFaxType]);

  const handleAutofill = async () => {
    try {
      setAutofillSuccess(false);
      setIsLoadingSsaDetails(true);

      const zipCode = application?.responseData?.zip;

      if (zipCode == null) {
        setAutofillSuccess(false);
        setIsLoadingSsaDetails(false);
        FlashMessage(
          "error",
          "Please update claimant zip code before proceeding"
        );
        return;
      }

      const response = await GetSsaFieldOfficeData(zipCode);

      const faxNumber = response.data.fax.startsWith("1-")
        ? response.data.fax.slice(2)
        : response.data.fax;

      const phoneNumber = response.data.phone.startsWith("1-")
        ? response.data.phone.slice(2)
        : response.data.phone;

      const coverLetterBody = `Hello, please see attached 1696 & FEE AGREEMENT. Please include attorney Theodore Glass when sending mail to Mindset Care Inc and mail acknowledgement that you received our representation forms.

In accordance with EM-25010 this document has been signed using a Commercial Product Alternative Signature (CPAS) process. The signer's name, signature date, and signature time are affixed to the document.

Thank you!`;

      // Auto-select relevant documents
      const autoSelectedDocs = documentOptions
        .filter(
          (doc) =>
            doc.label.toLowerCase().includes("ssa-1696") ||
            doc.label.toLowerCase().includes("fee agreement")
        )
        .map((doc) => doc.value);

      // Update form with autofill values
      faxForm.setFieldsValue({
        receiverName: "SSA Field Office",
        receiverFaxNumber: faxNumber.replace(/\D/g, ""),
        receiverPhoneNumber: phoneNumber.replace(/\D/g, ""),
        coverLetterSubject: "Form(s): 1696 & FEE AGREEMENT",
        coverLetterBody: coverLetterBody,
        faxDocuments: autoSelectedDocs,
      });

      setSelectedDocumentIds(autoSelectedDocs);

      setAutofillSuccess(true);
    } catch (error) {
      console.error("Error fetching SSA office data:", error);
      setAutofillSuccess(false);
      FlashMessage(
        "error",
        "Error retrieving field office details. Please manually enter the information."
      );
    } finally {
      setIsLoadingSsaDetails(false);
    }
  };

  // Fax Actions
  const createFaxDraft = async (formData: any) => {
    setIsFaxSaving(true);
    const selectedDocuments = documentOptions
      .filter((document) => selectedDocumentIds.includes(document.value))
      .map(({ value, documentType }: DocumentOptionType) => ({
        DocumentId: value,
        DocumentType: documentType,
      }));

    const faxTypeToSubmit =
      selectedFaxType === "Other" ? customFaxType : selectedFaxType;

    try {
      if (faxData?.id) {
        const faxPayload = {
          id: faxData.id,
          receiverName: formData.receiverName,
          receiverFaxNumber: formData.receiverFaxNumber.toString(),
          receiverPhoneNumber: formData.receiverPhoneNumber.toString(),
          faxType: faxTypeToSubmit,
          coverLetterSubject: formData.coverLetterSubject,
          coverLetterBody: formData.coverLetterBody,
          documentList: selectedDocuments,
        };

        let response = await UpdateFax(faxPayload, faxData.id);
        setFaxData(response?.data?.responseData);
      } else {
        const faxPayload = {
          encryptedClaimantMindsetUserId: userId,
          receiverName: formData.receiverName,
          receiverFaxNumber: formData.receiverFaxNumber.toString(),
          receiverPhoneNumber: formData.receiverPhoneNumber.toString(),
          faxType: faxTypeToSubmit,
          coverLetterSubject: formData.coverLetterSubject,
          coverLetterBody: formData.coverLetterBody,
          documentList: selectedDocuments,
        };
        let response = await CreateFaxDraft(faxPayload);
        setFaxData(response?.data?.responseData);
      }
      nextStep();
    } catch (error) {
      console.error("Error creating or updating fax:", error);
      setIsFaxSaving(false);
    } finally {
      setIsFaxSaving(false);
    }
  };

  const sendFax = async () => {
    if (!faxData) {
      return;
    }

    setIsFaxSending(true);

    try {
      await SendFax(faxData.id);
      handleClose();
      FlashMessage(
        "success",
        "Successfully initiated fax. Please reference the fax history table for updates."
      );
    } catch (error) {
      FlashMessage(
        "error",
        "Error sending fax. Please check the request data and try again."
      );
    } finally {
      setIsFaxSending(false);
    }
  };

  const handleDelete = async () => {
    if (!faxData?.id) {
      return;
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this fax draft? This action cannot be undone."
    );

    if (isConfirmed) {
      setIsDeletingFax(true);
      try {
        await DeleteFaxDraft(faxData.id);
        FlashMessage("success", "Fax draft deleted successfully.");
      } catch (error) {
        console.error(error);
        FlashMessage("error", "Failed to delete fax draft.");
      } finally {
        setIsDeletingFax(false);
        handleClose();
      }
    }
  };

  // Modal Steps
  const nextStep = () =>
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
  const prevStep = () =>
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  const handleStepChange = (step: number) => setCurrentStep(step + 1);

  useEffect(() => {
    if (visible) {
      setCurrentStep(initialStep);
    }
  }, [initialStep, visible]);

  const stepContents = [
    <div key='step1' className='fax-step'>
      <Form form={faxForm} onFinish={createFaxDraft} layout='vertical'>
        <div className='fax-form-item'>
          <Form.Item
            label='Fax Type'
            name='faxType'
            rules={[
              {
                required: true,
                message: "Please select the fax type",
              },
            ]}
          >
            <Select
              options={faxTypeOptions}
              placeholder='Please select a fax type'
              value={selectedFaxType}
              onChange={(value) => {
                setSelectedFaxType(value);
                if (value !== "Other") {
                  setCustomFaxType("");
                }
              }}
            />
          </Form.Item>
        </div>

        {selectedFaxType === "Other" && (
          <div className='fax-form-item'>
            <Form.Item
              label='Specify Fax Type'
              name='customFaxType'
              rules={[
                {
                  required: true,
                  message: "Please specify the reason for this fax",
                },
              ]}
            >
              <Input
                placeholder='Enter a custom fax type'
                value={customFaxType}
                onChange={(e) => setCustomFaxType(e.target.value)}
                maxLength={30}
              />
            </Form.Item>
          </div>
        )}

        <div className='fax-form-item'>
          <Form.Item
            label='Documents To Fax'
            name='faxDocuments'
            rules={[
              { required: true, message: "Please select documents to fax" },
            ]}
          >
            <Select
              mode='multiple'
              options={documentOptions}
              placeholder='Please select documents to fax'
              value={selectedDocumentIds}
              onChange={handleDocumentChange}
            />
          </Form.Item>
        </div>

        <div className='no-pdfs-warning'>
          {!documentOptionsLoading && documentOptions.length < 1 && (
            <div>
              <Alert
                message="No PDFs were found in the claimant's documents. Please add a PDF file to the
                        claimant's documents folder to send a fax."
                type='warning'
                showIcon
              />
            </div>
          )}
        </div>

        <div className='nav-buttons'>
          <Button
            type='primary'
            onClick={() => {
              faxForm
                .validateFields()
                .then(() => {
                  nextStep();
                })
                .catch((error) => {
                  console.log("Validation failed:", error);
                });
            }}
          >
            Next
          </Button>
        </div>
      </Form>
    </div>,
    <div key='step2' className='fax-step'>
      {selectedFaxType == "Rep Docs" && (
        <div className='autofill-section'>
          {isLoadingSsaDetails ? (
            <Alert
              message={`Loading SSA Field Office information for zip code: ${application.responseData.zip}`}
              type='warning'
            />
          ) : (
            <>
              {autofillSuccess && (
                <Alert
                  message={`Successfully autofilled SSA Field Office information for zip code: ${application.responseData.zip}`}
                  type='success'
                />
              )}
              {!autofillSuccess && (
                <>
                  <Alert
                    message='Unable to autofill SSA Field Office information, please enter details manually'
                    type='warning'
                    action={
                      <Button type='link' onClick={handleAutofill}>
                        Try Again
                      </Button>
                    }
                  />
                </>
              )}
            </>
          )}
        </div>
      )}

      <Form form={faxForm} onFinish={createFaxDraft} layout='vertical'>
        <div className='fax-form-item'>
          <Form.Item
            label='Recipient Name:'
            name='receiverName'
            rules={[
              {
                required: true,
                message: "Please enter the recipient name",
              },
            ]}
          >
            <Input placeholder='Recipient Name' maxLength={50} />
          </Form.Item>
        </div>

        <div className='fax-form-item'>
          <PhoneField
            label='Recipient Fax Number'
            name='receiverFaxNumber'
            placeholder="Recipient's fax number"
          />
        </div>

        <div className='fax-form-item'>
          <PhoneField
            label='Recipient Phone Number'
            name='receiverPhoneNumber'
            placeholder="Recipient's phone number"
          />
        </div>

        <div className='fax-form-item'>
          <Form.Item
            label='Cover Letter Subject'
            name='coverLetterSubject'
            rules={[
              {
                required: true,
                message: "Please enter a cover letter subject",
              },
            ]}
          >
            <Input placeholder='Cover letter subject' maxLength={50} />
          </Form.Item>
        </div>

        <div className='fax-form-item'>
          <Form.Item
            label='Cover Letter Body'
            name='coverLetterBody'
            rules={[
              { required: true, message: "Please enter a cover letter body" },
            ]}
          >
            <Input.TextArea
              rows={10}
              placeholder='Cover letter body'
              maxLength={500}
              showCount
            />
          </Form.Item>
        </div>

        <div className='nav-buttons'>
          <Button type="default" onClick={prevStep}>
            Back
          </Button>
          <Button type='primary' htmlType='submit' loading={isFaxSaving}>
            {faxData?.id ? "Generate New Preview" : "Generate Preview"}
          </Button>
        </div>
      </Form>
    </div>,
    <div key='step3' className='fax-step'>
      <FaxPreview fax={faxData} />
      {(() => {
        const isFailed =
          faxData?.faxHistory &&
          getLatestStatus(faxData.faxHistory) === "failed";

        return (
          <div className={isFailed ? "nav-buttons" : "final-nav-buttons"}>
            {!isFailed && (
              <Button
                icon={<DeleteOutlined />}
                type='link'
                className='delete-fax-button'
                onClick={handleDelete}
              >
                Delete Draft
              </Button>
            )}

            <Button onClick={prevStep}>
              Back to Edit
            </Button>
            <Button type='primary' onClick={sendFax} loading={isFaxSending}>
              Send Fax
            </Button>
          </div>
        );
      })()}
    </div>,
  ];

  return (
    <Modal
      open={visible}
      title='Create Fax'
      onCancel={handleClose}
      footer={null}
      width={600}
      maskClosable={false}
    >
      <Steps
        current={currentStep - 1}
        onChange={handleStepChange}
        size='small'
        labelPlacement='vertical'
        className='fax-steps'
        items={[
          { title: "Document Selection" },
          { title: "Fax Details", disabled: selectedDocumentIds.length == 0 },
          { title: "Fax Preview", disabled: !faxData?.id },
        ]}
      />
      {stepContents[currentStep - 1]}
    </Modal>
  );
};

export default SendFaxModal;
