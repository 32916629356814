import { useEffect, useState } from "react";
import "../index.scss";
import dwdtemp from "../../../images/download_temp.png";
import { AutoComplete, Button, Flex, Modal, Table, Tooltip } from "antd";
import _uniqBy from "lodash/uniqBy";
import { useDebounce } from "use-debounce";
import info from "../../../images/info.png";
import {
  getMedicalHistory,
  getMedicalHistoryById,
  deleteMedicalHistory,
  updateMedicalHistory,
  saveMedicalHistory,
} from "../../../actions/doctorsVisits";
import { FlashMessage } from "../../../utils/flash_message";
import { useSearchParams } from "react-router-dom";
import { Form, Input, DatePicker, SelectProps } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Constants } from "../../../common/constants";
import { validateBlankSpace } from "../../../validations/validateBlankSpace";
import {
  number,
  validateZipCode,
} from "../../../validations/numeric_only-validate";
import { minLength } from "../../../validations/validate_min_length";
import { lessThanFourCharacters } from "../../../validations/lessThanFourCharacters";
import Loader from "../../Loader";
import { getMedicalFacilities } from "../../../actions/application";
import GooglePlacesAutoCompleteWrapper from "../../GooglePlacesAutoCompleteWrapper";
import dayjs from "dayjs";

interface Facility {
  id: string;
  hospitalName: string;
  physicianAddress: string;
  addressTwo: string;
  physicianPhoneNumber: string;
  zip: string;
}

const DoctorVisits = (props: any) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [searchParams] = useSearchParams();
  const [appdata, setAppData] = useState({});
  const [addressValue, setAddressValue] = useState<string | null>(null);
  const [isAdd, setIsAdd] = useState(false);
  const dateFormat = "MM/DD/YYYY";
  const [form] = Form.useForm();

  const [facilityList, setFacilityList] = useState<Facility[]>([]);
  const [medicalFacilitySearchQuery, setMedicalFacilitySearchQuery] =
    useState("");
  const [debouncedHospitalNameSearchQuery] = useDebounce(
    medicalFacilitySearchQuery,
    Constants.debounceTime
  );
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);

  const [type1, setType1] = useState(false);
  const [type2, setType2] = useState(false);
  const [type3, setType3] = useState(false);
  const [isPopUpLoader, setisPopUpLoader] = useState(false);

  useEffect(() => {
    if (!openEdit) setMedicalFacilitySearchQuery("");
  }, [openEdit]);

  useEffect(() => {
    if (!debouncedHospitalNameSearchQuery) {
      setOptions([
        {
          value: "",
          label: (
            <div style={{ display: "flex" }}>
              <span>Please input value</span>
            </div>
          ),
        },
      ]);
    } else if (debouncedHospitalNameSearchQuery.length < 3) {
      setOptions([
        {
          value: "",
          label: (
            <div style={{ display: "flex" }}>
              <span>Please input at least 3 characters to search</span>
            </div>
          ),
        },
      ]);
    } else {
      setOptions([
        {
          value: "",
          label: (
            <div style={{ display: "flex" }}>
              <span>Loading......</span>
            </div>
          ),
        },
      ]);
      searchResult();
    }
  }, [debouncedHospitalNameSearchQuery]);

  const searchResult = () => {
    const userId = searchParams.get("id") ?? "0";
    getMedicalFacilities(userId, debouncedHospitalNameSearchQuery).then(
      (res: any) => {
        const resData = res.data;
        const facilities: Facility[] = resData.responseData;
        setFacilityList(facilities);

        if (!facilities) {
          setOptions([
            {
              value: debouncedHospitalNameSearchQuery,
              label: (
                <div style={{ display: "flex" }}>
                  <span>
                    {debouncedHospitalNameSearchQuery === ""
                      ? "Please input value"
                      : debouncedHospitalNameSearchQuery}
                  </span>
                </div>
              ),
            },
          ]);
        } else {
          setOptions(
            facilities.map((item) => {
              return {
                key: item.id,
                value: item.hospitalName,
                label: `${item.hospitalName} (${item.physicianAddress}, ${item.zip}, ${item.physicianPhoneNumber})`,
              };
            })
          );
        }
      }
    );
  };

  const handleTextLength = (item: any) => {
    if (item != null) {
      return (
        <Tooltip title={item}>
          {item.substring(0, 20) + (item.length > 20 ? "......" : "")}
        </Tooltip>
      );
    }
  };

  const columns = [
    {
      title: "Facility Name",
      dataIndex: "hospitalName",
      key: "hospitalName",
    },
    {
      title: "First Visit Date",
      dataIndex: "treatmentStartDate",
      key: "treatmentStartDate",
      render: (item: any, file: any) => {
        return item == null ? "" : dayjs(item).format(Constants.dateFormat);
      },
    },
    {
      title: "Facility Address",
      dataIndex: "physicianAddress",
      key: "physicianAddress",
      render: handleTextLength,
    },
    {
      title: "Reason for visit",
      dataIndex: "medicationReason",
      key: "medicationReason",
      render: handleTextLength,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return (
          <div className='d-flex'>
            <Tooltip title={"Edit"}>
              <a className='mr-2' onClick={() => getMedicalHistoryByIdApi(id)}>
                <EditFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <a onClick={() => deleteMedicalHistoryApi(id)}>
                <DeleteFilled
                  style={{
                    fontSize: "25px",
                    color: "#747497",
                  }}
                />
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleTypeOne = (e: any) => {
    setType1(e.target.checked);
  };
  const handleTypeTwo = (e: any) => {
    setType2(e.target.checked);
  };
  const handleTypeThree = (e: any) => {
    setType3(e.target.checked);
  };

  const addUpdateMedicalHistoryApi = (e: any) => {
    var param = searchParams.get("id") ?? "0";
    var physicianType = "";
    if (type1 == true) {
      physicianType += "1";
    }
    if (type2 == true) {
      physicianType += (physicianType != "" ? "," : "") + "2";
    }
    if (type3 == true) {
      physicianType += (physicianType != "" ? "," : "") + "3";
    }
    var payload = {
      PhysicianName: e.physicianName,
      TreatmentStartDate: dayjs(e.treatmentStartDate).format(
        Constants.dateFormatWithoutUtc
      ),
      RecentAppointment: dayjs(e.recentAppointment).format(
        Constants.dateFormatWithoutUtc
      ),
      PhysicianAddress: addressValue,
      PhysicianPhoneNumber: e.physicianPhoneNumber,
      PrescribedMedication: e.prescribedMedication,
      PMPhysicianName: e.pmPhysicianName,
      MedicationReason: e.medicationReason,
      HospitalName: e.hospitalName,
      PhysicianType: physicianType,
      LastVisitDate: dayjs(e.lastVisitDate).format(
        Constants.dateFormatWithoutUtc
      ),
      Id: isAdd ? 0 : e.id,
      EncryptedUserId: param,
      AddressTwo: e.addressTwo,
      NextScheduledVisitDate: e.nextScheduledVisitDate
        ? dayjs(e.nextScheduledVisitDate).format(
            Constants.dateFormatWithoutUtc
          )
        : null,
      Zip: e.zip,
      FaxNumber: e.faxNumber,
    };
    if (!isAdd) {
      setisPopUpLoader(true);
      updateMedicalHistory(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Doctor Visits updated Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          getMedicalHistoryApi();
        }
      });
    } else {
      setisPopUpLoader(true);
      saveMedicalHistory(payload).then((res) => {
        if (res.data.responseCode == 200) {
          FlashMessage("success", "Doctor Visits added Successfully.");
          setOpenEdit(false);
          setisPopUpLoader(false);
          props.TemplateCount();
        }
      });
    }
  };

  const deleteMedicalHistoryApi = (id: any) => {
    setisPopUpLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteMedicalHistory(id).then((response) => {
        if (response.data.responseCode == 200) {
          FlashMessage("success", response.data.responseMessage);
          setisPopUpLoader(false);
        } else if (response.data.responseCode != 200) {
          FlashMessage("error", response.data.responseMessage);
          setisPopUpLoader(false);
        }
        getMedicalHistoryApi();
        props.TemplateCount();
      });
    } else {
      setisPopUpLoader(false);
    }
  };

  const getMedicalHistoryByIdApi = (id: any) => {
    setisPopUpLoader(true);
    setOpenEdit(true);
    setAddressValue(null);
    getMedicalHistoryById(id).then((res) => {
      var eduData = res.data.responseData;
      if (res.data.responseCode == 200) {
        var lastVisitDateValueStore =
          eduData.lastVisitDate == null
            ? null
            : dayjs(eduData.lastVisitDate).format();
        eduData.lastVisitDate = lastVisitDateValueStore;
        var treatmentStartDateValueStore =
          eduData.treatmentStartDate == null
            ? null
            : dayjs(eduData.treatmentStartDate).format();
        eduData.treatmentStartDate = treatmentStartDateValueStore;
        var nextScheduledVisitDateValueStore =
          eduData.nextScheduledVisitDate == null
            ? null
            : dayjs(eduData.nextScheduledVisitDate).format();
        eduData.nextScheduledVisitDate = nextScheduledVisitDateValueStore;
        var physicianTypeArray = eduData.physicianType.split(",");
        for (var i = 0; i < physicianTypeArray.length; i++) {
          if (physicianTypeArray[i] == "1") {
            setType1(true);
          } else if (physicianTypeArray[i] == "2") {
            setType2(true);
          } else if (physicianTypeArray[i] == "3") {
            setType3(true);
          }
        }
        setAppData(eduData);
        setAddressValue(eduData.physicianAddress ?? "");
        setMedicalFacilitySearchQuery(eduData.hospitalName);
        setisPopUpLoader(false);
        form.setFieldsValue(eduData);
      }
    });
  };

  const getMedicalHistoryApi = () => {
    setisPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    var param = searchParams.get("id") ?? "0";
    getMedicalHistory(param).then((res) => {
      var medical = res.data.responseData;
      if (res.data.responseCode == 200) {
        setDocumentData(medical);
        setisPopUpLoader(false);
      } else {
        FlashMessage("error", res.data.responseMessage);
      }
    });
  };

  const formSubmit = () => {
    form.submit();
  };

  const addPopUp = () => {
    setIsAdd(true);
    setOpenEdit(true);
    resetFormValues();
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      physicianName: "",
      physicianPhoneNumber: "",
      physicianAddress: "",
      medicationReason: "",
      hospitalName: "",
      treatmentStartDate: null,
      lastVisitDate: null,
      type1: "",
      type2: "",
      type3: "",
      physicianType: "",
      nextScheduledVisitDate: null,
      addressTwo: "",
      zip: "",
      faxNumber: "",
    };

    setAddressValue(null);
    setType1(false);
    setType2(false);
    setType3(false);
    setTimeout(() => {
      setAppData(formDefaultValues);

      setAddressValue(formDefaultValues.physicianAddress);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };
  const onkeyup = (e: any) => {
    var onlyNumber = e.target.value
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split("-")
      .join("")
      .split(" ")
      .join("");
    if (onlyNumber.length > 10) {
      onlyNumber = onlyNumber.substring(0, 10);
    }
    form.setFieldsValue({ physicianPhoneNumber: onlyNumber });
  };

  const onFacilitySelect = (e: any) => {
    if (e.target.textContent === "") return;
    const selectedItem = facilityList.filter(
      (item) =>
        `${item.hospitalName} (${item.physicianAddress}, ${item.zip}, ${item.physicianPhoneNumber})` ===
        e.target.textContent
    )[0];
    form.setFieldsValue({
      physicianPhoneNumber: selectedItem?.physicianPhoneNumber ?? "",
      physicianAddress: selectedItem?.physicianAddress ?? "",
      addressTwo: selectedItem?.addressTwo ?? "",
      zip: selectedItem?.zip ?? "",
    });
    setAddressValue(selectedItem?.physicianAddress ?? "");
  };
  const predefinedValue = {
    label: addressValue,
    value: Constants.googleAutoCompleteStaticId,
  };
  return (
    <>
      {openEdit && (
        <Modal
          title={
            isAdd
              ? "Add Hospital/Doctor Visits"
              : "Update Hospital/Doctor Visits"
          }
          centered
          okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
          open={openEdit}
          onOk={() => (isPopUpLoader ? null : formSubmit())}
          onCancel={() => setOpenEdit(false)}
          width={1000}
        >
          <>
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <>
                <p className='fill_description mt-2 text-center mb-1'>
                  Please provide any hospital/doctor visits related to your
                  physical/mental condition.
                </p>

                <Form
                  className='mt-4'
                  form={form}
                  name='MedicalHistoryForm'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  initialValues={appdata}
                  autoComplete='off'
                  onFinish={addUpdateMedicalHistoryApi}
                  onFieldsChange={() => {
                    setMedicalFacilitySearchQuery(
                      form.getFieldValue("hospitalName")
                    );
                  }}
                >
                  <div className='d-flex mb-4 mobile_flex'>
                    <Form.Item name='id' />
                    <label className='col-form-label label_form_qs ml-0'>
                      a. Name of Hospital/Facility{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='hospitalName'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <AutoComplete
                          className='search_ant_drp'
                          dropdownStyle={{
                            textTransform: "capitalize",
                          }}
                          onClick={onFacilitySelect}
                          dropdownMatchSelectWidth={252}
                          options={options}
                        >
                          <Input
                            placeholder='Search by medication name'
                            className='input_style text-capitalize'
                          />
                        </AutoComplete>
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      b. First Visit Date{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='treatmentStartDate'
                        getValueFromEvent={(onChange) =>
                          onChange == null
                            ? null
                            : dayjs(onChange).format(dateFormat)
                        }
                        getValueProps={(i) => ({
                          value: i == null ? null : dayjs(i),
                        })}
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                      >
                        <DatePicker format={dateFormat} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      c. Last Visit Date{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='lastVisitDate'
                        getValueFromEvent={(onChange) =>
                          onChange == null
                            ? null
                            : dayjs(onChange).format(dateFormat)
                        }
                        getValueProps={(i) => ({
                          value: i == null ? null : dayjs(i),
                        })}
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                      >
                        <DatePicker
                          format={dateFormat}
                          disabledDate={(d) =>
                            d.isBefore(form.getFieldValue("treatmentStartDate"))
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      d. Next Scheduled Visit Date{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='nextScheduledVisitDate'
                        getValueFromEvent={(onChange) =>
                          onChange == null
                            ? null
                            : dayjs(onChange).format(dateFormat)
                        }
                        getValueProps={(i) => ({
                          value: i == null ? null : dayjs(i),
                        })}
                      >
                        <DatePicker format={dateFormat} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      e. Phone Number{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='physicianPhoneNumber'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                          { validator: number },
                          { validator: minLength },
                        ]}
                      >
                        <Input
                          onKeyUpCapture={onkeyup}
                          maxLength={15}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      f. Address of Hospital/Facility{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='physicianAddress'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        {addressValue != null && (
                          <GooglePlacesAutoCompleteWrapper
                            key={`google_place_autocomplete_${addressValue}`}
                            apiKey={process.env.REACT_APP_API_KEY ?? ""}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              value: predefinedValue,
                              isClearable: false,
                              onChange: (o: any) => {
                                form.setFieldsValue({
                                  physicianAddress: o?.label ?? "",
                                });
                                setAddressValue(o?.label ?? "");
                              },
                              onclose: (o: any) => {
                                setAddressValue(o?.label ?? "");
                              },
                            }}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      g. Address 2 (optional){" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='addressTwo'
                        rules={[
                          {
                            validator: validateBlankSpace,
                          },
                          {
                            validator: lessThanFourCharacters,
                          },
                        ]}
                      >
                        <Input
                          placeholder='i.e. Apt,Ste, Fl, Unit, etc..'
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      h. Zip Code{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='zip'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                          { validator: number },
                          {
                            validator: validateZipCode,
                          },
                        ]}
                      >
                        <Input
                          placeholder='zip code'
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      i. Reason for visit{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='medicationReason'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={1000}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      j. Physician Name{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='physicianName'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={50}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex mb-4 mobile_flex'>
                    <label className='col-form-label label_form_qs ml-0'>
                      k. Fax Number{" "}
                    </label>
                    <div className='w-100'>
                      <Form.Item
                        name='faxNumber'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                          { validator: number },
                          { validator: minLength },
                        ]}
                      >
                        <Input
                          maxLength={10}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      <span className='margin_label_end'>
                        l. Physician Type{" "}
                      </span>
                      <p className='choose_one margin_label_end'>
                        (Select all that apply)
                      </p>
                    </label>
                    <div className='col-sm-9'>
                      <div className='d-flex align-items-center'>
                        Medical Physician
                        <input
                          checked={type1}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type1'
                          value='option'
                          onChange={handleTypeOne}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        Psychiatrist (Mental Health)
                        <input
                          checked={type2}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type2'
                          value='option'
                          onChange={handleTypeTwo}
                        />
                        <span className='checkmark'></span>
                      </div>
                      <div className='d-flex align-items-center'>
                        Psychologist / Therapist (Mental Health)
                        <input
                          checked={type3}
                          className='form-check ms-2'
                          type='checkbox'
                          name='type3'
                          value='option'
                          onChange={handleTypeThree}
                        />
                        <span className='checkmark'></span>
                      </div>
                    </div>
                  </div>

                  <p className='click_doc text-center'>
                    Click save to enter additional hospital/doctor visits
                  </p>
                </Form>
              </>
            )}
          </>
        </Modal>
      )}
      {open && (
        <Modal
          title='Hospital/Doctor Visits'
          centered
          footer={null}
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {isPopUpLoader && <Loader></Loader>}
          {!isPopUpLoader && (
            <Table
              dataSource={documentData}
              pagination={{
                pageSize: Constants.gridDefaultPageSize,
                showSizeChanger: false,
              }}
              columns={columns}
            />
          )}{" "}
        </Modal>
      )}
      <div className='col-md-12 col-xl-4 mt-3 template_panel'>
        <div className='background_sq_boxes height_temp_box p-4 text-center position-relative'>
          <div className='popover__wrapper2 position-absolute'>
            <img className='img-fluid info' src={info} />
            <div className='popover__content3'>
              <p className='popover__message2'>
                {" "}
                Information regarding all doctor and hospital visits.
              </p>
            </div>
          </div>
          <img className='img-fluid dws_temp' src={dwdtemp} />
          <h5 className='boxes_headings mb-3 ps-4 pe-4'>
            Hospital/Doctor Visits
          </h5>
          <Flex gap={16} justify='center' vertical>
            <Button onClick={getMedicalHistoryApi} type='default'>
              View
            </Button>
            <Button onClick={() => addPopUp()} type='primary'>
              Add
            </Button>
          </Flex>
          {props.doctorVisitsCount == 1 ? (
            <p className='mr_record'>{props.doctorVisitsCount} record </p>
          ) : (
            <p className='mr_record'>{props.doctorVisitsCount} records</p>
          )}
        </div>
      </div>
    </>
  );
};

export default DoctorVisits;
