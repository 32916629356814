import { get } from "../../../../utils/clientStorageUtils";

export const INIT_APPLICATION_STATUSES = [
  "New Registration",
  "Pending Data Collection",
];

// If you need to tag someone in a note, you can use the following format: [Darren Webb]()
export const INTAKE_METHODS_DEFUALT_NOTE = {
  admin:
    "All information collected. EXPLAIN_WHY_HERE_AND_ANY_IMPORTANT_DETAILS CM assistant will fax rep forms to FO and tag the CM for introductory call and next steps after quality review.",
  ssa: "IA submitted.\nCover sheet and Receipt attached under the SSA correspondence template. SSI portion was included in the IA. CM assistant will fax rep forms to FO and tag the CM for introductory call and next steps after quality review.",
};

export type DatabaseOption = {
  id: number;
  name: string;
};

export type ClaimOptions = {
  caseLevels: DatabaseOption[];
  claimTypes: DatabaseOption[];
  initialApplicationOrigins: DatabaseOption[];
};

type CLAIM_UPDATE_STEP =
  | "ADMIN_INTAKE"
  | "SSA_INTAKE"
  | "CONFIRM_SUBMISSION"
  | "CONFIRM_PRIOR_SUBMISSION"
  | "CONFIRM_FAX";

export const updateClaimPayloads = (
  step: CLAIM_UPDATE_STEP,
  claim: any,
  isEligible: boolean
) => {
  const userIdData = JSON.parse(get("model")).userId;

  let payload = {
    Id: claim.id,
  };

  const IntakeCompletedByMindsetUserId = userIdData;
  const IntakeCompletedDateTimeUtc = new Date().toISOString();
  const IaSubmittedByMindsetUserId = userIdData;

  const ADMIN_INTAKE = {
    CaseLevelId: 2,
    IntakeCompletedByMindsetUserId: userIdData,
    IntakeCompletedDateTimeUtc: new Date().toISOString(),
  };

  const CONFIRM_SUBMISSION = {
    CaseLevelId: 3,
    IaSubmittedByMindsetUserId,
  };

  const step_payloads: {
    [key in CLAIM_UPDATE_STEP]: {
      Ssi: {
        CaseLevelId?: number;
        IntakeCompletedByMindsetUserId?: string;
        IntakeCompletedDateTimeUtc?: string;
        IaSubmittedByMindsetUserId?: string;
        Faxed8001ByMindsetUserId?: string;
        Faxed8001DateTimeUtc?: string;
      };
      Ssdi?: {
        CaseLevelId?: number;
        IntakeCompletedByMindsetUserId?: string;
        IntakeCompletedDateTimeUtc?: string;
        IaSubmittedByMindsetUserId?: string;
      };
    };
  } = {
    ADMIN_INTAKE: {
      Ssi: ADMIN_INTAKE,
      Ssdi: ADMIN_INTAKE,
    },
    SSA_INTAKE: {
      Ssi: {
        CaseLevelId: 3,
        IntakeCompletedByMindsetUserId,
        IntakeCompletedDateTimeUtc,
        IaSubmittedByMindsetUserId,
      },
      Ssdi: {
        CaseLevelId: 3,
        IntakeCompletedByMindsetUserId,
        IntakeCompletedDateTimeUtc,
        IaSubmittedByMindsetUserId,
      },
    },
    CONFIRM_SUBMISSION: {
      Ssi: {
        IaSubmittedByMindsetUserId,
      },
      Ssdi: CONFIRM_SUBMISSION,
    },
    CONFIRM_PRIOR_SUBMISSION: {
      Ssi: CONFIRM_SUBMISSION,
      Ssdi: CONFIRM_SUBMISSION,
    },
    CONFIRM_FAX: {
      Ssi: {
        CaseLevelId: 3,
        Faxed8001ByMindsetUserId: userIdData,
        Faxed8001DateTimeUtc: new Date().toISOString(),
      },
    },
  };

  const claimTypeKey =
    claim.ClaimTypeIdDisplayNameReadOnly as keyof (typeof step_payloads)[CLAIM_UPDATE_STEP];

  return {
    ...payload,
    ...step_payloads[step][claimTypeKey],
    isEligible: isEligible,
  };
};

export const PAY_FREQUENCY_OPTIONS = [
  {
    id: 1,
    name: `Hourly`,
  },
  {
    id: 2,
    name: `Daily`,
  },
  {
    id: 3,
    name: `Bi-Weekly`,
  },
  {
    id: 4,
    name: `Weekly`,
  },
  {
    id: 5,
    name: `Monthly`,
  },
  {
    id: 6,
    name: `Yearly`,
  },
];
