import { List, Button, Flex, Empty, Card } from "antd";
import "./index.scss";
import { DeleteOutlined, FileOutlined } from "@ant-design/icons";
import { TemplateOption } from "./index";

interface BundleList {
  items: (TemplateOption | undefined)[];
  onRemoveItem: (bundleListItem: TemplateOption) => void;
}

const BundleList = (props: BundleList) => {
  return (
    <Card>
      <List
        itemLayout='horizontal'
        locale={{
          emptyText: (
            <Empty
              description='Add a Template above to create a Document Bundle'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        dataSource={props.items}
        renderItem={(item) => {
          const displayLabel =
            item?.label === "Medical Records Request"
              ? "Medical Records Request Cover Letter"
              : item?.label;

          return (
            <List.Item
              actions={[
                <Button
                  id='listActionDeleteButton'
                  className='listActionButton'
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => {
                    props.onRemoveItem(item || { value: -1, label: "none" });
                  }}
                />,
              ]}
            >
              <Flex align='center' gap={8}>
                <FileOutlined />
                {displayLabel}
              </Flex>
            </List.Item>
          );
        }}
      />
    </Card>
  );
};

export default BundleList;
