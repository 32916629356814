import axios from "axios";
import {
  addMedicationName,
  getApplicationDetailsById,
  searchMedicationNames,
} from "../../../actions/application";
import { saveChildrenInformation } from "../../../actions/childrenInformation";
import { saveCollegeHistory } from "../../../actions/collegeHistory";
import dayjs from "dayjs";
import { saveEducationHistory } from "../../../actions/highSchoolInfo";
import { Constants } from "../../../common/constants";
import { saveEmergencyContact } from "../../../actions/emergencyContact";
import { saveMarriageInfo } from "../../../actions/marriageInfo";
import {
  getMedicalHistory,
  saveMedicalHistory,
} from "../../../actions/doctorsVisits";
import { saveMedicationDetails } from "../../../actions/medicationDetails";
import { saveWorkHistory } from "../../../actions/workHistory";
import { PAY_FREQUENCY_OPTIONS } from "./Claims/constants";

const createSelfServeTemplates = async (encryptedId: string) => {
  console.log("AUTO CREATE TEMPLATES");
  // create Templates
  const app_response = await getApplicationDetailsById(encryptedId);

  const userDetails = app_response.data.responseData;

  const intake_response = await axios(
    `/IntakeQuestionnaire?claimantMindsetUserId=${userDetails.id}`
  );

  let intakeDetails = {};

  if (intake_response.data.responseData) {
    intakeDetails = intake_response.data.responseData;
  }

  const ssa_response = await axios(
    `/GetByClaimantId?claimantId=${userDetails.id}`
  );

  let ssa_details = {};

  if (ssa_response.data.responseData) {
    ssa_details = JSON.parse(ssa_response.data.responseData.json || "{}");
  }

  const self_serve_details = {
    ...userDetails,
    ...intakeDetails,
    ...ssa_details,
  };

  let payload = {
    Id: 0,
    EncryptedUserId: encryptedId,
  };

  self_serve_details.childrenNames.forEach(
    async (record: any, index: number) => {
      await saveChildrenInformation({
        ...payload,
        FirstName: record.firstName,
        LastName: record.lastName,
      });
    }
  );

  const collegeEnabled = [
    "1 Year of College",
    "2 Years of College",
    "3 Years of College",
    "4 or More Years of College",
  ].includes(self_serve_details.highestLevelOfEducation);

  if (collegeEnabled) {
    await saveCollegeHistory({
      ...payload,
      CollegeName: self_serve_details.schoolName,
      CollegeEndDate: dayjs(self_serve_details.lastSchoolYear).utc().format(),
      Address:
        self_serve_details.schoolCity + ", " + self_serve_details.schoolState,
    });
  } else {
    await saveEducationHistory({
      ...payload,
      DateOfCertificate: self_serve_details.lastSchoolYear
        ? dayjs(self_serve_details.lastSchoolYear).format(
            Constants.dateFormatWithoutUtc
          )
        : null,
      IsGED: self_serve_details.highestLevelOfEducation === "GED",
      IsHighSchoolDiploma: self_serve_details.highestLevelOfEducation === "GED",
      HighGradeCompleted: self_serve_details.highestLevelOfEducation,
      LastHighSchoolAttended: self_serve_details.schoolName,
      HighSchoolCity: self_serve_details.schoolCity,
      HighSchoolState: self_serve_details.schoolState,
      IsSpecialEducation: self_serve_details.isSpecialEducation,
    });
  }

  self_serve_details.helpingContacts.forEach(async (record: any) => {
    await saveEmergencyContact({
      ...payload,
      FirstName: record.firstName,
      LastName: record.lastName,
      PhoneNumber: record.phone,
      StreetAddress: record.address,
      Relationship: record.relationship,
    });
  });

  let marriages = (self_serve_details.priorMarriages || []).map(
    (record: any) => {
      return {
        ...payload,
        DateOfMarriage: dayjs(record.marriageDate).format(
          Constants.dateFormatWithoutUtc
        ),
        CityOfMarriage: record.marriageCity,
        StateOfMarriage: record.marriageState,
        DateOfDivorce: dayjs(record.divorceDate).format(
          Constants.dateFormatWithoutUtc
        ),
        CityOfDivorce: record.divorceCity,
        StateOfDivorce: record.divorceState,
        SpouseName: `${record.spouseFirstName} ${record.spouseLastName}`,
        SpouseDob: record.spouseDOB
          ? dayjs(record.spouseDOB).format(Constants.dateFormatWithoutUtc)
          : null,
        SpouseSSN: record.spouseSSN,
      };
    }
  );

  if (self_serve_details.isMarried) {
    marriages.push({
      ...payload,
      DateOfMarriage: dayjs(self_serve_details.marriageDate).format(
        Constants.dateFormatWithoutUtc
      ),
      CityOfMarriage: self_serve_details.marriageCity,
      StateOfMarriage: self_serve_details.marriageState,
      SpouseName: `${self_serve_details.spouseFirstName} ${self_serve_details.spouseLastName}`,
      SpouseDob: self_serve_details.spouseDOB
        ? dayjs(self_serve_details.spouseDOB).format(
            Constants.dateFormatWithoutUtc
          )
        : null,
      SpouseSSN: self_serve_details.spouseSSN,
    });
  }

  marriages.forEach(async (record: any, index: number) => {
    await saveMarriageInfo(record);
  });

  const work_history = self_serve_details.jobListings.map((record: any) => ({
    ...payload,
    CompanyName: record.companyName,
    JobTitle: record.jobTitle,
    EmployedFrom: dayjs(record.startDate).format(
      Constants.dateFormatWithoutUtc
    ),
    EmployedTo: record.endDate
      ? dayjs(record.endDate).format(Constants.dateFormatWithoutUtc)
      : null,
    IsCurrentlyWorking: record.endDate ? false : true,
    HoursPerDay: parseInt(record.hoursPerDay),
    DaysPerWeek: parseInt(record.daysPerWeek),
    PayAmount: parseFloat(parseFloat(record.amountOfPay).toFixed(2)),
    PayFrequency: (
      PAY_FREQUENCY_OPTIONS.find(
        (option: any) => option.name === record.payFrequency
      ) || {}
    ).id,
  }));

  self_serve_details.employers.forEach(async (record: any) => {
    work_history.push({
      ...payload,
      CompanyName: record.name,
      CompanyAddress: record.address,
      EmployedFrom: dayjs(record.startDate).format(
        Constants.dateFormatWithoutUtc
      ),
      EmployedTo: record.hasEnded
        ? dayjs(record.endDate).format(Constants.dateFormatWithoutUtc)
        : null,
      IsCurrentlyWorking: record.hasEnded ? false : true,
    });
  });

  work_history.forEach(async (record: any) => {
    try {
      console.log(record);
      await saveWorkHistory(record);
    } catch (err) {
      console.error(err);
    }
  });

  (self_serve_details.hospitals || []).forEach(async (record: any) => {
    await saveMedicalHistory({
      ...payload,
      PhysicianName: `${record.suffix} ${record.firstName} ${record.middleName} ${record.lastName}`,
      TreatmentStartDate: dayjs(record.firstSeen).format(
        Constants.dateFormatWithoutUtc
      ),
      PhysicianAddress: record.officeAddress,
      PhysicianPhoneNumber: record.phone,
      MedicationReason: (record.medicalConditions || []).join(", "),
      HospitalName: record.officeName,
      LastVisitDate: dayjs(record.lastSeen).format(
        Constants.dateFormatWithoutUtc
      ),
      NextScheduledVisitDate: record.nextAppointment
        ? dayjs(record.nextAppointment).format(Constants.dateFormatWithoutUtc)
        : null,
    });
  });

  const {
    data: { responseData: doctors },
  } = await getMedicalHistory(encryptedId);

  // console.log(doctors);
  // console.log(self_serve_details.medicines);

  self_serve_details.medicines.forEach(async (record: any) => {
    const PhysicianId = (
      doctors.find(
        (doctor: any) => doctor.hospitalName === record.officeName
      ) || {}
    ).id;

    if (PhysicianId) {
      const resData = await searchMedicationNames(record.name);
      const filterRecord = resData.data ?? [];
      let existingMedication = filterRecord.filter(
        (item: any) =>
          item.name.trim().toLowerCase() === record.name.trim().toLowerCase()
      )[0];

      if (!existingMedication) {
        existingMedication = (await addMedicationName(record.name, false)).data;
      }

      await saveMedicationDetails({
        ...payload,
        PhysicianId,
        MedicationId: existingMedication.id,
        PrescriptionDetails: record.reason,
      });
    }
  });

  return true;
};

export default createSelfServeTemplates;
