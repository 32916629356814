import React, { useEffect, useState } from "react";
import AddressInputs from "./SharedFields/AddressWithNameField";
import { Form, Select } from "antd";
import { DocumentOptionType, MedicalProvider } from "..";
import { FileOutlined, MedicineBoxOutlined } from "@ant-design/icons";

interface MedicalRecordsHipaaAuthorizationProps {
  documents: DocumentOptionType[];
  form: any;
  providers: MedicalProvider[];
}

const MedicalRecordsHipaaAuthorization: React.FC<
  MedicalRecordsHipaaAuthorizationProps
> = ({ documents, form, providers }) => {
  const [providerSelected, setProviderSelected] = useState(false);

  useEffect(() => {
    const authorizationDocument = documents.find((doc) =>
      doc.label.includes("Authorization_to_Disclosed_PHI")
    );
    if (authorizationDocument) {
      form.setFieldValue("includedDocuments", [authorizationDocument.value]);
    }
  }, [documents, form]);

  const handleProviderSelect = (providerId?: number) => {
    if (!providerId) {
      form.resetFields([
        "addressName",
        "addressLine1",
        "city",
        "state",
        "country",
        "line1",
        "line2",
        "zip",
      ]);
      setProviderSelected(false);
      return;
    }

    const provider = providers.find((p) => p.id === providerId);
    if (!provider) return;

    const [addressLine1, city, state, country] = provider.physicianAddress
      .split(", ")
      .map((item) => item.trim());

    form.setFieldsValue({
      addressName: provider.hospitalName,
      addressLine1,
      city,
      state,
      country,
      line1: addressLine1,
      line2: provider.addressTwo,
      zip: provider.zip,
    });

    setProviderSelected(true);
  };

  return (
    <>
      <Form.Item label='Fill provider address from template'>
        <Select
          prefix={<MedicineBoxOutlined />}
          style={{ maxWidth: 300 }}
          placeholder='Select a provider'
          onChange={handleProviderSelect}
          allowClear
        >
          {providers.map((provider) => (
            <Select.Option key={provider.id} value={provider.id}>
              {provider.hospitalName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <AddressInputs
        label='Provider Name'
        disabledAddressFields={providerSelected}
      />

      <Form.Item
        required
        name='includedDocuments'
        label='Signed Mindset Form (Authorization to Disclosed PHI)'
      >
        <Select
          prefix={<FileOutlined />}
          options={documents}
          placeholder='Please select the authorization form'
          showSearch
          optionFilterProp='label'
        />
      </Form.Item>
    </>
  );
};

export default MedicalRecordsHipaaAuthorization;
