import { Button, Card, Spin, Typography } from "antd";
import { get } from "../../../utils/clientStorageUtils";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import ClaimCard from "./ClaimCard";
import CompleteIntake from "./CompleteIntake";
import { getClaimOptions } from "../../../actions/claims";
import ConfirmSubmission from "./ConfirmSubmission";
import CompleteRegistration from "./CompleteRegistration";
import { Constants } from "../../../common/constants";
import axios from "axios";
import { pipelineStatusDetails } from "../../../actions/pipeline";
import { ClaimOptions, INIT_APPLICATION_STATUSES } from "./Claims/constants";
import "./style.css";
import BrandButton from "../../BrandButton";
import { UndoOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { notificationContactMessage } from "../../../actions/taskManagement";
import { FlashMessage } from "../../../utils/flash_message";
import createSelfServeTemplates from "./createSelfServeTemplates";

const { Title } = Typography;

const CustomerClaims = ({
  claims,
  getClaimantClaims,
  claimantMindsetUserId,
  applicationStatus,
  updateApplicationStatus,
  caseManagerName,
  showPreClaimFlow,
  isIntakeReviewStarted,
  isIntakeReviewAccepted,
  getApplicationDetailsByIdApi,
  updateDocusignEligible,
  isSelfServeRegistration,
  onReject,
  isJailUser,
}: {
  claims: any[];
  getClaimantClaims: Function;
  claimantMindsetUserId: number;
  applicationStatus: string;
  updateApplicationStatus: Function;
  caseManagerName: string;
  showPreClaimFlow: boolean;
  isIntakeReviewStarted: boolean | null;
  isIntakeReviewAccepted: boolean | null;
  getApplicationDetailsByIdApi: Function;
  updateDocusignEligible: Function;
  isSelfServeRegistration: boolean;
  onReject: Function;
  isJailUser: boolean;
}) => {
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const selfMindsetUserId = JSON.parse(get("model")).userId;

  const [isLoading, setIsLoading] = useState(true);
  const [claimOptions, setClaimOptions] = useState<ClaimOptions | null>();
  const [applicationStatuses, setApplicationStatuses] = useState<any>([]);
  const [canDelete, setCanDelete] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const mountClaimOptions = async () => {
    const response = await getClaimOptions();
    setClaimOptions(response.data.claimOptions);
  };

  useEffect(() => {
    const loadAsyncData = async () => {
      await getClaimantClaims();
      await mountClaimOptions();
      setTimeout(() => setIsLoading(false), 500);
    };
    if (claimantMindsetUserId && applicationStatus) {
      loadAsyncData();
    }
  }, [claimantMindsetUserId, applicationStatus]);

  const getApplicationStatuses = async () => {
    const response = await pipelineStatusDetails();
    setApplicationStatuses(response.data.responseData);
  };

  useEffect(() => {
    getApplicationStatuses();
  }, []);

  const fetchClaimsUpdateApplicationStatus = async (
    statusName: "Pending Onboarding"
  ) => {
    setIsLoading(true);
    await getClaimantClaims();
    const newApplicationStatus = applicationStatuses.find(
      (status: any) => status.statusName === statusName
    );
    await updateApplicationStatus(newApplicationStatus.statusId, true);

    if (statusName === "Pending Onboarding") {
      await createSelfServeTemplates(searchParams.get("id") ?? "0");
    }
    setTimeout(() => setIsLoading(false), 500);
  };

  const moveStatusToStatus = async (
    statusName: string,
    pipelineName: string | undefined = undefined
  ) => {
    setIsLoading(true);
    await getClaimantClaims();
    const filteredStatuses = pipelineName
      ? applicationStatuses.filter(
          (status: any) => status.pipelineName === pipelineName
        )
      : applicationStatuses;
    const newApplicationStatus = filteredStatuses.find(
      (status: any) => status.statusName === statusName
    );
    await updateApplicationStatus(newApplicationStatus.statusId, true);
    setTimeout(() => setIsLoading(false), 500);
  };

  const registrationCompleted = async () => {
    setCanDelete(true);
    getClaimantClaims();
  };

  const onBeginFitReview = async () => {
    if (window.confirm("Are you sure you want to begin the claimant review?")) {
      setIsLoading(true);
      const {
        data: { responseData },
      } = await axios.get(
        `ClaimantAssignment?claimantMindsetUserId=${claimantMindsetUserId}`
      );

      if (responseData) {
        await axios.patch(
          `ClaimantAssignment?claimantMindsetUserId=${claimantMindsetUserId}`,
          {
            id: responseData.id,
            IntakeSpecialistMindsetUserId: selfMindsetUserId,
            version: responseData.version,
          }
        );
      } else {
        await axios.post(`ClaimantAssignment`, {
          ClaimantMindsetUserId: claimantMindsetUserId,
          IntakeSpecialistMindsetUserId: selfMindsetUserId,
        });
      }
      await axios.post(`UpdateIsIntakeReviewStarted`, {
        claimantMindsetUserId: claimantMindsetUserId,
        isIntakeReviewStarted: true,
      });

      await getApplicationDetailsByIdApi();
      setTimeout(() => setIsLoading(false), 500);
    }
  };

  const undoFitReviewStart = async () => {
    setIsLoading(true);
    await axios.post(`UpdateIsIntakeReviewStarted`, {
      claimantMindsetUserId: claimantMindsetUserId,
      isIntakeReviewStarted: null,
    });

    await getApplicationDetailsByIdApi();

    const {
      data: { responseData },
    } = await axios.get(
      `ClaimantAssignment?claimantMindsetUserId=${claimantMindsetUserId}`
    );

    await axios.delete("ClaimantAssignment", {
      data: {
        id: responseData.id,
        DeleteAssignedIntakeSpecialist: true,
        version: responseData.version,
      },
    });

    setTimeout(() => setIsLoading(false), 500);
  };

  const onAcceptClicked = async () => {
    if (
      window.confirm(
        "The Claimant’s Care dashboard will show they’ve been accepted and they will get a notification. Click okay to accept."
      )
    ) {
      setIsLoading(true);
      await axios.post(`UpdateIsIntakeReviewAccepted`, {
        claimantMindsetUserId: claimantMindsetUserId,
        IsIntakeReviewAccepted: true,
      });

      await getApplicationDetailsByIdApi();
      await updateDocusignEligible(true, false);

      if (isJailUser) {
        await moveStatusToStatus("Qualified - Pending Release", "Tablet Users");
        const param = searchParams.get("id") ?? "0";
        var payload = {
          EncryptedUserId: param,
          Message:
            "Great news! We’ve reviewed your information and can help with your disability benefits. We’ll need more details about your life to file your Social Security disability application. You can give us that information now. This is important so we can apply for benefits as soon as possible after you’re released.",
        };
        notificationContactMessage(payload).then((res: any) => {
          if (res.data.responseCode == 200) {
            FlashMessage(
              "success",
              "Claimant has been accepted and a notification has been sent."
            );
          } else {
            FlashMessage("error", "Kindly update intake specialist to proceed");
          }
        });
      } else if (isSelfServeRegistration) {
        await moveStatusToStatus("Pending Docusign", "Intake Data");
      } else {
        await moveStatusToStatus("Pending Registration", "Intake Data");
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.set("isSecureRegistrationLinkModalOpen", "true");
        setSearchParams(updatedSearchParams);
      }
      setIsLoading(false);
    }
  };
  const onRejectClicked = async () => {
    setIsLoading(true);
    await axios.post(`UpdateIsIntakeReviewAccepted`, {
      claimantMindsetUserId: claimantMindsetUserId,
      IsIntakeReviewAccepted: false,
    });
    onReject();

    await getApplicationDetailsByIdApi();
    setIsLoading(false);
  };

  const onRejectUndoClicked = async () => {
    setIsLoading(true);
    await axios.post(`UpdateIsIntakeReviewAccepted`, {
      claimantMindsetUserId: claimantMindsetUserId,
      undoReject: true,
    });

    await getApplicationDetailsByIdApi();
    setIsLoading(false);
  };

  const undoFitReviewAcceptance = async () => {
    setIsLoading(true);
    await axios.post(`UpdateIsIntakeReviewAccepted`, {
      claimantMindsetUserId: claimantMindsetUserId,
      IsIntakeReviewAccepted: undefined,
    });

    await getApplicationDetailsByIdApi();
    await updateDocusignEligible(false, false);

    if (isSelfServeRegistration) {
      await moveStatusToStatus("Intake Survey Completed", "Self - Serve");
    } else {
      await moveStatusToStatus("Pending Review");
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   createSelfServeTemplates(searchParams.get("id") ?? "0");
  // }, []);

  return (
    <div id='claim-area'>
      <Title level={3} className='brand-header'>
        Claims
      </Title>
      {!isLoading ? (
        <>
          {showPreClaimFlow ? (
            isIntakeReviewStarted ? (
              isIntakeReviewAccepted === false ? (
                <div>
                  This claimant was not accepted by Mindset and Claims cannot be
                  created. Click “Undo Review Rejection” to redo this decision.
                  <Button
                    className='undo-button p-0'
                    type='text'
                    icon={<UndoOutlined />}
                    loading={isLoading}
                    onClick={onRejectUndoClicked}
                  >
                    Undo Review Rejection
                  </Button>
                </div>
              ) : (
                <Card className='claim-card'>
                  <div>
                    Complete intake screening for the Claimant and Accept or
                    Reject them for Mindset services.
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      style={{ marginRight: "20px" }}
                      loading={isLoading}
                      onClick={onRejectClicked}
                    >
                      Reject
                    </Button>
                    <BrandButton label='Accept' onClick={onAcceptClicked} />
                  </div>

                  <Button
                    className='undo-button p-0'
                    type='text'
                    icon={<UndoOutlined />}
                    loading={isLoading}
                    onClick={undoFitReviewStart}
                    data-cy='claims-undo-registration-btn'
                  >
                    Undo Registration
                  </Button>
                </Card>
              )
            ) : (
              <Card className='claim-card'>
                <div>
                  This will assign the Claimant to you and allow you to reject
                  or accept this Claimant for Mindset services.
                </div>
                <BrandButton
                  label='Begin Review'
                  onClick={onBeginFitReview}
                  dataCy='begin-claimant-fit-btn'
                  style={{ marginTop: "5px" }}
                />
              </Card>
            )
          ) : (
            <>
              {claims.length === 0 &&
                !INIT_APPLICATION_STATUSES.includes(applicationStatus) && (
                  <Card className='claim-card'>
                    <p>
                      Claims can not be created until a Rep Form has been signed
                      and Registration is marked Completed.
                    </p>
                    <Button
                      className='undo-button p-0'
                      type='text'
                      icon={<UndoOutlined />}
                      loading={isLoading}
                      onClick={undoFitReviewAcceptance}
                    >
                      Undo Review Acceptance
                    </Button>
                  </Card>
                )}

              {claims.length === 0 &&
                INIT_APPLICATION_STATUSES.includes(applicationStatus) && (
                  <Card className='claim-card'>
                    <CompleteRegistration
                      claimTypes={claimOptions!.claimTypes}
                      claimantMindsetUserId={claimantMindsetUserId}
                      onSuccess={registrationCompleted}
                      isAllowed={Constants.userPermissions.includes(
                        roleInStorage
                      )}
                    />

                    <Button
                      className='undo-button p-0'
                      type='text'
                      icon={<UndoOutlined />}
                      loading={isLoading}
                      onClick={undoFitReviewAcceptance}
                    >
                      Undo Review Acceptance
                    </Button>
                  </Card>
                )}

              {claims.length > 0 &&
                claims.filter(
                  (claim: any) => !claim.intakeCompletedByMindsetUserId
                ).length > 0 && (
                  <Card className='claim-card'>
                    <CompleteIntake
                      claims={claims}
                      onSuccess={fetchClaimsUpdateApplicationStatus}
                      onDelete={getClaimantClaims}
                      isAllowed={Constants.userPermissions.includes(
                        roleInStorage
                      )}
                      caseManagerName={caseManagerName}
                      canDelete={canDelete}
                    />
                  </Card>
                )}

              {claims.length > 0 &&
                claims.filter(
                  (claim: any) =>
                    claim.intakeCompletedByMindsetUserId &&
                    !claim.iaSubmittedByMindsetUserId
                ).length > 0 && (
                  <Card className='claim-card'>
                    <ConfirmSubmission
                      claims={claims}
                      onSuccess={() =>
                        fetchClaimsUpdateApplicationStatus("Pending Onboarding")
                      }
                      claimOptions={claimOptions!}
                      isAllowed={[
                        ...Constants.caseManagerGroup,
                        Constants.appUserRoles.lawyer,
                      ].includes(roleInStorage)}
                    />
                  </Card>
                )}

              {claims.length > 0 && (
                <div id='claim-cards-container'>
                  {claims
                    .sort((a: any, b: any) =>
                      a.ClaimTypeIdDisplayNameReadOnly === "Ssdi" ? -1 : 1
                    )
                    .map((claim: any) => (
                      <ClaimCard
                        key={claim.id}
                        claim={claim}
                        getClaimantClaims={getClaimantClaims}
                        claimOptions={claimOptions!}
                      />
                    ))}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <Card className='dashed-card text-center'>
          <Spin indicator={<LoadingOutlined spin />} />
        </Card>
      )}
    </div>
  );
};

export default CustomerClaims;
