import { useNavigate, useSearchParams } from "react-router-dom";
import { getProfileViewDetails } from "../../actions/application";
import {
  formatPhoneNumber,
  getOnlyShowMyCasesFromSearchParams,
} from "../../utils/commonHelper";
import { useState } from "react";
import { AutoComplete, Input, theme, Tooltip } from "antd";
import { searchLeadsandClients } from "../../actions/application";
import type { SelectProps } from "antd/es/select";
import { get } from "../../utils/clientStorageUtils";
import { Constants } from "../../common/constants";
import "./searchBar.scss";
import { SearchOutlined } from "@ant-design/icons";

const { useToken } = theme;

let timeoutId: null | ReturnType<typeof setTimeout> = null;
let timeoutId1: null | ReturnType<typeof setTimeout> = null;

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState();
  const navigate = useNavigate();
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useToken();

  let isProfile = false;
  let isSearch = false;

  const navigateClient = (encryptedId: any, item: any) => {
    if (item.isLead == true) {
      navigate(`/${Constants.pageRoutes.leadProfile}?id=${item.id}`);
    } else if (roleInStorage != Constants.appUserRoles.preQualMsr) {
      navigate(
        `/${
          Constants.pageRoutes.claimantProfile
        }?id=${encryptedId}&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(
          searchParams
        )}`
      );
    }
  };

  const navigateProfileView = (item: any) => {
    if (item.mindsetUserId == null) {
      navigate(`/${Constants.pageRoutes.leadProfile}?id=${item.leadId}`);
    } else if (roleInStorage != Constants.appUserRoles.preQualMsr) {
      navigate(
        `/${Constants.pageRoutes.claimantProfile}?id=${
          item.encryptedId
        }&onlyShowMyCases=${getOnlyShowMyCasesFromSearchParams(searchParams)}`
      );
    }
  };

  const getProfileViewDetailsApi = () => {
    isProfile = true;

    getProfileViewDetails().then((res) => {
      if (res.data.responseCode == 200) {
        var data1 = res.data.responseData;

        if (data1 == null || data1.length == 0) {
          isProfile = false;
          setOptions([
            {
              label: (
                <div style={{ display: "flex" }}>
                  <span> No Record</span>
                </div>
              ),
            },
          ]);
        } else {
          isProfile = false;
          setOptions(
            data1.map((item: any) => {
              const newItem = JSON.stringify(item);
              return {
                value: "",
                label: (
                  <div
                    className={
                      item.mindsetUserId == null
                        ? "custom_leads"
                        : "custom_customer"
                    }
                    onClick={() => navigateProfileView(item)}
                    style={{ display: "flex" }}
                  >
                    {item.mindsetUserId == null && (
                      <span className='leads_icon'>L</span>
                    )}
                    {item.mindsetUserId != null && (
                      <span className='customer_icon'>C</span>
                    )}
                    {!isProfile && (
                      <span>
                        {item.fullName + " | "}
                        <a href={`tel:${formatPhoneNumber(item.phoneNumber)}`}>
                          {formatPhoneNumber(item.phoneNumber)}
                        </a>
                      </span>
                    )}
                  </div>
                ),
                item: JSON.parse(newItem),
              };
            })
          );
        }
      }
    });
    isProfile = true;
    setOptions([
      {
        value: "",
        label: (
          <div style={{ display: "flex" }}>
            {isProfile && <span>Loading......</span>}
          </div>
        ),
      },
    ]);
  };

  const getFormattedSearchResultDisplayForItem = (item: any) => {
    let displayValue = item.fullName;

    if (item.id) {
      displayValue += " | " + item.id;
    }

    if (item.email) {
      displayValue += " | " + item.email;
    }

    if (item.maskedSsn) {
      displayValue += " | " + item.maskedSsn;
    }

    if (item.phoneNumber) {
      displayValue += " | " + formatPhoneNumber(item.phoneNumber);
    }

    return displayValue;
  };

  const searchResult = (query: string) => {
    searchLeadsandClients(query).then((res) => {
      var resData = res.data.responseData;
      var filterRecord = resData;

      if (filterRecord == null || filterRecord.length == 0) {
        isSearch = false;
        setOptions([
          {
            value: "",
            label: (
              <div style={{ display: "flex" }}>
                <span> No Record</span>
              </div>
            ),
          },
        ]);
      } else {
        isSearch = false;
        setOptions(
          filterRecord.map((item: any) => {
            const newItem = JSON.stringify(item);
            return {
              value: "",
              label: (
                <div
                  className={item.isLead ? "custom_leads" : "custom_customer"}
                  onClick={() => navigateClient(item.encryptedId, item)}
                  style={{ display: "flex" }}
                >
                  {item.isLead && <span className='leads_icon'>L</span>}
                  {!item.isLead && <span className='customer_icon'>C</span>}
                  {!isSearch && (
                    <span>{getFormattedSearchResultDisplayForItem(item)}</span>
                  )}
                </div>
              ),
              item: JSON.parse(newItem),
            };
          })
        );
      }
    });
    isSearch = true;
    setOptions([
      {
        value: "",
        label: (
          <div style={{ display: "flex" }}>
            {isSearch && !isProfile && <span>Loading...</span>}
          </div>
        ),
      },
    ]);
  };

  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);

  const handleonSearch = (value: string) => {
    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    setOptions([]);
    timeoutId = setTimeout(function () {
      value.trim().toLocaleLowerCase()
        ? searchResult(value.trim())
        : getProfileViewDetailsApi();
    }, 1000);
  };
  const handleClick = (item: any) => {
    if (timeoutId1 != null) {
      clearTimeout(timeoutId1);
    }
    if (item.target.value != "") {
      return;
    }
    const optionLength = options?.length || 0;
    if (optionLength < 10) {
      setOptions([
        {
          value: "",
          label: (
            <div style={{ display: "flex" }}>{<span>Loading......</span>}</div>
          ),
        },
      ]);
      timeoutId1 = setTimeout(function () {
        getProfileViewDetailsApi();
      }, 0);

      return;
    }
  };

  return (
    <>
      <AutoComplete
        popupMatchSelectWidth={500}
        options={options}
        onClick={handleClick}
        onSearch={handleonSearch}
        className='header-search-bar'
      >
        <Input
          suffix={
            <Tooltip
              title='Search by number, email, name, or last 4 SSN'
              color={token.colorInfo}
            >
              <SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
          placeholder='Search by number, email, name, or last 4 SSN'
          value={searchValue}
          size='large'
          style={{ height: 42 }}
        />
      </AutoComplete>
    </>
  );
};

export default SearchBar;
