import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Table,
  Tooltip,
  Select,
  Button,
  Flex,
  Typography,
} from "antd";
import { useDebounce } from "use-debounce";
import {
  addSupportUser,
  updateSupportUser,
  getSupportUsers,
  deleteSupportUser,
} from "../../actions/supportUser";
import { FlashMessage } from "../../utils/flash_message";
import { EmailExist } from "../../actions/accounts";
import { responseCodes } from "../../components/Common/constants";
import { Constants } from "../../common/constants";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { validateEmail } from "../../validations/validateEmail";
import { number } from "../../validations/numeric_only-validate";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { get } from "../../utils/clientStorageUtils";
import { minLength } from "../../validations/validate_min_length";
import Loader from "../../components/Loader";
import {
  CaseInsensitiveContains,
  containsPhoneNumber,
  formatPhoneNumber,
} from "../../utils/commonHelper";
import usePermissions from "../../common/customHooks/usePermissions";

interface SupportUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  phoneNumber: string;
  supportUserType: string;
  encryptedUserId: string;
  createdDate: string;
}

const ManageUser = () => {
  const { canWrite } = usePermissions("manageUser");
  const [form] = Form.useForm();
  const [users, setUsers] = useState<SupportUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<SupportUser[]>([]);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebounce(
    searchString,
    Constants.debounceTime
  );
  const [userById, setUserById] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const roleInStorage = JSON.parse(get("model")).userRoles[0];
  const formSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    setFilteredUsers(
      users.filter(
        (data) =>
          CaseInsensitiveContains(
            pascalCaseToSpaceSeparated(data.supportUserType),
            debouncedSearchString
          ) ||
          CaseInsensitiveContains(data.firstName, debouncedSearchString) ||
          CaseInsensitiveContains(data.lastName, debouncedSearchString) ||
          CaseInsensitiveContains(data.email, debouncedSearchString) ||
          containsPhoneNumber(data.phoneNumber, debouncedSearchString)
      )
    );
  }, [users, debouncedSearchString]);

  const addUpdateSupportUserApi = (e: any) => {
    var payload = {
      Id: e.id,
      FirstName: e.firstName.trim(),
      LastName: e.lastName.trim(),
      Email: e.email,
      PhoneNumber: e.phoneNumber,
      SupportUserType: e.supportUserType,
    };
    if (!isAdd) {
      if (
        userById.supportUserType === Constants.appUserRoles.lawyer &&
        e.supportUserType != Constants.appUserRoles.lawyer
      ) {
        FlashMessage("error", "You cannot change the role of a lawyer!");
        return;
      } else if (
        (e.supportUserType === Constants.appUserRoles.lawyer &&
          Constants.caseManagerGroup.includes(userById.supportUserType)) ||
        (userById.supportUserType === Constants.appUserRoles.lawyer &&
          Constants.caseManagerGroup.includes(e.supportUserType))
      ) {
        FlashMessage(
          "error",
          "You cannot change the role between case manager to lawyer!"
        );
        return;
      }
      setIsPopUpLoader(true);
      updateSupportUser(payload).then((res) => {
        if (res.data.responseCode == 200) {
          setIsLoader(false);
          FlashMessage("success", "Admin Info updated Successfully.");
          setOpen(false);
          setIsPopUpLoader(false);
          getSupportUsersApi();
        }
      });
    } else {
      setIsPopUpLoader(true);
      addSupportUser(payload).then((res) => {
        if (res.data.responseCode == 200) {
          setIsLoader(false);
          FlashMessage("success", "Admin added Successfully.");
          setOpen(false);
          setIsPopUpLoader(false);
          getSupportUsersApi();
        }
      });
    }
  };

  const onChangeHandler = (event: any) => {
    var target = event.target.name;
    var value = event.target.value;
    if (target === "emailUser" && value != null && value.trim() != "") {
      EmailExist(value)
        .then((res) => {
          if (res.data.responseCode == responseCodes.success) {
            if (!res.data.responseData) {
              form.setFieldsValue({ email: value });
            } else {
              FlashMessage("info", "Email Already Exists");
              form.setFieldsValue({ email: "" });
              setIsPopUpLoader(false);
              setIsLoader(false);
            }
          } else if (res.data.responseCode != responseCodes.NotFound) {
            FlashMessage("error", Constants.errorMessages.commonErrorMessage);
            setIsPopUpLoader(false);
            setIsLoader(false);
          }
        })
        .catch((Error) => {});
    }
  };

  const deleteSupportUserApi = (id: any) => {
    setIsLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteSupportUser(id).then((response) => {
        if (response.data.responseCode == 200) {
          setIsLoader(false);
          FlashMessage("success", response.data.responseMessage);
        } else if (response.data.responseCode != 200) {
          setIsLoader(false);
          FlashMessage("error", response.data.responseMessage);
        }
        getSupportUsersApi();
      });
    } else {
      setIsLoader(false);
    }
  };

  const pascalCaseToSpaceSeparated = (pascalString: any) => {
    if (!pascalString) return "";
    return pascalString
      .replace(/([a-z0-9])([A-Z])/g, "$1 $2") // Add space before uppercase letters preceded by lowercase letters or numbers
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Add space before uppercase letters preceded by uppercase letters and followed by lowercase letters
      .trim();
  };
  const getSupportUsersApi = () => {
    setIsLoader(true);
    setIsAdd(false);
    getSupportUsers().then((res) => {
      var userdata = res.data.responseData;
      if (res.data.responseCode == 200) {
        setUsers(userdata);
        setIsLoader(false);
      }
    });
  };

  const getSupportUserById = (id: any) => {
    setOpen(true);
    setIsPopUpLoader(true);
    setIsAdd(false);

    const userToEdit = users.find((user) => user.encryptedUserId === id);

    if (userToEdit) {
      setUserById(userToEdit);
      form.setFieldsValue(userToEdit);
      setIsPopUpLoader(false);
    }
  };

  useEffect(() => {
    getSupportUsersApi();
  }, []);

  const columns = [
    {
      title: "Support User Type",
      dataIndex: "supportUserType",
      key: "supportUserType",
      render: (i: any) => {
        return (
          <>
            <div>{pascalCaseToSpaceSeparated(i)}</div>
          </>
        );
      },
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (number: string) => {
        return (
          number && (
            <a href={`tel:${formatPhoneNumber(number)}`}>
              {formatPhoneNumber(number)}
            </a>
          )
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "encryptedUserId",
      key: "encryptedUserId",
      render: (id: any, item: any) => {
        if (
          (roleInStorage == Constants.appUserRoles.caseManager &&
            (item.supportUserType == Constants.appUserRoles.admin ||
              item.supportUserType == Constants.appUserRoles.superUser ||
              item.supportUserType == Constants.appUserRoles.caseManager)) ||
          (roleInStorage == Constants.appUserRoles.customerSupportService &&
            (item.supportUserType == Constants.appUserRoles.superUser ||
              item.supportUserType == Constants.appUserRoles.admin ||
              item.supportUserType == Constants.appUserRoles.caseManager ||
              item.supportUserType ==
                Constants.appUserRoles.customerSupportService)) ||
          (roleInStorage == Constants.appUserRoles.admin &&
            (item.supportUserType == Constants.appUserRoles.admin ||
              item.supportUserType == Constants.appUserRoles.superUser))
        ) {
          return <></>;
        } else {
          if (canWrite) {
            return (
              <div className='d-flex'>
                <Tooltip title={"Edit"}>
                  <a className='mr-2' onClick={() => getSupportUserById(id)}>
                    <EditFilled
                      style={{
                        fontSize: "25px",
                        color: "#747497",
                      }}
                    />
                  </a>
                </Tooltip>
                <Tooltip title={"Delete"}>
                  <a onClick={() => deleteSupportUserApi(item.id)}>
                    <DeleteFilled
                      style={{
                        fontSize: "25px",
                        color: "#747497",
                      }}
                    />
                  </a>
                </Tooltip>
              </div>
            );
          }
        }
      },
    },
  ];

  const addPopUp = () => {
    setIsAdd(true);
    setOpen(true);
    resetFormValues();
  };
  const cancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      supportUserType: "",
    };
    setTimeout(() => {
      setUserById(formDefaultValues);
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const supportUsers = [
    {
      id: 1,
      name: `Super Admin`,
      value: Constants.appUserRoles.superUser,
    },
    {
      id: 2,
      name: `General Admin`,
      value: Constants.appUserRoles.admin,
    },
    {
      id: 3,
      name: `Case Manager`,
      value: Constants.appUserRoles.caseManager,
    },
    {
      id: 4,
      name: `Customer Support Service`,
      value: Constants.appUserRoles.customerSupportService,
    },
    {
      id: 5,
      name: `Pre-Qual MSR`,
      value: Constants.appUserRoles.preQualMsr,
    },
    {
      id: 6,
      name: `Intake MSR`,
      value: Constants.appUserRoles.intakeMsr,
    },
    {
      id: 7,
      name: `Lawyer`,
      value: Constants.appUserRoles.lawyer,
    },
  ];

  const supportUsersGlobal = [
    {
      id: 1,
      name: `General Admin`,
      value: Constants.appUserRoles.admin,
    },
    {
      id: 2,
      name: `Case Manager`,
      value: Constants.appUserRoles.caseManager,
    },
    {
      id: 3,
      name: `Customer Support Service`,
      value: Constants.appUserRoles.customerSupportService,
    },
    {
      id: 4,
      name: `Pre-Qual MSR`,
      value: Constants.appUserRoles.preQualMsr,
    },
    {
      id: 5,
      name: `Intake MSR`,
      value: Constants.appUserRoles.intakeMsr,
    },
    {
      id: 6,
      name: `Lawyer`,
      value: Constants.appUserRoles.lawyer,
    },
  ];

  const onkeyup = (e: any) => {
    var onlyNumber = e.target.value
      .split("(")
      .join("")
      .split("-")
      .join("")
      .split(")")
      .join("")
      .split(" ")
      .join("");
    if (onlyNumber.length > 10) {
      onlyNumber = onlyNumber.substring(0, 10);
    }
    form.setFieldsValue({ phoneNumber: onlyNumber });
  };
  return (
    <>
      <Typography.Title level={3}>Manage Support Users</Typography.Title>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          <Modal
            title={isAdd ? "Add Support User" : "Update Support User"}
            centered
            okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
            open={open}
            onOk={() => (isPopUpLoader ? null : formSubmit())}
            onCancel={() => cancel()}
            width={1000}
          >
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <Form
                className='mt-4'
                form={form}
                name='SupportUserForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={userById}
                autoComplete='off'
                onFinish={addUpdateSupportUserApi}
              >
                <div>
                  <Form.Item name='id' />
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      1. Support User Type
                    </label>
                    <div className='col-sm-9 p-0'>
                      {(roleInStorage == Constants.appUserRoles.admin ||
                        roleInStorage == Constants.appUserRoles.lawyer) && (
                        <Form.Item
                          name='supportUserType'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                            {
                              validator: validateBlankSpace,
                            },
                          ]}
                        >
                          <Select
                            options={supportUsersGlobal.map((loc: any) => ({
                              label: loc.name,
                              value: loc.value,
                            }))}
                          />
                        </Form.Item>
                      )}
                      {(roleInStorage == Constants.appUserRoles.superUser ||
                        roleInStorage == Constants.appUserRoles.intakeMsr) && (
                        <Form.Item
                          name='supportUserType'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                            {
                              validator: validateBlankSpace,
                            },
                          ]}
                        >
                          <Select
                            options={supportUsers.map((loc: any) => ({
                              label: loc.name,
                              value: loc.value,
                            }))}
                          />
                        </Form.Item>
                      )}
                      {roleInStorage == Constants.appUserRoles.caseManager && (
                        <Form.Item
                          name='supportUserType'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                            {
                              validator: validateBlankSpace,
                            },
                          ]}
                        >
                          <Select
                            open={false}
                            options={supportUsers.map((loc: any) => ({
                              label: loc.name,
                              value: loc.value,
                            }))}
                          />
                        </Form.Item>
                      )}
                      {roleInStorage ==
                        Constants.appUserRoles.customerSupportService && (
                        <Form.Item
                          name='supportUserType'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                            {
                              validator: validateBlankSpace,
                            },
                          ]}
                        >
                          <Select
                            open={false}
                            options={supportUsers.map((loc: any) => ({
                              label: loc.name,
                              value: loc.value,
                            }))}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      2. First Name
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='firstName'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      3. Last Name
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='lastName'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      4. Email
                    </label>
                    <div className='col-sm-9 p-0'>
                      {isAdd ? (
                        <Form.Item
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                            {
                              validator: validateEmail,
                            },
                            {
                              validator: validateBlankSpace,
                            },
                          ]}
                        >
                          <Input
                            name='emailUser'
                            onBlur={onChangeHandler}
                            maxLength={100}
                            className={`form-control input_form_sign`}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: "Please input the value!",
                            },
                            {
                              validator: validateBlankSpace,
                            },
                          ]}
                        >
                          <Input
                            readOnly
                            name='emailUser'
                            maxLength={100}
                            className={`form-control input_form_sign`}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      5. Phone Number
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='phoneNumber'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          { validator: number },
                          {
                            validator: validateBlankSpace,
                          },
                          { validator: minLength },
                        ]}
                      >
                        <Input
                          onKeyUpCapture={onkeyup}
                          maxLength={10}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Modal>

          <Flex gap={8} justify='flex-end'>
            <Input
              placeholder='Search support users...'
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              style={{ maxWidth: 500 }}
            />
            {canWrite && (
              <Button type='primary' onClick={() => addPopUp()}>
                Add
              </Button>
            )}
          </Flex>

          <Table
            style={{ marginTop: 40 }}
            dataSource={filteredUsers}
            pagination={{
              pageSize: Constants.logsDefaultPageSize,
              showSizeChanger: false,
              showTotal: (total) => {
                return `Total Count:  ${total}`;
              },
            }}
            columns={columns}
          />
        </>
      )}
    </>
  );
};

export default ManageUser;
