import { useMemo } from "react";
import LeadProfileTabKeys from "../../../common/constants/LeadProfileTabKeys";
import useUser from "../../../common/customHooks/useUser";
import useFeatureFlag from "../../useFeatureFlag";
import { Constants } from "../../../common/constants";

const useAuthorizedTabs = () => {
  const user = useUser();
  const { featureFlags } = useFeatureFlag();

  const hasPermission = (permissionGroup: string[], featureFlag?: boolean) =>
    permissionGroup.includes(user.role) && (featureFlag ?? true);

  return useMemo(
    () => ({
      [LeadProfileTabKeys.NOTES_TASKS]: hasPermission(
        Constants.permissionedRolesBySection.leadNotes.readGroup
      ),
      [LeadProfileTabKeys.PRE_QUALIFICATIONS]: hasPermission(
        Constants.permissionedRolesBySection.leadNotes.readGroup
      ),
    }),
    [user.role, featureFlags]
  );
};

export default useAuthorizedTabs;
