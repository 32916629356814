import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { Form, Input, Modal, Table, Select, Tooltip, Typography } from "antd";
import { Constants } from "../../common/constants";
import { get } from "../../utils/clientStorageUtils";
import usePermissions from "../../common/customHooks/usePermissions";
import {
  DeleteFilled,
  CheckOutlined,
  EditFilled,
  CloseOutlined,
} from "@ant-design/icons";
import {
  deleteMedication,
  getMedicationNames,
  updateMedication,
} from "../../actions/application";
import { FlashMessage } from "../../utils/flash_message";
import { validateBlankSpace } from "../../validations/validateBlankSpace";

const ManageMedication = () => {
  const navigate = useNavigate();
  const { canWrite } = usePermissions("manageMedication");
  const [form] = Form.useForm();
  const [currentMedicationItem, setCurrentMedicationItem] = useState<any>();
  const [searchString, setSearchString] = useState("");
  const [medications, setMedications] = useState([]);
  const [filteredMedications, setFilteredMedications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [updateMedicationNameModalOpen, setUpdateMedicationNameModalOpen] =
    useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);

  const formSubmit = () => {
    form.submit();
  };
  const cancel = () => {
    setUpdateMedicationNameModalOpen(false);
    form.resetFields();
  };

  const loadMedications = () => {
    setIsLoading(true);
    getMedicationNames().then((res) => {
      setMedications(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    loadMedications();
  }, []);

  useEffect(() => {
    if (currentMedicationItem && updateMedicationNameModalOpen) {
      form.setFieldsValue({
        id: currentMedicationItem.id,
        name: currentMedicationItem.name,
      });
    }
  }, [currentMedicationItem, updateMedicationNameModalOpen, form]);

  useEffect(() => {
    setFilteredMedications(
      medications.filter((medication: any) =>
        medication.name.toLowerCase().includes(searchString.toLowerCase())
      )
    );
  }, [medications, searchString]);

  const updateMedicationNameApi = (e: any) => {
    setIsLoading(true);
    setIsPopUpLoader(true);
    updateMedication(
      currentMedicationItem.id,
      e.name,
      currentMedicationItem.approved
    )
      .then(() => {
        setIsPopUpLoader(false);
        setUpdateMedicationNameModalOpen(false);
        FlashMessage("success", "Medication has been updated successfully.");
        loadMedications();
      })
      .finally(() => {
        setIsLoading(false); // Ensure loading is false after operation
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any, item: any) => {
        if (canWrite) {
          return (
            <div className='d-flex gap-2'>
              {!item.approved ? (
                <Tooltip title={"Approve"}>
                  <a
                    className='mr-2'
                    onClick={() => {
                      // Show a confirmation dialog
                      if (
                        window.confirm(
                          "Are you sure you want to approve this medication?"
                        )
                      ) {
                        setIsLoading(true);
                        updateMedication(id, item.name, true)
                          .then(() => {
                            FlashMessage(
                              "success",
                              "Medication has been approved successfully."
                            );
                            loadMedications();
                          })
                          .finally(() => {
                            setIsLoading(false); // Ensure loading is false after operation
                          });
                      }
                    }}
                  >
                    <CheckOutlined
                      style={{
                        fontSize: "25px",
                        color: "#747497",
                      }}
                    />
                  </a>
                </Tooltip>
              ) : (
                <Tooltip title={"UnApprove"}>
                  <a
                    className='mr-2'
                    onClick={() => {
                      // Show a confirmation dialog
                      if (
                        window.confirm(
                          "Are you sure you want to unapprove this medication?"
                        )
                      ) {
                        setIsLoading(true);
                        updateMedication(id, item.name, false)
                          .then(() => {
                            FlashMessage(
                              "success",
                              "Medication has been unapproved successfully."
                            );
                            loadMedications();
                          })
                          .finally(() => {
                            setIsLoading(false); // Ensure loading is false after operation
                          });
                      }
                    }}
                  >
                    <CloseOutlined
                      style={{
                        fontSize: "25px",
                        color: "#747497",
                      }}
                    />
                  </a>
                </Tooltip>
              )}
              <Tooltip title={"Update"}>
                <a
                  onClick={() => {
                    setCurrentMedicationItem({ ...item });
                    setUpdateMedicationNameModalOpen(true);
                  }}
                >
                  <EditFilled
                    style={{
                      fontSize: "25px",
                      color: "#747497",
                    }}
                  />
                </a>
              </Tooltip>
              <Tooltip title={"Delete"}>
                <a
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this record?"
                      )
                    ) {
                      setIsLoading(true);
                      deleteMedication(id)
                        .then(() => {
                          FlashMessage(
                            "success",
                            "Medication has been deleted successfully."
                          );
                        })
                        .catch((e) => {
                          if (e.response.status === 400) {
                            const relatedClaimants =
                              e.response.data.relatedMedicationDetails.map(
                                (item: any) => {
                                  return (
                                    <p
                                      onClick={() =>
                                        navigate(
                                          `/templates?id=${item.encryptedUserId}`
                                        )
                                      }
                                      className='text-primary underline mb-0'
                                      style={{ cursor: "pointer" }}
                                    >
                                      {`${item.firstName} ${item.lastName}`}
                                    </p>
                                  );
                                }
                              );
                            FlashMessage(
                              "error",
                              <div className='d-flex flex-column gap-2'>
                                There is(are) claimant(s) who are using this
                                medication.
                                <div className='d-flex flex-column gap-1'>
                                  {relatedClaimants}
                                </div>
                              </div>,
                              "",
                              7
                            );
                          }
                        })
                        .finally(() => {
                          loadMedications();
                        });
                    }
                  }}
                >
                  <DeleteFilled
                    style={{
                      fontSize: "25px",
                      color: "#747497",
                    }}
                  />
                </a>
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];
  return (
    <>
      <Typography.Title level={3}>Manage Medications</Typography.Title>
      <Modal
        title={"Update Medication Name"}
        centered
        okText={isPopUpLoader ? "Loading" : "Update"}
        open={updateMedicationNameModalOpen}
        onOk={() => (isPopUpLoader ? null : formSubmit())}
        onCancel={() => cancel()}
        width={1000}
      >
        {isPopUpLoader && <Loader></Loader>}
        {!isPopUpLoader && (
          <Form
            className='mt-4'
            form={form}
            name='SupportUserForm'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            autoComplete='off'
            onFinish={updateMedicationNameApi}
          >
            <div>
              <Form.Item name='id' />
              <div className='form-group row margin_mobile'>
                <label className='col-form-label fill_description col-sm-3 des_font'>
                  Medication Name
                </label>
                <div className='col-sm-9 p-0'>
                  <Form.Item
                    name='name'
                    rules={[
                      {
                        required: true,
                        message: "Please input the value!",
                      },
                      {
                        validator: validateBlankSpace,
                      },
                    ]}
                  >
                    <Input
                      maxLength={100}
                      className={`form-control input_form_sign`}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Modal>
      <div className='d-flex justify-content-end'>
        <div className='col-md-4 mb-4'>
          <div className='d-flex justify-content-end mt-5'>
            <Input
              placeholder='Search medications...'
              className={"input_style"}
              style={{
                borderRadius: "5px",
                marginRight: "20px",
              }}
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Table
        dataSource={filteredMedications}
        pagination={{
          pageSize: Constants.medicationsDefaultPageSize,
          showSizeChanger: false,
          showTotal: (total) => {
            return `Total Count: ${total}`;
          },
        }}
        loading={isLoading}
        columns={columns}
      />
    </>
  );
};

export default ManageMedication;
