import { Button, Form, Input, Select, Space } from "antd";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { SupportUser } from "../CaseManagement";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import axios from "axios";
import { MinusCircleOutlined } from "@ant-design/icons";
import { FlashMessage } from "../../utils/flash_message";
import "./styles.css";

export const ReassignmentStep3 = (props: {
  supportUsers: SupportUser[];
  supportUserLoading: boolean;
  caseManagerId: number;
  filterOption: any;
  setStep: (step: number) => void;
}) => {
  const [form] = useForm();
  const [claimCount, setClaimCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const compareByName = (a: SupportUser, b: SupportUser) => {
    const nameA = a.fullName.toUpperCase(); // ignore case
    const nameB = b.fullName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const getClaimCountApi = async () => {
    const res = await axios.get(
      `GetClaimantsCountForCaseManager?caseManagerId=${
        props.caseManagerId
      }&applicationStatusId=${
        props.filterOption.applicationStatusId ?? 0
      }&isSpanish=${props.filterOption.isSpanish ?? false}&isCritical=${
        props.filterOption.isCritical ?? false
      }&isDeceased=${props.filterOption.isDeceased ?? false}&isMinor=${
        props.filterOption.isMinor ?? false
      }&isTerminal=${props.filterOption.isTerminal ?? false}&isDenial=${
        props.filterOption.isDenial ?? false
      }`
    );
    setClaimCount(res.data.count);
  };

  useEffect(() => {
    getClaimCountApi();
  }, []);

  const validateTotalClaimants = () => {
    const values = form.getFieldValue("reassignments");
    if (!values) return;

    const totalAssigned = values.reduce((sum: number, current: any) => {
      return sum + (parseInt(current?.claimCount) || 0);
    }, 0);

    if (totalAssigned > claimCount) {
      FlashMessage(
        "error",
        "Total assigned claimants cannot exceed available claimants"
      );
      return false;
    }

    if (totalAssigned === 0) {
      FlashMessage("error", "Total assigned claimants must be greater than 0");
      return false;
    }

    return true;
  };

  return (
    <div className='reassignment-container'>
      <div className='reassignment-step'>
        <h2 className='reassignment-title'>Assign Claimants</h2>

        <div className='count-display'>
          <span>Claimants for current case manager: {claimCount}</span>
        </div>

        <Form
          className='reassignment-form'
          form={form}
          name='ReassignmentForm'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete='off'
          onFinish={(values) => {
            if (validateTotalClaimants()) {
              setIsSubmitting(true);
              axios
                .post("ReassignClaimants", {
                  originalCaseManagerId: props.caseManagerId,
                  reassignments: values.reassignments.map((r: any) => ({
                    caseManager: r.casemanager,
                    claimCount: parseInt(r.claimCount),
                  })),
                  filterOption: props.filterOption,
                })
                .then((response) => {
                  FlashMessage("success", "Claimants reassigned successfully");
                  props.setStep(0);
                })
                .catch((error) => {
                  FlashMessage(
                    "error",
                    "An error occurred while reassigning claimants"
                  );
                  console.error(error);
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            }
          }}
        >
          <Form.List name='reassignments' initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                <Form.ErrorList className='mb-3' />
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key} className='case-manager-row'>
                    <Space
                      style={{ display: "flex", width: "100%", gap: "16px" }}
                    >
                      <Form.Item
                        {...restField}
                        label='New Case Manager'
                        name={[name, "casemanager"]}
                        className='flex-1'
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select
                          placeholder='Select case manager'
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          loading={props.supportUserLoading}
                          options={props.supportUsers
                            .sort(compareByName)
                            .map((user: SupportUser) => ({
                              label: user.fullName,
                              value: user.id,
                            }))}
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label='Claimants Count'
                        name={[name, "claimCount"]}
                        className='w-32'
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input type='number' min={0} />
                      </Form.Item>

                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: "20px",
                            color: "#ff4d4f",
                            cursor: "pointer",
                            marginTop: "28px",
                          }}
                        />
                      )}
                    </Space>
                  </div>
                ))}

                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  className='mt-4'
                >
                  + Add Case Manager
                </Button>
              </>
            )}
          </Form.List>

          <div className='reassignment-actions'>
            <Button htmlType='submit' type='primary' disabled={isSubmitting}>
              {isSubmitting ? "Reassigning..." : "Reassign"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
