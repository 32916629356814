import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

interface User {
  firstName: string;
  lastName: string;
  picture: string | null;
  role: string;
  email: string;
  userId: number | null;
}

const useUser = (): User => {
  const [user, setUser] = useState<User>({
    firstName: "",
    lastName: "",
    picture: null,
    role: "",
    email: "",
    userId: null,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("accessToken");
      const model = localStorage.getItem("model");

      let firstName = "";
      let lastName = "";
      let picture: string | null = null;
      let role = "";
      let email = "";
      let userId = null;

      if (token) {
        try {
          const decoded: any = jwtDecode(token);
          firstName = decoded.firstName || "";
          lastName = decoded.lastName || "";
          picture = decoded.picture || null;
          email = decoded.email || "";
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }

      if (model) {
        try {
          const parsedModel = JSON.parse(model);
          firstName = firstName || parsedModel.firstName || "";
          lastName = lastName || parsedModel.lastName || "";
          picture = picture || parsedModel.picture || null;
          role = parsedModel.userRoles?.[0] || "";
          email = parsedModel.email || "";
          userId = parsedModel.userId || null;
        } catch (error) {
          console.error("Error parsing model:", error);
        }
      }

      setUser({ firstName, lastName, picture, role, email, userId });
    };

    fetchUserData();
  }, []);

  return user;
};

export default useUser;
