import { Form, Input } from "antd";
import StatesDropDownFormItem from "../../../../common/components/StatesDropdownFormItem";
import { antdNoEmptyRule } from "../../../../validations/validateBlankSpace";

interface AddressWithoutNameFieldProps {
  disabledAddressFields?: boolean;
}

const AddressWithoutNameField = ({
  disabledAddressFields,
}: AddressWithoutNameFieldProps) => {
  return (
    <>
      <Form.Item
        label='Line 1'
        name='line1'
        rules={[
          {
            required: true,
            message: "Please input Line 1 of the address",
          },
          antdNoEmptyRule,
        ]}
      >
        <Input disabled={disabledAddressFields} placeholder='Enter Address' />
      </Form.Item>
      <Form.Item
        label='Line 2 (optional)'
        name='line2'
        rules={[antdNoEmptyRule]}
      >
        <Input placeholder='Enter Address (optional)' />
      </Form.Item>
      <div className='d-flex'>
        <div style={{ flexGrow: "2", marginRight: "5px" }}>
          <Form.Item
            label='City'
            name='city'
            rules={[
              {
                required: true,
                message: "Please input City",
              },
              antdNoEmptyRule,
            ]}
          >
            <Input disabled={disabledAddressFields} placeholder='City' />
          </Form.Item>
        </div>

        <StatesDropDownFormItem showLabel disabled={disabledAddressFields} />

        <div style={{ marginLeft: "5px", maxWidth: "120px" }}>
          <Form.Item
            label='Zip'
            name='zip'
            rules={[
              {
                required: true,
                message: "Zip required",
              },
              antdNoEmptyRule,
            ]}
          >
            <Input
              disabled={disabledAddressFields}
              placeholder='Zip'
              maxLength={5}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default AddressWithoutNameField;
