import { Badge, Checkbox, Menu, Space, theme, Tooltip } from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import usePipelinesAndStatuses from "../../../common/customHooks/usePipelinesAndStatuses";
import useActiveKey from "../hooks/useActiveKey";
import { getOnlyShowMyCasesFromSearchParams } from "../../../utils/commonHelper";
import { getLeadsInboundCount } from "../../../actions/application";
import * as signalR from "@microsoft/signalr";
import useMediaQuery from "../../../common/customHooks/useMediaQuery";

const { useToken } = theme;

const LeftMenu: React.FC = () => {
  const { token } = useToken();
  const { pipelines } = usePipelinesAndStatuses();
  const isCompact = useMediaQuery(1150);
  const [searchParams] = useSearchParams();
  const activeKey = useActiveKey();
  const location = useLocation();
  const navigate = useNavigate();

  const [leadsCounts, setLeadsCount] = useState(0);
  const [onlyShowMyCases, setOnlyShowMyCases] = useState(
    getOnlyShowMyCasesFromSearchParams(searchParams) === "true"
  );

  const getleadsInboundCountApi = () => {
    getLeadsInboundCount().then((res) => {
      var resData = res.data.responseData;
      setLeadsCount(resData);
    });
  };

  useEffect(() => {
    getleadsInboundCountApi();
  }, []);

  useEffect(() => {
    setOnlyShowMyCases(
      getOnlyShowMyCasesFromSearchParams(searchParams) === "true"
    );
  }, [searchParams]);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_Mindset_API_URL ?? ""}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("TagLeadsCount", (res: any) => {
      getleadsInboundCountApi();
    });

    connection.start().catch((err) => console.log(err));

    return () => {
      connection.off("TagLeadsCount");
      connection.stop().catch((err) => console.log("connection Stop Error"));
    };
  }, [searchParams.toString()]);

  const handleToggleMyCases = (e: any) => {
    const updated = e.target.checked;
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.delete("onlyShowMyCases");
    newSearchParams.set("onlyShowMyCases", updated.toString());
    navigate(`${location.pathname}?${newSearchParams.toString()}`, {
      replace: true,
    });
  };

  const generatePipelineItems = (isLead: boolean) =>
    pipelines
      .filter((pipeline) => pipeline.isLead === isLead)
      .map((pipeline) => {
        const newSearchParams = new URLSearchParams(searchParams);

        newSearchParams.delete("pipelineStatusId");
        newSearchParams.delete("id");
        newSearchParams.set("pipelineId", pipeline.id.toString());

        return {
          key: `${isLead ? "lead" : "claimant"}-pipeline-${pipeline.id}`,
          label: (
            <Link
              to={`/${
                isLead ? "leads" : "claimants"
              }?${newSearchParams.toString()}`}
            >
              {pipeline.name}
            </Link>
          ),
        };
      });

  const menuItems = [
    {
      key: "leads",
      label: (
        <Space>
          Leads {!isCompact && <DownOutlined />}
          {
            <Tooltip
              title={`${leadsCounts} leads are in the Main Inbound pipeline.`}
              color={token.colorInfo}
            >
              <Badge
                title={""}
                style={{ color: token.colorText }}
                color={token.colorFillSecondary}
                count={leadsCounts}
              />
            </Tooltip>
          }
        </Space>
      ),
      children: generatePipelineItems(true),
    },
    {
      key: "claimants",
      label: <Space>Claimants {!isCompact && <DownOutlined />}</Space>,
      children: [
        ...generatePipelineItems(false),
        {
          type: "divider",
        },
        {
          key: "toggle-my-cases",
          label: (
            <Checkbox
              style={{ fontSize: 16 }}
              checked={onlyShowMyCases}
              onChange={handleToggleMyCases}
            >
              My Cases
            </Checkbox>
          ),
        },
      ],
    },
    { key: "tasks", label: <Link to='/globaltaskscreen'>Tasks</Link> },
    { key: "case-management", label: <Link to='/casemanagement'>Cases</Link> },
  ];

  return (
    <Menu
      className='navbar-menu'
      mode='horizontal'
      items={menuItems}
      selectedKeys={[activeKey]}
      overflowedIndicator={<MenuOutlined />}
    />
  );
};

export default LeftMenu;
