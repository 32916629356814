import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import {
  Form,
  Input,
  Modal,
  Table,
  Tooltip,
  Select,
  Button,
  Flex,
  Typography,
} from "antd";
import { DeleteFilled, EditFilled, CopyOutlined } from "@ant-design/icons";
import { Constants } from "../../common/constants";
import {
  number,
  validateZipCode,
} from "../../validations/numeric_only-validate";
import { validateBlankSpace } from "../../validations/validateBlankSpace";
import { minLength } from "../../validations/validate_min_length";
import {
  addTenant,
  downloadLogo,
  getTenant,
  deleteTenant,
  getTenantById,
  updateTenant,
  tenantExistsByCode,
  uploadLogo,
} from "../../actions/tenants";
import { FlashMessage } from "../../utils/flash_message";
import upload from "../../images/upload.png";
import Loader from "../../components/Loader";
import {
  CaseInsensitiveContains,
  containsPhoneNumber,
  formatPhoneNumber,
} from "../../utils/commonHelper";
import usePermissions from "../../common/customHooks/usePermissions";
import StatesDropDownFormItem from "../../common/components/StatesDropdownFormItem";
import GooglePlacesAutocompleteWrapper from "../../components/GooglePlacesAutoCompleteWrapper";
import { GetCarePublicWebsiteBaseUrl } from "../../utils/utils";
import trim from "lodash/trim";
import { add, set } from "lodash";

interface Tenant {
  code: string;
  confirmationMessage: string;
  displayName: string;
  id: number;
  isFAQNeeded: boolean;
  isHeaderNeeded: boolean;
  isFooterNeeded: number;
  isIncludeCalendlySchedule: boolean;
  isIncludeReleaseDate: boolean;
  logo: string;
  name: string;
  parentID: number;
  phone: string;
}

const { TextArea } = Input;
const Tenants = () => {
  const url = GetCarePublicWebsiteBaseUrl();
  const { canWrite } = usePermissions("tenantManagement");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [form] = Form.useForm();
  const [isLoader, setIsLoader] = useState(false);
  const [isPopUpLoader, setIsPopUpLoader] = useState(false);
  const [logo, setLogo] = useState("");
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [filteredTenants, setFilteredTenants] = useState<Tenant[]>([]);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebounce(
    searchString,
    Constants.debounceTime
  );
  const [isAdd, setIsAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [userById, setUserById] = useState({});
  const [isFAQNeeded, setIsFAQNeeded] = useState(false);
  const [isFooterNeeded, setIsFooterNeeded] = useState(false);
  const [isHeaderNeeded, setIsHeaderNeeded] = useState(false);
  const [isIncludeCalendlySchedule, setIsIncludeCalendlySchedule] =
    useState(false);
  const [fileForm, setFileForm] = useState(null);
  const [imageShowUrl, setImageShowUrl] = useState<string>();
  const [isIncludeReleaseDate, setIsIncludeReleaseDate] = useState(false);
  const [isCorrectionalFacility, setIsCorrectionalFacility] = useState(false);
  const [isOnBlurFire, setIsOnBlurFire] = useState(false);
  const [addressValue, setAddressValue] = useState<string | null>(null);

  const formSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    setFilteredTenants(
      tenants.filter(
        (data) =>
          CaseInsensitiveContains(data.name, debouncedSearchString) ||
          CaseInsensitiveContains(data.displayName, debouncedSearchString) ||
          containsPhoneNumber(data.phone, debouncedSearchString)
      )
    );
  }, [tenants, debouncedSearchString]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Display Name",
      dataIndex: "displayName",
      key: "displayName",
    },

    {
      title: "URL",
      dataIndex: "code",
      key: "code",
      render: (code: any) => {
        return (
          <>
            <div>
              <a
                href={`${url}/register?intake_registration=true&eid=${code}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                Register
              </a>
            </div>
            <div>
              <a
                href={`${url}/${code}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                Pre-qualification
              </a>
            </div>
          </>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      render: (number: string) => {
        return (
          number && (
            <a href={`tel:${formatPhoneNumber(number)}`}>
              {formatPhoneNumber(number)}
            </a>
          )
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        if (canWrite) {
          return (
            <div className='d-flex'>
              <Tooltip title={"Edit"}>
                <a className='mr-2' onClick={() => getTenantByIdApi(id)}>
                  <EditFilled
                    style={{
                      fontSize: "25px",
                      color: "#747497",
                    }}
                  />
                </a>
              </Tooltip>
              <Tooltip title={"Delete"}>
                <a className='mr-2' onClick={() => deleteTenantApi(id)}>
                  <DeleteFilled
                    style={{
                      fontSize: "25px",
                      color: "#747497",
                    }}
                  />
                </a>
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];

  const handleFaqNeeded = (e: any) => {
    setIsFAQNeeded(e.target.checked);
  };

  const handleFooterNeeded = (e: any) => {
    setIsFooterNeeded(e.target.checked);
  };

  const handleHeaderNeeded = (e: any) => {
    setIsHeaderNeeded(e.target.checked);
  };

  const handleIncludeCalendlySchedule = (e: any) => {
    setIsIncludeCalendlySchedule(e.target.checked);
  };
  const handleIncludeReleaseDate = (e: any) => {
    setIsIncludeReleaseDate(e.target.checked);
  };

  const handleIsCorrectionalFacility = (e: any) => {
    setIsCorrectionalFacility(e.target.checked);
  };
  const onkeyup = (e: any) => {
    var onlyNumber = e.target.value
      .split("(")
      .join("")
      .split("-")
      .join("")
      .split(")")
      .join("")
      .split(" ")
      .join("");
    if (onlyNumber.length > 10) {
      onlyNumber = onlyNumber.substring(0, 10);
    }
    form.setFieldsValue({ phone: onlyNumber });
  };

  const addPopUp = () => {
    setIsAdd(true);
    setOpen(true);
    resetFormValues();
    setFileForm(null);
  };

  const resetFormValues = () => {
    var formDefaultValues = {
      name: "",
      displayName: "",
      code: "",
      phone: "",
      logo: null,
      isFAQNeeded: false,
      isHeaderNeeded: false,
      isIncludeCalendlySchedule: false,
      isIncludeReleaseDate: false,
      isCorrectionalFacility: false,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      isFooterNeeded: false,
      parentID: null,
      confirmationMessage: "",
    };
    setTimeout(() => {
      setUserById(formDefaultValues);
      setImageShowUrl("");
      setIsFAQNeeded(false);
      setIsFooterNeeded(false);
      setIsHeaderNeeded(false);
      setIsIncludeCalendlySchedule(false);
      setIsIncludeReleaseDate(false);
      setIsCorrectionalFacility(false);
      setAddressValue("");
      form.setFieldsValue(formDefaultValues);
    }, 100);
  };

  const addUpdateTenantApi = (e: any) => {
    var file = e.logo?.file;
    var form = new FormData();
    form.append("file", file);
    var payload = {
      Id: e.id,
      Name: e.name,
      Code: e.code,
      DisplayName: e.displayName,
      ParentID: e.parentID,
      IsHeaderNeeded: isHeaderNeeded,
      IsFooterNeeded: isFooterNeeded,
      IsFAQNeeded: isFAQNeeded,
      IsIncludeCalendlySchedule: isIncludeCalendlySchedule,
      IsCorrectionalFacility: isCorrectionalFacility,
      Address1: e.address1,
      Address2: e.address2,
      City: e.city,
      State: e.state,
      Zip: e.zip,
      IsIncludeReleaseDate: isIncludeReleaseDate,
      Phone: e.phone,
      ConfirmationMessage: e.confirmationMessage,
    };
    if (isAdd) {
      setIsPopUpLoader(true);
      addTenant(payload, form).then((res) => {
        if (res.data.responseCode == 200) {
          if (fileForm != null) {
            var form = new FormData();
            form.append("file", fileForm);
            uploadLogo(res.data.responseData, form).then((res) => {
              FlashMessage("success", "Tenant added Successfully.");
              setOpen(false);
              setIsPopUpLoader(false);
              getTenantApi();
            });
          } else {
            FlashMessage("success", "Tenant added Successfully.");
            setOpen(false);
            setIsPopUpLoader(false);
            getTenantApi();
          }
        } else {
          setIsPopUpLoader(false);
          setIsOnBlurFire(true);
          if (isOnBlurFire == true) {
            FlashMessage("error", res.data.responseMessage);
          }
        }
      });
    } else {
      setIsPopUpLoader(true);
      updateTenant(payload).then((res) => {
        if (res.data.responseCode == 200) {
          if (fileForm != null) {
            var form = new FormData();
            form.append("file", fileForm);
            uploadLogo(e.id, form).then((res) => {
              FlashMessage("success", "Tenant updated Successfully.");
              setOpen(false);
              setIsPopUpLoader(false);
              getTenantApi();
            });
          } else {
            FlashMessage("success", "Tenant updated Successfully.");
            setOpen(false);
            setIsPopUpLoader(false);
            getTenantApi();
          }
        }
      });
    }
  };
  const cancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const getTenantApi = () => {
    setIsLoader(true);
    setIsAdd(false);
    getTenant().then((res) => {
      var userdata = res.data.responseData;
      setIsLoader(false);
      if (res.data.responseCode == 200) {
        setTenants(userdata);
      }
    });
  };

  const onCodeChange = (e: any) => {
    if (isAdd) {
      var value = e.target.value;
      var formDefaultValues = {
        code: "",
      };
      tenantExistsByCode(value)
        .then((res) => {
          if (res.data.responseData) {
            setIsOnBlurFire(false);
            if (isOnBlurFire == false) {
              FlashMessage("info", "Code Already Exists");
            }

            setTimeout(() => {
              setUserById(formDefaultValues);
              form.setFieldsValue(formDefaultValues);
            }, 100);
          }
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    getTenantApi();
  }, []);

  const deleteTenantApi = (id: any) => {
    setIsLoader(true);
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteTenant(id).then((response) => {
        if (response.data.responseCode == 200) {
          setIsLoader(false);
          FlashMessage("success", response.data.responseMessage);
        } else if (response.data.responseCode != 200) {
          setIsLoader(false);
          FlashMessage("error", response.data.responseMessage);
        }
        getTenantApi();
      });
    } else {
      setIsLoader(false);
    }
  };

  const getTenantByIdApi = (id: any) => {
    setIsPopUpLoader(true);
    setOpen(true);
    setIsAdd(false);
    setImageShowUrl("");
    getTenantById(id).then((res) => {
      setIsPopUpLoader(false);
      var appRes = res.data.responseData;
      if (appRes.logo != null && appRes.logo != "") {
        downloadLogoApi(id);
        setLogo(appRes?.logo);
      }
      setUserById(appRes);
      setIsFAQNeeded(appRes.isFAQNeeded);
      setIsFooterNeeded(appRes.isFooterNeeded);
      setIsHeaderNeeded(appRes.isHeaderNeeded);
      setAddressValue(appRes.address1 || "");
      setIsIncludeCalendlySchedule(appRes.isIncludeCalendlySchedule);
      setIsCorrectionalFacility(appRes.isCorrectionalFacility);
      setIsIncludeReleaseDate(appRes.isIncludeReleaseDate);
      form.setFieldsValue(appRes);
    });
  };

  const UploadLogoChange = () => {
    inputRef.current?.click();
  };

  const onChangeDocuments = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    var form = new FormData();
    form.append("file", file);
    var imgShow = file.name.split(".").pop();
    let acceptedExt = ["jpg", "jpeg", "png"];
    if (acceptedExt.includes(imgShow)) {
      let file_size = event.target.files[0].size;
      if (file_size <= 5000000) {
        setImageShowUrl(URL.createObjectURL(file));
        setFileForm(file);
      } else {
        FlashMessage(
          "error",
          "Please select a file whose size is less than 5MB."
        );
      }
    } else {
      FlashMessage("error", "Only png, jpg and jpeg files are allowed");
    }
  };

  const createAndViewBlobFile = (body: any, filename: any) => {
    var ext = filename.split(".").pop();
    var type = "image/png";
    if (ext == "pdf") type = "application/pdf";
    else if (ext == "txt") type = "plain/text";
    else type = "image/" + ext;
    const blob = new Blob([body], { type: type });
    const fileName = `${filename}`;
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      setImageShowUrl(url);
    }
  };

  const downloadLogoApi = (id: any) => {
    setIsPopUpLoader(true);
    downloadLogo(id).then((res) => {
      createAndViewBlobFile(base64ToArrayBuffer(res.data.responseData), logo);
      setIsPopUpLoader(false);
    });
  };

  const predefinedValue = {
    label: addressValue,
    value: Constants.googleAutoCompleteStaticId,
  };

  return (
    <>
      <Typography.Title level={3}>Manage Enterprises</Typography.Title>
      {isLoader && <Loader></Loader>}
      {!isLoader && (
        <>
          <Modal
            title={isAdd ? "Add Tenant" : "Update Tenant"}
            centered
            okText={isAdd ? "Add" : isPopUpLoader ? "Loading" : "Update"}
            open={open}
            onOk={() => (isPopUpLoader ? null : formSubmit())}
            onCancel={() => cancel()}
            width={1000}
          >
            {isPopUpLoader && <Loader></Loader>}
            {!isPopUpLoader && (
              <Form
                className='mt-4'
                form={form}
                name='TenantForm'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={userById}
                autoComplete='off'
                onFinish={addUpdateTenantApi}
              >
                <div>
                  <Form.Item name='id' />
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      1. Name
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='name'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      2. Display Name
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='displayName'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      3. Code
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='code'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                        ]}
                      >
                        <Input
                          onBlur={onCodeChange}
                          maxLength={50}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      4.Logo
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item name='logo'>
                        <input
                          type='file'
                          ref={inputRef}
                          onChange={onChangeDocuments}
                          accept='.png,.jpg,.jpeg'
                          style={{ display: "none" }}
                        />
                        <div
                          className='cursorPointer'
                          onClick={() => UploadLogoChange()}
                        >
                          <div className='text-left'>
                            <img
                              className='icon_logo img-fluid width_image_form mb-0'
                              src={
                                imageShowUrl != null && imageShowUrl != ""
                                  ? imageShowUrl
                                  : upload
                              }
                            />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        5. Correctional Faciltiy
                      </label>
                      <div className='col-sm-9 p-0'>
                        <input
                          checked={isCorrectionalFacility}
                          className='form-checkn'
                          type='checkbox'
                          name='isCorrectionalFacility'
                          value='option'
                          onChange={handleIsCorrectionalFacility}
                        />
                        <span className='checkmark'></span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        6. Include Release Date
                      </label>
                      <div className='col-sm-9 p-0'>
                        <input
                          checked={isIncludeReleaseDate}
                          className='form-checkn'
                          type='checkbox'
                          name='isIncludeReleaseDate'
                          value='option'
                          onChange={handleIncludeReleaseDate}
                        />
                        <span className='checkmark'></span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      7. Phone Number
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='phone'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          { validator: number },
                          {
                            validator: validateBlankSpace,
                          },
                          { validator: minLength },
                        ]}
                      >
                        <Input
                          onKeyUpCapture={onkeyup}
                          maxLength={15}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      8. Street Address
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item name='address1'>
                        {addressValue != null && (
                          <GooglePlacesAutocompleteWrapper
                            apiKey={process.env.REACT_APP_API_KEY ?? ""}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              value: predefinedValue,
                              isClearable: false,
                              onChange: (o: any) => {
                                form.setFieldsValue({
                                  address: o?.label ?? "",
                                });
                                if (o != null) {
                                  var addArr = o.label.split(",");
                                  var street =
                                    addArr.length > 0 ? addArr[0] : null;
                                  var city =
                                    addArr.length > 0 ? addArr[1] : null;
                                  var state =
                                    addArr.length > 0 ? addArr[2] : null;
                                  form.setFieldsValue({
                                    address1: trim(street),
                                    city: trim(city),
                                    state: trim(state),
                                  });
                                  setAddressValue(street);
                                }
                              },
                            }}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      9. Address 2 (optional)
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item name='address2'>
                        <Input
                          placeholder='i.e. Apt,Ste, Fl, Unit, etc..'
                          maxLength={1000}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      10. City
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item name='city'>
                        <Input
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      11. State
                    </label>
                    <div className='col-sm-9 p-0'>
                      <StatesDropDownFormItem noValidation />
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      12. Zip
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item
                        name='zip'
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                          {
                            validator: validateBlankSpace,
                          },
                          {
                            validator: number,
                          },
                          {
                            validator: validateZipCode,
                          },
                        ]}
                      >
                        <Input
                          maxLength={100}
                          className={`form-control input_form_sign`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      13. Parent Id
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item name='parentID'>
                        <Select
                          options={tenants
                            .sort((a: any, b: any) =>
                              a.name.localeCompare(b.name)
                            )
                            .map((loc: any) => ({
                              label: loc.displayName,
                              value: loc.id,
                            }))}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <label className='col-form-label fill_description col-sm-3 des_font'>
                      14. Confirmation Message
                    </label>
                    <div className='col-sm-9 p-0'>
                      <Form.Item name='confirmationMessage'>
                        <TextArea rows={4} maxLength={5000} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        15. FAQ required
                      </label>

                      <div className='col-sm-9 p-0'>
                        <input
                          checked={isFAQNeeded}
                          className='form-check'
                          type='checkbox'
                          name='isFAQNeeded'
                          value='option'
                          onChange={handleFaqNeeded}
                        />
                        <span className='checkmark'></span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        16. Header required
                      </label>
                      <div className='col-sm-9 p-0'>
                        <input
                          checked={isHeaderNeeded}
                          className='form-check'
                          type='checkbox'
                          name='isHeaderNeeded'
                          value='option'
                          onChange={handleHeaderNeeded}
                        />
                        <span className='checkmark'></span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        17. Footer required
                      </label>
                      <div className='col-sm-9 p-0'>
                        <input
                          checked={isFooterNeeded}
                          className='form-check'
                          type='checkbox'
                          name='isFooterNeeded'
                          value='option'
                          onChange={handleFooterNeeded}
                        />
                        <span className='checkmark'></span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group row margin_mobile'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='col-form-label fill_description col-sm-3 des_font'>
                        18. Include Calendly Schedule
                      </label>
                      <div className='col-sm-9 p-0'>
                        <input
                          checked={isIncludeCalendlySchedule}
                          className='form-checkn'
                          type='checkbox'
                          name='isIncludeCalendlySchedule'
                          value='option'
                          onChange={handleIncludeCalendlySchedule}
                        />
                        <span className='checkmark'></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Modal>

          <Flex gap={8} justify='flex-end'>
            <Input
              placeholder='Search enterprises...'
              style={{ maxWidth: 500 }}
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            {canWrite && (
              <Button type='primary' onClick={() => addPopUp()}>
                Add
              </Button>
            )}
          </Flex>

          <Table
            dataSource={filteredTenants}
            pagination={{
              pageSize: Constants.logsDefaultPageSize,
              showSizeChanger: false,
              showTotal: (total) => {
                return `Total Count:  ${total}`;
              },
            }}
            columns={columns}
            style={{ marginTop: 40 }}
          />
        </>
      )}
    </>
  );
};

export default Tenants;

export function base64ToArrayBuffer(base64: any) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}
