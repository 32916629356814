import { IntakeFieldItem } from "..";

const prequal_field_labels: {
  [key: string]: IntakeFieldItem;
} = {
  isUsCitizen: {
    label: "Are you a United States citizen or permanent resident?",
    type: "boolean",
  },
  isReceivingGovtBenefits: {
    label: "Are you currently receiving any type of Social Security benefit?",
    type: "boolean",
  },
  owesSocialSecurityForOverpayments: {
    label:
      "Do you owe money to the Social Security Administration for overpayments of disability benefits?",
    type: "boolean",
  },
  hasPhysicalOrMentalHealthCondition: {
    label: "Are you living with a physical or mental health condition?",
    type: "boolean",
  },
  isCurrentlyWorking: {
    label: "Are you currently working?",
    type: "boolean",
  },
  maximumHoursWorkedPerWeek: {
    label: "What is the most hours you work in a week?",
    type: "string",
    displayIf: "isCurrentlyWorking",
  },
  hourlyWage: {
    label: "How much do you get paid per hour?",
    type: "string",
    displayIf: "isCurrentlyWorking",
  },
  howDidYouHearAboutUs: {
    label: "How did you hear about us?",
    type: "select",
    options: [
      "Google Search",
      "In-Person Event or Webinar",
      "Online Advertisement",
      "Referral - Friend or Family",
      "Referral - Another Organization",
      "Social Media (Facebook, Instagram, etc.)",
      "Other",
    ],
  },
  isJailUser: {
    label: "Are you currently in jail or prison?",
    type: "boolean",
    isTablet: true,
  },
  estimatedReleaseDate: {
    label: "When is your estimated release date?",
    type: "date",
    displayIf: "isJailUser",
    isTablet: true,
  },
  isJailUserReleasedInNext6Months: {
    label: "Is the release date within the next 3 months?",
    type: "select",
    displayIf: "isJailUser",
    options: ["Yes", "No", "Unsure"],
    isTablet: true,
  },
  externalInmateId: {
    label: "What is your Inmate ID?",
    type: "string",
    displayIf: "isJailUser",
    isTablet: true,
  },
};

export default prequal_field_labels;
