import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip } from "antd";
import { useEffect, useState } from "react";

export const ScreeningListHeader = ({
  title,
  onFilter,
  onEdit,
  onSave,
  addOn,
}: {
  title: "Pre-Qualification" | "Intake Questions";
  onFilter?: (val: string) => void;
  onEdit?: (isEditing: boolean) => void;
  onSave?: () => void;
  addOn?: React.ReactNode;
}) => {
  const [filterFields, setFilterFields] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    onFilter && onFilter(filterFields);
  }, [filterFields]);

  useEffect(() => {
    onEdit && onEdit(isEditing);
  }, [isEditing]);

  const handleSave = () => {
    onSave && onSave();
    setIsEditing(false);
  };

  return (
    <div className='d-flex justify-content-between align-items-center w-100'>
      <h6 className='mb-0'>{title}</h6>
      <div className='d-flex justify-content-between align-items-center'>
        {onFilter && (
          <Input
            className='my-2'
            style={{
              width: "300px",
            }}
            value={filterFields}
            onChange={(e) => setFilterFields(e.target.value)}
            placeholder='Filter fields...'
          />
        )}
        {onEdit && onSave && (
          <Button
            className='ms-2'
            type={isEditing ? "primary" : "default"}
            onClick={isEditing ? handleSave : () => setIsEditing(true)}
            icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
        )}
        {addOn}
      </div>
    </div>
  );
};
