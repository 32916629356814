import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { App as AntdApp } from "antd";
import App from "./App";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const primaryColor = "#77d4b1";
const primaryButtonTextColor = "#182A23";
const secondaryColor = "#3E3E56";
const linkColor = "#3E8770";
const mainBackgroundColor = "#ffffff";

root.render(
  <GoogleOAuthProvider clientId='132697518019-o858lknp87vbeosruh83ikn4hoq7m8ce.apps.googleusercontent.com'>
    <ConfigProvider
      theme={{
        token: {
          controlHeight: 34,
          controlHeightLG: 42,
          colorPrimary: primaryColor,
          colorSuccess: primaryColor,
          colorLink: linkColor,
          colorInfo: secondaryColor,
          fontFamily:
            "Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
        },
        components: {
          Layout: {
            headerBg: mainBackgroundColor,
            siderBg: mainBackgroundColor,
            bodyBg: mainBackgroundColor,
            headerPadding: undefined,
          },
          Button: {
            primaryColor: primaryButtonTextColor,
          },
          Menu: {
            itemSelectedColor: secondaryColor,
            subMenuItemSelectedColor: primaryColor,
            horizontalItemSelectedColor: primaryColor,
          },
        },
      }}
    >
      <AntdApp>
        <App />
      </AntdApp>
    </ConfigProvider>
  </GoogleOAuthProvider>
);
