import { useState, useEffect, useCallback } from "react";
import { Dropdown, Badge, Space, Typography, theme, Button } from "antd";
import { BellFilled, CloseOutlined } from "@ant-design/icons";
import {
  getAllNotifications,
  clearNotification,
  readNotification,
  clearAllNotifications,
} from "../../actions/notification";
import { Constants } from "../../common/constants";
import * as signalR from "@microsoft/signalr";
import moment from "moment";
import { get } from "../../utils/clientStorageUtils";
import { Link } from "react-router-dom";
import { MenuProps } from "antd/lib";
import "./notificationBell.scss";
import { GetAdminApiBaseUrlFromWindow } from "../../utils/utils";
const voice = require("../../images/sounds/tn_tn.mp3");

const MAX_LIST = 99;

interface Notification {
  id: string;
  message: string;
  createdDate: string;
  isRead: boolean;
  route: string;
}

const NotificationBell: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const { token } = theme.useToken();

  const beep = () => {
    const sound = new Audio(voice);
    sound.volume = 1.0;
    sound.play().catch(console.error);
  };

  const fetchNotifications = useCallback(async () => {
    try {
      const res = await getAllNotifications();
      if (res.data.responseCode === 200) {
        const resData: Notification[] = res.data.responseData;
        setUnreadCount(resData.filter((n) => !n.isRead).length);
        setNotifications(resData.slice(0, MAX_LIST));
      }
    } catch (error) {
      console.error("Error fetching notifications", error);
    }
  }, []);

  const handleClearNotification = async (id: string) => {
    await clearNotification(id);
    setNotifications((prev) => {
      const updatedNotifications = prev.filter((n) => n.id !== id);
      setUnreadCount(updatedNotifications.filter((n) => !n.isRead).length);
      return updatedNotifications;
    });
  };

  const handleClearAllNotifications = async () => {
    await clearAllNotifications();
    setNotifications([]);
    setUnreadCount(0);
  };

  const handleReadNotification = async (id: string, route: string) => {
    await readNotification(id);
    setNotifications((prev) => {
      const updatedNotifications = prev.map((n) =>
        n.id === id ? { ...n, isRead: true } : n
      );
      setUnreadCount(updatedNotifications.filter((n) => !n.isRead).length);
      return updatedNotifications;
    });
    window.location.href = route;
  };

  useEffect(() => {
    fetchNotifications();
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${GetAdminApiBaseUrlFromWindow()}/notification`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("TagNotification", (res: string[]) => {
      const loginIdInStorage = JSON.parse(get("model"))?.userId;
      if (res.includes(loginIdInStorage)) {
        beep();
        fetchNotifications();
      }
    });

    connection.start().catch(console.error);

    return () => {
      connection.off("TagNotification");
      connection.stop().catch(console.error);
    };
  }, [fetchNotifications]);

  const items: MenuProps["items"] = [
    {
      key: "clearAll",
      label: notifications.length === 0 ? "No Notifications" : "Clear All",
      disabled: notifications.length === 0,
      onClick: handleClearAllNotifications,
    },
    {
      key: "test",
      type: "divider",
    },
    ...notifications.map((n) => ({
      key: n.id,
      label: (
        <>
          <Link
            to={n.route}
            onClick={() => handleReadNotification(n.id, n.route)}
          >
            <Space direction='vertical' size={4}>
              <Typography.Text type='secondary' className='notification-text'>
                {moment
                  .utc(n.createdDate)
                  .local()
                  .format(Constants.dateTimeFormat)}
              </Typography.Text>

              <Typography.Text strong={!n.isRead}>
                <Badge dot={!n.isRead} className='notification-badge' />
                {n.message}
              </Typography.Text>
            </Space>
          </Link>
          <CloseOutlined
            onClick={() => {
              handleClearNotification(n.id);
            }}
            className='notification-close-icon'
          />
        </>
      ),
    })),
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} key={unreadCount}>
      <Badge
        size='small'
        count={unreadCount}
        overflowCount={MAX_LIST}
        className='notification-item'
      >
        <Button
          type='text'
          className='notification-bell-icon'
          icon={<BellFilled />}
        />
      </Badge>
    </Dropdown>
  );
};

export default NotificationBell;
