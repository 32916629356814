import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useActiveKey = (): string => {
  const [activeKey, setActiveKey] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/leads")) {
      const pipelineId = new URLSearchParams(location.search).get("pipelineId");
      setActiveKey(pipelineId ? `lead-pipeline-${pipelineId}` : "leads");
    } else if (location.pathname.includes("/claimants")) {
      const pipelineId = new URLSearchParams(location.search).get("pipelineId");
      setActiveKey(
        pipelineId ? `claimant-pipeline-${pipelineId}` : "claimants"
      );
    } else if (location.pathname === "/globaltaskscreen") {
      setActiveKey("tasks");
    } else if (location.pathname === "/casemanagement") {
      setActiveKey("case-management");
    } else {
      setActiveKey("");
    }
  }, [location]);

  return activeKey;
};

export default useActiveKey;
