import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Alert, Col, Collapse, List, Progress, Row } from "antd";
import axios from "axios";
import Loader from "../../components/Loader";
import { GetApplicationDetailsById } from "../../apis/applcation";
import { green } from "@ant-design/colors";
import FieldItem from "./FieldItem";
import { CheckCircleFilled } from "@ant-design/icons";
import { getTemplateCount, getWorkHistory } from "../../actions/workHistory";
import { getChildrenInformation } from "../../actions/childrenInformation";
import { getMarriageInfo } from "../../actions/marriageInfo";
import { getCollegeHistory } from "../../actions/collegeHistory";
import { getEducationHistory } from "../../actions/highSchoolInfo";
import { getMedicalHistory } from "../../actions/doctorsVisits";
import { getEmergencyContact } from "../../actions/emergencyContact";
import { getMedicationDetails } from "../../actions/medicationDetails";
import { FlashMessage } from "../../utils/flash_message";
import {
  ApplicationValues,
  buildInitialApplication,
  getApplicationFields,
} from "../../utils/shared";

export const isFieldComplete = (field: any, values: any) => {
  let fieldVal = values[field.databaseKey];

  if (field.parentDatabaseKey) {
    const parentFieldValue = values[field.parentDatabaseKey] || [];
    fieldVal = (parentFieldValue[field.childIndex] || {})[field.databaseKey];
  }

  if (field.parentFieldType === "GeneratedList") {
    const parentFieldValue = values[field.databaseKey] || {};
    fieldVal = parentFieldValue[field.label];
  }

  if (field.type === "ConditionsInput" && (fieldVal || []).length === 0) {
    return false;
  }
  if (field.type === "ListManager" && (fieldVal || []).length === 0) {
    return false;
  }

  if (
    fieldVal === null ||
    fieldVal === undefined ||
    fieldVal === "" ||
    Number.isNaN(fieldVal)
  ) {
    return false;
  }
  return true;
};

export const calculateSectionProgress = ({
  section,
  values,
}: {
  section: any;
  values: any;
}) => {
  const fields_to_count = section.fields
    .filter((field: any) => shouldDisplayField(field, values))
    .flatMap((field: any) => flatMapFields(field, values))
    .filter((field: any) => shouldDisplayField(field, values));

  const question_count = fields_to_count.length;

  const completed = fields_to_count.filter(
    (field: any) => values[field.databaseKey] != null
  ).length;

  return {
    question_count,
    completed,
    progress: Math.round((completed / question_count) * 100),
  };
};

export const flatMapFields = (field: any, values: any) => {
  if (field.type === "ListManager") {
    // iterate over number of values in the list
    const currentList = values[field.databaseKey] || ([] as any[]);
    if (currentList.length === 0) {
      return field.children.map((childField: any) => ({
        ...childField,
        childIndex: 0,
        parentDatabaseKey: field.databaseKey,
      }));
    }
    return currentList.flatMap((_listItem: any, listItemIndex: number) => {
      return [
        ...field.children.map((child: any) => ({
          ...child,
          childIndex: listItemIndex,
          parentDatabaseKey: field.databaseKey,
        })),
      ];
    });
  }
  if (field.type === "GeneratedList") {
    // return [...field.renderListFields(values)];
  }
  return field;
};

const shouldDisplayField = (field: any, formValues: any) => {
  if (field.conditionalFn) {
    return field.conditionalFn(formValues);
  }

  return (
    field.displayIf !== false && // Ensure it's not explicitly hidden
    ((!field.displayIf && !field.hideIf) || // Show if neither condition exists
      formValues[field.displayIf] || // Show if displayIf condition is met
      (field.hideIf && formValues[field.hideIf] === false)) // Show if hideIf condition is explicitly false
  );
};

const SectionProgressIndicator = ({
  progress,
  fieldCount,
}: {
  progress: number;
  fieldCount: number;
}) => {
  return (
    <div
      style={{
        width: "200px",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {progress < 100 ? (
        <Progress
          className='self-serve-section-progress'
          percent={progress}
          steps={fieldCount}
          size='small'
          trailColor='#A9A9A9'
          strokeColor={green["6"]}
          style={{
            textAlign: "right",
          }}
        />
      ) : (
        <CheckCircleFilled
          style={{
            color: "#77d4b1",
          }}
        />
      )}
    </div>
  );
};

async function fetchTemplates(
  fetchFunction: (id: string) => Promise<{ data: { responseData: any[] } }>,
  id: string
) {
  const {
    data: { responseData = [] },
  } = await fetchFunction(id);
  return responseData;
}

const SSAForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [applicationFields, setApplicationFields] = useState<any[]>([]);
  const [values, setValues] = useState<any>({});
  const [applicationProgress, setApplicationProgress] = useState(0);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [datasets, setDatasets] = useState<{
    [key: string]: boolean;
  }>({
    user: true,
  });

  const getUserDetails = async () => {
    var encryptedId = searchParams.get("id") ?? "0";

    const app_response = await GetApplicationDetailsById(encryptedId);

    setUserDetails(app_response.data.responseData);
  };

  const loadFormData = async () => {
    try {
      let loaded_datasets: {
        [key: string]: boolean;
      } = {};

      loaded_datasets["user"] = true;

      const intake_response = await axios(
        `/IntakeQuestionnaire?claimantMindsetUserId=${userDetails.id}`
      ).catch((err) => {
        // will throw error if no intake form is found
      });

      let intakeDetails = {};

      if (((intake_response || {}).data || {}).responseData) {
        intakeDetails = (intake_response || {}).data.responseData;
        loaded_datasets["intake"] = true;
      }

      const ssa_response = await axios(
        `/GetByClaimantId?claimantId=${userDetails.id}`
      );

      let ssa_details = {};

      if (ssa_response.data.responseData) {
        ssa_details = JSON.parse(ssa_response.data.responseData.json || "{}");
        loaded_datasets["ssa"] = true;
      }

      const {
        data: { responseData: template_count },
      } = await getTemplateCount(userDetails.id);

      let children_templates = [],
        marriage_templates = [],
        college_templates = [],
        education_templates = [],
        doctor_templates = [],
        contact_templates = [],
        medication_templates = [],
        work_templates = [];

      if (template_count.childrenInfoCount) {
        children_templates = await fetchTemplates(
          getChildrenInformation,
          userDetails.id
        );
      }

      if (template_count.marriageInfoCount) {
        marriage_templates = await fetchTemplates(
          getMarriageInfo,
          userDetails.id
        );
      }

      if (template_count.collegeHistoryCount) {
        college_templates = await fetchTemplates(
          getCollegeHistory,
          userDetails.id
        );
      }

      if (template_count.highSchoolInfoCount) {
        education_templates = await fetchTemplates(
          getEducationHistory,
          userDetails.id
        );
      }

      if (template_count.doctorVisitsCount) {
        doctor_templates = await fetchTemplates(
          getMedicalHistory,
          userDetails.id
        );
      }

      if (template_count.emergencyContactCount) {
        contact_templates = await fetchTemplates(
          getEmergencyContact,
          userDetails.id
        );
      }

      if (template_count.medicationDetailsCount) {
        medication_templates = await fetchTemplates(
          getMedicationDetails,
          userDetails.id
        );
      }

      if (template_count.workHistoryCount) {
        work_templates = await fetchTemplates(getWorkHistory, userDetails.id);
      }

      const shared_values = {
        ...buildInitialApplication(userDetails, intakeDetails, {
          children: children_templates,
          marriage: marriage_templates,
          college: college_templates,
          education: education_templates,
          medications: medication_templates,
          emergency: contact_templates,
          work: work_templates,
          doctors: doctor_templates,
        }),
        ...ssa_details,
        gender: userDetails.gender,
      };

      console.log(shared_values);

      setApplicationFields(
        getApplicationFields(shared_values as ApplicationValues)
      );

      setDatasets(loaded_datasets);
      setValues(shared_values);
      setIsLoading(false);
    } catch (err) {
      FlashMessage("error", "Error loading application details.");
      console.error(err);
    }
  };

  const updateFormData = async (newSingleValue: any) => {
    try {
      const payload = {
        ...values,
        ...newSingleValue,
      };

      const response = await axios.post(`/PostByClaimantId`, {
        ClaimantMindsetUserId: userDetails.id,
        Json: JSON.stringify(payload),
      });

      setValues(JSON.parse(response.data.responseData.json || "{}"));
      FlashMessage("success", "Field saved successfully.");
    } catch (err) {
      FlashMessage("error", "Error saving field.");
      console.error(err);
    }
  };

  const calculateProgress = () => {
    const question_count = applicationFields.reduce(
      (count, section) =>
        count +
        section.fields
          .filter((field: any) => shouldDisplayField(field, values))
          .flatMap((field: any) => flatMapFields(field, values)).length,
      0
    );

    const completed = applicationFields.reduce((count, section) => {
      return (
        count +
        section.fields
          .filter((field: any) => shouldDisplayField(field, values))
          .flatMap((field: any) => flatMapFields(field, values))
          .filter((field: any) => isFieldComplete(field, values)).length
      );
    }, 0);

    const progress = Math.round((completed / question_count) * 100);
    setApplicationProgress(progress);
  };

  useEffect(() => {
    if (applicationFields && values) {
      calculateProgress();
    }
  }, [values]);

  useEffect(() => {
    if (userDetails) {
      loadFormData();
    }
  }, [userDetails]);

  useEffect(() => {
    if (searchParams.get("id")) {
      getUserDetails();
    }
  }, [searchParams.get("id")]);

  return (
    (!isLoading && (
      <section id='MindsetAdminTemplates' className='mt-2'>
        <Row gutter={16} className='mb-4'>
          <Col span={8}>
            <Alert
              message='User Details'
              description={
                datasets.user
                  ? "User details have been loaded."
                  : "User details are not available."
              }
              type={datasets.user ? "success" : "warning"}
            />
          </Col>
          <Col span={8}>
            <Alert
              message='Intake'
              description={
                datasets.intake
                  ? "Intake has been loaded."
                  : "Intake has not been started"
              }
              type={datasets.intake ? "success" : "warning"}
            />
          </Col>
          <Col span={8}>
            <Alert
              message='Self-Serve'
              description={
                datasets.ssa
                  ? "Self-serve application has been started."
                  : "Self-serve has not been started."
              }
              type={datasets.ssa ? "success" : "warning"}
            />
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col span={24}>
            <Progress percent={applicationProgress} strokeColor={green["6"]} />
          </Col>
        </Row>

        <Collapse>
          {applicationFields.map((section: any, index: number) => {
            let fields: any[] = section.fields.filter((field: any) =>
              shouldDisplayField(field, values)
            );
            return (
              <Collapse.Panel
                header={index + 1 + ") " + section.title}
                key={section.title + section.fields.length}
                extra={
                  <SectionProgressIndicator
                    progress={Math.round(
                      (fields.filter((field: any) =>
                        isFieldComplete(field, values)
                      ).length /
                        fields.length) *
                        100
                    )}
                    fieldCount={fields.length}
                  />
                }
              >
                <List
                  itemLayout='horizontal'
                  dataSource={fields}
                  renderItem={(field) => (
                    <FieldItem
                      field={field}
                      values={values}
                      onSave={updateFormData}
                    />
                  )}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </section>
    )) || <Loader />
  );
};

export default SSAForm;
