import { DatePicker, Input, InputNumber, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Constants } from "../../../common/constants";
import MedicationsInput from "../../MedicationsInput";

export const ScreeningFieldEdit = ({
  field,
  defaultValue,
  onChange,
}: {
  field: any;
  defaultValue: any;
  onChange: (val: any) => void;
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    onChange(value);
  }, [value]);

  if (field.type === "boolean") {
    return (
      <Radio.Group
        size='small'
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
      >
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    );
  } else if (field.type === "radio") {
    return (
      <Radio.Group
        size='small'
        onChange={(e) => {
          setValue(e.target.value === field.trueOption);
        }}
        value={
          value === true
            ? field.trueOption
            : value === false
            ? field.options[1]
            : ""
        }
      >
        {field.options.map((option: string) => (
          <Radio value={option}>{option}</Radio>
        ))}
      </Radio.Group>
    );
  } else if (field.type === "string") {
    return (
      <Input
        value={value}
        size='small'
        onChange={(e) => setValue(e.target.value)}
        placeholder={field.placeholder}
      />
    );
  } else if (field.type === "date") {
    return (
      <DatePicker
        size='small'
        value={value ? dayjs(value) : null}
        format='MM/DD/YYYY'
        onChange={(date) => setValue(dayjs(date).utc().format())}
      />
    );
  } else if (field.type === "select") {
    return (
      <Select
        size='small'
        style={{
          minWidth: "180px",
        }}
        placeholder='Select One'
        defaultValue={defaultValue}
        onChange={(val) => setValue(val)}
        options={field.options.map((option: string) => ({
          label: option,
          value: option,
        }))}
      />
    );
  } else if (field.type === "number") {
    return (
      <InputNumber
        size='small'
        style={{
          minWidth: "180px",
        }}
        placeholder='1'
        defaultValue={defaultValue}
        onChange={(val) => setValue(val)}
      />
    );
  } else if (field.type === "string_array") {
    return (
      <Select
        size='small'
        mode='multiple'
        allowClear
        style={{
          minWidth: "320px",
        }}
        placeholder='Select'
        defaultValue={defaultValue}
        disabled={(field.options || []).length === 0}
        onChange={(val) => setValue(val)}
        options={(field.options || []).map((option: string) => ({
          label: option,
          value: option,
        }))}
      />
    );
  } else if (field.type === "textarea") {
    return (
      <Input.TextArea
        size='small'
        style={{
          minWidth: "320px",
        }}
        placeholder='Enter information here...'
        defaultValue={defaultValue}
        onChange={(ev) => setValue(ev.target.value)}
      />
    );
  } else if (field.type === "dollar") {
    return (
      <InputNumber<number>
        size='small'
        defaultValue={defaultValue}
        formatter={(value) =>
          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) =>
          value?.replace(/\$\s?|(,*)/g, "") as unknown as number
        }
        style={{
          minWidth: "180px",
        }}
        onChange={(val) => setValue(val)}
      />
    );
  } else if (field.type === "medications_input") {
    return (
      <MedicationsInput
        defaultValue={defaultValue}
        onChange={(val) => setValue(val)}
      />
    );
  }

  return (
    <div>
      <p>Add Editing Support: {field.type}</p>
    </div>
  );
};
