import { useState } from "react";
import { Select, Spin } from "antd";
import axios from "axios";

const fetchMedications = async (term: string) => {
  if (!term) return [];
  try {
    const { data } = await axios.get(
      `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?sf=DISPLAY_NAME&df=DISPLAY_NAME&terms=${term}`
    );
    return data[1] || [];
  } catch (error) {
    return [];
  }
};

const MedicationsInput = ({
  defaultValue,
  onChange,
}: {
  defaultValue: string[];
  onChange: (val: string[]) => void;
}) => {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  const handleSearch = async (value: string) => {
    setFetching(true);
    const meds = await fetchMedications(value);
    setOptions(meds.map((med: string) => ({ value: med, label: med })));
    setFetching(false);
  };

  return (
    <Select
      mode='multiple'
      showSearch
      labelInValue
      placeholder='Search medications'
      notFoundContent={fetching ? <Spin size='small' /> : null}
      onSearch={handleSearch}
      defaultValue={defaultValue}
      onChange={(val) => onChange(val.map((v: any) => v.value))}
      filterOption={false}
      options={options}
      style={{ minWidth: "300px" }}
    />
  );
};

export default MedicationsInput;
