import { Image, Tooltip } from "antd";
import mindset_ai from "../../images/mindset-ai.png";
import { theme } from "antd";
import "./styles.scss";

const { useToken } = theme;

const MindsetAILogo = () => {
  const { token } = useToken();

  return (
    <Tooltip
      title='This was generated by Mindset AI and your feedback will help improve it over time. Let the team know if you find it useful or see problems.'
      trigger='hover'
      placement='left'
      color={token.colorInfo}
    >
      <Image
        src={mindset_ai}
        alt='Mindset AI'
        className='ai-image'
        preview={false}
        style={{ cursor: "pointer" }}
      />
    </Tooltip>
  );
};

export default MindsetAILogo;
